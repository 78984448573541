import * as React from 'react';
import DashboardChart, { SeriesData } from './DashboardChart';
import { DateTime } from 'luxon';
import { DashboardType, getDashboardDisplayName } from '../../../enums/Dashboards';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getDashboardData } from '../../../api/dashboardApi';
import { loadFromApi } from '../../../api/baseApi';
import { DashboardData } from '../../../types/dashboard';
import LoadingDisplay from '../LoadingDisplay';
import DashboardLoadFailedDisplay from './DashboardLoadFailedDisplay';
import { DateTime as LuxonDateTime } from 'luxon';
import { CompanyName } from '../../../types/common';

const includesIndustryData = (type: DashboardType) => {
    switch (type) {
        case DashboardType.DraftReturnRate:
        case DashboardType.RefundAverageAmount:
        case DashboardType.IdleConsumersRate:
        case DashboardType.ConsumerClosureRate:
        case DashboardType.ConsumerClosureSettlementRate:
        case DashboardType.SettlementsRate:
        case DashboardType.SettlementsAverageAmount:
        case DashboardType.PaidPerConsumer:
        case DashboardType.EnrollmentCount:
        case DashboardType.EnrollmentDraftRate:
            return true;
        default:
            return false;
    }
};
interface Props {
    coName: CompanyName;
    dashboardType: DashboardType;
    dateRange: {
        start: LuxonDateTime;
        end: LuxonDateTime;
    };
}

const StandardDashboard = (props: Props) => {
    const [seriesData, setSeriesData] = useState<SeriesData[]>();
    const [industryData, setIndustryData] = useState<SeriesData[]>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const hasIndustryData = includesIndustryData(props.dashboardType);

    useEffect(() => {
        const dashboardApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );

        const getFormattedData = (data: DashboardData[]): SeriesData[] => {                      
            return data.map(d => ({
                value: d.value,
                date: DateTime.fromISO(d.date)
            }))        
        };

        const loadDashboardSelections = async () => {
            setLoading(true);
            const dataResponse = dashboardApiWrapper(
                () =>
                    getDashboardData(
                        props.dashboardType,
                        props.dateRange.start.toISO(),
                        props.dateRange.end.toISO(),
                        false
                    ),
                data => setSeriesData(getFormattedData(data))
            );

            let industryResponse: Promise<any> | null = null;
            if (hasIndustryData) {
                industryResponse = dashboardApiWrapper(
                    () =>
                        getDashboardData(
                            props.dashboardType,
                            props.dateRange.start.toISO(),
                            props.dateRange.end.toISO(),
                            true
                        ),
                    data => setIndustryData(getFormattedData(data))
                );
            }

            await dataResponse;
            await industryResponse;

            setLoading(false);
            
        };

        loadDashboardSelections();
    }, [addToast, props.dashboardType, props.dateRange, hasIndustryData]);

    const sideBarOpen = localStorage.getItem('sideBarOpen') ?? 'false';
    let [key, setKey] = useState(0)
    useEffect(() => {
        setKey(key+1);
    }, [sideBarOpen])

    return loading ? (
        <LoadingDisplay />
    ) : (!seriesData || Number(seriesData.length) === 0) ? (
        <DashboardLoadFailedDisplay dashboardType={props.dashboardType} />
    ) : (
        <DashboardChart key={key}
            title={getDashboardDisplayName(props.dashboardType)}
            dataSeriesName={props.coName.name}
            data={seriesData}
            industryAverageData={industryData}            
        />
    );
};

export default StandardDashboard;
