import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { SettlementEdit } from '../../../../../types/settlements';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { validateNullOrPhone } from '../../../../../utils/validationRules';
import { phoneMask } from '../../../../../utils/masks';

export interface Props {
    viewOnly: boolean;
}

const PayByPhoneDetailsInputs = (props: Props) => {
    const { register } = useFormContext<SettlementEdit>();

    return (
        <div className="row">
            <div className="col-6">
                <WrappedInput<SettlementEdit>
                    inputName="payByPhoneDetails.payeePhone"
                    labelText="Payee Phone Number"
                >
                    <MaskedTextBoxComponent
                        readonly={props.viewOnly}
                        {...register('payByPhoneDetails.payeePhone', {
                            validate: validateNullOrPhone,
                            setValueAs: v => (v || '').replace(/\D/g, '')
                        })}
                        mask={phoneMask}
                    />
                </WrappedInput>
                <WrappedInput<SettlementEdit>
                    inputName="payByPhoneDetails.prepaid"
                    labelText="Pre-paid"
                    helpText="Only check this if the payment has already been called in."
                >
                    <input
                        type="checkbox"
                        readOnly={props.viewOnly}
                        {...register('payByPhoneDetails.prepaid')}
                    />
                </WrappedInput>
            </div>
            <div className="col-6">
                <WrappedInput<SettlementEdit>
                    inputName="payByPhoneDetails.payeePayByPhoneContact"
                    labelText="Payee Pay By Phone Contact"
                >
                    <input
                        readOnly={props.viewOnly}
                        {...register('payByPhoneDetails.payeePayByPhoneContact')}
                    />
                </WrappedInput>
            </div>
        </div>
    );
};

export default PayByPhoneDetailsInputs;
