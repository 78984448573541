import { Payee } from '../types/common';
import {
    Consumer,
    ConsumerNote,
    ConsumerContactInfo,
    ConsumerCreate,
    ConsumerUpdate,
    ConsumerHelpDeskTicket,
    ConsumerSettlementSummary
} from '../types/consumer';
import { Draft, DraftUpdate, DraftBulkUpdate, Schedule, ScheduleUpdate } from '../types/drafts';
import { FreeRefund, LedgerEntry, SimpleLedgerEntry } from '../types/ledgers';
import { Creditor, CreditorUpdate, Settlement, SettlementUpdate, SettlementAdvance } from '../types/settlements';
import { removeSpaces, sanitizeFileName } from '../utils/stringUtils';
import { downloadFile, httpRequest, Urls } from './baseApi';

const CONSUMER_URL = `${Urls.BASE_URL}/consumers`;
export const consumerUrl = (consumerId: number) => `${CONSUMER_URL}/${consumerId}`;
/**************** General ****************/

export const getConsumer = (consumerId: number) =>
    httpRequest<Consumer>(`${consumerUrl(consumerId)}`, 'get');

export const getConsumers = (searchText: string) =>
    httpRequest<Consumer[]>(`${CONSUMER_URL}?searchText=${searchText}`, 'get');        

export const getConsumerNotes = (consumerId: number) =>
    httpRequest<ConsumerNote[]>(`${consumerUrl(consumerId)}/notes`, 'get');

export const getConsumerSettlementSummary = (consumerId: number) =>
    httpRequest<ConsumerSettlementSummary>(`${consumerUrl(consumerId)}/settlementSummary`, 'get');

export const resetConsumerPassword = (consumerId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/resetPassword`, 'post');

export const getConsumerStatement = (consumerId: number, consumerName: string) =>
    downloadFile(
        `${consumerUrl(consumerId)}/statement`,
        sanitizeFileName(`${consumerId}_${removeSpaces(consumerName)}_Statement.pdf`)
    );

/**************** Contact Info ****************/

export const updateConsumerContactInfo = (consumerId: number, contactInfo: ConsumerContactInfo) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/contactInfo`, 'put', contactInfo);

export const addConsumer = (newConsumer: ConsumerCreate) =>
{
    console.log(newConsumer, "AddConsumerParam");
    if(newConsumer.excludeFromRepay == null)
    {
        newConsumer.excludeFromRepay = false;
    }

    if(newConsumer.mailedStatementOnly == null)
    {
        newConsumer.mailedStatementOnly = false;
    }

    if(newConsumer.accountStatus == null)
    {
        newConsumer.accountStatus = 21; //default to hold if not defined
    }

    if(newConsumer.achStatus == null)
    {
        newConsumer.achStatus = 0;
    }

    if(newConsumer.contactInfo.secondary?.address?.state == null)
    {
        if(newConsumer.contactInfo.secondary != null)
        {
            if(newConsumer.contactInfo.secondary.address != null)
            {
                newConsumer.contactInfo.secondary.address.state = "";
            }
        }
        
    }
    console.log(newConsumer, "AdjustedNewConsumer");
    return httpRequest<Consumer>(CONSUMER_URL, 'post', newConsumer);
}

/**************** Drafts ****************/

export const getConsumerDrafts = (consumerId: number) =>
    httpRequest<Draft[]>(`${consumerUrl(consumerId)}/drafts`, 'get');

export const getConsumerFees = (consumerId: number) =>
    httpRequest<any>(`${consumerUrl(consumerId)}/fees`, 'get');

export const getConsumeForecasts = (consumerId: number) =>
    httpRequest<any>(`${consumerUrl(consumerId)}/forecast`, 'get');

export const getConsumerFeeTypes = (consumerId: number) =>
    httpRequest<any>(`${consumerUrl(consumerId)}/feesTypes`, 'get');

export const getVendorPayeeNames = () =>
    httpRequest<any>(`${CONSUMER_URL}/payeeTypes`, 'get');

export const addConsumerFees = (consumerId: number, fees: any) =>
    httpRequest<any>(`${consumerUrl(consumerId)}/fees`, 'post', fees);

export const deleteConsumerFees = (consumerId: number, feeIds: any) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/fees`, 'delete', feeIds);

export const extendProgram = (consumerId: number, fees: any) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/payments/extendPaymentSchedule`, 'post', fees);

export const moveProgram = (consumerId: number,draftId: number,  fees: any) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/payments/${draftId}/movePaymentSchedule`, 'put', fees);

export const addConsumerDraft = (consumerId: number, draft: DraftUpdate) =>
    httpRequest<Draft>(`${consumerUrl(consumerId)}/drafts`, 'post', draft);

export const updateConsumerDraft = (consumerId: number, draft: DraftUpdate,draftId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/drafts/${draftId}`, 'put', draft);

export const deleteConsumerDraft = (consumerId: number, draftId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/drafts/${draftId}`, 'delete');

export const refundConsumerDraft = (consumerId: number, paymentId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/payments/${paymentId}/refund`, 'put');

export const bulkUpdateDrafts = (consumerId: number, draftBulkUpdate: DraftBulkUpdate) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/drafts`, 'put', draftBulkUpdate);

export const getConsumerSchedule = (consumerId: number) =>
    httpRequest<Schedule>(`${consumerUrl(consumerId)}/schedule`, 'get');

export const updateConsumerSchedule = (consumerId: number, scheduleUpdate: ScheduleUpdate) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/schedule`, 'put', scheduleUpdate);

export const deleteConsumerScheduledDrafts = (consumerId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/drafts/deleteScheduled`, 'post');

/**************** Settlements ****************/
export const getConsumerSettlements = (consumerId: number) =>
    httpRequest<Settlement[]>(`${consumerUrl(consumerId)}/settlements`, 'get');

export const deleteConsumerSettlement = (consumerId: number, settlementId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/settlements/${settlementId}`, 'delete');

export const addSettlement = (consumerId: number, settlement: SettlementUpdate) =>
    httpRequest<Settlement[]>(`${consumerUrl(consumerId)}/settlements`, 'post', settlement);

export const updateSettlement = (
    consumerId: number,
    settlementId: number,
    settlement: SettlementUpdate
) => httpRequest<{}>(`${consumerUrl(consumerId)}/settlements/${settlementId}`, 'put', settlement);

export const advanceConsumerSettlement = (consumerId: number, settlementId: number) =>
    httpRequest<SettlementAdvance>(
        `${consumerUrl(consumerId)}/settlements/${settlementId}/advance`,
        'post'
    );

export const voidConsumerSettlement = (consumerId: number, settlementId: number) =>
    httpRequest<Settlement>(`${consumerUrl(consumerId)}/settlements/${settlementId}/void`, 'post');

    export const getSettlementCheckImagesUrl = (consumerId: number, settlementIds: number[]) =>
    downloadFile(
        `${consumerUrl(consumerId)}/settlements/checks`,
        sanitizeFileName(`${consumerId}_Checks.pdf`), 'post', settlementIds
    );

/**************** Creditors ****************/
export const getCreditors = (searchText: string) =>
    httpRequest<Creditor[]>(`${Urls.BASE_URL}/PayeeInfo/?searchTerm=${searchText}`, 'get');

export const saveCreditorsAddress = (data: any) =>
    httpRequest<Creditor[]>(`${Urls.BASE_URL}/PayeeInfo`, 'post', data);

export const addCreditor = (creditorUpdate: CreditorUpdate) =>
    httpRequest<Creditor[]>(`${Urls.BASE_URL}/creditors`, 'post', creditorUpdate);

export const updateCreditor = (creditorId: number, creditorUpdate: CreditorUpdate) =>
    httpRequest<Creditor[]>(`${Urls.BASE_URL}/creditors/${creditorId}`, 'put', creditorUpdate);

/**************** Ledgers ****************/
export const getFullLedger = (consumerId: number, endDate: string, payee?: string) =>
    httpRequest<LedgerEntry[]>(
        `${consumerUrl(consumerId)}/ledger?endDate=${endDate}${
            payee && payee !== '0' ? `&payee=${payee}` : ''
        }`,
        'get'
    );

export const getSimpleLedger = (consumerId: number, endDate: string) =>
    httpRequest<SimpleLedgerEntry[]>(
        `${consumerUrl(consumerId)}/ledger/simple?endDate=${endDate}`,
        'get'
    );

export const getRefundSources = (consumerId: number) =>
    httpRequest<Payee[]>(`${consumerUrl(consumerId)}/ledger/refundSources`, 'get');

export const applyFreeRefund = (consumerId: number, refund: FreeRefund) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/ledger/refund`, 'post', refund);

export const getLedgerPayees = (consumerId: number) =>
    httpRequest<Payee[]>(`${consumerUrl(consumerId)}/ledger/payees`, 'get');

//refund from here is always back to trust, future state could allow direct to consumer by changing final routing
export const refundLedgerItem = (consumerId: number, transactionId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/ledger/${transactionId}/refund/0`, 'post');

export const getLedgerFile = (
    consumerId: number,
    isExcel: boolean,
    isFull: boolean,
    isSimple: boolean
) =>
    downloadFile(
        `${consumerUrl(
            consumerId
        )}/ledger/file?isExcel=${isExcel}&isFull=${isFull}&isSimple=${isSimple}`,
        sanitizeFileName(`${consumerId}_${isSimple ? 'Client' : 'Full'}_Ledger.${isExcel ? 'xlsx' : 'pdf'}`),
        'post'
    );
/**************** Account Setup ****************/
export const updateConsumer = (consumerId: number, consumer: ConsumerUpdate) =>
    httpRequest<Creditor[]>(`${consumerUrl(consumerId)}`, 'put', consumer);

/**************** Support Tickets ****************/
export const getConsumerSupportTickets = (consumerId: number) =>
    httpRequest<ConsumerHelpDeskTicket[]>(`${consumerUrl(consumerId)}/helpDesk`, 'get');

export const addConsumerSupportTicket = (consumerId: number, ticketDescription: string) =>
    httpRequest<ConsumerHelpDeskTicket[]>(
        `${consumerUrl(consumerId)}/helpDesk`,
        'post',
        ticketDescription
    );
