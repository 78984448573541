export interface Page {
    name: string;
    extension: string;
}

export const topLevelPages: {
    home: Page;
    userSettings: Page;
    checkImages: Page;
    resetPassword: Page;
} = {
    home: {
        name: 'Home',
        extension: ''
    },
    userSettings: {
        name:'userSettings',
        extension: 'userSettings'
    },
    checkImages: {
        name:'checkImages',
        extension: 'checkImages'
    },
    resetPassword: {
        name:'resetPassword',
        extension: 'resetPassword'
    }
};

export interface ConsumerParams {
    consumerId: string;
}

export const consumerPages: {
    addConsumer: Page;
    consumerSearch: Page;
    consumer: Page;
} = {
    addConsumer: {
        name: 'Add Consumer',
        extension: 'add'
    },
    consumerSearch: {
        name: 'Consumer Search',
        extension: 'search'
    },
    consumer: {
        name: 'Consumer',
        extension: ':consumerId'
    }
};

export const consumerSubPages: {
    contactInfo: Page;
    drafts: Page;
    fees: Page;
    forecast: Page;
    schedule: Page;
    bankingInfo: Page;
    settlementInfo: Page;
    ledger: Page;
    accountSetup: Page;
    supportTickets: Page;
} = {
    contactInfo: {
        name: 'Contact Info',
        extension: '/contactInfo'
    },
    drafts: {
        name: 'Drafts',
        extension: '/drafts'
    },
    fees: {
        name: 'Fees',
        extension: '/fees'
    },
    forecast: {
        name: 'Forecast',
        extension: '/forecast'
    },
    schedule: {
       name: 'Schedule',
        extension: '/schedule'
    },
    bankingInfo: {
        name: 'Banking Info',
        extension: '/bankingInfo'
    },
    settlementInfo: {
        name: 'Settlement Info',
        extension: '/settlementInfo'
    },
    ledger: {
        name: 'Ledger',
        extension: '/ledger'
    },
    accountSetup: {
        name: 'Account Setup',
        extension: '/accountSetup'
    },
    supportTickets: {
        name: 'Support Tickets',
        extension: '/supportTickets'
    }
};

export const reportingPages: {
    reportConfiguration: Page;
    report: Page;
} = {
    reportConfiguration: { name: 'Report Configuration', extension: 'configuration' },
    report: { name: 'Report Run', extension: 'run' }
};

export const dashboardPages: {
    enrollmentTracking: Page;
    draftPerformance: Page;
    refunds: Page;
    idleConsumers: Page;
    consumerClosure: Page;
    companyRevenue: Page;
    settlements: Page;
} = {
    enrollmentTracking: {
        name: 'Enrollment Tracking',
        extension: 'enrollmentTracking'
    },
    draftPerformance: {
        name: 'Draft Performance',
        extension: 'draftPerformance'
    },
    refunds: {
        name: 'Refunds',
        extension: 'refunds'
    },
    idleConsumers: {
        name: 'Idle Consumers',
        extension: 'idleConsumers'
    },
    consumerClosure: {
        name: 'Consumer Closure',
        extension: 'consumerClosure'
    },
    settlements: {
        name: 'Settlements',
        extension: 'settlements'
    },
    companyRevenue: {
        name: 'Company Revenue',
        extension: 'companyRevenue'
    }
};

export const companyPages: {
    companyInformation: Page;
    companyUsers: Page;
    affiliates: Page;
    feeSplit: Page;
    checkLookup: Page;
    shortSettlements: Page;
} = {
    companyInformation: {
        name: 'Company Information',
        extension: 'information'
    },
    companyUsers: {
        name: 'Company Users',
        extension: 'users'
    },
    affiliates: {
        name: 'Affiliates',
        extension: 'affiliates'
    },
    feeSplit: {
        name: 'Fee Splits',
        extension: 'feeSplit'
    },
    checkLookup: {
        name: 'Check Lookup',
        extension: 'checkLookup'
    },
    shortSettlements: {
        name: 'Short Settlements',
        extension: 'shortSettlements'
    }
};

export interface Section {
    name: string;
    extension: string;
    navigationPages?: Page[];
}

const consumerNavigationPages = [consumerPages.addConsumer, consumerPages.consumerSearch];
export const consumerSection: Section = {
    name: 'Consumers',
    extension: 'consumer',
    navigationPages: consumerNavigationPages
};

export const reportingSection: Section = {
    name: 'Reporting',
    extension: 'reporting'
};

const dashboardSectionPages = [
    dashboardPages.enrollmentTracking,
    dashboardPages.draftPerformance,
    dashboardPages.refunds,
    dashboardPages.idleConsumers,
    dashboardPages.consumerClosure,
    dashboardPages.settlements,
    dashboardPages.companyRevenue
];
export const dashboardSection: Section = {
    name: 'Dashboards',
    extension: 'dashboards',
    navigationPages: dashboardSectionPages
};

const companySectionPageList = [
    companyPages.companyInformation,
    companyPages.companyUsers,
    companyPages.affiliates,
    companyPages.feeSplit,
    companyPages.checkLookup,
    companyPages.shortSettlements
];
export const companySection: Section = {
    name: 'Company',
    extension: 'company',
    navigationPages: companySectionPageList
};

export const getSectionExtension = (page: Page) => {
    if (Object.values(topLevelPages).includes(page)) {
        return undefined;
    }

    if (Object.values(consumerPages).includes(page)) {
        return consumerSection.extension;
    }

    if (Object.values(reportingPages).includes(page)) {
        return reportingSection.extension;
    }

    if (Object.values(dashboardPages).includes(page)) {
        return dashboardSection.extension;
    }

    if (Object.values(companyPages).includes(page)) {
        return companySection.extension;
    }

    return undefined;
};

export const renderUrl = (sectionExtension: string) => (pageExtension: string) =>
    `/${sectionExtension}/${pageExtension}`;

export const pagePath = (page: Page) => {
    const sectionExtension = getSectionExtension(page);
    return sectionExtension ? renderUrl(sectionExtension)(page.extension) : `/${page.extension}`;
};

export const sectionPath = (section: Section) => {
    return `/${section.extension}`;
};

export const consumerRoutingPath = (consumerId: string, subPage: Page) => {
    const sectionExtension = getSectionExtension(consumerPages.consumer);
    return `/${sectionExtension}/${consumerId}${subPage.extension}`;
};

export const dashboardRoutingPath = (subPage: Page) => {
    const sectionExtension = getSectionExtension(dashboardPages.companyRevenue);
    return `/${sectionExtension}/${subPage.extension}`;
};

export const contactUsPageLink = 'https://reliantpayment.com/contact-us/';
