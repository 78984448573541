import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ScheduleEdit, ScheduleFeeEdit } from '../../../../../types/drafts';
import {
    validateCurrency,
    validateDraftDate,
    validatePositive,
    validateInteger,
    validateNullOrGreaterThanZero,
    validateNullOrInteger,
    validateNullOrNonNegative,
    validateNonNegative
} from '../../../../../utils/validationRules';
import DatePicker from '../../../../common/DatePicker';
import WrappedInput from '../../../../common/form/WrappedInput';
import { DateTime } from 'luxon-business-days';

export interface Props {
    index: number;
    defaultValues: ScheduleFeeEdit;
}

const ConsumerScheduleFeeInputs = (props: Props) => {
    const { register, control } = useFormContext<ScheduleEdit>();

    const watchFeeEdits = useWatch({
        control,
        name: 'feeEdits'
    });

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<ScheduleEdit>
                        inputName={`feeEdits[${props.index}].totalAmount`}
                        labelText="Total Amount"
                        helpText={`The total amount to be charged for this fee over the life of the program. 
                                    This amount is divided by the total number of drafts for this fee to derive the per-draft amount for this fee.`}
                        required={true}
                    >
                        {/* If fee group enabled, Required Non-negative currency value */}
                        <input
                            type="number"
                            step="0.01"
                            {...register(`feeEdits.${props.index}.totalAmount`, {
                                required: 'Please enter an amount.',
                                validate: {
                                    positive: validateNonNegative,
                                    currency: validateCurrency
                                },
                                valueAsNumber: true
                            })}
                            defaultValue={props.defaultValues.totalAmount}
                        />
                    </WrappedInput>
                    <WrappedInput<ScheduleEdit>
                        inputName={`feeEdits[${props.index}].startDateTime`}
                        labelText="Start Date"
                        required={true}
                    >
                        {/* If fee group enabled, required date at least one business day in the future */}
                        <Controller
                            name={`feeEdits.${props.index}.startDateTime`}
                            control={control}
                            rules={{
                                required: 'Please select a date.',
                                validate: validateDraftDate
                            }}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    isDateRange={false}
                                    initialStartDate={value}
                                    changeDate={onChange}
                                    minimumDate={DateTime.utc().plusBusiness()}
                                />
                            )}
                            defaultValue={props.defaultValues.startDateTime}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    <WrappedInput<ScheduleEdit>
                        inputName={`feeEdits[${props.index}].draftsCount`}
                        labelText="Number of Drafts"
                        helpText={`The total number of drafts which will include this fee. For example, 36 total fee drafts on a 36-month 
                                    program will charge this fee on every draft. 2 total drafts would result in this fee only being charged on the first two drafts.`}
                        required={true}
                    >
                        {/* If fee group enabled, Required Non-negative integer value with a minumum value of 1*/}
                        <input
                            type="number"
                            step="1"
                            {...register(`feeEdits.${props.index}.draftsCount`, {
                                required: 'Please enter a number.',
                                validate: {
                                    greaterThanZero: validatePositive,
                                    integer: validateInteger
                                },
                                valueAsNumber: true
                            })}
                            defaultValue={props.defaultValues.draftsCount}
                        />
                    </WrappedInput>
                </div>
            </div>
            <WrappedInput<ScheduleEdit>
                inputName={`feeEdits[${props.index}].useFeeWeighting`}
                labelText="Use Fee Weighting"
            >
                <input
                    type="checkbox"
                    {...register(`feeEdits.${props.index}.useFeeWeighting`)}
                    defaultChecked={props.defaultValues.useFeeWeighting}
                />
            </WrappedInput>
            {watchFeeEdits[props.index].useFeeWeighting && (
                <div className="row">
                    <div className="col-6">
                        <WrappedInput<ScheduleEdit>
                            inputName={`feeEdits[${props.index}].feeWeight.weightPercentage`}
                            labelText="Weight Percentage"
                            helpText={`The percentage of this fee to be weighted over the number of drafts you specify. 
                                        For example, weighting a Fee 50% over the first 2 months means that 50% of the total amount for that 
                                        Fee will be charged over the first two drafts for that Fee. The remaining 50% will then be spread over 
                                        the remaining drafts for that Fee. Leave this field 0 or blank to not weight the Fee at all.`}
                        >
                            {/* If fee group and fee weighting enabled, non-negative percentage value */}
                            <input
                                type="number"
                                step="0.01"
                                {...register(`feeEdits.${props.index}.feeWeight.weightPercentage`, {
                                    validate: validateNullOrNonNegative,
                                    valueAsNumber: true
                                })}
                                defaultValue={props.defaultValues.feeWeight?.weightPercentage}
                            />
                        </WrappedInput>
                    </div>
                    <div className="col-6">
                        <WrappedInput<ScheduleEdit>
                            inputName={`feeEdits[${props.index}].feeWeight.weightDrafts`}
                            labelText="Weight Draft Count"
                            helpText={`The total number of drafts over which you want to collect the weighted percentage you specified. 
                                        For example, to weight a Fee 50% over the first 2 drafts, you would type 50 in the Weight Percentage for this 
                                        Fee field and then type 2 in the Weighted Draft Count field.`}
                        >
                            {/* If fee group and fee weighting enabled, non-negative integer value of at least 1 */}
                            <input
                                type="number"
                                step="1"
                                {...register(`feeEdits.${props.index}.feeWeight.weightDrafts`, {
                                    validate: {
                                        greaterThanZero: validateNullOrGreaterThanZero,
                                        integer: validateNullOrInteger
                                    },
                                    valueAsNumber: true
                                })}
                                defaultValue={props.defaultValues.feeWeight?.weightDrafts}
                            />
                        </WrappedInput>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConsumerScheduleFeeInputs;
