import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { CreditorUpdate } from '../../../../../types/settlements';
import StateDropdown from '../../../../common/form/StateDropdown';

const SettlementEditCreditorFormInputs = () => {
    const { register, control } = useFormContext<CreditorUpdate>();
    return (
        <div>
            <WrappedInput<CreditorUpdate> inputName="name" labelText="Name" required={true}>
                <input
                    {...register('name', {
                        required: 'Please enter a name.'
                    })}
                />
            </WrappedInput>
            <WrappedInput<CreditorUpdate>
                inputName="address.streetAddress"
                labelText="Address"
                required={true}
            >
                <input
                    {...register('address.streetAddress', {
                        required: 'Please enter a street address.'
                    })}
                />
            </WrappedInput>
            <WrappedInput<CreditorUpdate> inputName="address.streetAddress2" labelText="Address 2">
                <input {...register('address.streetAddress2')} />
            </WrappedInput>
            <WrappedInput<CreditorUpdate> inputName="address.city" labelText="City" required={true}>
                <input
                    {...register('address.city', {
                        required: 'Please enter a city.'
                    })}
                />
            </WrappedInput>
            <WrappedInput<CreditorUpdate>
                inputName="address.state"
                labelText="State"
                required={true}
            >
                <Controller
                    name="address.state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <StateDropdown
                            required={true}
                            selectedState={value}
                            setSelectedState={onChange}
                            includePayeeTerritories={false}
                        />
                    )}
                />
            </WrappedInput>
            <WrappedInput<CreditorUpdate> inputName="address.zip" labelText="Zip" required={true}>
                <input
                    {...register('address.zip', {
                        required: 'Please enter a zip code.'
                    })}
                />
            </WrappedInput>
            <hr></hr>
            <div className="clear-fix">
                <div className="float-left">
                    <input className="btn btn-primary" type="submit" value="Save" />
                </div>
            </div>
        </div>
    );
};

export default SettlementEditCreditorFormInputs;
