import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ModalProps } from '../../../../../types/common';
import '../../../../../styles/common/ModalDialog.scss';
import '../../../../../styles/views/Consumer.scss';
import { CreditCardView } from '../../../../../types/bankingInformation';
import ViewCreditCard from './ViewCreditCard';
import '../../../../../styles/views/Consumer.scss';

interface Props extends ModalProps {
    consumerCards: CreditCardView[];
    refreshConsumerCards: () => void;
    handleRemoveConsumerCard: (consumerCard: CreditCardView) => Promise<void>;
    viewOnly: boolean;
}

const ViewExistingCardsPopup = (props: Props) => {
    const defaultButtons = [
        {
            buttonModel: {
                content: 'Close'
            },
            click: () => {
                props.setShowDialog(false);
            }
        }
    ];

    return (
        <div>
            <DialogComponent
                header="View Existing Cards"
                isModal={true}
                buttons={defaultButtons}
                target={props.targetId ? '#' + props.targetId : undefined}
                visible={props.showDialog}
                showCloseIcon={true}
                close={() => props.setShowDialog(false)}
                width="800px"
                height="auto"
            >
                <div>
                    <div className="view-existing-cards-popup">
                        {props.consumerCards.map((c, index) => (
                            <ViewCreditCard
                                key={index}
                                consumerCard={c}
                                refreshConsumerCards={props.refreshConsumerCards}
                                handleRemoveConsumerCard={props.handleRemoveConsumerCard}
                                viewOnly={props.viewOnly}
                            />
                        ))}
                    </div>
                </div>
            </DialogComponent>
        </div>
    );
};

export default ViewExistingCardsPopup;
