import { Company, CompanyEvent, CompanySettings, CompanyShortSettlementsRequest, CompanyUpdate } from '../types/company';
import { httpRequest, Urls } from './baseApi';

const COMPANY_URL = `${Urls.BASE_URL}/Company`;

export const getCompany = () => httpRequest<Company>(`${COMPANY_URL}`, 'get');

export const updateCompany = (companyUpdate: CompanyUpdate) =>
    httpRequest<{}>(`${COMPANY_URL}`, 'put', companyUpdate);

export const getCompanyEvents = () => httpRequest<CompanyEvent[]>(`${Urls.BASE_URL}/events`, 'get');

export const getCompanySettings = () =>
    httpRequest<CompanySettings>(`${COMPANY_URL}/settings`, 'get');

export const companyShortSettlements = (settlements: CompanyShortSettlementsRequest) =>
    httpRequest<[]>(`${COMPANY_URL}/companyShortSettlements`, 'post', settlements);


