import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../../types/common';
import AlertBanner from '../../../../common/AlertBanner';
import { DraftsCalculation, ScheduleFee } from '../../../../../types/drafts';
import ConsumerDraftsGrid from '../../drafts/ConsumerDraftsGrid';
import LoadingDisplay from '../../../../common/LoadingDisplay';
import { useContext } from 'react';
import { FeeGroupContext } from '../../ConsumerPage';
import { formatAsCurrency } from '../../../../../utils/stringUtils';
import { feeTotalMatches } from '../../../../../utils/draftUtils';

interface Props extends ModalProps {
    draftsCalculation?: DraftsCalculation;
    targetDraftCount: number;
    targetFees: ScheduleFee[];
    targetMonthlyDraft: number;
    onAcceptClick: () => void;
}

const ConsumerSchedulePreviewModal = (props: Props) => {    
    const feeGroups = useContext(FeeGroupContext);

    return (
        <DialogComponent
            header="Schedule Information"
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
            }}
            width="1500px"
            height="auto"
        >
            <div className="consumer-input-section consumer-schedule-preview">
                {props.showDialog &&
                    (props.draftsCalculation ? (
                        <div>
                            <AlertBanner message="This generates a payment schedule from the information you have provided but does NOT apply the schedule to the customer unless you click the Accept button."></AlertBanner>
                            <div className="row consumer-schedule-summary">
                                <div className="col-6">
                                    <div className="wrapped-info">
                                        <label>Savings:</label>
                                        <span className="value">
                                            {formatAsCurrency(
                                                props.draftsCalculation.totalSavingsAmount
                                            )}
                                        </span>
                                    </div>
                                    {feeGroups.map((group, index) => (
                                        <div
                                            className={`wrapped-info ${
                                                feeTotalMatches(
                                                    props.draftsCalculation?.totalFeeAmounts[index],
                                                    props.targetFees,
                                                    group.id
                                                )
                                                    ? ''
                                                    : 'error-text'
                                            }`}
                                            key={index}
                                        >
                                            <label>{`${group.name}:`}</label>
                                            <span className="value">
                                                {formatAsCurrency(
                                                    props.draftsCalculation
                                                        ? props.draftsCalculation.totalFeeAmounts[
                                                              index
                                                          ]
                                                        : 0
                                                )}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-6">
                                    <div className="wrapped-info">
                                        <label>Total Payments:</label>
                                        <span
                                            className={`value ${
                                                props.draftsCalculation.totalPaymentCount ===
                                                props.targetDraftCount
                                                    ? ''
                                                    : 'error-text'
                                            }`}
                                        >
                                            {props.draftsCalculation.totalPaymentCount}
                                        </span>
                                    </div>
                                    <div className="wrapped-info">
                                        <label>Total Of Payments:</label>
                                        <span className="value">
                                            {formatAsCurrency(
                                                props.draftsCalculation.totalPaymentAmount
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="clear-fix button-bar">
                                <button
                                    className="btn btn-primary float-left"
                                    onClick={props.onAcceptClick}
                                >
                                    Accept Schedule
                                </button>
                            </div>
                            <ConsumerDraftsGrid
                                drafts={props.draftsCalculation.drafts}
                                selectedDrafts={[]}
                                setSelectedDrafts={() => {}}
                                onDraftRefunded={() => {}}
                                isSchedulePreview={true}
                                targetMonthlyDraft={props.targetMonthlyDraft}
                            />
                        </div>
                    ) : (
                        <LoadingDisplay />
                    ))}
            </div>
        </DialogComponent>
    );
};

export default ConsumerSchedulePreviewModal;
