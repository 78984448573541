import { setValue } from '@syncfusion/ej2-base';
import * as React from 'react';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../../authentication/authContext';
import { consumerPages, pagePath } from '../../pages';
import '../../styles/components/TopBar.scss';
import MenuSelect from '../common/MenuSelect';
import SearchBar from '../common/SearchBar';
import menuclosed from '../../assets/images/menuclosed.png';
import menuopened from '../../assets/images/menuopened.png';
import { useState } from 'react';
import logo from '../../assets/images/logo.png';

const TopBar = () => {
    const {  logout, username} = useContext(AuthContext);

    const history = useHistory();
    const onSearch = (searchValue: string) => {
        history.push(`${pagePath(consumerPages.consumerSearch)}?searchText=${searchValue}`);
    };
    
    const isConsumerSearchPage = useRouteMatch(pagePath(consumerPages.consumerSearch));
    const open = localStorage.getItem('sideBarOpen') !== 'false' ? true : false;
    const [sideBarOpen, setSideBarOpen] = useState(open);
    return (
        <div className= "top-bar-container">
            <div className="top-bar-layout">
            <div className="app-logo">
            <img src={sideBarOpen ? menuopened : menuclosed } 
                onClick={
                    () => {
                        setSideBarOpen(!sideBarOpen);
                        const originalSetItem = localStorage.setItem;

                        localStorage.setItem = function(...args) {
                        const event = new Event('hidePanel');
                        document.dispatchEvent(event);

                        originalSetItem.apply(this, args);
                        };                        
                        localStorage.setItem('sideBarOpen', String(!sideBarOpen))
                    }
                } alt="Logo" />
                <img src={logo} alt="Logo" />
            </div>
                {isConsumerSearchPage ? (
                    <div></div>
                ) : (
                    <div className="search-section">
                        <SearchBar onSearch={onSearch} />
                        <span>Search by First Name, Last Name, Consumer, etc.</span>
                    </div>
                )}
                <MenuSelect menuName={username ?? 'User'}>
                   <div onClick={logout}>Logout</div>
                </MenuSelect>
            </div>
            <hr />
        </div>
    );
};

export default TopBar;
