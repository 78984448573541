import * as React from 'react';
import { ConsumerContactInfo } from '../../../../types/consumer';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../common/form/WrappedInput';
import StateDropdown, { unsupportedConsumerStates } from '../../../common/form/StateDropdown';
import { validateNullOrPhone, validatePhone, validateTaxId } from '../../../../utils/validationRules';
import InfoTooltip from '../../../common/InfoTooltip';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { dateMask, phoneMask } from '../../../../utils/masks';
import { MAX_TEXT_BOX } from '../../../../utils/formConstants';

interface Props {
    viewOnly: boolean;
    initialValues: any;
}

const ContactFormInputs = (props: Props) => {
    const { register, control } = useFormContext<ConsumerContactInfo>();

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <div className="flex">
                        <h4>Primary Contact</h4>
                        <InfoTooltip
                            text={`Use these fields to enter the primary contact information for this account. 
                            If this account is for two people (e.g. John and Pat Doe) who share a mailing address, 
                            just type the second contact's name in the secondary contact fields and leave the other 
                            secondary contact fields blank.`}
                        />
                    </div>
                    <div className="row">
                        <div className="first-name">
                            <WrappedInput<ConsumerContactInfo>
                                inputName="primary.firstName"
                                labelText="First Name"
                                required={true}
                            >
                                <input
                                    {...register('primary.firstName', {
                                        required: `Please enter a first name.`,
                                        maxLength: MAX_TEXT_BOX
                                    })}
                                    readOnly={props.viewOnly}
                                />
                            </WrappedInput>
                        </div>
                        <div className="middle-initial">
                            <WrappedInput<ConsumerContactInfo>
                                inputName="primary.middleInitial"
                                labelText="MI"
                            >
                                <input
                                    {...register('primary.middleInitial', {
                                        maxLength: MAX_TEXT_BOX
                                    })}
                                    readOnly={props.viewOnly}
                                />
                            </WrappedInput>
                        </div>
                    </div>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.lastName"
                        labelText="Last Name"
                        required={true}
                    >
                        <input
                            {...register('primary.lastName', {
                                required: `Please enter a last name.`
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.address.streetAddress"
                        labelText="Address"
                        required={true}
                    >
                        <input
                            {...register('primary.address.streetAddress', {
                                required: `Please enter a street address.`,
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>                  
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.address.city"
                        labelText="City"
                        required={true}
                    >
                        <input
                            {...register('primary.address.city', {
                                required: `Please enter a city.`,
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.address.state"
                        labelText="State"
                        required={true}
                    >
                        <Controller
                            name="primary.address.state"
                            control={control}                            
                            rules={{
                                validate: v =>
                                    !unsupportedConsumerStates.includes(v) ||
                                    'Reliant cannot service consumers in CT, NJ, or WA. Contact RAM support for more information.'
                            }}
                            render={({ field: { onChange, value } }) => (
                                <StateDropdown
                                    required={true}
                                    selectedState={value}
                                    setSelectedState={onChange}
                                    includePayeeTerritories={false}
                                    readOnly={props.viewOnly}
                                />
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.address.zip"
                        labelText="Zip"
                        required={true}
                    >
                        <input
                            {...register('primary.address.zip', {
                                required: `Please enter a zip code.`,
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.taxId"
                        labelText="Tax ID"                 
                        required={true}
                    >
                         <label>{(props.initialValues as ConsumerContactInfo).primary?.taxId}</label>
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.birthDate"
                        labelText="Date of Birth"
                        required={true}
                    >
                        <MaskedTextBoxComponent
                            {...register('primary.birthDate', {
                                required: `Please enter a date of birth.`
                            })}
                            mask={dateMask}
                            placeholder="mm/dd/yyyy"
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="primary.phone"
                        labelText="Phone Number 1"
                    >
                        <MaskedTextBoxComponent
                            {...register('primary.phone', {
                                validate: validatePhone,
                                setValueAs: v => v.replace(/\D/g, '')
                            })}
                            mask={phoneMask}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="phone3"
                        labelText="Phone Number 3"
                    >
                        <MaskedTextBoxComponent
                            {...register('phone3', {
                                validate: validateNullOrPhone,
                                setValueAs: v => (v || '').replace(/\D/g, '')
                            })}
                            mask={phoneMask}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    <h4>Secondary Contact</h4>
                    <div className="row">
                        <div className="first-name">
                            <WrappedInput<ConsumerContactInfo>
                                inputName="secondary.firstName"
                                labelText="First Name"
                            >
                                <input
                                    {...register('secondary.firstName', {
                                        maxLength: MAX_TEXT_BOX
                                    })}
                                    readOnly={props.viewOnly}
                                />
                            </WrappedInput>
                        </div>
                        <div className="middle-initial">
                            <WrappedInput<ConsumerContactInfo>
                                inputName="secondary.middleInitial"
                                labelText="MI"
                            >
                                <input
                                    {...register('secondary.middleInitial', {
                                        maxLength: MAX_TEXT_BOX
                                    })}
                                    readOnly={props.viewOnly}
                                />
                            </WrappedInput>
                        </div>
                    </div>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.lastName"
                        labelText="Last Name"
                    >
                        <input
                            {...register('secondary.lastName', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.address.streetAddress"
                        labelText="Address"
                    >
                        <input
                            {...register('secondary.address.streetAddress', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>                 
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.address.city"
                        labelText="City"
                    >
                        <input
                            {...register('secondary.address.city', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.address.state"
                        labelText="State"
                    >
                        <Controller
                            name="secondary.address.state"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <StateDropdown
                                    required={false}
                                    selectedState={value}
                                    setSelectedState={onChange}
                                    includePayeeTerritories={false}
                                    readOnly={props.viewOnly}
                                />
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.address.zip"
                        labelText="Zip"
                    >
                        <input
                            {...register('secondary.address.zip', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.taxId"
                        labelText="Tax ID"
                    >
                         <label>{(props.initialValues as ConsumerContactInfo).secondary?.taxId}</label>
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.birthDate"
                        labelText="Date of Birth"
                    >
                        <MaskedTextBoxComponent
                            {...register('secondary.birthDate', {setValueAs: v => v == "01011900" ? "" : v})}
                            mask={dateMask}
                            placeholder="mm/dd/yyyy"
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerContactInfo>
                        inputName="secondary.phone"
                        labelText="Phone Number 2"
                    >
                        <MaskedTextBoxComponent
                            {...register('secondary.phone', {
                                validate: validatePhone,
                                setValueAs: v => v.replace(/\D/g, '')
                            })}
                            mask={phoneMask}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                </div>
            </div>
            <hr></hr>
            {!props.viewOnly ? (
                <div className="clear-fix">
                    <input className="btn btn-primary float-left" type="submit" value="Save" />
                </div>
            ) : null}
        </div>
    );
};

export default ContactFormInputs;
