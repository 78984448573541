import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import '../../../../styles/views/Company.scss';
import { Affiliate, AffiliateUpdate } from '../../../../types/affiliate';
import { MAX_TEXT_BOX } from '../../../../utils/formConstants';
import { serverPhoneToDisplayPhone } from '../../../../utils/phoneUtils';
import WrappedInput from '../../../common/form/WrappedInput';
import '../../../../styles/views/Company.scss';
import { BankAccountType } from '../../../../types/enums';
import { bankAccountTypes } from '../../consumer/bankingInfo/BankAccountTypeDropdown';
import InfoTooltip from '../../../common/InfoTooltip';

interface Props {
    affiliate: Affiliate;
}

const bankAccountTypeToDisplayName = (accountType: BankAccountType) =>
    bankAccountTypes.find(t => t.value === accountType)?.name;

const UpdateAffiliateFormInputs = (props: Props) => {
    const { register } = useFormContext<Affiliate>();

    return (
        <div>
            <h4 className="row">Affiliate Information</h4>
            <div className="row">
                <div className="col-6">
                    <div className="wrapped-info">
                        <label>Name</label>
                        <span className="value">{props.affiliate.name}</span>
                        <InfoTooltip
                            text={`The company name or DBA of the Affiliate. This 
                                information appears on reports as the Payee name.`}
                        />
                    </div>
                    <div className="row">
                        <WrappedInput<AffiliateUpdate>
                            inputName="printedName"
                            labelText="Printed Name"
                            helpText={`This field is optional.`}
                        >
                            <input
                                className="printed-name"
                                {...register('printedName', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <div className="clearFix">
                            <input
                                className="btn btn-primary float-left"
                                type="submit"
                                value="Update"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div className="col-6">
                    <div className="wrapped-info">
                        <label>Address</label>
                        <span className="value">{`${props.affiliate.address.streetAddress} ${
                            props.affiliate.address.streetAddress2 &&
                            ` ${props.affiliate.address.streetAddress2}`
                        }`}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>City</label>
                        <span className="value">{props.affiliate.address.city}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>State</label>
                        <span className="value">{props.affiliate.address.state}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>ZIP</label>
                        <span className="value">{props.affiliate.address.zip}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>Phone</label>
                        <span className="value">{`${serverPhoneToDisplayPhone(
                            props.affiliate.phone
                        )}`}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>ID</label>
                        <span className="value">{props.affiliate.id}</span>
                    </div>
                </div>

                <div className="col-6">
                    <div className="wrapped-info">
                        <label>Bank Name</label>
                        <span className="value">{props.affiliate.bankAccount.bankName}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>Account Holder's Name</label>
                        <span className="value">{props.affiliate.bankAccount.nameOnAccount}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>Account #</label>
                        <span className="value">{props.affiliate.bankAccount.accountNumber}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>Routing</label>
                        <span className="value">{props.affiliate.bankAccount.bankRouting}</span>
                    </div>
                    <div className="wrapped-info">
                        <label>Account Type</label>
                        <span className="value">
                            {bankAccountTypeToDisplayName(
                                props.affiliate.bankAccount.bankAccountType
                            )}
                        </span>
                    </div>
                </div>
            </div>
            <hr></hr>
        </div>
    );
};

export default UpdateAffiliateFormInputs;
