import * as React from 'react';
import { useContext } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ScheduleEdit } from '../../../../../types/drafts';
import { DraftFrequency } from '../../../../../types/enums';
import {
    validateDraftDate,
    validatePositive,
    validateInteger,
    validateNullOrCurrency,
    validateNullOrNonNegative
} from '../../../../../utils/validationRules';
import DatePicker from '../../../../common/DatePicker';
import WrappedInput from '../../../../common/form/WrappedInput';
import { FeeGroupContext } from '../../ConsumerPage';
import { DateTime } from 'luxon-business-days';
import { updateSelectedOptionsArray } from '../../../../../utils/objectUtils';
import ConsumerScheduleFeeInputs from './ConsumerScheduleFeeInputs';

export interface Props {
    cleanInputs: ScheduleEdit;
    enabledFeeGroupIds: number[];
    onEnableFeeGroupChange: (newFeeGroups: number[]) => void;
    savingEnabled: boolean;
}

const ConsumerScheduleFormInputs = (props: Props) => {    
    const feeGroups = useContext(FeeGroupContext);    
    const { register, control, reset } = useFormContext<ScheduleEdit>();
    const { fields } = useFieldArray({
        control,
        name: 'feeEdits'
    });

    const getFeeGroupNameById = (feeGroupId: number) => {
        let matchingFeeGroup = feeGroups.find(g => {
            return g.id === feeGroupId;
        });
        return matchingFeeGroup ? matchingFeeGroup.name : '';
    };

    const onResetClick = () => {
        reset(props.cleanInputs);
        props.onEnableFeeGroupChange([]);
    };

    return (
        <div>
            <div className="row margin-left-10">
                <div className="col-6">
                    <WrappedInput<ScheduleEdit>
                        inputName="startDateTime"
                        labelText="Start Date"
                        required={true}
                        helpText={`The date on which the payment program for this consumer begins. 
                        This date cannot be earlier than 1 business day from the current date the first time you set up a draft schedule. 
                        This date determines on what day of the month the consumer's drafts will be scheduled 
                        (e.g. if your start date is July 16th, all drafts will be scheduled for the 16th of each month).`}
                    >
                        {/* Required date at least one business day in the future */}
                        <Controller
                            name="startDateTime"
                            control={control}
                            rules={{
                                required: 'Please select a date.',
                                validate: validateDraftDate
                            }}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    isDateRange={false}
                                    initialStartDate={value}
                                    changeDate={onChange}
                                    minimumDate={DateTime.utc().plusBusiness()}
                                />
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ScheduleEdit>
                        inputName="occurrences"
                        labelText="Number of Drafts"
                        required={true}
                        helpText={`The total number of drafts for this consumer for this program. For example, 36 for a 36-month program.`}
                    >
                        {/* Total number of drafts should be a non-negative integer value of 1 or more*/}
                        <input
                            type="number"
                            step="1"
                            {...register('occurrences', {
                                required: 'Please enter number of drafts.',
                                validate: {
                                    greaterThanZero: validatePositive,
                                    integer: validateInteger
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<ScheduleEdit>
                        inputName="feePercent"
                        labelText="Fee Percentage"
                        helpText={`The percentage of total debt to be charged in fees. This field is not required and does not affect any draft schedule calculations.`}
                    >
                        {/* Non-negative percentage value */}
                        <input
                            type="number"
                            step="any"
                            {...register('feePercent', {
                                validate: validateNullOrNonNegative,
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                </div>

                <div className="col-6">
                    <WrappedInput<ScheduleEdit>
                        inputName="draftFrequency"
                        labelText="Draft Frequency"
                        helpText={`The frequency of the consumer's scheduled drafts. 
                        Options are weekly, biweekly, monthly, bimonthly, quarterly, semi-annually, and annually.`}
                    >
                        {/* One option for each DraftFrequency except Not Set is this is a functionaly required value. 
                        Default is Monthly, which is set by setting the default value from the parent component */}
                        <Controller
                            name="draftFrequency"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <select
                                    className="dropdown-select"
                                    value={value}
                                    onChange={e => onChange(parseInt(e.target.value))}
                                >
                                    <option value={DraftFrequency.Weekly}>Weekly</option>
                                    <option value={DraftFrequency.Biweekly}>Biweekly</option>
                                    <option value={DraftFrequency.Monthly}>Monthly</option>
                                    <option value={DraftFrequency.Bimonthly}>Bimonthly</option>
                                    <option value={DraftFrequency.Quarterly}>Quarterly</option>
                                    <option value={DraftFrequency.Semiannually}>
                                        Semiannually
                                    </option>
                                    <option value={DraftFrequency.Annually}>Annually</option>
                                    <option value={DraftFrequency.Daily}>Daily</option>
                                </select>
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ScheduleEdit>
                        inputName="totalAllowed"
                        labelText="Total Owed"
                        helpText={`The total debt for this consumer. This field is not required and does not affect any draft schedule calculations. 
                        This field is reported on the Posted Payment and First Payment reports.`}
                    >
                        {/* Non-negative currency value */}
                        <input
                            type="number"
                            step="0.01"
                            {...register('totalAllowed', {
                                validate: {
                                    positive: validateNullOrNonNegative,
                                    currency: validateNullOrCurrency
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<ScheduleEdit>
                        inputName="monthlyDraft"
                        labelText="Total Monthly Payment"
                        helpText={`The total monthly payment requested by the consumer. This information is used to derive the monthly payment for the payment schedule and also the 
                        monthly savings amount (total payment less fees equals the savings amount). The monthly payment calculated can exceed this amount if the fees require it, 
                        but the monthly payment will not be less than this amount. See How Schedules Are Generated for more information.`}
                    >
                        {/* Non-negative currency value */}
                        <input
                            type="number"
                            step="0.01"
                            {...register('monthlyDraft', {
                                validate: {
                                    positive: validateNullOrNonNegative,
                                    currency: validateNullOrCurrency
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                </div>
            </div>
            {fields.map((field, index) => (
                <div key={field.id}>
                    <h4>{getFeeGroupNameById(field.feeId)}</h4>
                    <div className="wrapped-input">
                        <label>Enable Fee Group</label>
                        <input
                            className="default-checkbox"
                            type="checkbox"
                            checked={props.enabledFeeGroupIds.includes(field.feeId)}
                            onChange={e =>
                                props.onEnableFeeGroupChange(
                                    updateSelectedOptionsArray(
                                        field.feeId,
                                        e.target.checked,
                                        props.enabledFeeGroupIds
                                    )
                                )
                            }
                        />
                    </div>
                    {props.enabledFeeGroupIds.includes(field.feeId) && (
                        <ConsumerScheduleFeeInputs
                            index={index}
                            defaultValues={field}
                        ></ConsumerScheduleFeeInputs>
                    )}
                </div>
            ))}
            <hr></hr>
            <div className="clear-fix">
                <div className="float-left">
                    <input
                        className="btn btn-primary margin-right-10"
                        type="submit"
                        value="Save and Preview Schedule"
                        disabled={!props.savingEnabled}
                    />
                    <button className="btn btn-danger" onClick={onResetClick}>
                        Reset
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConsumerScheduleFormInputs;
