export enum ConsumerNoteType {
    Company = 1,
    RAM = 2
}

export enum BankAccountType {
    NotSet = 0,
    Savings = 1,
    Checking = 2
}

export enum AccountStatus {
    Active = 1,
    Cancelled = 6,
    Completed = 7,
    Hold = 21,
    Hold_Request = 22
}

export enum AchStatus {
    Active = 0,
    OnHoldRequest = 98,
    OnHoldReturn = 99
}

export enum AuthStatus {
    NotApproved = 0,
    Approved = 1
}

export enum CardNetwork {
    Visa = 0,
    MasterCard = 1,
    Discover = 2,
    Amex = 3
}

export enum PaymentType {
    RamCheck = 2,
    WireTransfer = 3,
    Ach = 4,
    PayByPhone = 7,
    AchDebit = 8
}

export enum PaymentDirection {
    Debit = 0,
    Credit = 1
}

export enum DraftFrequency {
    NotSet = 0,
    Weekly = 1,
    Biweekly = 2,
    Monthly = 3,
    Bimonthly = 4,
    Quarterly = 5,
    Semiannually = 6,
    Annually = 7,
    Daily = 8 // monday thru friday
}

export enum DataSeriesType {
    EnrollmentCount = 0,
    EnrollmentDraftRate = 1,

    DraftExecutionCount = 2,
    DraftReturnCount = 3,
    DraftReturnRate = 4,

    RefundCount = 5,
    RefundAmount = 6,
    RefundAmountAverage = 7,

    IdleCount = 8,
    IdleRate = 9,

    ClosureCount = 10,
    ClosureRate = 11,
    ClosureSettlementRate = 12,

    SettlementsCount = 13,
    SettlementsRate = 14,
    SettlementAmountAverage = 15,

    PaidTotal = 16,
    PaidPerConsumer = 17,
    PaidPerSettlement = 18
}

export enum UserRole {
    CompanyUnlimited = 0,
    CompanyLimited = 1,
    Affiliate = 2
}

export enum DraftStatus {
    Posted = 'POSTED',
    Returned = 'RETURNED',
    Scheduled = 'SCHEDULED'
}

export enum DraftMoveDirection {
    None = 0,
    Up = -1, //Earlier
    Back = 1 //Later
}

export enum UserPermission {
    ViewAffiliates = 0,
    ViewChecks = 1,
    EditSettlements = 2,
    EditConsumers = 3,
    ViewLedger = 4,
    EditFees = 5,
    EditDrafts = 6,
    ViewFees = 7,
    IssueRefunds = 8
}
