import * as React from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { ConsumerContext } from '../ConsumerPage';
import { ConsumerHelpDeskTicket } from '../../../../types/consumer';
import { addConsumerSupportTicket, getConsumerSupportTickets } from '../../../../api/consumerApi';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import SupportTicketsGridView from './SupportTicketsGridView';
import AddSupportTicketView from './AddSupportTicketView';
import '../../../../styles/views/Consumer.scss';
import { loadFromApi } from '../../../../api/baseApi';


const ConsumerSupportTicketsTab = () => {
    const consumer = useContext(ConsumerContext);

    const [supportTickets, setSupportTickets] = useState<ConsumerHelpDeskTicket[]>();

    
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (errorContext: string) => (error?: string) =>
            error &&
            addToast(`${errorContext} (${error})`, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const loadTickets = useCallback(async () => {
        loadFromApi(setApiError('Failed to load support tickets'), setLoading)(
            () => getConsumerSupportTickets(consumer.consumerID),
            setSupportTickets
        );
    }, [consumer.consumerID, setApiError]);

    useEffect(() => {
        loadTickets();
    }, [loadTickets]);

    const handleAddTicket = async (ticketDescription: string) => {
        const handleTicketAdded = async () => {
            addToast('Support ticket added', {
                appearance: 'success',
                autoDismiss: true
            });
            await loadTickets();
        };
        loadFromApi(setApiError(`Failed to add support ticket`), setLoading)(
            () => addConsumerSupportTicket(consumer.consumerID, ticketDescription),
            undefined,
            handleTicketAdded
        );
    };

    return (
        <div className="consumer-input-section support-tickets-tab">
            <h3>Support Tickets</h3>
            {loading ? (
                <LoadingDisplay />
            ) : !supportTickets ? (
                <LoadFailedDisplay />
            ) : (
                <div>
                    <SupportTicketsGridView supportTickets={supportTickets} />
                       <AddSupportTicketView addSupportTicket={handleAddTicket} />                   
                </div>
            )}
        </div>
    );
};

export default ConsumerSupportTicketsTab;
