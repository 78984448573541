import * as React from 'react';
import '../../../styles/Form.scss';
import '../../../styles/views/Company.scss';
import '../../../styles/common/Elements.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { CompanyUpdate, CompanyEvent } from '../../../types/company';
import WrappedInput from '../../common/form/WrappedInput';
import CheckboxArray from '../../common/form/CheckboxArray';
import { validateEmail, validatePhone } from '../../../utils/validationRules';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { phoneMask } from '../../../utils/masks';
import { MAX_TEXT_BOX } from '../../../utils/formConstants';

interface Props {
    eventOptions: CompanyEvent[];
}

const CompanyInformationFormInputs = (props: Props) => {
    const { register, control } = useFormContext<CompanyUpdate>();
    const showevents = false; //later implementation for event grid

    return (
        <div>
            <WrappedInput<CompanyUpdate> inputName="name" labelText="Name" required={true}>
                <input
                    {...register('name', {
                        required: `Please enter the company's name.`,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="phone"
                labelText="Consumer Support Phone Number"
                helpText="This number is given to consumers who call RAM."
                required={true}
            >
                <MaskedTextBoxComponent
                    {...register('phone', {
                        required: `Please enter the company's phone.`,
                        validate: validatePhone,
                        setValueAs: v => v.replace(/\D/g, '')
                    })}
                    mask={phoneMask}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="notificationEmail"
                labelText="Notifications Email"
            >
                <input
                    {...register('notificationEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="draftEmail"
                labelText="Draft Change Request Email"
            >
                <input
                    {...register('draftEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="returnEmail"
                labelText="Return Notification Email"
            >
                <input
                    {...register('returnEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="settlementEmail"
                labelText="Settlement Notification Email"
            >
                <input
                    {...register('settlementEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="checkEmail"
                labelText="Outstanding Check Notification Email"
            >
                <input
                    {...register('checkEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="supportEmail"
                labelText="Consumer Support Notification Email"
            >
                <input
                    {...register('supportEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            <WrappedInput<CompanyUpdate>
                inputName="addressChangeEmail"
                labelText="Address Change Notification Email"
            >
                <input
                    {...register('addressChangeEmail', {
                        required: false,
                        validate: validateEmail,
                        maxLength: MAX_TEXT_BOX
                    })}
                />
            </WrappedInput>
            {showevents ? (
            <WrappedInput<CompanyUpdate> inputName="eventIdsEnabled" labelText="Events Enabled">
                <Controller                    
                    name="eventIdsEnabled"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrEventsEnabled"
                            options={props.eventOptions.map(e => ({
                                id: e.id,
                                display: e.displayName
                            }))}
                            selectedOptions={value}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>) : ( <br></br>)}
     <br></br>
            <hr></hr>
            <div className="clear-fix">
                <input
                    className="btn btn-primary float-left"
                    type="submit"
                    value="Update Information"
                />
            </div>
        </div>
    );
};

export default CompanyInformationFormInputs;
