import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../common/form/WrappedInput';
import BankAccountTypeDropdown from './BankAccountTypeDropdown';
import { BankAccount } from '../../../../types/common';
import { validateRouting } from '../../../../api/consumerBankingInfoApi';
import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import '../../../../styles/Form.scss';
import '../../../../styles/views/Consumer.scss';
import { MAX_TEXT_BOX } from '../../../../utils/formConstants';

interface Props {
    viewOnly: boolean;
}

const UpdateBankAccountFormInputs = (props: Props) => {
    const { register, control, watch, setValue } = useFormContext<BankAccount>();
    const watchRoutingNumber = watch('bankRouting');

    const [loadingValidate, setLoadingValidate] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const handleClickValidateRoutingNum = async () => {
        setLoadingValidate(true);
        const validateRoutingResponse = await validateRouting(watchRoutingNumber);
        if (validateRoutingResponse.error) {
            setApiError(`Unable to validate routing number (${validateRoutingResponse.error})`);
        } else {
            if (validateRoutingResponse.data?.valid) {
                addToast('Routing number valid', { appearance: 'success', autoDismiss: true });
                if (validateRoutingResponse.data?.bankName) {
                    setValue('bankName', validateRoutingResponse.data.bankName);
                }
            } else {
                addToast('Routing number invalid', { appearance: 'error', autoDismiss: true });
            }
        }
        setLoadingValidate(false);
    };

    return (
        <div>
            <h4 className="row">Update Bank Account</h4>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<BankAccount>
                        inputName="bankName"
                        labelText="Bank Name"
                        helpText={`The name of the consumer's bank.`}
                    >
                        <input
                            {...register('bankName', {
                                required: 'Please enter the bank name.',
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<BankAccount>
                        inputName="accountNumber"
                        labelText="Bank Account Number"
                        helpText={`The consumer's bank account number for the account to be drafted.`}
                    >
                        <input
                            {...register('accountNumber', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>

                    <WrappedInput<BankAccount>
                        inputName="bankRouting"
                        labelText="Bank Routing"
                        helpText={`The 9-digit ABA routing number for the consumer's bank. After entering the 
                            routing number you can click the Validate Routing icon to the right of the Routing 
                            field to validate the routing number and bank name.`}
                    >
                        <input
                            {...register('bankRouting', {
                                pattern: {
                                    value: /^[0-9]{9}$/,
                                    message: 'Routing Number must be numeric and 9 digits.'
                                }
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>

                    <div>
                        {!props.viewOnly ? (
                            <button
                                type="button"
                                className="btn btn-secondary validate-routing-btn"
                                disabled={!watchRoutingNumber || loadingValidate}
                                onClick={handleClickValidateRoutingNum}
                            >
                                {loadingValidate
                                    ? `Validating Routing Number...`
                                    : `Validate Routing Number`}
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="col-6">
                    <WrappedInput<BankAccount>
                        inputName="nameOnAccount"
                        labelText="Bank Account Name"
                        helpText={`The name on the consumer's bank account (usually the consumer's name).`}
                    >
                        <input
                            {...register('nameOnAccount', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<BankAccount>
                        inputName="bankAccountType"
                        labelText="Bank Account Type"
                    >
                        <Controller
                            name="bankAccountType"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <BankAccountTypeDropdown
                                    selectedAccountStatus={value}
                                    setSelectedAccountStatus={onChange}
                                    readOnly={props.viewOnly}
                                />
                            )}
                        />
                    </WrappedInput>
                </div>
            </div>

            <hr></hr>
            {!props.viewOnly ? (
                <div className="clearFix">
                    <input className="btn btn-primary float-left" type="submit" value="Update" />
                </div>
            ) : null}
        </div>
    );
};

export default UpdateBankAccountFormInputs;
