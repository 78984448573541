import * as React from 'react';
import '../../styles/components/TopBar.scss';
import searchIcon from '../../assets/images/search.png';
import { useState } from 'react';

interface SearchBarProps {
    onSearch: (searchValue: string) => void;
    type?: string;
    initialValue?: string;
}

const SearchBar = (props: SearchBarProps) => {
    const [search, setSearch] = useState(props.initialValue || '');

    const onInputFocus = (args: React.FocusEvent) => {
        ((args.target as HTMLElement).parentElement as HTMLElement).classList.add('search-focus');
    };

    const onInputBlur = (args: React.FocusEvent) => {
        ((args.target as HTMLElement).parentElement as HTMLElement).classList.remove(
            'search-focus'
        );
    };

    const onSearch = () => {
        props.onSearch(search);
    };

    const onKeyUp = (event: React.KeyboardEvent) => {
        // Catch the enter key, perform search
        if (event.key === 'Enter' || event.keyCode === 13) {
            onSearch();
        }
    };

    return (
        <div className="search-bar" onFocus={onInputFocus} onBlur={onInputBlur}>
            <img src={searchIcon} alt="search" onClick={onSearch} />
            <input
                type={props.type || 'text'}
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search..."
                onKeyUp={onKeyUp}
            />
        </div>
    );
};

export default SearchBar;
