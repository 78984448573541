import * as React from 'react';
import { contactUsPageLink } from '../../pages';

interface Props {}

const LoadFailedDisplay = (props: Props) => (
    <div>
        <h2>Data Load Failed</h2>
        <p>
            Page data failed to load. Please refresh the page to try again. If still not working,
            please <a href={contactUsPageLink}>contact us</a> and let us know what you're trying to
            do.
        </p>
    </div>
);

export default LoadFailedDisplay;
