import * as React from 'react';

interface NumericDropdownOption {
    value: number;
    text: string;
}

interface Props {
    options: NumericDropdownOption[];
    defaultOptionText?: string;
    selectedValue?: number;
    setSelectedValue: (value?: number) => void;
    readOnly?: boolean;
    setUserRoleTypeValue?: (value?: number) => void;
}

const NumericDropdown = (props: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = parseInt(e.currentTarget.value);        
        // Convert a selectedValue of 0 to undefined
        // (The use of undefined/0 corresponds to the default option)
        props.setSelectedValue(selectedValue || undefined);
        props.setUserRoleTypeValue && props.setUserRoleTypeValue(selectedValue || undefined);
    };

    return (
        <select
            className="dropdown-select"
            // If a value has not yet been selected from the dropdown (i.e. undefined), use a value of 0 instead
            // (The use of undefined/0 corresponds to the default option)
            value={props.selectedValue || 0}
            onChange={handleChange}
            disabled={props.readOnly}
        >
            {props.defaultOptionText && <option value={0}>{props.defaultOptionText}</option>}

            {props.options.map((a, index) => (
                <option key={index} value={a.value}>
                    {a.text}
                </option>
            ))}
        </select>
    );
};

export default NumericDropdown;
