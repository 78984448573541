import { DateTime } from 'luxon-business-days';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import { deleteConsumerFees, getConsumerFees, getConsumerFeeTypes, getVendorPayeeNames } from '../../../../api/consumerApi';
import '../../../../styles/views/Consumer.scss';
import {  UserPermission } from '../../../../types/enums';
import { luxonDateToServerDate, serverDateToLuxonDateTime } from '../../../../utils/dateUtils';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { ConsumerContext } from '../ConsumerPage';
import ConsumerAddFeesModal from './ConsumerAddFeestModal';
import ConsumerFeesGrid from './ConsumerFeesGrid';

const ConsumerFeesTab = () => {
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);
    const [fees, setFees] = useState<any>([]);
    const [feeTypes, setFeeTypes] = useState<any>([]);
    const [payeeTypes, setPayeeTypes] = useState<any>([]);
    const [vendorPayeeNames, setVendorPayeeNames] = useState<any>([]);
    const [selectedFees, setSelectedFees] = useState<any[]>([]);
    const [showAddFeesModal, setShowAddFeesModal] = useState(false);
   
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditFeesPermissions = userHasPermission(UserPermission.EditFees);

    const defaultFees: any = {
        id: 0,
        "clientId": 0,
        "feeAmount": 0,
        vendorPayeeNames: payeeTypes?.value?.length  === 1 ? payeeTypes?.[0]?.name: '',
        "numberofPayments": 1,
        "paymentFrequency": 0,
        "description": "",
        "externalID": "",
        "tCode": "",
        feeTypes: "",
        "tSubCode": "",
        "scheduleDate": serverDateToLuxonDateTime(luxonDateToServerDate(DateTime.utc().plusBusiness({ days: 2 }))),
    };

    const onDeleteFees = async () => {
        let selectedFeesIds = selectedFees.map(fees => fees.id);
        let error = false;
        const result = await deleteConsumerFees(consumer.consumerID, selectedFeesIds);
        if (result.error && !error) {
            setApiError(result.error);
            error = true; //Prevent errors from pilling up for bulk deletes
        }
        else if(result.data){
            addToast('Deleted Successfully', {
                appearance:'success',
                autoDismiss: true
            })
        }
        refreshGrid();
    };


    const refreshGrid = useCallback(() => {
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );
        const loadConsumerFees = async () => {
            setLoading(true);
            const consumerFeesLoad = consumerPageApiWrapper(
                () => getConsumerFees(consumer.consumerID),
                setFees
            );
            await consumerFeesLoad;
            setSelectedFees([]); 
            setLoading(false);
        };

        loadConsumerFees();
    }, [addToast, consumer.consumerID]);


   
    useEffect(()=>{
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );
        const getFeeTypes = async () => {
            const consumeFeeTypesLoad = consumerPageApiWrapper(
                () => getConsumerFeeTypes(consumer.consumerID),
                setFeeTypes
            );
            await consumeFeeTypesLoad;
            const consumPayeeTypesLoad = consumerPageApiWrapper(
                () => getVendorPayeeNames(),
                setPayeeTypes
            );
            await consumPayeeTypesLoad;
        };

        getFeeTypes();

    }, [])

    useEffect(()=>{
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );
        const getPayeeNames = async () => {
            const loadVendorPayeeNames = consumerPageApiWrapper(
                () => getVendorPayeeNames(),
                setVendorPayeeNames
            );
            await loadVendorPayeeNames;
        };

        getPayeeNames();

    }, [])

    useEffect(() => {
        refreshGrid();
    }, [refreshGrid, addToast, consumer.consumerID]);

    return (
        <div className="consumer-input-section Drafts-tab Fees-tab">
            <h3>Fees</h3>
            {loading ? (
                <LoadingDisplay />
            ) : !consumer || !fees ? (
                <LoadFailedDisplay />
            ) : (
                <div>

                    {hasEditFeesPermissions ? (
                        <div className="button-bar clear-fix">
                            <div className="float-left">
                          
                            </div>
                            <div className="float-right">
                                <button
                                    className="btn btn-outline-primary margin-right-10"
                                    disabled={selectedFees.length <= 0}
                                    onClick={() => {
                                       
                                        onDeleteFees();
                                    }}
                                >
                                    Delete
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setShowAddFeesModal(true);
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </div>
                    ) : null}
                    <ConsumerFeesGrid
                        fees={fees}
                        setSelectedFees={setSelectedFees}
                        hasEditPermissions={hasEditFeesPermissions}
                       
                    />
                    <ConsumerAddFeesModal
                                fees={defaultFees}
                                viewOnly={!hasEditFeesPermissions}
                                targetId="consumerPage"
                                feeTypes={feeTypes.value}
                                payeeTypes={payeeTypes.value}
                                showDialog={showAddFeesModal}
                                setShowDialog={(open: any, error: any, data: any)=>{
                                    if(error){
                                        addToast(data !='' ?data : 'Something went wrong', {
                                            appearance: 'error',
                                            autoDismiss: true
                                        })
                                    }
                                    else if(data){
                                        refreshGrid();
                                        addToast(data, {
                                            appearance: 'success',
                                            autoDismiss: true
                                        })
                                    }
                                    setShowAddFeesModal(false)
                                  
                                }}
                                refreshGrid={refreshGrid}/>
                </div>
            )}
        </div>
    );
};

export default ConsumerFeesTab;
