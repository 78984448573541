import * as React from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    ExcelExport,
    GridComponent,
    Inject,
    PdfExport,
    Toolbar,
    FilterSettingsModel,
    Filter,
    Sort,
    Resize
} from '@syncfusion/ej2-react-grids';
import {
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';
import { useEffect } from 'react';
import { ConsumerHelpDeskTicket } from '../../../../types/consumer';
import NewTabLink from '../../../common/NewTabLink';
import '../../../../styles/views/Consumer.scss';
import { serverDateTimeToDisplayDateTime } from '../../../../utils/dateUtils';
import { DateTime } from 'luxon';
import {GridViewContext} from '../../../GridViewService';

interface Props {
    supportTickets: ConsumerHelpDeskTicket[];
}

const SupportTicketsGridView = (props: Props) => {
    const gridOptions = useCommonGridOptions('ConsumerSupportTickets');
    const supportTicketsGrid = gridOptions && gridOptions.ref;
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    useEffect(() => {
        supportTicketsGrid.current?.refresh();
    }, [props.supportTickets, supportTicketsGrid]);

    /** Value Accessor functions **/
    //  used to manipulate or format raw data before displaying it in the grid
    const getTimestampDisplay: InternalValueAccessor<ConsumerHelpDeskTicket> = (_field, data) =>
        serverDateTimeToDisplayDateTime(data.timestamp, DateTime.DATETIME_FULL);
    const { currentView } = React.useContext(GridViewContext);
        let dataBound = () =>{
            if(gridOptions.ref.current && currentView === 2)
            {
                gridOptions.ref.current.autoFitColumns([])
            }
        }
    return (
        <div>
            <GridComponent {...gridOptions} 
                dataSource={props.supportTickets}
                className={`e-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid '} ${currentView === 2 ?  'comfort-grid-view' : 'default-grid-view'}`}
                dataBound= { dataBound }
                filterSettings={filterOptions}
                allowFiltering={false}
                allowSorting={false}
                clipMode='EllipsisWithTooltip'
                >
                <ColumnsDirective>
                    <ColumnDirective
                        field="ticketNumber"
                        headerText="Ticket Number"
                        width="30px"
                        textAlign="Left"
                        template={(props: ConsumerHelpDeskTicket) => (
                            <NewTabLink url={props.ticketUrl}>{props.ticketNumber}</NewTabLink>
                        )}
                    />
                    <ColumnDirective
                        valueAccessor={vaWrapper(getTimestampDisplay)}
                        headerText="Created"
                        width="90px"
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="description"
                        headerText="Description" 
                        width="200px"                       
                        textAlign="Left"
                    />
                     <ColumnDirective
                        field="status"
                        headerText="Status"
                        width="50px"
                        textAlign="Left"
                    />
                </ColumnsDirective>
                <Inject services={[CommandColumn, PdfExport, ExcelExport, Toolbar, Filter, Sort, Resize]} />
            </GridComponent>
        </div>
    );
};

export default SupportTicketsGridView;
