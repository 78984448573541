import * as React from 'react';
import { useState,useCallback, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import '../../../styles/Form.scss';
import '../../../styles/views/Consumer.scss';
import { dashboardPages, dashboardRoutingPath } from '../../../pages';
import EnrollmentTracking from './EnrollmentTracking';
import { DateRange } from '../../../types/common';
import DraftPerformance from './DraftPerformance';
import Refunds from './Refunds';
import IdleConsumers from './IdleConsumers';
import ConsumerClosure from './ConsumerClosure';
import CompanyRevenue from './CompanyRevenue';
import Settlements from './Settlements';
import { getDefaultDateRange } from '../../../utils/dateUtils';
import { Company } from '../../../types/company';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../api/baseApi';
import { getCompany } from '../../../api/companyApi';


const DashboardPage = () => {
    const [dateRange, setDateRange] = useState<DateRange>(getDefaultDateRange());
    const [company, setCompany] = useState<Company>();        
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const companyPageApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {            
            const companyLoad = companyPageApiWrapper(getCompany, setCompany);
            await companyLoad;            
        };               
        loadCompanyPageData();                

    }, [setApiError]);

    
    function getCompanyName()  {
        return (company ? {name: company.name} : {name: "You"});
    }

    return (
        <div className="dashboard-page">
            <Switch>
                <Route
                    path={dashboardRoutingPath(dashboardPages.enrollmentTracking)}
                    render={() => (
                        <EnrollmentTracking dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()} />
                    )}
                />
                <Route
                    path={dashboardRoutingPath(dashboardPages.draftPerformance)}
                    render={() => (
                        <DraftPerformance dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()}/>
                    )}
                />
                <Route
                    path={dashboardRoutingPath(dashboardPages.refunds)}
                    render={() => <Refunds dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()}/>}
                />
                <Route
                    path={dashboardRoutingPath(dashboardPages.idleConsumers)}
                    render={() => (
                        <IdleConsumers dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()}/>
                    )}
                />
                <Route
                    path={dashboardRoutingPath(dashboardPages.consumerClosure)}
                    render={() => (
                        <ConsumerClosure dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()} />
                    )}
                />
                <Route
                    path={dashboardRoutingPath(dashboardPages.settlements)}
                    render={() => (
                        <Settlements dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()}/>
                    )}
                />
                <Route
                    path={dashboardRoutingPath(dashboardPages.companyRevenue)}
                    render={() => (
                        <CompanyRevenue dateRange={dateRange} changeDateRange={setDateRange} coName={getCompanyName()}/>
                    )}
                />
            </Switch>
        </div>
    );
};

export default DashboardPage;
