import * as React from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import {
    getConsumerBankAccount,
    getConsumerCards,
    removeConsumerCard,
    updateConsumerBankAccount
} from '../../../../api/consumerBankingInfoApi';
import { BankAccount } from '../../../../types/common';
import WrappedForm from '../../../common/form/WrappedForm';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { ConsumerContext } from '../ConsumerPage';
import AddCardPopup from './addCardModal/AddCardPopup';
import UpdateBankAccountFormInputs from './UpdateBankAccountFormInputs';
import ViewExistingCardsPopup from './viewCardsModal/ViewExistingCardsPopup';
import BankAccountAuthorization from './BankAccountAuthorization';
import { CompanySettingsContext } from '../../../CompanyService';
import InfoTooltip from '../../../common/InfoTooltip';
import { CreditCardView } from '../../../../types/bankingInformation';
import '../../../../styles/views/Consumer.scss';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { UserPermission } from '../../../../types/enums';

const ConsumerBankingInfoTab = () => {
    const companySettings = useContext(CompanySettingsContext);
    const consumer = useContext(ConsumerContext);

    const [initialBankAccount, setInitialBankAccount] = useState<BankAccount>();
    const [consumerCards, setConsumerCards] = useState<CreditCardView[]>();

    const [showAddCardPopup, setShowAddCardPopup] = useState(false);
    const [showViewExistingCardsPopup, setShowViewExistingCardsPopup] = useState(false);

    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingSubmitForm, setLoadingSubmitForm] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);    
    
    useEffect(() => {
        const bankingInfoApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {
            setLoadingPage(true);
            const bankAccountLoad = bankingInfoApiWrapper(
                () => getConsumerBankAccount(consumer.consumerID),
                setInitialBankAccount
            );            
            const consumerCardsLoad = companySettings.enableCreditCard && bankingInfoApiWrapper(
                () => getConsumerCards(consumer.consumerID),
                setConsumerCards
            );
            await bankAccountLoad;            
            await consumerCardsLoad;
            setLoadingPage(false);
        };
        loadCompanyPageData();
    }, [setApiError, consumer.consumerID]);

    const onSubmitUpdateBankAccount = async (formBankAccount: BankAccount) => {
        setLoadingSubmitForm(true);
        const response = await updateConsumerBankAccount(consumer.consumerID, formBankAccount);
        if (response.error) {
            setApiError(`Failed to update bank account (${response.error})`);
        } else {
            setInitialBankAccount(formBankAccount);
            addToast('Bank account updated.', {
                appearance: 'success',
                autoDismiss: true
            });
        }
        setLoadingSubmitForm(false);
    };

    const addCardToCards = (newCard: CreditCardView) => {
        if (consumerCards) {
            setConsumerCards([...consumerCards, newCard]);
        }
    };

    const refreshConsumerCards = async () => {                
        loadFromApi(setApiError, setLoadingPage)(
            () => getConsumerCards(consumer.consumerID),
            setConsumerCards
        );
    };

    const removeCardFromCards = async (cardToRemove: CreditCardView) => {
        const response = await removeConsumerCard(consumer.consumerID, cardToRemove.id);
        if (response.error) {
            setApiError(`Failed to remove card (${response.error})`);
        } else {
            addToast(`Card ending in ${cardToRemove.lastFour} removed`, {
                appearance: 'success',
                autoDismiss: true
            });
            // Remove from local list
            if (consumerCards) {
                setConsumerCards(consumerCards.filter(c => c.id !== cardToRemove.id));
            }
        }
    };

    return (
        <div className="consumer-input-section banking-info-tab">
            <h3>Banking Information</h3>
            {loadingPage || loadingSubmitForm ? (
                <LoadingDisplay />
            ) : initialBankAccount ? (
                <div>
                    <WrappedForm<BankAccount>
                        onSubmit={onSubmitUpdateBankAccount}
                        initialValues={initialBankAccount}
                    >
                        <UpdateBankAccountFormInputs viewOnly={!hasEditConsumerPermissions} />
                    </WrappedForm>
                </div>
            ) : (
                <LoadFailedDisplay />
            )}
            {hasEditConsumerPermissions ? <BankAccountAuthorization /> : null}
            {companySettings.enableCreditCard && (
                <div className="card-information">
                    <div className="title-with-tooltip">
                        <h4>Card Information</h4>
                        <InfoTooltip
                            text="You can provide card information here in order to charge a
                                    payment to a client credit card (VISA, MC, or Discover). You
                                    must provide the card information, then upload a signed card
                                    authorization with matching card information via the form at the
                                    bottom. Once the card is authorized for use through RAM, you can
                                    charge the card by clicking 'View Existing Cards', selecting the
                                    appropriate card and charging it. Card funds are held for 5
                                    business days before appearing in the client's RAM account to be
                                    paid as fees or applied to settlements. Additionally, a RAM
                                    transaction fee is charged separately to your company for every
                                    successful card transaction."
                        />
                    </div>
                    <button className="btn btn-secondary" onClick={() => setShowAddCardPopup(true)}>
                        Add Card
                    </button>
                    {loadingPage ? (
                        <LoadingDisplay />
                    ) : consumerCards ? (
                        consumerCards.length > 0 && (
                            <button
                                className="btn btn-secondary"
                                onClick={() => setShowViewExistingCardsPopup(true)}
                            >
                                View Existing Cards
                            </button>
                        )
                    ) : (
                        <LoadFailedDisplay />
                    )}
                </div>
            )}
            <AddCardPopup
                targetId="consumerPage"
                showDialog={showAddCardPopup}
                setShowDialog={setShowAddCardPopup}
                addCardToCards={addCardToCards}
            />

            {consumerCards && (
                <ViewExistingCardsPopup
                    targetId="consumerPage"
                    showDialog={showViewExistingCardsPopup}
                    setShowDialog={setShowViewExistingCardsPopup}
                    consumerCards={consumerCards}
                    refreshConsumerCards={refreshConsumerCards}
                    handleRemoveConsumerCard={removeCardFromCards}
                    viewOnly={!hasEditConsumerPermissions}
                />
            )}
        </div>
    );
};

export default ConsumerBankingInfoTab;
