import * as React from 'react';
import { useEffect, useState } from 'react';
import { getDashboardStatistics } from '../../../../api/dashboardApi';
import { DashboardStatistics } from '../../../../types/dashboard';
import '../../../../styles/views/Home.scss';
import StatisticView from './StatisticView';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import DatePicker from '../../../common/DatePicker';
import aboveAverageIcon from '../../../../assets/images/blue-square.png';
import belowAverageIcon from '../../../../assets/images/blue-circle.png';
import equalToAverageIcon from '../../../../assets/images/orange-line.png';
import { useToasts } from 'react-toast-notifications';
import { DateRange } from '../../../../types/common';

interface Props {
    dateRange: DateRange;
    setDateRange: (range: DateRange) => void;
}

const CompanyStatisticsView = (props: Props) => {
    const [companyStatistics, setCompanyStatistics] = useState<DashboardStatistics>();
    const [industryStatistics, setIndustryStatistics] = useState<DashboardStatistics>();

    const [apiLoading, setApiLoading] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        const loadStatistics = async () => {
            setApiLoading(true);

            setCompanyStatistics(undefined);
            setIndustryStatistics(undefined);

            const promises = [
                getDashboardStatistics(
                    props.dateRange.start.toISODate(),
                    props.dateRange.end.toISODate(),
                    false // Get Company Stats
                ),
                getDashboardStatistics(
                    props.dateRange.start.toISODate(),
                    props.dateRange.end.toISODate(),
                    true // Get Industry Stats
                )
            ];
            const responses = await Promise.all(promises);
            setCompanyStatistics(responses[0].data);
            setIndustryStatistics(responses[1].data);
            responses
                .filter(r => r.error)
                .map(r =>
                    addToast(r.error, {
                        autoDismiss: true,
                        appearance: 'error'
                    })
                );

            setApiLoading(false);
        };
        loadStatistics();
    }, [props.dateRange, addToast]);

    return (
        <div>
            <div className="title-container-stats">
                <div>
                    <label>Company Statistics</label>
                    <span>
                        <img src={belowAverageIcon} alt="Above Average" /> Below Avg.
                    </span>
                    <span>
                        <img src={aboveAverageIcon} alt="Below Average" /> Above Avg.
                    </span>
                    <span>
                        <img src={equalToAverageIcon} alt="Average" /> Average
                    </span>
                </div>

                <div className="date-picker">
                    <DatePicker
                        isDateRange={true}
                        initialStartDate={props.dateRange.start}
                        initialEndDate={props.dateRange.end}
                        changeDateRange={props.setDateRange}
                    />
                </div>
            </div>

            {apiLoading ? (
                <LoadingDisplay />
            ) : companyStatistics && industryStatistics ? (
                <div className="statistics-row-flex-container">
                    <StatisticView
                        label="Active"
                        value={companyStatistics.activeCount}
                        industryAverage={industryStatistics.activeCount}
                    />
                    <StatisticView
                        label="Enrolled"
                        value={companyStatistics.enrolledCount}
                        industryAverage={industryStatistics.enrolledCount}
                    />
                    <StatisticView
                        label="Drafted"
                        value={companyStatistics.draftedCount}
                        industryAverage={industryStatistics.draftedCount}
                    />
                    <StatisticView
                        label="Closed"
                        value={companyStatistics.closedCount}
                        industryAverage={industryStatistics.closedCount}
                    />
                    <StatisticView
                        label="Settlements"
                        value={companyStatistics.paidCount}
                        industryAverage={industryStatistics.paidCount}
                    />
                    <StatisticView
                        label="Returns"
                        value={companyStatistics.returnsCount}
                        industryAverage={industryStatistics.returnsCount}
                    />
                    <StatisticView
                        label="Refunds"
                        value={companyStatistics.refundsCount}
                        industryAverage={industryStatistics.refundsCount}
                    />
                    <StatisticView
                        label="Idle"
                        value={companyStatistics.idleCount}
                        industryAverage={industryStatistics.idleCount}
                    />
                </div>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default CompanyStatisticsView;
