import * as React from 'react';
import NewsAndUpdatesView from './news/NewsAndUpdatesView';
import '../../../styles/views/Home.scss';
import CompanyStatisticsView from './statistics/CompanyStatisticsView';
import MyDashboardView from './dashboard/MyDashboardView';
import { useState, useCallback, useEffect } from 'react';
import ReturnsView from './returns/ReturnsView';
import RecentlyCreatedConsumersView from './recentlyCreatedCustomers/RecentlyCreatedConsumersView';
import { DateRange } from '../../../types/common';
import { getDefaultDateRange } from '../../../utils/dateUtils';
import { useToasts } from 'react-toast-notifications';
import { Company } from '../../../types/company';
import { loadFromApi } from '../../../api/baseApi';
import { getCompany } from '../../../api/companyApi';

function HomePage() {
    const [viewAllReturns, setViewAllReturns] = useState(false);
    const [viewAllConsumers, setViewAllConsumers] = useState(false);
    const [company, setCompany] = useState<Company>();
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    
    const [dateRange, setDateRange] = useState<DateRange>(getDefaultDateRange());

    useEffect(() => {
        const companyPageApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {            
            const companyLoad = companyPageApiWrapper(getCompany, setCompany);
            await companyLoad;            
        };               
        loadCompanyPageData();                
    }, [setApiError]);


    
    const handleReturnsClick = () => {
        setViewAllConsumers(false);
        setViewAllReturns(!viewAllReturns);
    };
    const handleConsumersClick = () => {
        setViewAllReturns(false);
        setViewAllConsumers(!viewAllConsumers);
    };

    return (
        <div className="page-home">
            <div className="main-window">
                <div className="main-window-pane">
                    <NewsAndUpdatesView />
                </div>
                <div className="main-window-pane">
                    <CompanyStatisticsView dateRange={dateRange} setDateRange={setDateRange} />
                </div>
                <div className="main-window-pane">
                    <MyDashboardView dateRange={dateRange} coName={(company ? {name: company.name} : {name: "You"})}/>
                </div>
            </div>
            <div className="sidebar">
                <div>
                    <ReturnsView
                        viewAllReturns={viewAllReturns}
                        toggleViewAllReturns={handleReturnsClick}
                    />
                </div>
                <div>
                    <RecentlyCreatedConsumersView
                        viewAllNewConsumers={viewAllConsumers}
                        toggleViewAllNewConsumers={handleConsumersClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default HomePage;
