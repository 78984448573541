import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Payee } from '../../../../types/common';
import { FreeRefund } from '../../../../types/ledgers';
import { validateCurrency, validateNonNegative } from '../../../../utils/validationRules';
import WrappedInput from '../../../common/form/WrappedInput';
import NumericDropdown from '../../../common/NumericDropdown';
import TextDropdown from '../../../common/TextDropdown';
import { useEffect, useState } from 'react';
import { CheckBox } from '@syncfusion/ej2-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import AlertBanner from '../../../common/AlertBanner';
import { watch } from 'fs';

interface Props {
    refundSources: Payee[];
}

const FreeRefundFormInputs = (props: Props) => {
    const { register, control, setValue } = useFormContext<FreeRefund>();
    const destination = useWatch( {control, name: "destination", defaultValue: 1});
    const sendAsCheckchecked = useWatch( {control, name: "issueAsCheck"});

      useEffect(() => {
        setValue("destination", 1);
     }, []);

    return (
        <div className="consumer-input-section">
            <div>
                <WrappedInput<FreeRefund>
                    inputName="refundSourceId"
                    labelText="Refund Source"
                    required={true}
                >
                    <Controller
                        name="refundSourceId"
                        control={control}
                        rules={{
                            validate: a =>
                                (a !== 0 && a !== undefined) || 'Please select a Refund Source.'
                        }}
                        render={({ field: { onChange, value } }) => (
                            <NumericDropdown
                                options={props.refundSources.map(r => ({
                                    value: r.id,
                                    text: r.name
                                }))}
                                defaultOptionText="Select..."
                                selectedValue={value}
                                setSelectedValue={onChange}
                            />
                        )}
                    />
                </WrappedInput>
                <WrappedInput<FreeRefund> inputName="amount" labelText="Amount">
                    <input
                        type="number"
                        step="0.01"
                        {...register('amount', {
                            validate: {
                                positive: validateNonNegative,
                                currency: validateCurrency
                            },
                            valueAsNumber: true
                        })}
                    />
                </WrappedInput>
                <WrappedInput<FreeRefund> inputName="description" labelText="Description">
                    <input
                        type="text"
                        {...register('description')}
                    />
                </WrappedInput>
                <WrappedInput<FreeRefund> inputName="destination" labelText="Destination">
                <Controller
                        name="destination"
                        control={control}

                        render={({ field: { onChange, value } }) => (
                            <NumericDropdown
                                options={[
                                    {
                                        value: 1,
                                        text: "Consumer Savings/Trust"
                                    },

                                    {
                                        value: 2,
                                        text: "Consumer (bank account or check)"
                                    }
                                ]}
                                selectedValue={value}
                                setSelectedValue={onChange}
                            />
                        )}
                        
                    />
                </WrappedInput>
                <WrappedInput<FreeRefund> inputName="issueAsCheck" labelText="Issue as Check">
                        <input
                            type="checkbox"
                            checked={sendAsCheckchecked}
                            
                            {...register("issueAsCheck")}
                        />
                </WrappedInput>
                { sendAsCheckchecked && (destination === 1) && (
                    <AlertBanner message="You cannot refund to savings via check!"></AlertBanner>

                )}
            </div>
            <hr></hr>
            <div className="clear-fix">
                <input className="btn btn-primary float-left" type="submit" value="Submit" />
            </div>
        </div>
    );
};

export default FreeRefundFormInputs;


