import * as React from 'react';
import '../../../../styles/views/Home.scss';
import aboveAverageIcon from '../../../../assets/images/blue-square.png';
import belowAverageIcon from '../../../../assets/images/blue-circle.png';
import equalToAverageIcon from '../../../../assets/images/orange-line.png';

interface Props {
    label: string;
    value: number;
    aboveIndustryAveragePercent: number;
}

const StatisticCoreView = (props: Props) => {
    const industryAverageComparison = isNaN(props.aboveIndustryAveragePercent) // NaN happens when industry average === 0
        ? props.value === 0
            ? { icon: equalToAverageIcon, altText: 'Average' }
            : props.value > 0
            ? { icon: aboveAverageIcon, altText: 'Above Average' }
            : { icon: belowAverageIcon, altText: 'Below Average' }
        : props.aboveIndustryAveragePercent === 0
        ? { icon: equalToAverageIcon, altText: 'Average' }
        : props.aboveIndustryAveragePercent > 0
        ? { icon: aboveAverageIcon, altText: 'Above Average' }
        : { icon: belowAverageIcon, altText: 'Below Average' };

    return (
        <div>
            <label>{props.label}</label>
            <img src={industryAverageComparison.icon} alt={industryAverageComparison.altText} />
            {/* Use toLocaleString() to add in thousands place separators */}
            <div className="value">{props.value.toLocaleString()}</div>
        </div>
    );
};

export default StatisticCoreView;
