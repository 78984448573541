import * as React from 'react';
import SearchBar from '../../common/SearchBar';
import '../../../styles/views/Company.scss';
import { useCallback, useContext, useState } from 'react';
import { CheckInfo } from '../../../types/company';
import { useToasts } from 'react-toast-notifications';
import { checkImageUrl, getChecks, voidCheck } from '../../../api/checkApi';
import { loadFromApi } from '../../../api/baseApi';
import LoadingDisplay from '../../common/LoadingDisplay';
import {
    ColumnsDirective,
    ColumnDirective,
    ExcelExport,
    Inject,
    PdfExport,
    Toolbar,
    PdfQueryCellInfoEventArgs,
    ExcelQueryCellInfoEventArgs,
    ColumnModel,
    ExcelExportCompleteArgs,
    PdfExportCompleteArgs
} from '@syncfusion/ej2-react-grids';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import { InternalValueAccessor, useCommonGridOptions, vaWrapper } from '../../../utils/gridUtils';
import { serverDateToDisplayDate } from '../../../utils/dateUtils';
import { Consumer } from '../../../types/consumer';
import { getConsumer } from '../../../api/consumerApi';
import { nameToFormattedString } from '../../../utils/stringUtils';
import NewTabLink from '../../common/NewTabLink';
import CheckLink from '../../common/CheckLink';
import alertIcon from '../../../assets/images/alert.png';
import MessagePopup from '../../common/MessagePopup';
import { RowDataBoundEventArgs } from '@syncfusion/ej2-react-grids';
import { useHistory } from 'react-router-dom';
import { UserAuthorizationContext } from '../../UserAuthorizationService';
import { useEffect } from 'react';
import { pagePath, topLevelPages } from '../../../pages';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { UserPermission } from '../../../types/enums';

const CheckLookupPage = () => {
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasViewChecksPermissions = userHasPermission(UserPermission.ViewChecks);
    const history = useHistory();
    useEffect(() => {
        if (!hasViewChecksPermissions) {
            history.push(`${pagePath(topLevelPages.home)}`);
        }
    }, [hasViewChecksPermissions, history]);

    const gridOptions = useCommonGridOptions('Checks');
    const [checks, setChecks] = useState<CheckInfo[]>();
    const [consumers, setConsumers] = useState<Consumer[]>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const loadSearchResults = async (search: string) => {
        let checkNumber = parseInt(search);
        if (isNaN(checkNumber)) {
            setError('Check number must be numeric');
            return;
        }

        setLoading(true);

        let loadedChecks: CheckInfo[] | undefined = undefined;
        await loadFromApi(setError)(
            () => getChecks(checkNumber),
            c => (loadedChecks = c)
        );
        setChecks(loadedChecks);

        if (!loadedChecks) {
            setLoading(false);
            return;
        }

        const checkArray = loadedChecks as unknown as CheckInfo[];

        const uniqueConsumerIds =
            checkArray && Array.from(new Set(checkArray.map(c => c.consumerId).values()));
        let consumerList: Consumer[] = [];
        const promises = uniqueConsumerIds.map(id =>
            loadFromApi(setError)(
                () => getConsumer(id),
                consumer => consumerList.push(consumer)
            )
        );
        await Promise.all(promises);
        setConsumers(consumerList);
        setLoading(false);
    };

    const formatSentDate: InternalValueAccessor<CheckInfo> = (_field, check) =>
        serverDateToDisplayDate(check.datePrinted);

    const consumerName: InternalValueAccessor<CheckInfo> = (_field, check) => {
        const consumer = consumers && consumers.find(c => c.id === Number(check.consumerId));        
        return consumer
            ? nameToFormattedString(
                  consumer.contactInfo.primary.firstName,
                  consumer.contactInfo.primary.middleInitial,
                  consumer.contactInfo.primary.lastName
              )
            : '';
    };

    const FrontTemplate = (check: CheckInfo) => (
        <CheckLink url={checkImageUrl(check.frontImageFile)} fileName={check.frontImageFile ? check.frontImageFile : ''}>Front</CheckLink>
    );

    const BackTemplate = (check: CheckInfo) => (
        <CheckLink url={checkImageUrl(check.backImageFile)} fileName={check.backImageFile ? check.backImageFile : ''}>Back</CheckLink>
    );

    const VoidTemplate = (check: CheckInfo) => {
        const [showConfirm, setShowConfirm] = useState(false);

        const onConfirmClick = async () => {
            setLoading(true);
            //await voidCheck(Number(check.checkNumber), check.amount);            
            //let a: {};
            await loadFromApi(setError)(
                   () => voidCheck(Number(check.checkNumber), check.amount)
                   //c => (a = c)
            );          
            
            
            // update local check voided status
            setChecks(checks && checks.map(c => (c.id === check.id ? { ...c, void: true } : c)));

            setLoading(false);
        };        


        return (
            <div className="void-column">
                {check.void ? (
                    <img src={alertIcon} alt="Check is void" title="Check is void" />
                ) : check.userVoidable ? (
                    <button className="btn btn-secondary" onClick={() => setShowConfirm(true)}>
                    Void Check
                </button>                    
                ) : ("Cleared")}
                <MessagePopup
                    targetId="check-lookup-page"
                    message={`Are you sure you want to void this check to ${check.payee}?`}
                    showDialog={showConfirm}
                    setShowDialog={setShowConfirm}
                    requireConfirmation={true}
                    onConfirmClick={onConfirmClick}
                />
            </div>
        );
    };

    const onRowDataBound = (args?: RowDataBoundEventArgs) => {
        if (!args || !args.data || !args.row) {
            return;
        }

        const check = args.data as CheckInfo;
        if (check.void) {
            args.row.className = 'void-row';
        }
    };

    const setExportCellStyle = (
        propName: string,
        args?: PdfQueryCellInfoEventArgs | ExcelQueryCellInfoEventArgs
    ) => {
        if (!args || !args.data) {
            return;
        }

        const check = args.data as CheckInfo;
        if (check.void) {
            let fontColor = '#ba0000';
            args.style =
                propName === 'fontColor' ? { fontColor: fontColor } : { textBrushColor: fontColor };
        }
    };

    const customExcelQueryCellInfo = (args?: ExcelQueryCellInfoEventArgs) => {
        gridOptions.excelQueryCellInfo(args);
        setExportCellStyle('fontColor', args);
    };

    const customPdfQueryCellInfo = (args?: PdfQueryCellInfoEventArgs) => {
        gridOptions.pdfQueryCellInfo(args);
        setExportCellStyle('textBrushColor', args);
    };

    const customToolbarClick = (args?: ClickEventArgs) => {
        if (args?.item.text?.includes('Export')) {
            const columns = gridOptions.ref.current?.columns as ColumnModel[];
            if (columns) {
                columns[7].visible = false;
                columns[8].visible = false;
                columns[9].visible = false;
            }
        }
        gridOptions.toolbarClick(args);
    };

    const excelExportComplete = (args?: ExcelExportCompleteArgs) => {
        const columns = gridOptions.ref.current?.columns as ColumnModel[];
        if (columns) {
            columns[7].visible = true;
            columns[8].visible = true;
            columns[9].visible = true;
        }
    };

    const pdfExportComplete = (args?: PdfExportCompleteArgs) => {
        const columns = gridOptions.ref.current?.columns as ColumnModel[];
        if (columns) {
            columns[7].visible = true;
            columns[8].visible = true;
            columns[9].visible = true;
        }
    };

    return (
        <div className="page check-lookup" id="check-lookup-page">
            <h2 className="page-title">Check Lookup Page</h2>
            <div className="search-section">
                <SearchBar onSearch={loadSearchResults} type="number" />
                <span>Search by Check Number</span>
            </div>
            {loading ? (
                <LoadingDisplay />
            ) : (
                checks && (
                    <GridComponent
                        {...gridOptions}
                        width='1500px'
                        dataSource={checks}
                        rowDataBound={onRowDataBound}
                        toolbarClick={customToolbarClick}
                        excelQueryCellInfo={customExcelQueryCellInfo}
                        pdfQueryCellInfo={customPdfQueryCellInfo}
                        excelExportComplete={excelExportComplete}
                        pdfExportComplete={pdfExportComplete}
                    >
                        <ColumnsDirective>
                            <ColumnDirective
                                field="checkNumber"
                                headerText="Check #"
                                width="15%"
                                textAlign="Left"
                            />
                            <ColumnDirective
                                field="payee"
                                headerText="Payee"
                                textAlign="Left"
                                width="15%"
                            />
                            <ColumnDirective
                                field="address.streetAddress"
                                headerText="Address"
                                textAlign="Left"
                                width="25%"
                            />
                            <ColumnDirective
                                field="consumerId"
                                headerText="Consumer ID"
                                width="15%"
                                textAlign="Left"
                            />
                            <ColumnDirective
                                field="consumer.contactInfo.primary.firstName"
                                valueAccessor={vaWrapper(consumerName)}
                                headerText="Consumer Name"
                                textAlign="Left"
                                width="25%"
                            />
                            <ColumnDirective
                                field="amount"
                                headerText="Amount"
                                format="C2"
                                width="10%"
                                textAlign="Left"
                            />
                            <ColumnDirective
                                field="datePrinted"
                                valueAccessor={vaWrapper(formatSentDate)}
                                headerText="Sent"
                                width="10%"
                                textAlign="Left"
                            />
                            <ColumnDirective
                                field="frontImageExtension"
                                headerText="Front"
                                template={FrontTemplate}
                                width="10%"
                            />
                            <ColumnDirective
                                field="backImageExtension"
                                headerText="Back"
                                template={BackTemplate}
                                width="10%"
                            />
                            <ColumnDirective
                                field="void"
                                headerText="Status"
                                template={VoidTemplate}
                                width="15%"
                            />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, ExcelExport, PdfExport]} />
                    </GridComponent>
                )
            )}
        </div>
    );
};

export default CheckLookupPage;
