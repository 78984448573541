import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useCallback, useContext, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import { addConsumerFees } from '../../../../api/consumerApi';
import '../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../types/common';
import { luxonDateToServerDate } from '../../../../utils/dateUtils';
import WrappedForm from '../../../common/form/WrappedForm';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { ConsumerContext, FeeContext } from '../ConsumerPage';
import FeesFormInputs from './ConsumerFeesFormInputs';

interface Props extends ModalProps {
    fees: any;
    refreshGrid: () => void;
    feeTypes: any;
    payeeTypes: any;
    setShowDialog: any
    viewOnly: boolean;
}

const ConsumerAddFeesModal = (props: Props) => {
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);
    const [showError,setShowError] = useState(false);
    const { addToast } = useToasts();

    const onSubmit = async (data: any) => {
        let { paymentFrequency, numberofPayments, vendorPayeeNames, feeAmount,feeTypes,description,externalID,scheduleDate } = data;
        let feeType = props.feeTypes.filter((type: any) => type.name === feeTypes)[0]
        let vendorPayeeType = props.payeeTypes.filter((type: any) => type.name === vendorPayeeNames)[0]
            const payload= {
                "clientId": 0,
                feeAmount,
                payeeId : vendorPayeeType.id,
                numberofPayments,
                paymentFrequency,
                description,
                externalID,
                tCode: feeType.tCode,
                tSubCode: feeType.tSubCode.toString(),
                "scheduleDate": luxonDateToServerDate(scheduleDate)
            }
            setLoading(true);

            const loadConsumerFees = async () => {
                setLoading(true);
                const consumerFeesLoad: any = await addConsumerFees(consumer.consumerID,payload)
                setLoading(false);
                console.log('fgfdg', consumerFeesLoad)
                if(consumerFeesLoad.error || consumerFeesLoad.error == ''){
                    console.log('fgfdg9', consumerFeesLoad)
                    props.setShowDialog(false, true, consumerFeesLoad.error);
                }
                else{
                    props.setShowDialog(false, false, consumerFeesLoad.data.message);
                }
            };
    
            loadConsumerFees();
           
          
    };

   

    return (
        <DialogComponent
            header={'Add Consumer Fees'}
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
                setShowError(false);
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <WrappedForm<any>
                        initialValues={props.fees}
                        onSubmit={onSubmit}
                    >
                        <div>{loading ? <LoadingDisplay /> :                         
                        <FeesFormInputs 
                            feeTypes={props.feeTypes}
                            vendorPayeeNames={props.payeeTypes}
                            initialValues = {props.fees}
                            viewOnly={props.viewOnly}
                            showError={showError}/>
                        }
                        </div>
                    </WrappedForm>
                )
                }
            </div>
        </DialogComponent>
    );
};

export default ConsumerAddFeesModal;
