import * as React from 'react';
import '../../../../styles/views/Home.scss';
import MenuSelect from '../../../common/MenuSelect';
import { DashboardType, getDashboardDisplayName } from '../../../../enums/Dashboards';
import { useState } from 'react';
import { useEffect } from 'react';

interface Props {
    selectedDashboards: number[];
    setSelectedDashboards: (dashboards: number[]) => void;
}

interface DashboardSelection {
    type: DashboardType;
    label: string;
    selected: boolean;
}

const DashboardMenuSelect = (props: Props) => {
    const [customSelection, setCustomSelection] = useState<DashboardSelection[]>([]);

    useEffect(() => {
        if (props.selectedDashboards) {
            // start list with selected dashboards
            const selection: DashboardSelection[] = props.selectedDashboards.map(d => ({
                type: d,
                label: getDashboardDisplayName(d),
                selected: true
            }));

            // append all un-selected dashboard in order
            for (let dashboardType in DashboardType) {
                var type = Number(dashboardType);
                if (!isNaN(type)) {
                    if (!props.selectedDashboards.includes(type)) {
                        selection.push({
                            type: type,
                            label: getDashboardDisplayName(type),
                            selected: false
                        });
                    }
                }
            }
            setCustomSelection(selection);
        }
    }, [props.selectedDashboards]);

    const toggleSelection = (type: DashboardType) => {
        var selection = customSelection.map(s => {
            if (s.type === type) {
                return {
                    ...s,
                    selected: !s.selected
                };
            }
            return s;
        });
        setCustomSelection(selection);
    };

    const clearAll = () => {
        var selection = customSelection.map(s => {
            return {
                ...s,
                selected: false
            };
        });
        setCustomSelection(selection);
    };

    const updateSelections = () => {
        var selected = customSelection.filter(s => s.selected).map(s => s.type);
        props.setSelectedDashboards(selected);
    };

    return (
        <MenuSelect menuName="Customize" onClose={updateSelections} caretOnRight={true}>
            <div className="dashboard-menu-options">
                <div className="red-text-button" onClick={clearAll}>
                    Clear all
                </div>
                {customSelection.map(s => (
                    <div className="dashboard-selection" key={s.type}>
                        <input
                            id={s.label}
                            className="default-checkbox"
                            type="checkbox"
                            checked={s.selected}
                            onChange={() => toggleSelection(s.type)}
                        />
                        <div onClick={() => toggleSelection(s.type)}>{s.label}</div>
                    </div>
                ))}
            </div>
        </MenuSelect>
    );
};

export default DashboardMenuSelect;
