import * as React from 'react';
import '../../../styles/views/UserSettings.scss';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { AuthContext } from '../../../authentication/authContext';
import {GridViewContext} from '../../GridViewService';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { loadFromApi } from '../../../api/baseApi';
import { updatePreferredView } from '../../../api/userApi';

function UserSettingsPage() {
    const { changeView, currentView } = React.useContext(GridViewContext);
    const { changePassword, loggedInUser } = React.useContext(AuthContext);
    const [view, setView] = React.useState(1);
    const userViewPref:  { [key: string]: Object }[] = [
        { viewId: 1, viewType: 'Default View' },
        { viewId: 2, viewType: 'Comfort View' },
        { viewId: 3, viewType: 'Compact View' }
    ];
    const fields: object = { text: 'viewType', value: 'viewId' };
    const onChange = (args: any) =>{
        changeView && changeView(args.itemData.viewId);
        setView(args.itemData.viewId);
        if(loggedInUser){
            loadFromApi(()=> {})(() => 
            updatePreferredView(loggedInUser.userId,args.itemData.viewId ))
        }
    }
    return (
        <div className="page-user-preferences">
            <div className="main-window">
                <div className='row'>
                    <h2>User Settings</h2>
                </div>
                <div className='row email-section'>
                   <div className='first-column'>
                            <div>Registered Email</div>
                            <p>{loggedInUser && loggedInUser.email}</p>
                    </div>
                    {/* <div className='second-column'>
                           <p> <ButtonComponent> Change Email</ButtonComponent></p>
                    </div> */}
                </div>
                <div className='row password-section'>
                    <div className='first-column'>
                        <div>Change Password</div>
                        <p>
                            On Clicking change password your registered email will receive an email with instructions to change your password
                        </p>
                    </div>
                    <div className='second-column'>
                            <ButtonComponent onClick={changePassword}>Change Password</ButtonComponent>
                    </div>
                </div>
                <div className='row view-section'>
                   <div className='first-column'>
                        <div>Set Preferred View</div>
                    </div>
                    <div className='second-column'>
                        <DropDownListComponent change={onChange}
                         dataSource={userViewPref} fields={fields} value={currentView !==null && currentView}></DropDownListComponent>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettingsPage;
