import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ConsumerNote } from '../../../../types/consumer';
import { ConsumerNoteType } from '../../../../types/enums';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import '../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../types/common';

interface Props extends ModalProps {
    consumerNotes?: ConsumerNote[];
    consumerName: string;
    errorMessage?: string;
    loading: boolean;
}

const ConsumerNotesModal = (props: Props) => {
    function getNotesByCompany(notes: ConsumerNote[]): ConsumerNote[] {
        return notes.filter(note => note.noteType === ConsumerNoteType.Company);
    }
    function getNotesAddedByRAM(notes: ConsumerNote[]): ConsumerNote[] {
        return notes.filter(note => note.noteType === ConsumerNoteType.RAM);
    }
    return (
        <DialogComponent
            header={'RAM Notes for ' + props.consumerName}
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => props.setShowDialog(false)}
            width="800px"
            height="auto"
        >
            <div>
                {props.loading ? (
                    <LoadingDisplay />
                ) : props.consumerNotes ? (
                    <div className="consumer-notes">
                        <h3>Notes Added by Your Company</h3>
                        <hr></hr>
                        <div>
                            {getNotesByCompany(props.consumerNotes).length === 0 ? (
                                <span>No notes found.</span>
                            ) : (
                                getNotesByCompany(props.consumerNotes).map((note, i) => (
                                    <div key={i}>
                                        <span className="value">{note.text}</span>
                                        <div className="wrapped-info">
                                            <label>Added</label>
                                            <span className="value">{note.date}</span>
                                        </div>
                                        <div className="wrapped-info">
                                            <label>Added By</label>
                                            <span className="value">{note.addedByEmail}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                        <h3 className="ram-notes-title">Notes Added by RAM</h3>
                        <hr></hr>
                        {getNotesAddedByRAM(props.consumerNotes).map((note, i) => (
                            <div key={i}>
                                <span className="value">{note.text}</span>
                                <div className="wrapped-info">
                                    <label>Added</label>
                                    <span className="value">{note.date}</span>
                                </div>
                                <div className="wrapped-info">
                                    <label>Added By</label>
                                    <span className="value">{note.addedByEmail}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <LoadFailedDisplay />
                        <br />
                        {props.errorMessage && <span>Error Message: {props.errorMessage}</span>}
                    </div>
                )}
            </div>
        </DialogComponent>
    );
};

export default ConsumerNotesModal;
