import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../styles/common/ModalDialog.scss';
import { FeeUpdate, ModalProps } from '../../../../types/common';
import WrappedForm from '../../../common/form/WrappedForm';
import { Draft, DraftEdit } from '../../../../types/drafts';
import DraftFormInputs from './ConsumerDraftFormInputs';
import { useCallback, useContext, useState } from 'react';
import { ConsumerContext, FeeContext } from '../ConsumerPage';
import { getPropertyByString } from '../../../../utils/objectUtils';
import { addConsumerDraft, extendProgram, updateConsumerDraft } from '../../../../api/consumerApi';
import { useToasts } from 'react-toast-notifications';
import { luxonDateToServerDate, serverDateToLuxonDateTime } from '../../../../utils/dateUtils';
import LoadingDisplay from '../../../common/LoadingDisplay';

interface Props extends ModalProps {
    draft: Draft;
    refreshGrid: () => void;
    type?: string;
    drafts?: Draft[];
    viewOnly: boolean;
}

const ConsumerEditDraftModal = (props: Props) => {
    const feeGroups = useContext(FeeContext);
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);
    const [showError,setShowError] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onSubmit = async (data: any) => {
        //Pull a DraftUpdate object with the correct fee record from the DraftEdit object
        let { editFees, dateTime, ...draftUpdate } = data;
        let feesRecord: Record<number, number> = {};
        data.editFees.forEach(function (fee : any) {            
            feesRecord[fee.id] = fee.value ? fee.value : 0; //If blank text is entered fee.value will be NaN and the feeRecord value is set to 0
        });

        //Submit the DraftEdit to the API
        // Display loading spinner while submitted
            setLoading(true);
            let result = null;
            if(props.type=='extend'){
                let lastRecord = props.drafts && props.drafts[props.drafts.length-1];
                result = await extendProgram(consumer.consumerID,{...lastRecord, numberofPayments: draftUpdate.numberofPayments});
            }
            else{
                if (Number(props.draft.id) === Number(0)) {
                if((draftUpdate.paymentFrequency !== 0 && draftUpdate.numberofPayments && draftUpdate.numberofPayments > 1) 
                || draftUpdate.numberofPayments === 1){
                    draftUpdate = {
                        ...draftUpdate,
                        fees: feesRecord,
                        date: luxonDateToServerDate(data.dateTime)
                    };
                    result = await addConsumerDraft(consumer.consumerID, draftUpdate);
                }
                else{
                    setShowError(true)
                }
            } else {
                draftUpdate = {
                    savingsAmount: draftUpdate.savingsAmount,
                    fees: feesRecord,
                    date: luxonDateToServerDate(data.dateTime)
                };
                result = await updateConsumerDraft(consumer.consumerID, draftUpdate,props.draft.id);
            }
        }
            if (result && result.error) {
    
                setApiError(result.error);
    
            } else if(result) {           
                addToast(props.type=== 'extend' ? 'Program Extended': Number(props.draft.id) === Number(0) ? 'Draft Added' : 'Draft Updated', {
                    appearance: 'success',
                    autoDismiss: true
                });
                 //Close the modal
                 setShowError(false)
                props.setShowDialog(false);
            }
            setLoading(false);
          
            //If successful, trigger the grid to refresh
            if (result && !result.error) {
                props.refreshGrid();
            }
    };

    function getDraftEditFromDraft(draft: Draft): DraftEdit {
        let feeValues: FeeUpdate[] = [];
        feeGroups.forEach(function (group) {
            let feeValue = 0;
            if (Object.keys(props.draft.fees).includes(group.slot.toString())) {
                feeValue = getPropertyByString(props.draft.fees, group.slot.toString());                
            }
            feeValues.push({ id: group.slot, name: group.name, value: feeValue, edit:  false });
        });
        let draftEdit: DraftEdit = {
            date: props.draft.date,
            dateTime: serverDateToLuxonDateTime(props?.draft?.date ?? ''),
            savingsAmount: props.draft.savingsAmount,
            fees: [],
            editFees: feeValues,
            numberofPayments: props.draft?.['numberofPayments'],
            paymentFrequency: props.draft?.['paymentFrequency']
        };
        return draftEdit;
    }

    return (
        <DialogComponent
            header={ props.type == 'extend' ? 'Extend Program' : props.draft.id === 0 ? 'Add Consumer Draft' : 'Edit Consumer Draft'}
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
                setShowError(false);
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <WrappedForm<DraftEdit>
                        initialValues={getDraftEditFromDraft(props.draft)}
                        onSubmit={onSubmit}
                    >
                        <div>{loading ? <LoadingDisplay /> : 
                        <DraftFormInputs 
                        type={props.type=='extend' ? 'extend' :props.draft.id === 0 ? 'Add' : 'Edit'}
                        initialValues = {props.draft}
                        viewOnly={props.viewOnly}
                        showError={showError}/>}</div>
                    </WrappedForm>
                )}
            </div>
        </DialogComponent>
    );
};

export default ConsumerEditDraftModal;
