import * as React from 'react';
import { useState } from 'react';
import ConsumerScheduleProcessModal from './modals/ConsumerScheduleProcessModal';
import '../../../../styles/common/Elements.scss';

const ConsumerScheduleInfo = () => {
    const [showInfoModal, setShowInfoModal] = useState(false);
    return (
        <div>
            <button
                className="btn btn-secondary float-right"
                onClick={() => setShowInfoModal(true)}
            >
                How Are Schedules Generated
            </button>
            <ConsumerScheduleProcessModal
                targetId="consumerPage"
                showDialog={showInfoModal}
                setShowDialog={setShowInfoModal}
            />
        </div>
    );
};

export default ConsumerScheduleInfo;
