import * as React from 'react';
import {
    ChangeEventArgs,
    ComboBoxComponent,
    FilteringEventArgs
} from '@syncfusion/ej2-react-dropdowns';
import { Address } from '../../../../../types/common';
import { Creditor } from '../../../../../types/settlements';
import { defaultAddress } from '../../../../../utils/defaultData';
import AddressDisplay from '../../../../common/AddressDisplay';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CompanySettingsContext } from '../../../../CompanyService';
//Styles needed for the combobox
import '../../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css';
import '../../../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
import { useToasts } from 'react-toast-notifications';
import { getCreditors } from '../../../../../api/consumerApi';
import { debounce } from '@syncfusion/ej2-base';
import { getPropertyByString } from '../../../../../utils/objectUtils';

interface Props {
    viewOnly: boolean;
    selectedCreditorName: string;
    onSelectedCreditorNameChange: (creditorName: string, creditorId: any, status: any, creditorAddress: Address) => void;
    onEditCreditor: (creditor?: Creditor) => void;
    setEnablefields: (val?: any) => void;
    disable?: any;
}

interface CreditorOption {
    id: number;
    status: any;
    data: { name: string; address: Address };
}

const CreditorsDropdown = (props: Props) => {
    const { addToast } = useToasts();
    const companySettings = useContext(CompanySettingsContext);

    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const handleChange = (e?: ChangeEventArgs) => {                
        if (!e) {
            props.onSelectedCreditorNameChange('', 0, 0, { ...defaultAddress });
            return;
        }

        //No item data is a cleared event
        if (!e.itemData) {
            props.onSelectedCreditorNameChange('', 0, 0, { ...defaultAddress });
            return;
        }

        //Sanitize the change args
        if (
            !getPropertyByString(e.itemData, 'data.name') ||
            !getPropertyByString(e.itemData, 'data.address')
        ) {
            return;
        }

        let selectedCreditor = e.itemData as CreditorOption;
        props.onSelectedCreditorNameChange(
            selectedCreditor.data.name,
            selectedCreditor.id,
            selectedCreditor.status,
            selectedCreditor.data.address
        );
    };

    const creditorOptionTemplate = (creditorOption: CreditorOption) => (
        <div className="row">
            <div className="col-10">
                <AddressDisplay
                    name={creditorOption.data.name}
                    address={creditorOption.data.address}
                    className="creditor-dropdown"
                />
            </div>
            {companySettings.canEditCreditors && (
                <div className="col-2">
                    <button
                        className="btn btn-secondary float-right btn-edit-creditor"
                        onClick={() =>
                            props.onEditCreditor({
                                id: creditorOption.id,
                                name: creditorOption.data.name,
                                address: creditorOption.data.address
                            })
                        }
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );

    const getCreditorOptionsFromCreditors = (creditors: any[]) =>                        
        creditors.map(creditor => { 
            return { id: creditor.uid, 
                status: creditor.status,
                data: { name: creditor.payeeName, address:{
                streetAddress: creditor.payeeAddress,
                streetAddress2:creditor.payeeAddress2,
                state:creditor.payeeState,
                city: creditor.payeeCity,
                zip: creditor.payeeZIP
            } } };
        });

    const setDebounce = debounce((args: FilteringEventArgs) => {
        queryCreditors(args);
    }, 700);

    const queryCreditors = async (args: FilteringEventArgs) => {
        if(args.text.length > 3)
        {
        const result = await getCreditors(args.text);
        if (result.error) {
            setApiError(result.error);
            return;
        }

        if (!result.data) {
            return;
        }

        args.updateData(            
            getCreditorOptionsFromCreditors(                
                result.data.filter((creditor : any) => (creditor.payeeName.toLowerCase().includes(args.text.toLowerCase())) || (creditor.payeeAddress.toLowerCase().includes(args.text.toLowerCase())))
            )
        );
        }
        debounce(queryCreditors, 50);
    };

    const [newCreditor, setNewCreditor] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)

   
    useEffect(()=>{
        if(buttonClicked){
            props.setEnablefields(newCreditor)
            props.onSelectedCreditorNameChange(
                '',
                0,
                0,
                 {
                     streetAddress: '',
                     streetAddress2: '',
                     state: '',
                     city: '',
                     zip: '',
                 }
             );
        }
    }, [newCreditor])


    const onFiltering = (args?: FilteringEventArgs) => {
        if (!args) {
            return;
        }

        args.preventDefaultAction = true;
        setDebounce(args);
    };

    return (
        <div className="creditor-dropdown">
            {!newCreditor ? <ComboBoxComponent
                dataSource={[]}
                fields={{ text: 'data.name', value: 'id' }}
                itemTemplate={creditorOptionTemplate}
                noRecordsTemplate="Type to search creditors"
                change={handleChange}
                placeholder="Type to search creditors"
                text={props.selectedCreditorName}
                readonly={props.viewOnly}
                allowFiltering={true}
                filtering={onFiltering}
            /> :  <input onChange={(e)=>{
                props.onSelectedCreditorNameChange(
                    e.target.value,
                    0,
                    0,
                    {
                        streetAddress: '',
                        streetAddress2: '',
                        state: '',
                        city: '',
                        zip: '',
                    }
                );
            }}></input>}
             <a className='settlement-button' onClick={()=>{
                    setNewCreditor(!newCreditor);
                    setButtonClicked(true);         
                  
            }}>{ !newCreditor ? "Add New Creditor" : 'Use Existing Creditor'}</a>
        </div>
    );
};

export default CreditorsDropdown;
