import { FeeGroup, Fee } from '../types/feeGroupTypes';
import { httpRequest, Urls } from './baseApi';

const FEE_GROUPS_URL = `${Urls.BASE_URL}/FeeGroups`;

export const getFeeGroups = () => httpRequest<FeeGroup[]>(`${FEE_GROUPS_URL}`, 'get');

//pulls splits and details
export const getFeesByFeeGroup = (feeGroupId: number) =>
    httpRequest<Fee[]>(`${FEE_GROUPS_URL}/${feeGroupId}/Fees`, 'get');

export const getPayees = () => httpRequest<FeeGroup[]>(`${FEE_GROUPS_URL}/Payees`, 'get');

export const updateFeeGroup = (feeGroupId: number, newFeeGroupName: string) =>
    httpRequest<string>(`${FEE_GROUPS_URL}/${feeGroupId}`, 'put', newFeeGroupName);
