import * as React from 'react';
import { CreditCardChargeEdit, CreditCardView } from '../../../../../types/bankingInformation';
import {
    authStatusToDisplayName,
    cardNetworkToDisplayName
} from '../../../../../utils/consumerUtils';
import CreditCardAuthorization from './CreditCardAuthorization';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { chargeConsumerCard } from '../../../../../api/consumerBankingInfoApi';
import { ConsumerContext } from '../../ConsumerPage';
import WrappedForm from '../../../../common/form/WrappedForm';
import ChargeCardFormInputs from './ChargeCardFormInputs';
import { Fee } from '../../../../../types/feeGroupTypes';
import { getFeesByFeeGroup } from '../../../../../api/feeSplitApi';
import { loadFromApi } from '../../../../../api/baseApi';
import LoadingDisplay from '../../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../../common/LoadFailedDisplay';
import '../../../../../styles/common/ModalDialog.scss';
import '../../../../../styles/views/Consumer.scss';
import LoadingButton from '../../../../common/LoadingButton';

// The form cannot handle the "fees" Record property in CreditCardCharge
// To solve this, the form uses an intermediate "editFees" property, which is initialized here
function initializeCreditCardChargeEdit(consumerFees: Fee[]): CreditCardChargeEdit {
    let feesWithValues = consumerFees
        .sort((a, b) => a.slot - b.slot)
        .map(cf => ({ id: cf.slot, name: cf.name, value: 0 }));
    let creditCardChargeEdit: CreditCardChargeEdit = {
        savingsAmount: 0,
        cvv: '',
        fees: [],
        editFees: feesWithValues
    };
    return creditCardChargeEdit;
}

interface Props {
    consumerCard: CreditCardView;
    refreshConsumerCards: () => void;
    handleRemoveConsumerCard: (consumerCard: CreditCardView) => Promise<void>;
    viewOnly: boolean;
}

const ViewCreditCard = (props: Props) => {
    const consumer = useContext(ConsumerContext);

    const [consumerFees, setConsumerFees] = useState<Fee[]>();

    const [loading, setLoading] = useState(false);
    const [loadingRemoveConsumerCard, setLoadingRemoveConsumerCard] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        loadFromApi(setApiError, setLoading)(
            () => getFeesByFeeGroup(consumer.feeGroupId),
            setConsumerFees
        );
    }, [setApiError, consumer.feeGroupId]);

    const handleChargeConsumerCard = async (data: CreditCardChargeEdit) => {
        //Pull a CreditCardCharge object with the correct fee record from the CreditCardChargeEdit object
        let { editFees, ...creditCardCharge } = data;
        let feesRecord: Record<number, number> = {};
        data.editFees.forEach(function (editFee) {
            feesRecord[editFee.id] = editFee.value ? editFee.value : 0; //If blank text is entered fee.value will be NaN and the feeRecord value is set to 0
        });
        creditCardCharge = {
            ...creditCardCharge,
            fees: feesRecord
        };

        setLoading(true);
        const response = await chargeConsumerCard(
            consumer.consumerID,
            props.consumerCard.id,
            creditCardCharge
        );
        if (response.error) {
            setApiError(`Failed to charge card (${response.error})`);
        } else {
            addToast('Card charged', { appearance: 'success', autoDismiss: true });
        }
        setLoading(false);
    };

    const handleRemoveConsumerCardClick = async () => {
        setLoadingRemoveConsumerCard(true);
        await props.handleRemoveConsumerCard(props.consumerCard);
        setLoadingRemoveConsumerCard(false);
    };

    return (
        <div className="credit-card-container">
            <div className="card-row">
                <div>
                    <label>Card Last 4: </label>
                    <output>{props.consumerCard.lastFour}</output>
                </div>

                <div>
                    <label>Card Type: </label>
                    <output>{cardNetworkToDisplayName(props.consumerCard.cardNetwork)}</output>
                </div>

                <div>
                    <label>Expiration: </label>
                    <output>{props.consumerCard.expiry}</output>
                </div>
            </div>
            <div className="card-row">
                <div>
                    <label>Status: </label>
                    <output>{authStatusToDisplayName(props.consumerCard.authStatus)}</output>
                </div>
            </div>
            {!props.viewOnly ? (
                <div className="card-row">
                    <CreditCardAuthorization
                        cardId={props.consumerCard.id}
                        refreshConsumerCards={props.refreshConsumerCards}
                    />
                </div>
            ) : null}

            {!props.viewOnly && props.consumerCard.authOnFile ? (
                loading ? (
                    <LoadingDisplay />
                ) : consumerFees ? (
                    <WrappedForm<CreditCardChargeEdit>
                        onSubmit={handleChargeConsumerCard}
                        initialValues={initializeCreditCardChargeEdit(consumerFees)}
                    >
                        <ChargeCardFormInputs
                            feeGroupId={consumer.consumerID}
                            cardNetwork={props.consumerCard.cardNetwork}
                        />
                    </WrappedForm>
                ) : (
                    <LoadFailedDisplay />
                ) 
            )  : null}

            {!props.viewOnly ? (
                <div className="card-row">
                    <LoadingButton
                        text="Remove This Card"
                        loadingText="Removing Card..."
                        loading={loadingRemoveConsumerCard}
                        onClick={handleRemoveConsumerCardClick}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ViewCreditCard;
