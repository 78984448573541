import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { SettlementEdit } from '../../../../../types/settlements';

export interface Props {
    viewOnly: boolean;
}

const BankAccountFormInputs = (props: Props) => {
    const { register } = useFormContext<SettlementEdit>();

    return (
        <div className="row">
            <div className="col-6">
                <WrappedInput<SettlementEdit>
                    inputName="bankAccount.accountNumber"
                    labelText="Payee Bank Account # (for EFT)"
                    required={true}
                    helpText={`The creditor's bank account number. This is ONLY required for EFT payments.`}
                >
                    <input
                        readOnly={props.viewOnly}
                        {...register('bankAccount.accountNumber', {
                            required: 'Please enter an account number.'
                        })}
                    />
                </WrappedInput>
                <WrappedInput<SettlementEdit>
                    inputName="bankAccount.nameOnAccount"
                    labelText="Payee Bank Account Name (for EFT)"
                    required={true}
                    helpText={`The name on the creditor's bank account (such as ACME Inc.). This is ONLY required for EFT payments.`}
                >
                    <input
                        readOnly={props.viewOnly}
                        {...register('bankAccount.nameOnAccount', {
                            required: 'Please enter an account name.'
                        })}
                    />
                </WrappedInput>
            </div>
            <div className="col-6">
                <WrappedInput<SettlementEdit>
                    inputName="bankAccount.bankRouting"
                    labelText="Payee Bank Routing (for EFT)"
                    required={true}
                    helpText={`The 9-digit ABA routing number for the creditor's bank. This is ONLY required for EFT payments.`}
                >
                    <input
                        readOnly={props.viewOnly}
                        {...register('bankAccount.bankRouting', {
                            required: 'Please enter a routing number.'
                        })}
                    />
                </WrappedInput>
            </div>
        </div>
    );
};

export default BankAccountFormInputs;
