import * as React from 'react';
import { BankAccountType } from '../../../../types/enums';
import '../../../../styles/views/Consumer.scss';

interface Props {
    selectedAccountStatus: BankAccountType;
    setSelectedAccountStatus: (value: BankAccountType) => void;
    readOnly?: boolean;
}

interface bankAccountTypeDetails {
    name: string;
    value: BankAccountType;
}

export const bankAccountTypes: bankAccountTypeDetails[] = [
    { name: 'Select Account Type', value: BankAccountType.NotSet },
    { name: 'Checking', value: BankAccountType.Checking },
    { name: 'Savings', value: BankAccountType.Savings }
];

function BankAccountTypeDropdown(props: Props) {
    return (
        <div>
            <select
                className="dropdown-select"
                value={props.selectedAccountStatus}
                onChange={e => props.setSelectedAccountStatus(parseInt(e.currentTarget.value))}
                disabled={props.readOnly}
            >
                {bankAccountTypes.map(ba => (
                    <option key={ba.value} value={ba.value}>
                        {ba.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default BankAccountTypeDropdown;
