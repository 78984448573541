import { DateTime } from 'luxon-business-days';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DraftEdit } from '../../../../types/drafts';
import { DraftFrequency } from '../../../../types/enums';
import {
    validateCurrency,
    validateDraftDate,
    validateNonNegative
} from '../../../../utils/validationRules';
import DatePicker from '../../../common/DatePicker';
import WrappedInput from '../../../common/form/WrappedInput';

const DraftFormInputs = (props:any) => {
    const { register, control,getValues } = useFormContext<DraftEdit>();
    const { fields } = useFieldArray({
        control,
        name: 'editFees'
    });

    const [payments, setpayments] = useState(getValues('numberofPayments') ?? 1);
    useEffect(()=>{
        const element = document.getElementById('payments_text');
        element &&  element.addEventListener('change', () =>{
            const value = getValues('numberofPayments');
            setpayments(value);
            
        })
    },[])
    return (
        <div className="consumer-input-section">
            <div>
                {props.type!='extend' && <WrappedInput<DraftEdit> inputName="dateTime" labelText="Date" required={true}>
                    <Controller
                        name="dateTime"
                        control={control}
                        rules={{
                            required: 'Please select a date.',
                            validate: validateDraftDate
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                isDateRange={false}
                                initialStartDate={value}
                                changeDate={onChange}
                                minimumDate={DateTime.utc().plusBusiness()}
                            />
                        )}
                    />
                </WrappedInput>}
                {props.type!='Edit' && <WrappedInput<DraftEdit> inputName="numberofPayments" labelText="Number of Payments">
                    <input
                        id='payments_text'
                        type="number"
                        min={1}
                        max={260}
                        readOnly={props.viewOnly}
                        {...register('numberofPayments', {
                            valueAsNumber: true,
                        })}
                    />
                </WrappedInput>}
               {props.type == 'Add' && payments > 1 && <WrappedInput<DraftEdit>  required={true} inputName="PaymentsFrequency" labelText="Payments Frequency">
                         <select
                            className="dropdown-select"
                            disabled={props.viewOnly}
                            {...register('paymentFrequency',
                            {
                                valueAsNumber:true
                            })}>
                            <option value={DraftFrequency.NotSet}>Not Set</option>
                            <option value={DraftFrequency.Daily}>Daily</option>
                            <option value={DraftFrequency.Weekly}>Weekly</option>
                            <option value={DraftFrequency.Biweekly}>Biweekly</option>
                            <option value={DraftFrequency.Monthly}>Monthly</option>
                            <option value={DraftFrequency.Bimonthly}>Bimonthly</option>
                            <option value={DraftFrequency.Quarterly}>Quarterly</option>
                            <option value={DraftFrequency.Semiannually}>Semiannually</option>
                            <option value={DraftFrequency.Annually}>Annually</option>
                        </select>
                </WrappedInput>}
                {props.showError && props.type=='Add' && <span className='payment_error'>Payment Frequency is mandatory</span> }
                {props.type!='extend' && <WrappedInput<DraftEdit> inputName="savingsAmount" labelText="Savings Amount">
                    <input
                        type="number"
                        step=".01"
                        readOnly={props.viewOnly}
                        {...register('savingsAmount', {
                            validate: {
                                positive: validateNonNegative,
                                currency: validateCurrency
                            },
                            valueAsNumber: true
                        })}
                    />
                </WrappedInput>}
                {props.type!='extend' && fields.map((field, index) => (
                    <WrappedInput<DraftEdit>
                        key={field.id}
                        inputName={`editFees[${index}].value`}
                        labelText={field.name}
                    >
                        <input
                            type="number"
                            step=".01"
                            readOnly={props.viewOnly}
                            {...register(`editFees.${index}.value`, {
                                validate: {
                                    positive: validateNonNegative,
                                    currency: validateCurrency
                                },
                                valueAsNumber: true
                            })}
                            defaultValue={field.value}
                        />
                    </WrappedInput>
                ))}
            </div>
            <hr></hr>
            <div className="clear-fix">
                <input className="btn btn-primary float-left"  disabled={props.viewOnly} type="submit" value="Save" />
            </div>
        </div>
    );
};

export default DraftFormInputs;
