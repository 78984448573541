import * as React from 'react';
import '../../../../styles/views/Home.scss';
import { useState } from 'react';
import StatisticCoreView from './StatisticCoreView';

interface Props {
    label: string;
    value: number;
    industryAverage: number;
}

const StatisticView = (props: Props) => {
    const [showExpandedStatistic, setShowExpandedStatistic] = useState(false);

    // Possibility to divide-by-zero is intentionally left in (NaN is interpreted as a special case)
    const aboveIndustryAveragePercent = ((1.0 * props.value) / props.industryAverage - 1.0) * 100.0;

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setShowExpandedStatistic(true);
    };
    const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        setShowExpandedStatistic(false);
    };

    const roundToTwoDecimals = (value: number) => {
        return Number(Math.round(parseFloat(value + 'e2')) + 'e-2');
    };

    return (
        <div
            className="statistic-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {!showExpandedStatistic && (
                <StatisticCoreView
                    label={props.label}
                    value={props.value}
                    aboveIndustryAveragePercent={aboveIndustryAveragePercent}
                />
            )}

            {showExpandedStatistic && (
                <div className="statistic-expanded">
                    <StatisticCoreView
                        label={props.label}
                        value={props.value}
                        aboveIndustryAveragePercent={aboveIndustryAveragePercent}
                    />

                    <div className="details-container">
                        <div className="details">
                            <div>
                                <div>
                                    <label>Industry&nbsp;Average</label>
                                </div>
                                <div className="industry-average">
                                    {props.industryAverage.toLocaleString()}
                                </div>
                            </div>

                            {aboveIndustryAveragePercent === 0 ||
                            isNaN(aboveIndustryAveragePercent) ? null : (
                                <div>
                                    <div>
                                        <label>
                                            {aboveIndustryAveragePercent > 0
                                                ? 'Above Average'
                                                : 'Below Average'}
                                        </label>
                                    </div>
                                    <div className="above-average-percent">
                                        {/* Round to (up to) 2 decimal places */}
                                        {`${roundToTwoDecimals(aboveIndustryAveragePercent)}%`}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatisticView;
