import * as React from 'react';
import { ConsumerContext } from '../ConsumerPage';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getFullLedger, getLedgerPayees, refundLedgerItem } from '../../../../api/consumerApi';
import { useToasts } from 'react-toast-notifications';
import { useCommonGridOptions } from '../../../../utils/gridUtils';
import MessagePopup from '../../../common/MessagePopup';
import { LedgerEntry } from '../../../../types/ledgers';
import TextDropdown from '../../../common/TextDropdown';
import { Payee } from '../../../../types/common';
import { loadFromApi } from '../../../../api/baseApi';
import { nowAsServerDate } from '../../../../utils/dateUtils';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import FullLedgersGrid from './FullLedgersGrid';
import { UserPermission } from '../../../../types/enums';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';

interface BalancedLedger extends LedgerEntry {
    rowBalance: number;
}

const FullLedgers = (props: any) => {
    const consumer = useContext(ConsumerContext);
    const { addToast } = useToasts();
    const gridOptions = useCommonGridOptions('ConsumerLedgers');
    const ledgersGrid = gridOptions && gridOptions.ref;
    const [refundTransactionId, setRefundTransactionId] = useState(0);
    const [showRefundLedgerConfirmation, setShowRefundLedgerConfirmation] = useState(false);
    const [ledgers, setLedgers] = useState<BalancedLedger[]>();

    const [ledgerPayees, setLedgerPayees] = useState<Payee[]>([]);
    const [selectedLedgerPayee, setSelectedLedgerPayee] = useState<string>();
    const [filtered, setFiltered] = useState(false);

    const [loading, setLoading] = useState(false);

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasViewLedgerPermissions = userHasPermission(UserPermission.ViewLedger);
    useEffect(()=>{
        if(props.refresh == true){
            refreshGrid(selectedLedgerPayee !== undefined);
        }
    }, [props.refresh])

    useEffect(() => {
        if (refundTransactionId > 0) {
            setShowRefundLedgerConfirmation(true);
        }
    }, [refundTransactionId]);

    useEffect(() => {
        ledgersGrid.current?.refresh();
    }, [ledgers, ledgersGrid]);

    const refreshGrid = useCallback(
        (filter: boolean) => {
            const loadLedgers = async () => {
                const consumerPageApiWrapper = loadFromApi(
                    error => error && addToast(error, { appearance: 'error', autoDismiss: true }),
                    setLoading
                );

                const setLedgersWithBalance = (intermediateLedgers: LedgerEntry[]) => {
                    // Set the balance for each row
                    var balance = 0;
                    var balancedLedgers = intermediateLedgers.map(f => {
                        balance += f.amount;
                        return {
                            ...f,
                            rowBalance: balance
                        };
                    });
                    setLedgers(balancedLedgers);
                    props.refreshed()
                };

                consumerPageApiWrapper(
                    () =>
                        getFullLedger(
                            consumer.consumerID,
                            nowAsServerDate(),
                            filter ? selectedLedgerPayee : undefined
                        ),
                    setLedgersWithBalance
                );
            };

            loadLedgers();
        },
        [addToast, consumer.consumerID, selectedLedgerPayee]
    );

    // Initial load
    useEffect(() => {
        refreshGrid(selectedLedgerPayee !== undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Reload on filter
    const onFilter = () => {
        setFiltered(selectedLedgerPayee !== undefined);
        refreshGrid(selectedLedgerPayee !== undefined);
    };

    // /** Api Definitions **/
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const ledgerPayeeWrapper = loadFromApi(setApiError, setLoading);
        ledgerPayeeWrapper(() => getLedgerPayees(consumer.consumerID), setLedgerPayees);
    }, [setApiError, consumer.consumerID]);

    const onRefundClick = async () => {
        const result = await refundLedgerItem(consumer.consumerID, refundTransactionId);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Ledger item refunded', {
                appearance: 'success',
                autoDismiss: true
            });
            consumer.balanceChanged = true;
        }
        setRefundTransactionId(0);
    };

    return (
        <div>
            {loading ? (
                <LoadingDisplay />
            ) : !ledgers ? (
                <LoadFailedDisplay />
            ) : (
                <div>
                    {/* {hasViewLedgerPermissions && (
                        <>
                            <label className="margin-right-10">
                                Select a ledger payee to filter the list:
                            </label>
                            <TextDropdown
                                options={ledgerPayees.map(lp => ({
                                    value: lp.name,
                                    text: lp.name
                                }))}
                                defaultOptionText="Select..."
                                selectedValue={selectedLedgerPayee || undefined}
                                setSelectedValue={setSelectedLedgerPayee}
                            />
                            <button className="btn btn-primary margin-left-10" onClick={onFilter}>
                                Filter
                            </button>
                        </>
                    )} */}
                    <FullLedgersGrid
                        filtered={filtered}
                        ledgers={ledgers}
                        setRefundTransactionId={setRefundTransactionId}
                    />
                    <MessagePopup
                        message="Are you sure you want to refund this line item back to client trust?"
                        showDialog={showRefundLedgerConfirmation}
                        setShowDialog={setShowRefundLedgerConfirmation}
                        requireConfirmation={true}
                        onConfirmClick={onRefundClick}
                    />
                </div>
            )}
        </div>
    );
};

export default FullLedgers;
