import * as React from 'react';
import * as queryString from 'query-string';
import { useEffect } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getAffiliates } from '../../../api/affiliateApi';
import { loadFromApi } from '../../../api/baseApi';
import { getFeeGroups } from '../../../api/feeSplitApi';
import { getReportTypes } from '../../../api/reportApi';
import { Affiliate } from '../../../types/affiliate';
import { FeeGroup } from '../../../types/feeGroupTypes';
import { ReportType, RunReportFormFields, RunReportQueryParameters } from '../../../types/report';
import WrappedForm from '../../common/form/WrappedForm';
import LoadFailedDisplay from '../../common/LoadFailedDisplay';
import LoadingDisplay from '../../common/LoadingDisplay';
import NumericDropdown from '../../common/NumericDropdown';
import ReportConfigurationFormInputs from './ReportConfigurationFormInputs';
import { getDefaultDateRange, luxonDateToServerDate } from '../../../utils/dateUtils';
import { pagePath, reportingPages } from '../../../pages';
import '../../../styles/views/Reporting.scss';

const ReportConfigurationPage = () => {
    const [reportTypes, setReportTypes] = useState<ReportType[]>();
    const [affiliates, setAffiliates] = useState<Affiliate[]>();
    const [feeGroups, setFeeGroups] = useState<FeeGroup[]>();

    const [selectedReportTypeId, setSelectedReportTypeId] = useState<number>();
    const selectedReportType =
        reportTypes && reportTypes.find(rt => rt.reportTypeId === selectedReportTypeId);

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = React.useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const apiWrapper = loadFromApi(setApiError);
        const loadPageData = async () => {
            setLoading(true);
            const reportTypesResponse = apiWrapper(getReportTypes, setReportTypes);
            const affiliatesResponse = apiWrapper(getAffiliates, setAffiliates);
            const feeGroupsResponse = apiWrapper(getFeeGroups, setFeeGroups);
            await reportTypesResponse;
            await affiliatesResponse;
            await feeGroupsResponse;
            setLoading(false);
        };

        loadPageData();
    }, [setApiError]);

    const formInitialValues: RunReportFormFields = {
        consumerId: null,
        dateRange: getDefaultDateRange(),
        // For the following dropdown fields, undefined corresponds to the dropdown's default option
        affiliateId: undefined,
        feeGroupId: undefined,
        accountStatus: undefined
    };

    const handleSubmit = async (rawFormData: RunReportFormFields) => {
        if (selectedReportType) {
            const formattedFormData: RunReportQueryParameters = {
                reportId: selectedReportType.reportTypeId,
                consumerId:
                    !selectedReportType.consumerIdArg || rawFormData.consumerId === null
                        ? undefined
                        : rawFormData.consumerId.toString(),
                startDate:
                    !selectedReportType.dateRangeArg || !rawFormData.dateRange.start
                        ? undefined
                        : luxonDateToServerDate(rawFormData.dateRange.start),
                endDate:
                    !selectedReportType.dateRangeArg || !rawFormData.dateRange.end
                        ? undefined
                        : luxonDateToServerDate(rawFormData.dateRange.end),
                affiliateId:
                    !selectedReportType.affiliateIdArg || rawFormData.affiliateId === undefined
                        ? undefined
                        : rawFormData.affiliateId.toString(),
                feeGroupId:
                    !selectedReportType.feeGroupArg || rawFormData.feeGroupId === undefined
                        ? undefined
                        : rawFormData.feeGroupId.toString(),
                accountStatus:
                    !selectedReportType.accountStatusArg || rawFormData.accountStatus === undefined
                        ? undefined
                        : rawFormData.accountStatus.toString()
            };

            const stringifiedQuery = queryString.stringify(formattedFormData);

            window.open(`${pagePath(reportingPages.report)}?${stringifiedQuery}`, '_blank');
        }
    };

    return (
        <div className="reporting-page">
            <h2 className="page-title">Reporting</h2>
            <div>
                {loading ? (
                    <LoadingDisplay />
                ) : !reportTypes ? (
                    <LoadFailedDisplay />
                ) : (
                    <div className="row top-row">
                        <label>Select Report:</label>
                        <NumericDropdown
                            options={reportTypes.map(rt => ({
                                value: rt.reportTypeId,
                                text: rt.reportName
                            }))}
                            defaultOptionText="Select report..."
                            selectedValue={selectedReportTypeId || undefined}
                            setSelectedValue={setSelectedReportTypeId}
                        />
                    </div>
                )}
            </div>

            {selectedReportType && formInitialValues && affiliates && feeGroups && (
                <div>
                    <div className="row">
                        <label>Report Description:</label>
                        <p dangerouslySetInnerHTML={{ __html:selectedReportType.reportDescription}}>{}</p>
                    </div>

                    <WrappedForm<RunReportFormFields>
                        onSubmit={handleSubmit}
                        initialValues={formInitialValues}
                    >
                        <ReportConfigurationFormInputs
                            reportType={selectedReportType}
                            affiliates={affiliates}
                            feeGroups={feeGroups}
                        />
                    </WrappedForm>
                </div>
            )}
        </div>
    );
};

export default ReportConfigurationPage;
