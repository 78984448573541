import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UserRole } from '../../../../types/enums';
import { UserCreate } from '../../../../types/user';
import WrappedInput from '../../../common/form/WrappedInput';
import NumericDropdown from '../../../common/NumericDropdown';
import { validateUsername } from '../../../../utils/validationRules';

interface Props {
    isAffiliateUser?: boolean;
}

const AddUserFormInputs = (props: Props) => {
    const { register, control } = useFormContext<UserCreate>();
    return (
        <>
            <WrappedInput<UserCreate>
                inputName="username"
                labelText="Username"
                required={true}
                helpText="This is the username the user 
                must enter to log in to the RAM system. 
                Using an email address is recommended."
            >
                <input {...register('username', { 
                    required: true, 
                    validate: validateUsername
                    })} />
            </WrappedInput>

            <WrappedInput<UserCreate>
                inputName="email"
                labelText="Email"
                required={true}
                helpText="The user's email address."
            >
                <input
                    {...register('email', {
                        required: true,
                        validate: value =>
                            (value && value.includes('@')) ||
                            'User email address may not be valid, please check it.'
                    })}
                />
            </WrappedInput>

            {/* For Affiliate users, the form does not support editing the User Type */}
            {!props.isAffiliateUser && (
                <WrappedInput<UserCreate>
                    inputName="userRole"
                    labelText="User Type"
                    helpText="Select the user type for this user. 
                Unlimited users have access to all Affiliates 
                and screens and can make and save 
                changes to consumer information. 
                Limited users only have access to 
                screens and Affiliates that you specify."
                >
                    <Controller
                        control={control}
                        name="userRole"
                        render={({ field: { onChange, value } }) => (
                            <NumericDropdown
                                options={[
                                    { value: UserRole.CompanyUnlimited, text: 'Unlimited' },
                                    { value: UserRole.CompanyLimited, text: 'Limited' }
                                ]}
                                selectedValue={value}
                                setSelectedValue={onChange}
                            />
                        )}
                    />
                </WrappedInput>
            )}
        </>
    );
};

export default AddUserFormInputs;
