export const getPropertyByString = function (o: any, s: string) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

export function updateSelectedOptionsArray<T>(
    id: T,
    checked: boolean,
    currentSelectedOptions: T[]
): T[] {
    let newSelectedArray = currentSelectedOptions && [...currentSelectedOptions];
    if (checked && !newSelectedArray.includes(id)) {
        newSelectedArray = [...newSelectedArray, id];
    } else if (!checked && newSelectedArray.includes(id)) {
        newSelectedArray = newSelectedArray.filter(i => i !== id);
    }
    return newSelectedArray;
}
