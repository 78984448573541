import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import {
    getDashboardSelectedControls,
    updateDashboardSelectedControls
} from '../../../../api/dashboardApi';
import '../../../../styles/views/Home.scss';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import LoadingDisplay from '../../../common/LoadingDisplay';
import DashboardMenuSelect from './DashboardMenuSelect';
import DashboardView from '../../../common/dashboard/DashboardView';
import { DashboardType } from '../../../../enums/Dashboards';
import { DateRange, CompanyName } from '../../../../types/common';
interface Props {
    dateRange: DateRange;
    coName: CompanyName;
}

const defaultDashboardSelection = [
    DashboardType.DraftExecutionCount,
    DashboardType.DraftReturnCount,
    DashboardType.EnrollmentCount,
    DashboardType.StateBreakdown
];

const MyDashboardView = (props: Props) => {
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [selectedControls, setSelectedControls] = useState<number[] | null>();

    const updateSelectedControls = async (dashboards: number[]) => {
        setSelectedControls(dashboards);

        const apiResponse = await updateDashboardSelectedControls(dashboards);
        if (apiResponse.error) {
            setApiError(`Failed to save selection (${apiResponse.error})`);
        } else {
            addToast('Selection saved', { appearance: 'success', autoDismiss: true });
        }
    };

    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        // Set either the selection from the API or the default selection
        const setControls = (dashboardTypes: number[] | null) => {
            var convertedTypes = dashboardTypes
                ? dashboardTypes.length > 0
                    ? dashboardTypes
                    : defaultDashboardSelection
                : null;
            setSelectedControls(convertedTypes);
        };

        loadFromApi(setApiError, setLoading)(getDashboardSelectedControls, setControls);
    }, [addToast, setApiError]);

    return (
        <div>
            <div className="title-container-dashboard">
                <label className="title">My Dashboard</label>
                <DashboardMenuSelect
                    selectedDashboards={selectedControls ?? []}
                    setSelectedDashboards={updateSelectedControls}
                />
            </div>
            <div className="dashboard-charts">
                {loading ? (
                    <LoadingDisplay />
                ) : selectedControls ? selectedControls === null ? (
                    <div>
                        You have no dashboards selected. Click on "Customize" to select default
                        dashboards to appear on this page.
                    </div>
                ) : (
                    selectedControls.map(c => (
                        <DashboardView key={c} dashboardType={c} dateRange={props.dateRange} coName={props.coName} />
                    ))
                ) : (
                    <LoadFailedDisplay />
                )}
            </div>
        </div>
    );
};

export default MyDashboardView;
