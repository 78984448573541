import { AccountStatusDetails } from '../types/consumer';
import { AccountStatus, AuthStatus } from '../types/enums';
import { CardNetworkDetails } from '../types/consumer';
import { CardNetwork } from '../types/enums';

// Account Status - break enum up into name/value pairs for use in dropdown inputs
export const accountStatuses: AccountStatusDetails[] = [
    { name: 'Active', value: AccountStatus.Active },
    { name: 'Hold', value: AccountStatus.Hold },
    { name: 'Hold (Request)', value: AccountStatus.Hold_Request },
    { name: 'Cancelled', value: AccountStatus.Cancelled },
    { name: 'Completed', value: AccountStatus.Completed }
];

export const accountStatusToDisplayName = (accountStatusValue: AccountStatus) => {
    return accountStatuses.find(as => as.value === accountStatusValue)?.name || 'Unknown Status';
};

// Card Network - break enum up into name/value pairs for use in dropdown inputs
export const cardNetworks: CardNetworkDetails[] = [
    { name: 'Visa', value: CardNetwork.Visa },
    { name: 'MasterCard', value: CardNetwork.MasterCard },
    { name: 'Discover', value: CardNetwork.Discover },
    { name: 'American Express', value: CardNetwork.Amex }
];

export const cardNetworkToDisplayName = (cardNetworkValue: CardNetwork) => {
    const match = cardNetworks.find(as => as.value === cardNetworkValue);
    if (match) {
        return match.name;
    } else {
        return 'Unknown Network';
    }
};

export const authStatusToDisplayName = (authStatus: AuthStatus) => {
    switch (authStatus) {
        case AuthStatus.NotApproved:
            return 'Not Approved';
        case AuthStatus.Approved:
            return 'Approved';
        default:
            return 'Unknown Status';
    }
};
