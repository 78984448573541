import * as React from 'react';
import { useContext } from 'react';
import { nameToFormattedString } from '../../../../utils/stringUtils';
import AlertBanner from '../../../common/AlertBanner';
import { ConsumerContext } from '../ConsumerPage';

const ConsumerHeader = () => {
    const consumer = useContext(ConsumerContext);
    return (
        <div>
            {consumer.ramHold && (
                <AlertBanner message="This account is being held by RAM and will not draft even if you set both statuses to Active. Please contact Support for more information."></AlertBanner>
            )}
            <div className="row">
                <div className="col-3">
                    <div className="wrapped-info stacked">
                        <label>Consumer Name</label>
                        <h2>
                            {nameToFormattedString(
                                consumer.contactInfo.primary.firstName,
                                consumer.contactInfo.primary.middleInitial,
                                consumer.contactInfo.primary.lastName
                            )}
                        </h2>
                    </div>
                </div>
                <div className="col-3">
                    <div className="wrapped-info stacked">
                        <label>Consumer ID</label>
                        <h2>
                            {consumer.consumerID || consumer.consumerID === 0
                                ? consumer.consumerID
                                : ''}
                        </h2>
                    </div>
                </div>
                <div className="col-6">
                    <div className="wrapped-info stacked available-balance">
                        <label>Available Balance</label>
                        <div className="flex">
                            <h3>{'$' + consumer.savingsBalance}</h3>
                            {consumer.savingsPending > 0 && (
                                <span className="info">
                                    {'($' + consumer.savingsPending.toString() + ' pending)'}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>
        </div>
    );
};

export default ConsumerHeader;
