import * as React from 'react';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {    
    uploadKYC
} from '../../../api/consumerBankingInfoApi';
import { ConsumerContext } from '../consumer/ConsumerPage';
import InfoTooltip from '../../common/InfoTooltip';
import '../../../styles/views/Consumer.scss';
import LoadingButton from '../../common/LoadingButton';

interface Props {}

function KYCUpload(props: Props) {
    const consumer = useContext(ConsumerContext);
    
    const [authFile, setAuthFile] = useState<File>();
    const [loadingUploadAuth, setLoadingUploadAuth] = useState(false);    
    

    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );



    const onFilePickerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAuthFile(undefined);
        e.target.files && setAuthFile(e.target.files[0]);
    };

    const onAuthUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (authFile) {
            setLoadingUploadAuth(true);
            const response = await uploadKYC(consumer.consumerID, authFile);
            if (response.error) {
                setApiError(response.error);
            } else {
                addToast('KYC document added', { appearance: 'success', autoDismiss: true });                
            }
            setLoadingUploadAuth(false);
        }
    };


    return (
        <div>
            <div className="title-with-tooltip">
                <h4>KYC ID Upload</h4>
                <InfoTooltip
                    text="Use the controls below to upload a valid government-issued ID for this client. The file should be in PDF format." 
                />
            </div>

            <div className="row">
                <input
                    className="file-picker"
                    type="file"
                    accept=".pdf,.jpg"
                    onChange={onFilePickerChange}
                />
                <LoadingButton
                    text="Upload KYC"
                    loadingText="Uploading KYC..."
                    loading={loadingUploadAuth}
                    onClick={onAuthUpload}                    
                />               
            </div>
        </div>
    );
}

export default KYCUpload;
