import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import '../../../../styles/views/FeeView.scss';
import { FeeGroup } from '../../../../types/feeGroupTypes';
import { getFeeGroups, getPayees } from '../../../../api/feeSplitApi';
import FeesListView from './FeesListView';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import EditFeeGroup from './EditFeeGroup';
import { loadFromApi } from '../../../../api/baseApi';
import { useToasts } from 'react-toast-notifications';
import NumericDropdown from '../../../common/NumericDropdown';

const FeeSplitPage = () => {
    const [feeGroups, setFeeGroups] = useState<FeeGroup[]>();
    const [payees, setPayees] = useState<FeeGroup[]>();
    const [selectedFeeGroupId, setSelectedFeeGroupId] = useState<number>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const feeSplitPageApiWrapper = loadFromApi(setApiError);

        const loadFeeSplitPageData = async () => {
            setLoading(true);
            const feeGroupLoad = feeSplitPageApiWrapper(getFeeGroups, setFeeGroups);
            const payeeLoad = feeSplitPageApiWrapper(getPayees, setPayees);

            await feeGroupLoad;
            await payeeLoad;
            setLoading(false);
        };

        loadFeeSplitPageData();
    }, [setApiError]);

    const selectedFeeGroup = feeGroups && feeGroups.find(fg => fg.id === selectedFeeGroupId);

    const handleGroupNameEdited = () => {
        addToast('Fee Group name changed', { appearance: 'success', autoDismiss: true });
        loadFromApi(setApiError, setLoading)(getFeeGroups, setFeeGroups);
    };

    return (
        <div className="fee-split-page">
            <h2 className="page-title">Fee Split Groups</h2>
            {loading ? (
                <LoadingDisplay />
            ) : !feeGroups ? (
                <LoadFailedDisplay />
            ) : (
                <div className="fee-group-flex-container">
                    <label className="dropdown-label">Select Fee Group: </label>

                    <div>
                        <div className="row-container">
                            <div>
                                <NumericDropdown
                                    options={feeGroups.map(fg => ({ value: fg.id, text: fg.name }))}
                                    defaultOptionText="Select..."
                                    selectedValue={selectedFeeGroupId}
                                    setSelectedValue={setSelectedFeeGroupId}
                                />
                            </div>
                            <div>
                                <label className="api-id">API ID: {selectedFeeGroupId}</label>
                            </div>
                        </div>
                        <div>
                            {selectedFeeGroup && (
                                <EditFeeGroup
                                    currentFeeGroup={selectedFeeGroup}
                                    onUpdate={handleGroupNameEdited}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {selectedFeeGroupId && payees && (
                <FeesListView selectedFeeGroupId={selectedFeeGroupId} payees={payees} />
            )}
        </div>
    );
};

export default FeeSplitPage;
