import * as React from 'react';
import { Address } from '../../types/common';

interface Props {
    address: Address | null;
    name?: string;
    className?: string;
}

const AddressDisplay = (props: Props) => {
    return (
        <div className={`address-display ${props.className || ''}`}>
            {props.name && <span className="value name">{props.name}</span>}
            {props.address && (
                <div>
                    <span className="value street-address-1">{props.address.streetAddress}</span>
                    {props.address.streetAddress2 != null && (
                        <>
                            <br></br>
                            <span className="value street-address-2">
                                {props.address.streetAddress2}
                            </span>
                        </>
                    )}
                    <br></br>
                    <span className="value city-state-zip">
                        {`${props.address.city}, ${props.address.state} ${props.address.zip}`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default AddressDisplay;
