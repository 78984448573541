import { directToLogin, getToken, isTokenRefreshRequired } from '../authentication/authFunctions';

function buildRequest(
    requestUrl: string,
    method: string = 'get',
    body?: any,
    bodyIsFile?: boolean
): Request {
    let headers: Headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${getToken()}`); // Replace with localStorage.getItem('token')

    let requestBody: string | File | undefined = body;

    if (body && !bodyIsFile) {
        requestBody = JSON.stringify(body);
        headers.append('Content-Type', 'application/json');
    }

    return new Request(requestUrl, {
        method: method,
        body: requestBody,
        headers: headers
    });
}

async function checkStatus(response: Response): Promise<Response> {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    }         
    else {                
        let err = await response.json();        
        //well formed Reliant errors will have a json error object
        let error = err.error ?? new Error(err.detail) ?? new Error(err.error);
        throw error;
    }
}

function parseJSON<T>(response: Response): Promise<T | undefined> {
    if (response.status !== Number(204)) {
        return response.json() as Promise<T>;
    } 
    else if (response.status === Number(503)){
        return Promise.reject("Service unavailable, please wait and try again.");
    }
    else {
        return Promise.resolve(undefined);
    }
}

export interface ApiResult<T> {
    data?: T;
    error?: string;
}

export function httpRequest<T>(
    requestUrl: string,
    method: string = 'get',
    body?: any
): Promise<ApiResult<T>> {
    if (isTokenRefreshRequired()) {
        // Forward the user to https://ramidentity-stage.azurewebsites.net/account/login
        // and include parameter returnUrl – this will be the urlencoded URL to send the user after login is completed
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpDate');
        localStorage.removeItem('id');
        directToLogin();
    }

    let request = buildRequest(requestUrl, method, body);

    return fetch(request)
        .then(response => checkStatus(response))
        .then(response => parseJSON<T>(response))
        .then(data => ({ data }))
        .catch(error => ({ error: error.message }));
}

export const downloadFile: (
    requestUrl: string,
    filename: string,
    method?: string,
    selectedSettlements?: number[]
) => Promise<ApiResult<undefined>> = async (requestUrl, filename, method, selectedSettlements) => {
    const request = buildRequest(requestUrl, method,selectedSettlements);

    return fetch(request)
        .then(response => checkStatus(response))
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return {};
        })
        .catch(error => ({ error: error.message }));
};

//Check if a file is available for download, but do not download it
export const checkDownloadFile: (requestUrl: string) => Promise<ApiResult<boolean>> =
    async requestUrl => {
        const request = buildRequest(requestUrl);

        return fetch(request)
            .then(response => checkStatus(response))
            .then(response => {
                return { data: response.ok };
            })
            .catch(error => {
                return { data: false, error };
            });
    };

export const uploadFile: (requestUrl: string, file: File) => Promise<ApiResult<undefined>> = async (
    requestUrl,
    file
) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const request = buildRequest(requestUrl, 'POST', formData, true);

    return fetch(request)
        .then(response => checkStatus(response))
        .then(response => parseJSON<undefined>(response))
        .then(data => ({ data }))
        .catch(error => ({ error: error.message }));
};
const env = process.env;
const BASE_URL = env.REACT_APP_COMPANY_API_URL;

export const Urls = {
    BASE_URL
};

export const loadFromApi =
    (setError: (error?: string) => void, setLoading?: (loading: boolean) => void) =>
    async <T>(
        apiRequest: () => Promise<ApiResult<T>>,
        setData?: (data: T) => void,
        onSuccess?: () => void
    ) => {
        setLoading && setLoading(true);
        setError();
        const { data, error } = await apiRequest();
        if (error) {
            setError(error);
        } else if (onSuccess) {
            onSuccess();
        }
        if (data && setData) {
            setData(data);
        }
        setLoading && setLoading(false);
    };
