import * as React from 'react';
import { ConsumerContactInfo } from '../../../../types/consumer';
import { ConsumerContext } from '../ConsumerPage';
import { useContext, useState } from 'react';
import WrappedForm from '../../../common/form/WrappedForm';
import { updateConsumerContactInfo } from '../../../../api/consumerApi';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { serverDateToDisplayDate, getApiDateFormat } from '../../../../utils/dateUtils';
import { useToasts } from 'react-toast-notifications';
import { serverPhoneToInternalPhone } from '../../../../utils/phoneUtils';
import ContactFormInputs from './ContactFormInputs';
import { defaultConsumerContact } from '../../../../utils/defaultData';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { UserPermission } from '../../../../types/enums';

export interface Props {
    refreshHeader: (newContactInfo: ConsumerContactInfo) => void;
    loadConsumerPageData: () =>void;
}

const ConsumerContactInfoTab = (props: Props) => {
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const consumer = useContext(ConsumerContext);

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);

    const onSubmit = async (data: ConsumerContactInfo) => {

        // For Dates:
        // The API will reject an empty string; replace with null.
        // If non-empty, convert from "mm/dd/yyyy" format to "yyyy-mm-dd" format as the API expects.
        data.primary.birthDate = data.primary.birthDate
            ? getApiDateFormat(data.primary.birthDate)
            : null;
        if (data.secondary) {
            data.secondary.birthDate = data.secondary.birthDate
                ? getApiDateFormat(data.secondary.birthDate)
                : null;
        }
        const result = await updateConsumerContactInfo(consumer.consumerID, data);
        if (result.error) {
            addToast(result.error, { appearance: 'error', autoDismiss: true });
        } else {
            addToast('Company Contact Information Updated', {
                appearance: 'success',
                autoDismiss: true
            });
            props.refreshHeader(data); //Send the updated info to the page to refresh the header information
            props.loadConsumerPageData();
        }
        setReload(true);
        setTimeout(()=>{
            setReload(false);
        }, 600)
    };

    const formatConsumerContactInfo = (contactInfo: ConsumerContactInfo) => {
        let formattedInfo: ConsumerContactInfo = {
            ...contactInfo,
            primary: {
                ...consumer.contactInfo.primary,
                birthDate: serverDateToDisplayDate(
                    consumer.contactInfo.primary.birthDate,
                    undefined,
                    'LLddyyyy'
                ),
                phone: serverPhoneToInternalPhone(consumer.contactInfo.primary.phone)
            },
            secondary: consumer.contactInfo.secondary
                ? {
                      ...consumer.contactInfo.secondary,
                      birthDate: serverDateToDisplayDate(
                          consumer.contactInfo.secondary.birthDate,
                          undefined,
                          'LLddyyyy'
                      ),
                      phone: serverPhoneToInternalPhone(consumer.contactInfo.secondary.phone)
                  }
                : defaultConsumerContact,
            phone3:
                consumer.contactInfo.phone3 &&
                serverPhoneToInternalPhone(consumer.contactInfo.phone3)
        };
        return formattedInfo;
    };

    return (
        <div className="consumer-input-section">
            <h3>Contact Information</h3>
            {
                reload ?  <LoadingDisplay /> :             
                <WrappedForm<ConsumerContactInfo>
                    initialValues={formatConsumerContactInfo(consumer.contactInfo)}
                    onSubmit={onSubmit}
                >
                    {loading ? (
                        <LoadingDisplay />
                    ) : (
                        <ContactFormInputs viewOnly={!hasEditConsumerPermissions}
                        initialValues={formatConsumerContactInfo(consumer.contactInfo)}
                        />
                    )}
                </WrappedForm>}
        </div>
    );
};

export default ConsumerContactInfoTab;
