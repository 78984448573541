import * as React from 'react';
import { CardNetwork } from '../../../../../types/enums';
import { cardNetworks } from '../../../../../utils/consumerUtils';
import '../../../../../styles/views/Consumer.scss';

interface Props {
    selectedCardNetwork: CardNetwork;
    setSelectedCardNetwork: (value: CardNetwork) => void;
}

function CreditCardTypeDropdown(props: Props) {
    return (
        <div>
            <select
                className="dropdown-select"
                value={props.selectedCardNetwork}
                onChange={e => props.setSelectedCardNetwork(parseInt(e.currentTarget.value))}
            >
                {cardNetworks.map(cn => (
                    <option key={cn.value} value={cn.value}>
                        {cn.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default CreditCardTypeDropdown;
