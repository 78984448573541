import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../common/form/WrappedInput';
import { Affiliate } from '../../../types/affiliate';
import { FeeGroup } from '../../../types/feeGroupTypes';
import '../../../styles/Form.scss';
import '../../../styles/views/Consumer.scss';
import NumericDropdown from '../../common/NumericDropdown';
import { MAX_TEXT_BOX } from '../../../utils/formConstants';
import { ReportType, RunReportFormFields } from '../../../types/report';
import DateRangePickerFormWrapper from '../../common/DateRangePickerFormWrapper';
import { accountStatuses } from '../../../utils/consumerUtils';
import '../../../styles/views/Reporting.scss';
import '../../../styles/Form.scss';

interface Props {
    reportType: ReportType;
    affiliates: Affiliate[];
    feeGroups: FeeGroup[];
}

const ReportConfigurationFormInputs = (props: Props) => {
    const { register, control } = useFormContext<RunReportFormFields>();

    return (
        <div>
            <h4 className="row">Report Options</h4>
            <div className="row">
                <div className="col-6">
                    {props.reportType.consumerIdArg && (
                        <WrappedInput<RunReportFormFields>
                            inputName="consumerId"
                            labelText="Consumer ID"
                        >
                            <input
                                {...register('consumerId', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                    )}
                    {props.reportType.dateRangeArg && (
                        <WrappedInput<RunReportFormFields>
                            inputName="dateRange"
                            labelText="Date Range"
                        >
                            <Controller
                                name="dateRange"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <DateRangePickerFormWrapper changeDateRange={onChange} />
                                )}
                            />
                        </WrappedInput>
                    )}
                    {props.reportType.affiliateIdArg && (
                        <WrappedInput<RunReportFormFields>
                            inputName="affiliateId"
                            labelText="Affiliate"
                        >
                            <Controller
                                name="affiliateId"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <NumericDropdown
                                        options={props.affiliates.map(a => ({
                                            value: a.id,
                                            text: a.name
                                        }))}
                                        defaultOptionText={
                                            props.reportType.allAffiliatesOption
                                                ? 'All Affiliates'
                                                : 'Select Affiliate...'
                                        }
                                        selectedValue={value}
                                        setSelectedValue={onChange}
                                    />
                                )}
                            />
                        </WrappedInput>
                    )}
                    {props.reportType.feeGroupArg && (
                        <WrappedInput<RunReportFormFields>
                            inputName="feeGroupId"
                            labelText="Fee Group"
                        >
                            <Controller
                                name="feeGroupId"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <NumericDropdown
                                        options={props.feeGroups.map(fg => ({
                                            value: fg.id,
                                            text: fg.name
                                        }))}
                                        defaultOptionText="All Fee Groups"
                                        selectedValue={value}
                                        setSelectedValue={onChange}
                                    />
                                )}
                            />
                        </WrappedInput>
                    )}
                    {props.reportType.accountStatusArg && (
                        <WrappedInput<RunReportFormFields>
                            inputName="accountStatus"
                            labelText="Account Status"
                        >
                            <Controller
                                name="accountStatus"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <NumericDropdown
                                        options={accountStatuses.map(as => ({
                                            value: as.value,
                                            text: as.name
                                        }))}
                                        defaultOptionText="All Account Statuses"
                                        selectedValue={value}
                                        setSelectedValue={onChange}
                                    />
                                )}
                            />
                        </WrappedInput>
                    )}
                </div>
            </div>

            <hr></hr>
            <div className="clearFix">
                <input className="btn btn-primary float-left" type="submit" value="Run Report" />
            </div>
        </div>
    );
};

export default ReportConfigurationFormInputs;
