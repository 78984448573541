import * as React from 'react';
import {
    AccordionComponent,
    AccordionItemsDirective,
    AccordionItemDirective
} from '@syncfusion/ej2-react-navigations';
import {
    companySection,
    consumerSection,
    contactUsPageLink,
    dashboardSection,
    reportingPages,
    topLevelPages
} from '../../pages';
import { useNavigation } from '../../hooks/useNavigation';
import { SidenavSectionContent } from './SideNavSectionContent';
import SideNavHeaderLayout from './SideNavHeaderLayout';
import homeIcon from '../../assets/images/home.png';
import consumerIcon from '../../assets/images/consumers.png';
import reportingIcon from '../../assets/images/reporting.png';
import dashboardIcon from '../../assets/images/dashboards.png';
import companyIcon from '../../assets/images/company.png';
import contactIcon from '../../assets/images/contact.png';
import userSettingsIcon from '../../assets/images/usersettings.png';

const SideNavLayout = () => {
    const goToPage = useNavigation();
    const open = localStorage.getItem('sideBarOpen') !== 'false' ? true : false;
    const [value, setvalue] = React.useState(open);
    const localStorageSetHandler = function(e: any) {
        if(open !== null){
            setvalue(!open );
        }
    };
    document.addEventListener("hidePanel", localStorageSetHandler, false);

    return (
         <div className={`layout-first-column ${value ? 'sideBarOpen': ''}`} 
         onMouseEnter={() => {
            if(open === false){
                setvalue(true);               
            }          
         }}
         onMouseLeave={() => {
            if(open === false){    
                setvalue(false);                 
            } 
         }}>
            <AccordionComponent expandMode="Single">
                <AccordionItemsDirective>
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ? "Home" : '' }
                                icon={homeIcon}
                                onClick={() => goToPage(topLevelPages.home)}
                            />
                        )}
                    />
                    <AccordionItemDirective
                        header={() => <SideNavHeaderLayout label={value ?"Consumers" : ''} 
                        icon={consumerIcon} />}
                        content={() => <SidenavSectionContent section={ consumerSection } />}
                    />
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ?"Reporting":''}
                                icon={reportingIcon}
                                onClick={() => goToPage(reportingPages.reportConfiguration)}
                            />
                        )}
                    />
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout label={value ?"Dashboards":''} 
                            icon={dashboardIcon} />
                        )}
                        content={() => <SidenavSectionContent section={dashboardSection} />}
                    />
                    <AccordionItemDirective
                        header={() => <SideNavHeaderLayout label={value ?"Company":''} 
                        icon={companyIcon} />}
                        content={() => <SidenavSectionContent section={companySection} />}
                    />
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ?"Contact": ''}
                                icon={contactIcon}
                                onClick={() => {
                                    window.location.href = contactUsPageLink;
                                }}
                            />
                        )}
                    />
                    <AccordionItemDirective
                        header={() => (
                            <SideNavHeaderLayout
                                label={value ?"User Preferences": ''}
                                icon={userSettingsIcon}
                                onClick={() => {
                                    goToPage(topLevelPages.userSettings)
                                }}
                            />
                        )}
                    />
                </AccordionItemsDirective>
            </AccordionComponent>
        </div>
    );
};

export default SideNavLayout;
