import * as React from 'react';
import * as queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../api/baseApi';
import { getReportTypes, runReport } from '../../../api/reportApi';
import { Report, ReportType, RunReportQueryParameters } from '../../../types/report';
import LoadFailedDisplay from '../../common/LoadFailedDisplay';
import LoadingDisplay from '../../common/LoadingDisplay';
import ReportGrid from './ReportGrid';
import '../../../styles/views/Reporting.scss';

const ReportPage = () => {
    const [report, setReport] = useState<Report>();
    const [reportTypes, setReportTypes] = useState<ReportType[]>();
    const [selectedReport, setSelectedReport] = useState<ReportType>();

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = React.useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const queryStringFromURL = useMemo(() => {
        const qs = queryString.parse(window.location.search) as RunReportQueryParameters;
        return qs;
    }, []);

    useEffect(() => {
        let report = reportTypes?.filter((report) => queryStringFromURL.reportId ==report.reportTypeId );
        report && setSelectedReport(report[0])
    }, [reportTypes]);

    useEffect(() => {
        if (queryStringFromURL.reportId) {
            loadFromApi(setApiError, setLoading)(() => runReport(queryStringFromURL), setReport);
            loadFromApi(setApiError)(() => getReportTypes(), setReportTypes);
        }
    }, [setApiError, queryStringFromURL]);

    return (
        <div className="reporting-page">
            <h2 className="page-title">{selectedReport ? selectedReport.reportName : ''}</h2>
            <div>
                {loading ? (
                    <LoadingDisplay />
                ) : report ? report.data.length === 0 ? (
                    <p>No results to display</p>
                ) : (
                    <ReportGrid report={report} reportId={queryStringFromURL.reportId}/>
                ) : (
                    <LoadFailedDisplay />
                )}
            </div>
        </div>
    );
};

export default ReportPage;
