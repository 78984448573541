import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../../types/common';
import { Settlement } from '../../../../../types/settlements';
import {serverDateToDisplayDate} from '../../../../../utils/dateUtils';

interface Props extends ModalProps {
    settlement: Settlement;
}

const PaymentDetailsModal = (props: Props) => {
    return (
        <DialogComponent
            header="Payment Details"
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <div className="consumer-payment-details">
                        <div className="wrapped-info">
                            <label>Payee</label>
                            <span className="value">{props.settlement.payeeName}</span>
                        </div>
                        <div className="wrapped-info">
                            <label>Account and Ref</label>
                            <span className="value">{`${props.settlement.consumerAccountNumber} ${
                                props.settlement.referenceNumber
                                    ? '; ' + props.settlement.referenceNumber
                                    : ''
                            }`}</span>
                        </div>
                        <div className="wrapped-info">
                            <label>Payment Info</label>
                            <span className="value">Paid:&nbsp;{serverDateToDisplayDate(props.settlement?.sentDate)}</span>
                            
                        </div>
                    </div>
                )}
            </div>
        </DialogComponent>
    );
};

export default PaymentDetailsModal;
