import * as React from 'react';
import '../../styles/common/Elements.scss';
import '../../styles/components/LoadingButton.scss';

interface Props {
    text?: string;
    loadingText?: string;
    loading: boolean;
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const LoadingButton = (props: Props) => (
    <div className="loading-button">
        <button
            className="btn btn-secondary"
            onClick={props.onClick}
            disabled={Boolean(props.disabled)}
        >
            {props.loading ? props.loadingText : props.text}
        </button>
    </div>
);

export default LoadingButton;
