export function round(value: number, precision: number) {
    return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function sanitizeFractionDenominator(denominator: number, invalidFlag?: boolean) {
    if (denominator === 0) {
        if (invalidFlag) {
            invalidFlag = true;
        }
        denominator = 1;
    }
    return denominator;
}
