import { checkImage, CheckInfo } from '../types/company';
import { httpRequest, Urls } from './baseApi';

const CHECK_URL = `${Urls.BASE_URL}/Checks`;
export const CHECK_Image_URL = (consumerId: number) => `${CHECK_URL}/${consumerId}`;

export const getChecks = (checkNumber: number) =>
    httpRequest<CheckInfo[]>(`${CHECK_URL}?checkNumber=${checkNumber}`);

export const getCheckImage = (check: string,consumerid: number) =>  httpRequest<checkImage[]>(`${CHECK_Image_URL(consumerid)}/CheckImages?checkNumber=${check}`);
export const checkImageUrl = (extension: string) => `${CHECK_URL}/images/${extension}`;

export const voidCheck = (checkId: number, amount: number) =>
    httpRequest<{}>(`${CHECK_URL}/${checkId}/void?amount=${amount}`, 'post');
