import * as React from 'react';
import { useState } from 'react';
import '../../../../styles/views/Consumer.scss';
import { MAX_TEXT_AREA } from '../../../../utils/formConstants';

interface Props {
    addSupportTicket: (ticketDescription: string) => void;
}

const AddSupportTicketView = (props: Props) => {
    const [newTicketDescription, setNewTicketDescription] = useState<string>();

    const handleEditTicketDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewTicketDescription(e.currentTarget.value);
    };

    return (
        <div className="add-ticket-view">
            <h4>Add Ticket</h4>
            <div>
                <textarea
                    className="add-ticket-input"
                    placeholder="Enter ticket description"
                    value={newTicketDescription || ''}
                    onChange={handleEditTicketDescription}
                    rows={5}
                    cols={60}
                    maxLength={MAX_TEXT_AREA.value}
                />
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!newTicketDescription}
                    onClick={() =>
                        newTicketDescription && props.addSupportTicket(newTicketDescription)
                    }
                >
                    Add Ticket
                </button>
            </div>
        </div>
    );
};

export default AddSupportTicketView;
