import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { clearDashbardWarning, getDashbardWarnings } from '../../../../api/dashboardApi';
import { DashboardWarning } from '../../../../types/dashboard';
import WarningView from './WarningView';
import '../../../../styles/views/Home.scss';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';

const NewsAndUpdatesView = () => {
    const [activeWarnings, setActiveWarnings] = useState<DashboardWarning[]>();
    const [apiLoading, setApiLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const loadWarnings = useCallback(async () => {
        setActiveWarnings(undefined);
        const warningsResponse = await getDashbardWarnings();
        if (warningsResponse.error) {
            setApiError(warningsResponse.error);
        } else {
            setActiveWarnings(warningsResponse.data);
        }
    }, [setApiError]);

    useEffect(() => {
        const loadInitialWarnings = async () => {
            setApiLoading(true);
            await loadWarnings();
            setApiLoading(false);
        };
        loadInitialWarnings();
    }, [loadWarnings]);

    const clearWarning = async (warningId: number) => {
        setApiLoading(true);
        const clearWarningResponse = await clearDashbardWarning(warningId);
        if (clearWarningResponse.error) {
            setApiError(clearWarningResponse.error);
        }
        loadWarnings(); // Update active warnings for user feedback that the warning cleared
        setApiLoading(false);
    };

    const clearAllWarnings = async () => {
        if (activeWarnings) {
            setApiLoading(true);
            const promises = activeWarnings.map(w => clearDashbardWarning(w.id));
            const responses = await Promise.all(promises);
            responses.filter(r => r.error).map(r => setApiError(r.error));
            loadWarnings(); // Update active warnings for user feedback that all warnings cleared
            setApiLoading(false);
        }
    };

    return (
        <div>
            <label className="title-container-warnings">
                News/Updates
                <span>
                    {activeWarnings && activeWarnings.length > 0 && (
                        <button className="red-text-button" onClick={clearAllWarnings}>
                            Clear all
                        </button>
                    )}
                </span>
            </label>

            <div>
                {apiLoading ? (
                    <LoadingDisplay />
                ) : !activeWarnings ? (
                    <LoadFailedDisplay />
                ) : (
                    <ul>
                        {activeWarnings.length > 0 ? (
                            activeWarnings.map((w, index) => (
                                <WarningView key={index} warning={w} clearWarning={clearWarning} />
                            ))
                        ) : (
                            <label className="warning">No news or updates to display.</label>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default NewsAndUpdatesView;
