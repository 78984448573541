import * as React from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../api/baseApi';
import { getCheckImage } from '../../../api/checkApi';
import { checkImage } from '../../../types/company';
import '../../../styles/views/Check.scss';

function CheckImage() {

    const { addToast } = useToasts();
    const setApiError = React.useCallback(
        (error?: string) =>
            error &&
            addToast(`Failed to load Check images (${error})`, {
                appearance: 'error',
                autoDismiss: true
            }),
        [addToast]
    );

    const [Images, setImages] = useState<checkImage[]>();
    React.useEffect(()=>{
        const apiWrapper = loadFromApi(setApiError);
        const params = new URLSearchParams(window.location.search);
        const checknumber =params.get('checkNumber')
        const consumerId = Number(params.get('Consumer'))
        if(checknumber !== null && consumerId !== null){
            const loadPageData = async () => {
                await apiWrapper(()=> getCheckImage(checknumber,consumerId), setImages);
            };        
            loadPageData();
        }
        
    }, [])
    return (
        <div className="page-check-Image">
            {
                Images?.map((img) => {
                   return <img src={`data:${img.contentType};base64,${img.imageBytes}`}></img>
                })
            }
        </div>
    );
}

export default CheckImage;
