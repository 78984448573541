import * as React from 'react';
import SearchBar from '../../../common/SearchBar';
import * as queryString from 'query-string';
import { getConsumers } from '../../../../api/consumerApi';
import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';
import { Consumer } from '../../../../types/consumer';
import LoadingDisplay from '../../../common/LoadingDisplay';
import ConsumerSearchResultsGrid from './ConsumerSearchResultsGrid';
import { useConsumerNavigation } from '../../../../hooks/useConsumerNavigation';
import { useMemo } from 'react';
import '../../../../styles/components/TopBar.scss';
import '../../../../styles/views/Consumer.scss';
import { loadFromApi } from '../../../../api/baseApi';

interface QueryStringParams {
    searchText?: string;
}

const ConsumerSearchPage = () => {
    const [consumersSearchResult, setConsumersSearchResult] = useState<Consumer[]>();

    const navigateToConsumerPage = useConsumerNavigation();

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onSearch = useCallback(
        (searchText: string) => {
            setConsumersSearchResult(undefined);
            loadFromApi(setApiError, setLoading)(
                () => getConsumers(searchText),
                setConsumersSearchResult
            );
        },
        [setApiError]
    );

    // Searches are performed either by (1) entering a search query on this page's Search Bar, or (2) entering a search query on the global Search Bar.
    // If this page is loaded as a result of searching from the global Search Bar, the corresponding search query is parsed and then executed.
    const queryStringFromSearch = useMemo(() => {
        const qs = queryString.parse(window.location.search) as QueryStringParams;
        return qs;
    }, []);

    const initialSearch = queryStringFromSearch.searchText;

    useEffect(() => {
        if (initialSearch) {
            onSearch(initialSearch);
        }
    }, [initialSearch, onSearch]);

    useEffect(() => {
        if (consumersSearchResult && consumersSearchResult.length === 1) {
            addToast('One result found', { appearance: 'success', autoDismiss: true });
            navigateToConsumerPage(consumersSearchResult[0].id);
        }
    }, [consumersSearchResult, addToast, navigateToConsumerPage]);

    return (
        <div className="page consumer-search-page">
            <h2 className="page-title">Consumer Search</h2>
            <div className="search-section">
                <SearchBar onSearch={onSearch} initialValue={initialSearch} />
                <span>Search by First Name, Last Name, Consumer, etc.</span>
            </div>
            {loading ? (
                <LoadingDisplay />
            ) : !consumersSearchResult ? (
                // Means loading failed, or nothing has been searched for yet. Use toasts to indicate loading failed.
                <div />
            ) : consumersSearchResult.length === 0 ? (
                <p>No results to display</p>
            ) : (
                <ConsumerSearchResultsGrid consumers={consumersSearchResult} />
            )}
        </div>
    );
};

export default ConsumerSearchPage;
