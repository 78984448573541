import * as React from 'react';
import { DashboardType } from '../../../enums/Dashboards';
import { StateEnrollment } from '../../../types/dashboard';
import DashboardLoadFailedDisplay from './DashboardLoadFailedDisplay';
import LoadingDisplay from '../LoadingDisplay';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../api/baseApi';
import { getStateData } from '../../../api/dashboardApi';
import StateBreakdownMap from './StateBreakdownMap';

const StateBreakdownDashboard = () => {
    const [stateEnrollmentData, setStateEnrollmentData] = useState<StateEnrollment[]>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        const dashboardApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true }),
            setLoading
        );

        const loadDashboardSelections = async () => {
            await dashboardApiWrapper(getStateData, setStateEnrollmentData);
        };

        loadDashboardSelections();
    }, [addToast]);

    return loading ? (
        <LoadingDisplay />
    ) : !stateEnrollmentData ? (
        <DashboardLoadFailedDisplay dashboardType={DashboardType.StateBreakdown} />
    ) : (
        <StateBreakdownMap breakdownData={stateEnrollmentData} />
    );
};

export default StateBreakdownDashboard;
