import * as React from 'react';
import { DashboardType } from '../../../enums/Dashboards';
import StateBreakdownDashboard from './StateBreakdownDashboard';
import StandardDashboard from './StandardDashboard';
import { DateRange, CompanyName } from '../../../types/common';
interface Props {
    dashboardType: DashboardType;
    dateRange: DateRange;
    coName: CompanyName;
}

const DashboardView = (props: Props) => {
    return (
        <div className="dashboard-view">
            {props.dashboardType !== DashboardType.StateBreakdown ? (
                <StandardDashboard
                    dashboardType={props.dashboardType}
                    dateRange={props.dateRange}
                    coName={props.coName}
                />
            ) : (
                <StateBreakdownDashboard />
            )}
        </div>
    );
};

export default DashboardView;
