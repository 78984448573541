import * as React from 'react';
import '../../../../styles/views/Home.scss';
import { consumerRoutingPath, consumerSubPages } from '../../../../pages';
import { useHistory } from 'react-router-dom';
import { Consumer } from '../../../../types/consumer';
import { formatPhoneNumber } from '../../../../utils/stringUtils';

interface Props {
    consumer: Consumer;
}

const ConsumerView = (props: Props) => {
    const history = useHistory();

    return (
        <li
            className="card"
            onClick={() => {
                history.push(
                    consumerRoutingPath(props.consumer.consumerID.toString(), {
                        name: consumerSubPages.contactInfo.name,
                        extension: consumerSubPages.contactInfo.extension
                    })
                );
            }}
        >
            <div className="top-flex-container">
                <div className="consumer-id">{`Account: ${props.consumer.consumerID}`}</div>
            </div>
            <div className="bottom-flex-container">
                <div>
                    <div className="consumer-name">{props.consumer.contactInfo.primary.firstName + ' ' + props.consumer.contactInfo.primary.lastName}</div>
                </div>
                <div>
                    <div className="phone-number">{`${formatPhoneNumber(props.consumer.contactInfo.primary.phone)}`}</div>
                </div>
            </div>
        </li>
    );
};

export default ConsumerView;
