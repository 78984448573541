import * as React from 'react';
import '../../../../styles/views/Consumer.scss';
import { ConsumerContext} from '../ConsumerPage';
import { useCallback, useContext, useEffect, useState } from 'react';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { ApiResult, loadFromApi } from '../../../../api/baseApi';
import {
    deleteConsumerSettlement,
    getConsumerSettlements,
    getSettlementCheckImagesUrl
} from '../../../../api/consumerApi';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';
import MessagePopup from '../../../common/MessagePopup';
import { Creditor, Settlement } from '../../../../types/settlements';
import SettlementsGrid from './SettlementsGrid';
import { UserPermission } from '../../../../types/enums';
import { defaultCreditor, defaultSettlement } from '../../../../utils/defaultData';
import { AuthContext } from '../../../../authentication/authContext';
import SettlementEditModal from './modals/SettlementEditModal';
import SettlementEditCreditorModal from './modals/SettlementEditCreditorModal';
import NewTabLink from '../../../common/NewTabLink';
import { DateTime } from 'luxon';
import { luxonDateToServerDate, nowAsServerDate } from '../../../../utils/dateUtils';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';

const ConsumerSettlementInfoTab = () => {
    const { addToast } = useToasts();
    const consumer = useContext(ConsumerContext);

    const [loading, setLoading] = useState(false);

    const [settlements, setSettlements] = useState<Settlement[]>([]);

    const [selectedSettlementIds, setSelectedSettlementIds] = useState<number[]>([]);
    const [editSettlementModalId, setEditSettlementModalId] = useState(0);
    const [selectedCreditor, setSelectedCreditor] = useState<Creditor>();

    const [showAddEditSettlementModal, setShowAddEditSettlementModal] = useState(false);
    const [showEditCreditorModal, setShowEditCreditorModal] = useState(false);

    const [showDeleteSettlementsConfirmation, setShowDeleteSettlementsConfirmation] =
        useState(false);

    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const [settlementIds, setsettlementIds] = useState<number[]>([]);
    const { isUnlimitedUser } = useContext(AuthContext);
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditSettlementsPermissions = userHasPermission(UserPermission.EditSettlements);

    const refreshGrid = useCallback(() => {
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );
        const loadConsumerSettlements = async () => {
            setLoading(true);
            const settlementsLoad = consumerPageApiWrapper(
                () => getConsumerSettlements(consumer.consumerID),
                setSettlements
            );
            await settlementsLoad;
            setSelectedSettlementIds([]); //Start with none selected
            setLoading(false);
        };

        loadConsumerSettlements();
    }, [addToast, consumer.consumerID]);

    const onDeleteSettlements = async () => {
        //Call delete settlement for all checked settlements
        let deleteSettlements = selectedSettlementIds.map((id: number) =>
            deleteConsumerSettlement(consumer.consumerID, id)
        );

        //Await all delete settlement calls complete
        let result = await Promise.all(deleteSettlements);

        //Parse the result to see if any failures occured
        let failure = false;
        result.reduce(function (_prevResult: ApiResult<{}>, settlementResult: ApiResult<{}>) {
            if (settlementResult.error && !failure) {
                //Only display the first error to prevent errors from accumulating on every settlement
                failure = true;
                setApiError(settlementResult.error);
            }
            return settlementResult;
        });
        if (!failure) {
            //If no failures occured then display a success banner
            addToast('Settlements deleted', { appearance: 'success', autoDismiss: true });
            //Refresh the grid so that the deleted settlements are no longer present. If the api fails the items will remain in the grid
            refreshGrid();
        }
    };

    const onSettlementVoided = (settlementId: number) => {
        var updatedSettlements = settlements.map(s => ({
            ...s,
            void: s.void || s.id === settlementId
        }));
        setSettlements(updatedSettlements);
    };

    useEffect(() => {
        refreshGrid();
    }, [refreshGrid]);
    const onDownloadCheckImages = () => {
        
        getSettlementCheckImagesUrl(consumer.consumerID, settlementIds)
    };
    return (
        <div className="consumer-input-section">
            {loading ? (
                <LoadingDisplay />
            ) : consumer && settlements ? (
                <div>
                    <div className="clear-fix settlement-header-bar">
                        <div className="float-left">
                            <h3>Settlement Info</h3>
                        </div>
                        <div className="float-right">
                            {hasEditSettlementsPermissions && (
                            <button
                                className="btn btn-primary margin-right-10"
                                onClick={() => {
                                    setEditSettlementModalId(0);
                                    setShowAddEditSettlementModal(true);
                                }}
                            >
                                Add New
                            </button>
                    )}
                            {hasEditSettlementsPermissions && (
                                <button
                                    className="btn btn-outline-primary margin-right-10"
                                    disabled={selectedSettlementIds.length <= 0}
                                    onClick={() => {
                                        setShowDeleteSettlementsConfirmation(true);
                                    }}
                                >
                                    Delete
                                </button>
                            )}
                            <button className="btn btn-outline-primary margin-right-10" onClick={onDownloadCheckImages}>
                                Download All Images
                             </button>
                        </div>
                    </div>
                    <SettlementsGrid
                        settlementIds = {
                            (ids: number[]) =>{
                                setsettlementIds(ids);
                            }
                        }
                        settlements={settlements}
                        setSelectedSettlementIds={setSelectedSettlementIds}
                        onEditSettlement={(id: number) => {
                            setEditSettlementModalId(id);
                            setShowAddEditSettlementModal(true);
                        }}
                        hasEditPermissions={hasEditSettlementsPermissions}
                        hasUnlimitedPermissions={isUnlimitedUser()}
                        onSettlementVoided={onSettlementVoided}
                    />
                    <MessagePopup
                        targetId="consumerPage"
                        message="Are you sure you want to delete the selected settlements?"
                        showDialog={showDeleteSettlementsConfirmation}
                        setShowDialog={setShowDeleteSettlementsConfirmation}
                        requireConfirmation={true}
                        onConfirmClick={onDeleteSettlements}
                    />
                    <SettlementEditModal
                        settlement={
                            settlements.find(s => s.id === editSettlementModalId) || {
                                ...defaultSettlement,
                                sentDate: nowAsServerDate(),
                                clearedDate: nowAsServerDate(),
                                dueDate: luxonDateToServerDate(DateTime.utc().plus({ days: 3 }))
                            }
                        }
                        refreshGrid={() => {
                            refreshGrid();
                        }}
                        targetId="consumerPage"
                        showDialog={showAddEditSettlementModal}
                        setShowDialog={setShowAddEditSettlementModal}
                        viewOnly={!hasEditSettlementsPermissions}
                        onEditCreditor={(creditor?: Creditor) => {
                            setSelectedCreditor(creditor || defaultCreditor);
                            setShowAddEditSettlementModal(false);
                            setShowEditCreditorModal(true);
                        }}
                    />
                    <SettlementEditCreditorModal
                        creditor={selectedCreditor ? selectedCreditor : defaultCreditor}
                        onClose={() => setShowAddEditSettlementModal(true)}
                        targetId="consumerPage"
                        showDialog={showEditCreditorModal}
                        setShowDialog={setShowEditCreditorModal}
                    />
                </div>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default ConsumerSettlementInfoTab;
