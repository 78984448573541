import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useCallback, useContext, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { bulkUpdateDrafts } from '../../../../api/consumerApi';
import '../../../../styles/common/ModalDialog.scss';
import { FeeUpdate, ModalProps } from '../../../../types/common';
import { Draft, DraftBulkEdit, DraftBulkUpdate } from '../../../../types/drafts';
import { getPropertyByString } from '../../../../utils/objectUtils';
import WrappedForm from '../../../common/form/WrappedForm';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { ConsumerContext, FeeContext } from '../ConsumerPage';
import DraftBulkEditFormInputs from './ConsumerDraftBulkEditFormInputs';
import { DateTime } from 'luxon-business-days';

interface Props extends ModalProps {
    drafts: Draft[];
    refreshGrid: () => void;
    viewOnly: boolean;
}

const ConsumerBulkEditDraftModal = (props: Props) => {
    const consumer = useContext(ConsumerContext);
    const feeGroups = useContext(FeeContext);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    function getDraftBulkEditFromDrafts(drafts: Draft[]): DraftBulkEdit {
        //Pull draftBulkUpdate object from list of drafts
        let bulkUpdate: DraftBulkUpdate = {
            draftIds: drafts.map(draft => draft.id),
            savingsAmount: 0,
            draftAmountChanged: false,
            savingsAmountChanged: false,
            feesChanged: {},
            draftAmount: 0,
            fees: {},
            draftDateChanged: false,
            draftDate: DateTime.utc().plusBusiness({ days: 1 }).toUTC(),
            paymentFrequency: 0,
            draftDateDayofMonthChanged: false,
            // draftDateDayOfMonth: 1
        };

        //Pull draftBulkEdit object from draftBulkUpdate
        let feeValues: FeeUpdate[] = [];
        feeGroups.forEach(function (group) {
            let feeValue = 0;
            if (Object.keys(bulkUpdate.fees).includes(group.slot.toString())) {
                feeValue = getPropertyByString(bulkUpdate.fees, group.name.toString());
            }
            feeValues.push({ id: group.slot, name: group.name, value: feeValue, edit: false });
        });
        let draftBulkEdit = {
            ...bulkUpdate,
            editFees: feeValues
        };

        return draftBulkEdit;
    }

    const onSubmit = async (data: DraftBulkEdit) => {
        //Pull a DraftBulkUpdate object with the correct fee record from the DraftBulkEdit object
        let { editFees,  ...draftUpdate } = data;
        let feesRecord: Record<number, number> = {};
        let feesChanged: Record<number, boolean> = {};
        data.editFees.forEach(function (fee) {
            feesRecord[fee.id] = fee.value ? fee.value : 0; //If blank text is entered fee.value will be NaN and the feeRecord value is set to 0
        });
        data.editFees.forEach(function (fee) {
            feesChanged[fee.id] = fee.edit ? fee.edit : false; //If blank text is entered fee.value will be NaN and the feeRecord value is set to 0
        });
        draftUpdate = {
            ...draftUpdate,
            fees: feesRecord,
            feesChanged
        };

        //Submit the DraftBulkUpdate to the API
        // Display loading spinner while submitted
        setLoading(true);
        draftUpdate.draftDate = draftUpdate.draftDate.set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
        let result = await bulkUpdateDrafts(consumer.consumerID, draftUpdate);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Drafts Updated', {
                appearance: 'success',
                autoDismiss: true
            });
        }
        setLoading(false);

        //Close the modal
        props.setShowDialog(false);

        //If successful, trigger the grid to refresh
        if (!result.error) {
            props.refreshGrid();
        }
    };

    return (
        <DialogComponent
            header="Edit Checked Items"
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <WrappedForm<DraftBulkEdit>
                        initialValues={getDraftBulkEditFromDrafts(props.drafts)}
                        onSubmit={onSubmit}
                    >
                        <div>{loading ? <LoadingDisplay /> : <DraftBulkEditFormInputs viewOnly={props.viewOnly}/>}</div>
                    </WrappedForm>
                )}
            </div>
        </DialogComponent>
    );
};

export default ConsumerBulkEditDraftModal;
