import * as React from 'react';
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    CheckBoxChangeEventArgs,
    ColumnDirective,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,
    Edit,
    ExcelExport,
    ExcelQueryCellInfoEventArgs,
    Filter,
    FilterSettingsModel,
    Grid,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    PdfQueryCellInfoEventArgs,
    Resize,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    Toolbar
} from '@syncfusion/ej2-react-grids';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
    formatDate,
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';
import { Settlement } from '../../../../types/settlements';
import { paymentTypeToString } from '../../../../utils/stringUtils';
import NewTabLink from '../../../common/NewTabLink';
import { checkImageUrl } from '../../../../api/checkApi';
import MessagePopup from '../../../common/MessagePopup';
import { useToasts } from 'react-toast-notifications';
import { ConsumerContext } from '../ConsumerPage';
import {GridViewContext} from '../../../GridViewService';
import { advanceConsumerSettlement, voidConsumerSettlement } from '../../../../api/consumerApi';
import PaymentDetailsModal from './modals/PaymentDetailsModal';
import { getSettlementGridViewFromSettlements } from '../../../../utils/settlementUtils';
import { TooltipComponent } from '@syncfusion/ej2-react-popups/src/tooltip';
interface Props {
    settlements: Settlement[];
    // selectedSettlementIds: number[];
    setSelectedSettlementIds: (newSelectedSettlementIds: number[]) => void;
    onEditSettlement: (settlementId: number) => void;
    hasEditPermissions: boolean;
    hasUnlimitedPermissions: boolean;
    onSettlementVoided: (draftId: number) => void;
    changeView?:number|null|undefined;
    settlementIds: (ids: number[]) => void;
}

enum Command {
    None = 'None',
    Edit = 'Edit',
    Info = 'Info',
    Advance = 'Advance and Pay',
    Void = 'Void'
}

interface SettlementCommand {
    id: number;
    command: string;
}




const SettlementsGrid = (props: Props) => {
    const consumer = useContext(ConsumerContext);
    const gridOptions = useCommonGridOptions('ConsumerSettlements');
    const settlementsGrid = gridOptions && gridOptions.ref; //any =React.useRef<GridComponent>(null);
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };

    const [settlementCommand, setSettlementCommand] = useState<SettlementCommand>({
        id: 0,
        command: Command.None
    });
    const [showVoidConfirmation, setShowVoidConfirmation] = useState(false);
    const [showAdvanceConfirmation, setShowAdvanceConfirmation] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [advancedSettlements, setAdvancedSettlements] = useState<number[]>([]);

    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onVoid = async (settlementId: number) => {       
        const result = await voidConsumerSettlement(consumer.consumerID, settlementId);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Settlement voided', {
                appearance: 'success',
                autoDismiss: true
            });
            //Let the parent component know that the settlement has been voided
            props.onSettlementVoided(settlementId);
        }
        setSettlementCommand({
            id: 0,
            command: Command.None
        });
    };

    const onAdvance = async (settlementId: number) => {
        const result = await advanceConsumerSettlement(consumer.consumerID, settlementId);
        if (result.error) {
            setApiError(result.error);
        } else {
            console.log(result.data);
            var msg = 'Settlement advanced.\n ';            
            (result.data ? msg += ' Advanced: $' + result.data.advanced : msg += '');
            (result.data ? msg += ' Daily total: $' + result.data.dailyAdvancedTotal : msg += '');
            addToast(msg, {
                appearance: 'success',
                autoDismiss: true
            });
            //Retain that this settlement has been advanced so we can duplicate requests
            setAdvancedSettlements([...advancedSettlements, settlementId]);
            settlementsGrid.current?.refresh();
        }
        setSettlementCommand({
            id: 0,
            command: Command.None
        });
    };
    useEffect(() => {                                   
        if (settlementCommand.id > 0 && settlementCommand.command !== String(Command.None)) {                                                         
            //id coming in undefined            
            switch (settlementCommand.command) {
                case Command.Info:                    
                    setShowInfoModal(true);
                    break;
                case Command.Advance:
                    setShowAdvanceConfirmation(true);
                    break;
                case Command.Void:                                    
                    setShowVoidConfirmation(true);
                    break;
            }
        }
    }, [settlementCommand]);

    useEffect(() => {
        settlementsGrid.current?.refresh();
    }, [props.settlements, settlementsGrid]);

    /** Grid Option Definitions **/
    const editCommand: CommandModel[] = [
        {
            title: Command.Edit,
            buttonOption: {
                cssClass: props.hasEditPermissions ? 'e-outline edit-icon' : 'e-outline view-icon'
            }
        }
    ];
    const limitedAccessUserCommands: CommandModel[] = [
        {
            title: Command.Info,
            buttonOption: {
            cssClass: 'e-outline info-icon'}
        }
    ];
    const unlimitedUserCommands: CommandModel[] = [
        ...limitedAccessUserCommands,
        {
            title: Command.Advance,
            buttonOption: { cssClass: 'e-outline advance-pay-icon' }
        },
        {
            title: Command.Void,
            buttonOption: { content: 'Void', cssClass: 'e-btn btn-void' }
        }
    ];

    const selectionSettings: SelectionSettingsModel = { type: 'Multiple', checkboxOnly: true };

    /** Value Accessor functions **/
    //  used to manipulate or format raw data before displaying it in the grid

    const formatVoid: InternalValueAccessor<Settlement> = (_field, data) =>
        data ? (data.void ? 'Void' : '') : '';

    const formatPaymentType: InternalValueAccessor<Settlement> = (_field, data) =>
        data ? paymentTypeToString(data.paymentType) : '';

    const formatSent: InternalValueAccessor<Settlement> = (_field, data) =>
        data ? (data.sent ? 'Sent' : 'Not Sent') : '';

    /** Grid event handler functions **/
    const onCommandClick = (args?: CommandClickEventArgs) => {
        if (args && args.rowData) {
            let settlement = args.rowData as Settlement;
            let title = args.commandColumn?.title || '';            
            if (title === String(Command.Edit)) {
                props.onEditSettlement(settlement.id);            }            
            else {                
                setSettlementCommand({                    
                    id: settlement.id,
                    command: title
                });
            }
        }
    };

    const onRowDataBound = (args?: RowDataBoundEventArgs) => {
        //Add custom classes to rows based on their value
        if (!args || !args.data) {
            return;
        }
        let settlement = args.data as Settlement;   

        if (settlement.sent) {
            //Only un-paid settlements can be deleted or edited - disable the checkbox on records that have been paid
            if (args.row && args.row.getElementsByClassName('e-gridchkbox').length > 0) {
                args.row
                    .getElementsByClassName('e-gridchkbox')[0]
                    .classList.add('disabled-checkbox');
            }
            if (args.row && args.row?.getElementsByClassName('e-checkbox-wrapper').length > 0) {
                args.row
                    .getElementsByClassName('e-checkbox-wrapper')[0]
                    .classList.add('disabled-checkbox');
            }

            //Only un-paid settlements can be deleted or edited - disable the checkbox on records that have been paid
            let editList = args.row?.querySelectorAll('.edit-icon');
            if (editList && editList.length > 0) {
                editList[0].classList.add('e-disabled');
            }


            //Only un-paid settlements can be advanced - disable the advance button on records that have been paid
            let elementList = args.row?.querySelectorAll('.advance-pay-icon');
            if (elementList && elementList.length > 0) {
                elementList[0].classList.add('e-disabled');
            }
            //CLEARED can't be voided            
            if (settlement.clearedDate !== String('1900-01-01T00:00:00')) {
                let elementList = args.row?.querySelectorAll('.btn-void');
                if (elementList && elementList.length > 0) {                
                    elementList[0].classList.add('e-disabled');                }

            }

            //Only checks can void
            if (settlement.paymentType !== 2) {
                let elementList = args.row?.querySelectorAll('.btn-void');
                if (elementList && elementList.length > 0) {                
                    elementList[0].classList.add('e-disabled');                }

            }

        } else {
            //Settlement that have not been paid yet cannot be voided -- disable the void button and hide info
            let elementList = args.row?.querySelectorAll('.btn-void');
            if (elementList && elementList.length > 0) {                
                elementList[0].classList.add('e-disabled');
            }

            let elementList2 = args.row?.querySelectorAll('.info-icon');
            if (elementList2 && elementList2.length > 0) {                
                elementList2[0].classList.add('e-disabled');
            }

        }

        //If the settlement is voided then the text should appear in red and the voided button should instead be an icon
        if (settlement.void) {
            let elementList = args.row?.querySelectorAll('.btn-void');
            if (elementList && elementList.length > 0) {
                elementList[0].classList.add('e-disabled');
            }
            args.row?.classList.add('error-text');
        }

        //If the settlement has already been advanced in the lifetime of this component, disabled the advanced button
        if (advancedSettlements.includes(settlement.id)) {
            let elementList = args.row?.querySelectorAll('.btn-advance');
            if (elementList && elementList.length > 0) {
                elementList[0].classList.add('e-disabled');
            }
        }
    };

    const onRowSelected = () => {
        if (settlementsGrid && settlementsGrid.current) {
            // Get the selected records and send them to the parent
            const selectedrecords = settlementsGrid.current.getSelectedRecords() as Settlement[];
            props.setSelectedSettlementIds(selectedrecords.map(s => s.id));
        }
    };

    const onCheckboxChange = (args?: CheckBoxChangeEventArgs) => {
        //Prevent the select all button from selected rows which have been marked as disabled
        if (
            args &&
            args.checked &&
            args.target?.classList.contains('e-checkselectall') &&
            args.selectedRowIndexes &&
            settlementsGrid &&
            settlementsGrid.current
        ) {
            let selected = args.selectedRowIndexes.filter(index => {
                let row = settlementsGrid.current?.getRowByIndex(index);
                return row && !row.querySelector('.disabled-checkbox');
            });
            settlementsGrid.current.selectRows(selected);
        }
    };

    const setExportCellStyle = (
        propName: string,
        args?: PdfQueryCellInfoEventArgs | ExcelQueryCellInfoEventArgs
    ) => {
        if (!args || !args.data) {
            return;
        }

        let settlement = args.data as Settlement;

        //If the settlement is voided then the text should appear in red and the voided button should instead be an icon
        if (settlement.void) {
            let fontColor = '#ba0000';
            args.style =
                propName === 'fontColor' ? { fontColor: fontColor } : { textBrushColor: fontColor };
        }
    };

    const customExcelQueryCellInfo = (args?: ExcelQueryCellInfoEventArgs) => {
        gridOptions.excelQueryCellInfo(args);
        setExportCellStyle('fontColor', args);
    };

    const customPdfQueryCellInfo = (args?: PdfQueryCellInfoEventArgs) => {
        gridOptions.pdfQueryCellInfo(args);
        setExportCellStyle('textBrushColor', args);
    };
 
    const footerSum=(props:any):any=>
    {
        return(
            <span>
            {props.Sum}
            </span>
        )
    }
    const footerAvg=(props:any):any=>
    {
        return(
            <span>
            {props.Average}
            </span>
        )
    }
    const footerText=(props:any):any=>
    {
        return(
            <span>
            Total
            </span>
        )
    }


    const { currentView } = React.useContext(GridViewContext);
    let grid:Grid|null
    let dataBound = () =>{
        if(settlementsGrid.current && currentView === 2)
        {
            settlementsGrid.current.autoFitColumns([])
        }
        const rows = ['debtAmount', 'Payment Type', 'paymentAmount', 'additionalFees', 'ramFee', 'Status', 'Check Number', 'Date Sent', 'Date Cleared', 'Tracking', '']
        let tbody    = document.getElementsByClassName('e-content')[0].getElementsByClassName('e-table')[0].getElementsByTagName('tbody')[0];
        let trr = tbody.getElementsByTagName('tr')[1];
        let trs = tbody.getElementsByTagName('tr');
        let headerTrs = [];
        for(let i=0;i<trs.length;i++){
            if(trs[i].classList.value.includes('e-firstchildrow')){
                headerTrs.push(trs[i-1]); 
            }
        }
        let groupdAggregatedata: any=[];
        if(settlementsGrid.current) {
            settlementsGrid.current.currentViewData.map((data: any)=>{
                data.items.map((item: any)=>{
                    groupdAggregatedata.push(item)
                })
            })
        }
        headerTrs.forEach((header, id)=>{
            header.getElementsByTagName('td')[2].setAttribute('colspan', '7')
            let start = 5;
    
            rows.forEach((row, i)=>{
                let tdd = header.insertCell();
                let span = document.createElement('span');
                if(row =='paymentAmount' || row=='additionalFees' || row=='ramFee'){
                    span.innerText = '$'+ Number(groupdAggregatedata[id].aggregates[`${row} - sum`]).toFixed(2);
                }
                if(row== 'debtAmount'){
                    span.innerText = '$'+ Number(groupdAggregatedata[id].aggregates[`${row} - average`]).toFixed(2);
                }
                tdd.appendChild(span);           
                tdd.classList.add('e-templatecell');
                tdd.classList.add('e-summarycell');
                tdd.classList.add('e-ellipsistooltip');
                tdd.tabIndex = -1;
               let index = start + i;
                tdd?.setAttribute('index',index.toString())
                tdd?.setAttribute('role','gridcell')
            })
        })
       

    }
    const groupOptions = {
        columns: ['payeeName', 'consumerAccountNumber'],
        showDropArea: false,
        showGroupedColumn: true,
    };

    const addSettlementTabGridDueDate = (data: any) => {
        return data.map((settlement: Settlement) => {
            settlement.settlementTabGridDueDate = new Date(settlement.dueDate);
            return settlement;
        })}

    return (
        <div>
            <GridComponent
            //   {...gridOptions}
            ref={settlementsGrid}
              allowExcelExport= {true}
              allowPdfExport= {true}
             toolbar ={['ExcelExport', 'PdfExport', 'CsvExport']}
                 toolbarClick={gridOptions.toolbarClick}
                 allowSorting= {false}
                 allowTextWrap= {false}
                clipMode='EllipsisWithTooltip' 
                  width='1500px'
                dataBound= { dataBound } 
                className={`e-grid ram-grid settlement-info-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid'} ${currentView === 2 ?  'comfort-grid-view' : ''}`}
                dataSource={getSettlementGridViewFromSettlements(addSettlementTabGridDueDate(props.settlements))}
                commandClick={onCommandClick}
                rowDataBound={onRowDataBound}
                selectionSettings={selectionSettings}
                rowSelected={onRowSelected}
                rowDeselected={onRowSelected}
                checkBoxChange={onCheckboxChange}
                filterSettings={filterOptions}
                allowFiltering={true}
                excelQueryCellInfo={customExcelQueryCellInfo}
                pdfQueryCellInfo={customPdfQueryCellInfo}
                allowGrouping={true}
                groupSettings={groupOptions}
            >
                <ColumnsDirective>
                    {props.hasEditPermissions && (
                        <ColumnDirective
                            field="select"
                            type="checkbox"
                            width="3.5%"
                            textAlign="Left"
                            allowFiltering={false}
                        />
                    )}
                     <ColumnDirective
                        field="btnInfoAdv"
                        headerText=""
                        width="8%"
                        commands={
                            props.hasUnlimitedPermissions
                                ? unlimitedUserCommands
                                : limitedAccessUserCommands
                        }
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        headerText=""
                        field="btnEdit"
                        commands={editCommand}
                        width="4%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        headerText=""
                        field="alert"
                       template={(props: any)=>{
                       
                          return (props.uidStatus == 1 || props.uidStatus == 3) ? <span title='This settlement has unapproved creditor info.' className='alert-icon'/>:<></>
                       }}                      
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="settlementTabGridDueDate"
                        //This column is set as a js object so that it can be filtered as a date
                        format={{ type: 'date', format: 'MM/dd/yyyy' }}
                        type="date"
                        width="7%"
                        headerText="Due Date"
                        textAlign="Left"
                        headerTextAlign="Left"
                    />
                    <ColumnDirective
                        field="payeeName"
                        width="0"
                        headerText="Payee"
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="consumerAccountNumber"
                        width="0"
                        headerText="Account #"
                        textAlign="Left"
                        headerTextAlign="Left"
                    />
                    <ColumnDirective
                        field="debtAmount"
                        width="7%"
                        headerText="Total Debt"
                        format="C2"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="paymentType"
                        valueAccessor={vaWrapper(formatPaymentType)}
                        width="8%"
                        headerText="Payment Type"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="paymentAmount"
                        width="9%"
                        headerText="Payment Amt"
                        format="C2"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="additionalFees"
                        width="8%"
                        headerText="Addl. Fees"
                        format="C2"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="ramFee"
                        width="8%"
                        headerText="RAM Fee"
                        format="C2"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="sent"
                        valueAccessor={vaWrapper(formatSent)}
                        width="7%"
                        headerText="Status"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="checkNumber"
                        template={(props: Settlement) => {
                            if(!props.void && props.paymentType === 2){
                                return <NewTabLink url={`/checkImages?Consumer=${consumer.consumerID}&checkNumber=${props.checkNumber}`}>
                                 {props.checkNumber}
                                 </NewTabLink>
                            }
                            else {
                                return <>{props.checkNumber}</>
                            }
                        }}
                        width="5%"
                        headerText="Check Number"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="sentDate"
                        valueAccessor={vaWrapper(formatDate)}
                        width="7%"
                        headerText="Date Sent"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="clearedDate"
                        valueAccessor={vaWrapper(formatDate)}
                        width="7%"
                        headerText="Date Cleared"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="trackingNumber"
                        template={(props: Settlement) => (
                            <TooltipComponent offsetX={-30} enableHtmlParse content={`<div><span>${props.trackingNumber}</span><br><span style="float: left">Status: ${(props?.upsStatus && props?.upsStatus.trim() !=="") ? props?.upsStatus : ""}</span><br><span style="float: left">Location: ${ (props?.location && props?.location.trim() !== "") ? props.location : ""}</span><br><span style="float: left">Signed For By: ${(props?.signedForBy && props?.signedForBy.trim() !== "") ? props?.signedForBy : ""}</span></div>`}>
                                <NewTabLink
                                url={`https://www.ups.com/track?tracknum=${props.trackingNumber}`}
                                className='.tooltip-target'
                                >
                                    {props.trackingNumber}
                                </NewTabLink>
                            </TooltipComponent>
                        )}
                        width="6%"
                        headerText="Tracking"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                        clipMode='Ellipsis'
                    />
                    <ColumnDirective
                        field="void"
                        width="6%"
                        valueAccessor={vaWrapper(formatVoid)}
                        headerText=""
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}                        
                    />
                </ColumnsDirective>
                <AggregatesDirective>
                    <AggregateDirective >
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective format="C2"
                         field='paymentAmount'  type='Sum' footerTemplate={footerSum}/>
                         <AggregateColumnDirective format="C2"
                         field='debtAmount'  type='Average' footerTemplate={footerAvg}/>
                         <AggregateColumnDirective format="C2"
                         field='additionalFees'  type='Sum' footerTemplate={footerSum}/>
                          <AggregateColumnDirective format="C2"
                         field='ramFee'  type='Sum' footerTemplate={footerSum}/>
                         <AggregateColumnDirective 
                         field='dueDateTime' type='Max' footerTemplate={footerText}/>
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
                <Inject services={[Edit, Page,CommandColumn, Group, PdfExport, ExcelExport, Toolbar, Filter,Aggregate,Resize]} />
            </GridComponent>
            <MessagePopup
                requireConfirmation={true}
                message="Are you sure you want to advance this settlement?"
                showDialog={showAdvanceConfirmation}
                setShowDialog={setShowAdvanceConfirmation}
                onConfirmClick={() => onAdvance(settlementCommand.id)}
                onCancelClick={() =>
                    setSettlementCommand({
                        id: 0,
                        command: Command.None
                    })
                }
            ></MessagePopup>
            <MessagePopup
                requireConfirmation={true}
                message="Are you sure you want to void this settlement?"
                showDialog={showVoidConfirmation}
                setShowDialog={setShowVoidConfirmation}
                onConfirmClick={() => onVoid(settlementCommand.id)}
                onCancelClick={() =>
                    setSettlementCommand({
                        id: 0,
                        command: Command.None
                    })
                }                
            ></MessagePopup>
            <PaymentDetailsModal
                settlement={
                    props.settlements.find(s => s.id === settlementCommand.id) ||
                    props.settlements[0]
                                }
                targetId="consumerPage"
                showDialog={showInfoModal}
                setShowDialog={setShowInfoModal}
            />
        </div>
    );
};

export default SettlementsGrid;
