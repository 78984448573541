import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { companyPages, consumerPages, Page, pagePath, Section } from '../../pages';
import { UserPermission } from '../../types/enums';
import { UserAuthorizationContext } from '../UserAuthorizationService';

interface Props {
    section: Section;
}

const SideNavLink = (props: { page: Page }) => (
    <Link to={pagePath(props.page)}>{props.page.name}</Link>
);

export const SidenavSectionContent = (props: Props) => {
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);
    const hasViewChecksPermissions = userHasPermission(UserPermission.ViewChecks);
    const hasViewAffiliatesPermissions = userHasPermission(UserPermission.ViewAffiliates);

    return (
        <div className="sidenav-section-content">
            {props.section.navigationPages &&
                props.section.navigationPages
                    .filter(
                        navPage =>
                            (navPage !== companyPages.affiliates &&
                                navPage !== companyPages.checkLookup &&
                                navPage !== consumerPages.addConsumer) ||
                            (navPage === companyPages.affiliates && hasViewAffiliatesPermissions) ||
                            (navPage === companyPages.checkLookup && hasViewChecksPermissions) ||
                            (navPage === consumerPages.addConsumer && hasEditConsumerPermissions)
                    )
                    .map(navPage => <SideNavLink key={navPage.extension} page={navPage} />)}
        </div>
    );
};
