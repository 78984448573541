import * as React from 'react';
import { DashboardType } from '../../../enums/Dashboards';
import DashboardView from '../../common/dashboard/DashboardView';
import { DateRange,CompanyName } from '../../../types/common';
import DashboardPageWrapper from './DashboardPageWrapper';

interface Props {
    dateRange: DateRange;
    changeDateRange?: (dateRange: DateRange) => void;
    coName: CompanyName;
}

const Refunds = (props: Props) => {
    return (
        <DashboardPageWrapper
            dateRange={props.dateRange}
            changeDateRange={props.changeDateRange}
            pageName="Refunds"
        >
            <DashboardView dashboardType={DashboardType.RefundsCount} dateRange={props.dateRange} coName={props.coName}/>
            <DashboardView
                dashboardType={DashboardType.RefundTotalAmount}
                dateRange={props.dateRange}
                coName={props.coName}
            />
            <DashboardView
                dashboardType={DashboardType.RefundAverageAmount}
                dateRange={props.dateRange}
                coName={props.coName}
            />
        </DashboardPageWrapper>
    );
};

export default Refunds;
