import * as React from 'react';
import alertIcon from '../../assets/images/alert.png';
import '../../styles/components/AlertBanner.scss';

interface Props {
    message: string;
}

const AlertBanner = (props: Props) => (
    <div className="alert-banner">
        <img src={alertIcon} alt="alert" />
        <p>{props.message}</p>
    </div>
);

export default AlertBanner;
