import * as React from 'react';
import '../../../styles/Form.scss';
import '../../../styles/views/Company.scss';
import '../../../styles/common/Elements.scss';
import { Company, CompanyUpdate, CompanyEvent } from '../../../types/company';
import WrappedForm from '../../common/form/WrappedForm';
import { getCompany, getCompanyEvents, updateCompany } from '../../../api/companyApi';
import { useCallback, useEffect, useState } from 'react';
import { loadFromApi } from '../../../api/baseApi';
import LoadingDisplay from '../../common/LoadingDisplay';
import LoadFailedDisplay from '../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';
import CompanyInformationFormInputs from './CompanyInformationFormInputs';

const CompanyInformationPage = () => {
    const [company, setCompany] = useState<Company>();
    const [companyAvailableEvents, setCompanyAvailableEvents] = useState<CompanyEvent[]>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onSubmit = async (data: CompanyUpdate) => {
        setLoading(true);
        const result = await updateCompany(data);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Company Information Updated', { appearance: 'success', autoDismiss: true });
        }
        //Set the company update back to the state so the form retains those values on re-render
        setCompany({
            ...data,
            bankAccountName: company ? company.bankAccountName : '',
            bankAccountNumberLast4: company ? company.bankAccountNumberLast4 : ''
        });
        setLoading(false);
    };

    function getCompanyUpdateFromCompany(company: Company) {
        const { bankAccountName, bankAccountNumberLast4, ...newCompanyUpdate } = company;
        return newCompanyUpdate;
    }

    useEffect(() => {
        const companyPageApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {
            setLoading(true);
            const companyLoad = companyPageApiWrapper(getCompany, setCompany);
            const companyEventLoad = companyPageApiWrapper(
                getCompanyEvents,
                setCompanyAvailableEvents
            );

            await companyLoad;
            await companyEventLoad;

            setLoading(false);
        };

        loadCompanyPageData();
    }, [setApiError]);

    return (
        <div className="page company-information">
            <h2 className="page-title">Company Information</h2>
            {loading ? (
                <LoadingDisplay />
            ) : company && companyAvailableEvents ?  (
                <div>
                    <WrappedForm<CompanyUpdate>
                        initialValues={getCompanyUpdateFromCompany(company)}
                        onSubmit={onSubmit}
                    >
                        <CompanyInformationFormInputs eventOptions={companyAvailableEvents} />
                    </WrappedForm>
                    <div className="banking-information">
                        <p>
                            Due to banking regulations, you can no longer change your company bank
                            information directly. To make changes, please contact Reliant Support and
                            submit an updated ACH agreement with the new account information.
                        </p>
                        <div className="wrapped-info">
                            <label>Bank Account Name</label>
                            <span className="value">{company.bankAccountName}</span>
                        </div>
                        <div className="wrapped-info">
                            <label>Bank Account Number (last 4)</label>
                            <span className="value">{company.bankAccountNumberLast4}</span>
                        </div>
                    </div>
                </div>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default CompanyInformationPage;
