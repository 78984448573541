import * as React from 'react';
import {
    CheckBoxChangeEventArgs,
    ColumnDirective,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,
    Edit,
    ExcelExport,
    ExcelQueryCellInfoEventArgs,
    FilterSettingsModel,
    Filter,
    GridComponent,
    Inject,
    PdfExport,
    PdfQueryCellInfoEventArgs,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    Toolbar,
    Sort,
    Grid,
    Resize,
    AggregatesDirective,
    AggregateColumnsDirective,
    AggregateColumnDirective,
    AggregateDirective,
    Aggregate
} from '@syncfusion/ej2-react-grids';
import { Draft } from '../../../../types/drafts';
import { ConsumerContext, FeeContext } from '../ConsumerPage';
import {GridViewContext} from '../../../GridViewService';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatAsCurrency } from '../../../../utils/stringUtils';
import { moveProgram, refundConsumerDraft } from '../../../../api/consumerApi';
import { DateTime } from 'luxon';
import { useToasts } from 'react-toast-notifications';
import { serverDateToLuxonDateTime } from '../../../../utils/dateUtils';
import {
    formatDate,
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';
import { DraftStatus, UserPermission } from '../../../../types/enums';
import MessagePopup from '../../../common/MessagePopup';
import { sumDraftAmount } from '../../../../utils/draftUtils';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';

interface Props {
    drafts: Draft[];
    refreshGrid?: () => void;
    selectedDrafts: Draft[];
    setSelectedDrafts: (newSelectedDrafts: Draft[]) => void;
    onDraftRefunded: (draftId: number) => void;
    isSchedulePreview: boolean;
    targetMonthlyDraft?: number;
    hasEditPermissions?: boolean;
}

const ConsumerDraftsGrid = (props: Props) => {
    const consumer = useContext(ConsumerContext);    
    const fees = useContext(FeeContext);
    const { addToast } = useToasts();
    const gridOptions = useCommonGridOptions('ConsumerDrafts');
    const draftsGrid = gridOptions && gridOptions.ref;
    const [refundDraftId, setRefundDraftId] = useState(0);
    const [showRefundConfirmation, setShowRefundConfirmation] = useState(false);
    const [draftDetails, setDraftDetails] = useState<any>([]);
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasIssueRefundPermissions = userHasPermission(UserPermission.IssueRefunds);
    useEffect(() => {
        if (refundDraftId > 0) {
            setShowRefundConfirmation(true);
        }
    }, [refundDraftId]);

    useEffect(()=>{
        const details = props.drafts.map((draft)=>{
            return {
                ...draft,
                ...draft.fees,
            }
        })
        setDraftDetails(details); 
    }, [props.drafts])

    useEffect(() => {
        draftsGrid.current?.refresh();
    }, [props.drafts, draftsGrid]);

    /** Api Definitions **/
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onRefundClick = async (draftId: number) => {
        const result = await refundConsumerDraft(consumer.consumerID, draftId);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Consumer draft refunded', {
                appearance: 'success',
                autoDismiss: true
            });
            //Let the parent component know that the draft has been refunded
            props.onDraftRefunded(draftId);
        }
        setRefundDraftId(0);
    };

    /** Grid Option Definitions **/
    const commands: CommandModel[] = [
        { title: 'Refund', buttonOption: {  cssClass:'e-outline refund-icon'  }}
    ];

    const selectionSettings: SelectionSettingsModel = { type: 'Multiple', checkboxOnly: true };

    /** Value Accessor functions **/
    //  used to manipulate or format raw data before displaying it in the grid
    const getRowNumber: InternalValueAccessor<Draft> = (_field, data) =>
        data ? draftDetails.indexOf(data) + 1 : 0;

    const getDraftTotalAmount: InternalValueAccessor<Draft> = (_field, draftData) => {
        return formatAsCurrency(sumDraftAmount(draftData));
    };

    const pullFeeGroupAmount: InternalValueAccessor<Draft> = (field, draftData) => {
        let amount = 0;
        if (draftData) {                        
            if (Object.keys(draftData.fees).includes(field)) {
                let feeValue = draftData.fees[field as any];
                if (feeValue !== undefined) {                    
                    amount = feeValue;
                }
            }
        }
        return formatAsCurrency(amount);
    };

    /** Grid event handler functions **/
    const onCommandClick = async (args?: CommandClickEventArgs) => {
        if (args && args.rowData) {
            let draftData = args.rowData as Draft;
            if(args.target?.classList.contains('scheduled-status')){
                let result = await moveProgram(consumer.consumerID, draftData.id, draftData);
                if (result && !result.error) {
                    props.refreshGrid && props.refreshGrid();
                }
            }
            else if (args.commandColumn?.title === 'Refund') {
                setRefundDraftId(draftData.id);
            }
        }
    };

    const onRowDataBound = (args?: RowDataBoundEventArgs) => {
        //Add custom classes to rows based on their value
        if (args && args.data) {
            let draftData = args.data as Draft;
            //Disable the selection checkbox for all rows when Draft Status is not Scheduled.
            //Earlier this was disabled when the date is in the past.
            // let draftStatus = draftData.status;
            if (draftData.status.toString() != "SCHEDULED") {
                args.row
                    ?.getElementsByClassName('e-gridchkbox')[0]
                    .classList.add('disabled-checkbox');
                args.row
                    ?.getElementsByClassName('e-checkbox-wrapper')[0]
                    .classList.add('disabled-checkbox');
            }
            //Drafts with status returned should have red text
            if (draftData.status === DraftStatus.Returned) {
                args.row?.classList.add('error-text');
            }
            //If in schedule preview mode - display already posted drafts in gray
            if (draftData.status === DraftStatus.Posted) {
                args.row?.classList.add('draft-posted');
            }
            //If in schedule preview mode - check for total amounts that exceed total monthly payments and mark as red
            if (
                props.isSchedulePreview &&
                props.targetMonthlyDraft &&
                draftData.status === DraftStatus.Scheduled &&
                sumDraftAmount(draftData) > props.targetMonthlyDraft
            ) {
                args.row?.getElementsByClassName('total-amount')[0].classList.add('error-text');
            }
            let rowIndex =args.row?.getAttribute('aria-rowIndex');

            //Disable the refund button for all rows where the draft status is not posted
            if ((draftData.status !== DraftStatus.Posted) &&
                (draftData.status !== DraftStatus.Scheduled ||
                (rowIndex && Number(rowIndex) == (props.drafts.length)-1 ))) {
                let elementList = args.row?.querySelectorAll('.refund-icon');
                if (elementList && elementList.length > 0) {
                    elementList[0].classList.add('e-disabled');
                }
            }
            //Hiding refund button if Draft status is Posted and User do not have refund permissions
            if(draftData.status == DraftStatus.Posted && !hasIssueRefundPermissions)
            {
                let elementList = args.row?.querySelectorAll('.refund-icon');
                if (elementList && elementList.length > 0) {
                    (elementList[0] as any).style.display = 'none';
                }
            }

            if (draftData.status === DraftStatus.Scheduled) {
                let elementList = args.row?.querySelectorAll('.refund-icon');
                if (elementList && elementList.length > 0) {
                    elementList[0].classList.add('scheduled-status');
                    elementList[0].setAttribute('title', 'Move to Bottom');
                }
            }
        }
    };

    const onRowSelected = () => {
        if (draftsGrid && draftsGrid.current) {
            // Get the selected records and send them to the parent
            const selectedrecords = draftsGrid.current.getSelectedRecords() as Draft[];
            props.setSelectedDrafts(selectedrecords);
        }
    };

    const onCheckboxChange = (args?: CheckBoxChangeEventArgs) => {
        //Prevent the select all button from selected rows which have been marked as disabled
        if (
            args &&
            args.checked &&
            args.target?.classList.contains('e-checkselectall') &&
            args.selectedRowIndexes &&
            draftsGrid &&
            draftsGrid.current
        ) {
            var customsSelect = [];
            for (var i = 0; i < args.selectedRowIndexes.length; i++) {
                var row = draftsGrid.current.getRowByIndex(args.selectedRowIndexes[i]);
                if (!row.querySelector('.disabled-checkbox')) {
                    customsSelect.push(args.selectedRowIndexes[i]);
                }
            }
            draftsGrid.current.selectRows(customsSelect);
        }
    };

    const setExportCellStyle = (
        propName: string,
        args?: PdfQueryCellInfoEventArgs | ExcelQueryCellInfoEventArgs
    ) => {
        if (!args || !args.data || !args.column) {
            return;
        }

        //Drafts with status returned should have red text
        let draftData = args.data as Draft;
        if (draftData.status === DraftStatus.Returned) {
            let fontColor = '#ba0000';
            args.style =
                propName === 'fontColor' ? { fontColor: fontColor } : { textBrushColor: fontColor };
        }

        //If in schedule preview mode - display already posted drafts in gray
        if (props.isSchedulePreview && draftData.status === DraftStatus.Posted) {
            let fontColor = '#8e8e8e';
            args.style =
                propName === 'fontColor' ? { fontColor: fontColor } : { textBrushColor: fontColor };
        }
        //If in schedule preview mode - check for total amounts that exceed total monthly payments and mark as red
        if (
            props.isSchedulePreview &&
            props.targetMonthlyDraft &&
            draftData.status === DraftStatus.Scheduled &&
            sumDraftAmount(draftData) > props.targetMonthlyDraft &&
            args.column.headerText === 'Amount ($)'
        ) {
            let fontColor = '#ba0000';
            args.style =
                propName === 'fontColor' ? { fontColor: fontColor } : { textBrushColor: fontColor };
        }
    };

    const customExcelQueryCellInfo = (args?: ExcelQueryCellInfoEventArgs) => {
        gridOptions.excelQueryCellInfo(args);
        setExportCellStyle('fontColor', args);
    };

    const customPdfQueryCellInfo = (args?: PdfQueryCellInfoEventArgs) => {
        gridOptions.pdfQueryCellInfo(args);
        setExportCellStyle('textBrushColor', args);
    };

    const { currentView } = React.useContext(GridViewContext);
    let grid:Grid|null
        let dataBound = () =>{
            if(gridOptions.ref.current && currentView === 2)
            {
                gridOptions.ref.current.autoFitColumns([])
            }
        }
     const footerSum = (props: any): any  =>{
            return(<span>
                    {props.Sum}
                </span>)
                };
     
     const footerText = () => {
        return( <span>
                    Total
                </span> )
                };
    return (
        <div>
            <GridComponent
                {...gridOptions}
               className={`e-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid'} ${currentView === 2 ?  'comfort-grid-view' : 'default-grid-view'}`}
                dataBound= { dataBound } 
                dataSource={draftDetails}
                commandClick={onCommandClick}
                rowDataBound={onRowDataBound}
                selectionSettings={selectionSettings}
                rowSelected={onRowSelected}
                rowDeselected={onRowSelected}
                checkBoxChange={onCheckboxChange}
                filterSettings={filterOptions}
                allowFiltering={true}
                allowSorting={false}
                excelQueryCellInfo={customExcelQueryCellInfo}
                pdfQueryCellInfo={customPdfQueryCellInfo}
                clipMode='EllipsisWithTooltip'
            >
                <ColumnsDirective>
                    <ColumnDirective
                        type="checkbox"
                        width="3.5%"
                        textAlign="Left"
                        visible={!props.isSchedulePreview && props.hasEditPermissions}
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="Row#"
                        valueAccessor={vaWrapper(getRowNumber)}
                        headerText="#"
                        width="3.5%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="date"
                        valueAccessor={vaWrapper(formatDate)}
                        headerText="Date"
                        width="7%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        valueAccessor={vaWrapper(getDraftTotalAmount)}
                        headerText="Total Draft"
                        width="6%"
                        textAlign="Left"
                        customAttributes={{ class: 'total-amount' }}
                        format="C2"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="savingsAmount"
                        headerText="Savings"
                        width="6%"
                        format="C2"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    {fees.map(fee => (
                        <ColumnDirective
                            key={fee.slot}
                            field={String(fee.slot)}
                            headerText={`${fee.name}`}
                            width="6%"
                            textAlign="Left"
                            format="C2"
                            allowFiltering={false}
                        />
                    ))}
                    <ColumnDirective
                        field="ramFee"
                        headerText="RAM Fee"
                        format="C2"
                        width="6%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="status"
                        headerText="Status"
                        template={(props: any)=>{
                            if(props.status == DraftStatus.Returned){
                                return props.returnedReason
                            }
                            return props.status;
                        }}
                        width="6%"
                        textAlign="Left"
                        allowFiltering={true}
                    />
                    <ColumnDirective
                        field="bankName"
                        headerText={props.isSchedulePreview ? 'ACH Bank' : 'Source'}
                        width="6%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    {!props.isSchedulePreview && (
                        <ColumnDirective
                            field="id"
                            headerText=""
                            width="5%"
                            commands={commands}
                            textAlign="Left"
                            allowFiltering={false}
                        />
                    )}
                </ColumnsDirective>
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective 
                                field='ramFee' type='Sum' format="C2" footerTemplate={footerSum} 
                            />
                            <AggregateColumnDirective 
                                field='savingsAmount' type='Sum' format="C2" footerTemplate={footerSum} 
                            />
                            {fees.map((fee,index) => (
                                <AggregateColumnDirective key={index}
                                    field={String(fee.slot)} type='Sum' format="C2" footerTemplate={footerSum}
                                />
                            ))}
                            <AggregateColumnDirective 
                                field='date' type='Max' footerTemplate={footerText}
                            />
                        </AggregateColumnsDirective>
                    </AggregateDirective>
             </AggregatesDirective>
                <Inject services={[Edit, CommandColumn,Aggregate, PdfExport, ExcelExport, Toolbar, Filter, Sort, Resize]} />
            </GridComponent>
            <MessagePopup
                requireConfirmation={true}
                message="Are you sure you want to refund this draft?"
                showDialog={showRefundConfirmation}
                setShowDialog={setShowRefundConfirmation}
                onConfirmClick={() => onRefundClick(refundDraftId)}
                onCancelClick={() => setRefundDraftId(0)}
            ></MessagePopup>
        </div>
    );
};

export default ConsumerDraftsGrid;
