import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../types/common';

interface Props extends ModalProps {
    headerText?: string;
    message: string;
    requireConfirmation: boolean;
    onConfirmClick?: () => void;
    onCancelClick?: () => void;
}

const MessagePopup = (props: Props) => {
    const confirmationButtons = [
        {
            buttonModel: {
                content: 'OK',
                isPrimary: true
            },
            click: () => {
                if (props.onConfirmClick) {
                    props.onConfirmClick();
                }
                props.setShowDialog(false);
            }
        },
        {
            buttonModel: {
                content: 'Cancel'
            },
            click: () => {
                if (props.onCancelClick) {
                    props.onCancelClick();
                }
                props.setShowDialog(false);
            }
        }
    ];
    const defaultButtons = [
        {
            buttonModel: {
                content: 'Close'
            },
            click: () => {
                props.setShowDialog(false);
            }
        }
    ];
    return (
        <DialogComponent
            header={props.headerText}
            isModal={true}
            buttons={props.requireConfirmation ? confirmationButtons : defaultButtons}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => props.setShowDialog(false)}
            width="500px"
            height="auto"
        >
            <div className="message-popup">
                <div>{props.message}</div>
            </div>
        </DialogComponent>
    );
};

export default MessagePopup;
