import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ModalProps } from '../../../../../types/common';
import '../../../../../styles/common/ModalDialog.scss';
import '../../../../../styles/views/Consumer.scss';
import AddCardFormInputs from './AddCardFormInputs';
import WrappedForm from '../../../../common/form/WrappedForm';
import { CreditCardCreate, CreditCardView } from '../../../../../types/bankingInformation';
import { useCallback, useContext } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ConsumerContext } from '../../ConsumerPage';
import { addConsumerCard, selectPaymentMethod } from '../../../../../api/consumerBankingInfoApi';
import { CardNetwork } from '../../../../../types/enums';
import '../../../../../styles/views/Consumer.scss';
import LoadingDisplay from '../../../../common/LoadingDisplay';

interface Props extends ModalProps {
    addCardToCards: (newCard: CreditCardView) => void;
}

const AddCardPopup = (props: Props) => {
    const consumer = useContext(ConsumerContext);

    const [loadingAddCard, setLoadingAddCard] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const initializeForm: CreditCardCreate = {
        number: '',
        expiry: '',
        cardNetwork: CardNetwork.Visa
    };

    const defaultButtons = [
        {
            buttonModel: {
                content: 'Cancel'
            },
            click: () => {
                props.setShowDialog(false);
            }
        }
    ];

    const onSubmitAddCard = async (rawFormData: CreditCardCreate) => {
        setLoadingAddCard(true);
        // Server expects expiry dates formatted as "mm/yy"
        const formattedData: CreditCardCreate = {
            ...rawFormData,
            expiry: `${rawFormData.expiry.substring(0, 2)}/${rawFormData.expiry.substring(2)}`
        };
        const addCardResponse = await addConsumerCard(consumer.consumerID, formattedData);
        if (addCardResponse.error) {
            setApiError(`Failed to add credit card (${addCardResponse.error})`);
        } else if (addCardResponse.data) {
            addToast('Credit card added', {
                appearance: 'success',
                autoDismiss: true
            });
            props.addCardToCards(addCardResponse.data);

            // After adding a card, change the payment method to that card
            const setPaymentResponse = await selectPaymentMethod(
                consumer.consumerID,
                addCardResponse.data.id
            );
            if (setPaymentResponse.error) {
                setApiError(
                    `Failed to set payment method to credit card (${setPaymentResponse.error})`
                );
            } else {
                addToast(
                    `Payment method set to credit card ending in ${addCardResponse.data.lastFour}`,
                    {
                        appearance: 'success',
                        autoDismiss: true
                    }
                );
            }
        }
        setLoadingAddCard(false);
    };

    return (
        <div>
            <DialogComponent
                header="Add Card"
                isModal={true}
                buttons={defaultButtons}
                target={props.targetId ? '#' + props.targetId : undefined}
                visible={props.showDialog}
                showCloseIcon={true}
                close={() => {
                    props.setShowDialog(false);
                }}
                width="600px"
                height="auto"
            >
                <div>
                    {props.showDialog &&
                        (loadingAddCard ? (
                            <LoadingDisplay />
                        ) : (
                            <WrappedForm<CreditCardCreate>
                                onSubmit={onSubmitAddCard}
                                initialValues={initializeForm}
                            >
                                <AddCardFormInputs />
                            </WrappedForm>
                        ))}
                </div>
            </DialogComponent>
        </div>
    );
};

export default AddCardPopup;
