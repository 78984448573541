import { DateTime as LuxonDateTime } from 'luxon';
import * as queryString from 'query-string';

export function getToken(): string | null {
    return localStorage.getItem('token');
}

export function getTokenExpiration(): string | null {
    return localStorage.getItem('tokenExpDate');
}

export function isTokenRefreshRequired(): boolean {
    const token = getToken();
    const tokenExpiration = getTokenExpiration();
    const tokenExpDate =
        tokenExpiration && LuxonDateTime.fromSeconds(parseInt(tokenExpiration, 10));
    const now = LuxonDateTime.now();

    return (
        tokenExpiration === null ||
        token === null ||
        (tokenExpDate !== null && tokenExpDate !== '' && tokenExpDate < now)
    );
}

export const stripTokenFromUrl = () => queryString.exclude(window.location.href, ['jwt']);

export function directToLogin() {
    const env = process.env;
    const loginUrl =  '/login?returnUrl=/';
    window.location.href = loginUrl;
}
