import * as React from 'react';
import { DashboardType } from '../../../enums/Dashboards';
import DashboardView from '../../common/dashboard/DashboardView';
import { DateRange, CompanyName } from '../../../types/common';
import DashboardPageWrapper from './DashboardPageWrapper';


interface Props {
    dateRange: DateRange;
    changeDateRange?: (dateRange: DateRange) => void;
    coName: CompanyName;
}

const CompanyRevenue = (props: Props) => {
    return (
        <DashboardPageWrapper
            dateRange={props.dateRange}
            changeDateRange={props.changeDateRange}
            pageName="Company Revenue"
        >
            <DashboardView dashboardType={DashboardType.TotalPaid} dateRange={props.dateRange} coName={props.coName} />
            <DashboardView
                dashboardType={DashboardType.PaidPerConsumer}
                dateRange={props.dateRange}
                coName={props.coName}
            />
            <DashboardView
                dashboardType={DashboardType.PaidPerSettlement}
                dateRange={props.dateRange}
                coName={props.coName}
            />
        </DashboardPageWrapper>
    );
};

export default CompanyRevenue;
