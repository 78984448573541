import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../../types/common';
import WrappedForm from '../../../../common/form/WrappedForm';
import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import LoadingDisplay from '../../../../common/LoadingDisplay';
import { Creditor, CreditorUpdate } from '../../../../../types/settlements';
import ConsumerEditCreditorFormInputs from '../form/SettlementEditCreditorFormInputs';
import { addCreditor, updateCreditor } from '../../../../../api/consumerApi';
import { getCreditorUpdateFromCreditor } from '../../../../../utils/settlementUtils';

interface Props extends ModalProps {
    creditor: Creditor;
    onClose: () => void;
}

const SettlementEditCreditorModal = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onSubmit = async (data: CreditorUpdate) => {
        //Submit the CreditorUpdate to the API
        // Display loading spinner while submitted
        setLoading(true);
        let result = null;
        //Use add consumer endpoint if new and edit consumer endpoint if existing
        if (props.creditor.id === 0) {
            result = await addCreditor(data);
        } else {
            result = await updateCreditor(props.creditor.id, data);
        }
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast(props.creditor.id === 0 ? 'Creditor Added' : 'Creditor Updated', {
                appearance: 'success',
                autoDismiss: true
            });
        }
        setLoading(false);

        //Close the modal
        props.setShowDialog(false);

        //Trigger close event on the parent component
        props.onClose();
    };

    return (
        <DialogComponent
            header={props.creditor.id > 0 ? 'Edit Creditor' : 'Add Creditor'}
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
                props.onClose();
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <WrappedForm<CreditorUpdate>
                        initialValues={getCreditorUpdateFromCreditor(props.creditor)}
                        onSubmit={onSubmit}
                    >
                        <div>
                            {loading ? <LoadingDisplay /> : <ConsumerEditCreditorFormInputs />}
                        </div>
                    </WrappedForm>
                )}
            </div>
        </DialogComponent>
    );
};

export default SettlementEditCreditorModal;
