import * as React from 'react';
import { ChangeEvent, useCallback, useContext, useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
    checkGetAuthorization,
    getAuthorization,
    uploadAuthorization
} from '../../../../api/consumerBankingInfoApi';
import { nameToFormattedString } from '../../../../utils/stringUtils';
import { ConsumerContext } from '../ConsumerPage';
import InfoTooltip from '../../../common/InfoTooltip';
import '../../../../styles/views/Consumer.scss';
import LoadingButton from '../../../common/LoadingButton';

interface Props {}

function BankAccountAuthorization(props: Props) {
    const consumer = useContext(ConsumerContext);

    const [authOnFile, setAuthOnFile] = useState(false);
    const [authFile, setAuthFile] = useState<File>();

    const [loadingAuthOnFile, setLoadingAuthOnFile] = useState(false);
    const [loadingUploadAuth, setLoadingUploadAuth] = useState(false);
    const [loadingDownloadAuth, setLoadingDownloadAuth] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const checkAuthOnFile = async () => {
            setLoadingAuthOnFile(true);
            const response = await checkGetAuthorization(consumer.consumerID);
            if (response.error) {
                setApiError('Unable to load bank authorization: ' + (response.error as any).message);
            } else if (response.data) {
                setAuthOnFile(response.data);
            }
            setLoadingAuthOnFile(false);
        };
        checkAuthOnFile();
    }, [setApiError, consumer.consumerID]);

    const onFilePickerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAuthFile(undefined);
        e.target.files && setAuthFile(e.target.files[0]);
    };

    const onAuthUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (authFile) {
            setLoadingUploadAuth(true);
            const response = await uploadAuthorization(consumer.consumerID, authFile);
            if (response.error) {
                setApiError(response.error);
            } else {
                addToast('Authorization added', { appearance: 'success', autoDismiss: true });
                setAuthOnFile(true);
            }
            setLoadingUploadAuth(false);
        }
    };

    const onAuthDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setLoadingDownloadAuth(true);
        const response = await getAuthorization(
            consumer.consumerID,
            nameToFormattedString(
                consumer.contactInfo.primary.firstName,
                consumer.contactInfo.primary.middleInitial,
                consumer.contactInfo.primary.lastName
            )
        );
        if (response.error) {
            setApiError(`Unable to load bank authorization (${response.error}`);
        } else {
            addToast('Authorization retreived', { appearance: 'success', autoDismiss: true });
        }
        setLoadingDownloadAuth(false);
    };

    return (
        <div>
            <div className="title-with-tooltip">
                <h4>Bank Account Authorization</h4>
                <InfoTooltip
                    text="Use the controls below to upload an ACH authorization for this client. The
            authorization must be signed and must be in PDF format. This field should only be
            used for bank account authorization forms."
                />
            </div>

            <div className="row">
                <input
                    className="file-picker"
                    type="file"
                    accept=".pdf"
                    onChange={onFilePickerChange}
                />
                <LoadingButton
                    text="Upload Authorization"
                    loadingText="Uploading Authorization..."
                    loading={loadingUploadAuth}
                    onClick={onAuthUpload}
                    disabled={!authFile}
                />
                {!loadingAuthOnFile && authOnFile && (
                    <LoadingButton
                        text="Download Authorization"
                        loadingText="Downloading Authorization..."
                        loading={loadingDownloadAuth}
                        onClick={onAuthDownload}
                        disabled={!authOnFile}
                    />
                )}
            </div>
        </div>
    );
}

export default BankAccountAuthorization;
