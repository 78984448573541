import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import WrappedForm from '../../../common/form/WrappedForm';
import { ConsumerCreate } from '../../../../types/consumer';
import AddConsumerFormInputs from './AddConsumerFormInputs';
import { addConsumer } from '../../../../api/consumerApi';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import { Affiliate } from '../../../../types/affiliate';
import { FeeGroup } from '../../../../types/feeGroupTypes';
import { getAffiliates } from '../../../../api/affiliateApi';
import { getFeeGroups } from '../../../../api/feeSplitApi';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import AlertBanner from '../../../common/AlertBanner';
import { getApiDateFormat } from '../../../../utils/dateUtils';
import { useConsumerNavigation } from '../../../../hooks/useConsumerNavigation';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { useHistory } from 'react-router-dom';
import { pagePath, topLevelPages } from '../../../../pages';
import { UserPermission } from '../../../../types/enums';
import { CompanySettingsContext } from '../../../CompanyService';

const AddConsumerPage = () => {
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const companySettings = useContext(CompanySettingsContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);
    const history = useHistory();
    useEffect(() => {
        if (!hasEditConsumerPermissions) {
            history.push(`${pagePath(topLevelPages.home)}`);
        }
    }, [hasEditConsumerPermissions, history]);

    const [affiliates, setAffiliates] = useState<Affiliate[]>();
    const [feeGroups, setFeeGroups] = useState<FeeGroup[]>();

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [isBusinessDebt, setIsBusinessDebt] = useState<boolean>();
    
    const navigateToConsumerPage = useConsumerNavigation();

    useEffect(() => {
        const apiWrapper = loadFromApi(setApiError);
        const loadPageData = async () => {
            setLoading(true);
            const affiliatesResponse = apiWrapper(getAffiliates, setAffiliates);
            const feeGroupsResponse = apiWrapper(getFeeGroups, setFeeGroups);
            await affiliatesResponse;
            await feeGroupsResponse;

            if(companySettings.isBusinessDebt)
            {
                setIsBusinessDebt(companySettings.isBusinessDebt)
            } else {
                setIsBusinessDebt(false);
            }

            setLoading(false);
        };

        loadPageData();
    }, [setApiError]);

    const onSubmit = async (rawData: ConsumerCreate) => {
        // For Dates:
        // The API will reject an empty string; replace with null.
        // If non-empty, convert from "mm/dd/yyyy" format to "yyyy-mm-dd" format as the API expects.
        const {id, ...data} = rawData;
        const formattedData: ConsumerCreate = {
            id: !id  ? 0 : id, 
            ...data,
            dateOfFormation: (rawData.dateOfFormation && getApiDateFormat(rawData.dateOfFormation)) || null, 
            contactInfo: {
                ...rawData.contactInfo,
                primary: {
                    ...rawData.contactInfo.primary,
                    birthDate:
                        (rawData.contactInfo.primary.birthDate &&
                            getApiDateFormat(rawData.contactInfo.primary.birthDate)) ||
                        null
                },
                secondary: rawData.contactInfo.secondary && {
                    ...rawData.contactInfo.secondary,
                    birthDate:
                        (rawData.contactInfo.secondary.birthDate &&
                            getApiDateFormat(rawData.contactInfo.secondary.birthDate)) ||
                        null
                },
                

            }
        };

        setLoading(true);
        const addConsumerResponse = await addConsumer(formattedData);
        console.log(formattedData, "data");
        if (addConsumerResponse.error) {
            setApiError(addConsumerResponse.error);
        } else if (!addConsumerResponse.data) {
            setApiError('No consumer data returned');
        } else {
            addToast('Consumer successfully created.', {
                appearance: 'success',
                autoDismiss: true
            });
            navigateToConsumerPage(addConsumerResponse.data.consumerID);
        }
        setLoading(false);
    };

    return (
        <div className="page consumer-page">
            <h2 className="page-title">Add New Consumer</h2>

            <div className="consumer-input-section">
                {loading ? (
                    <LoadingDisplay />
                ) : !affiliates || !feeGroups ? (
                    <LoadFailedDisplay />
                ) : (
                    <WrappedForm<ConsumerCreate> onSubmit={onSubmit}>
                        <AddConsumerFormInputs affiliates={affiliates.filter((a) => a.payeeType != "COMPANY" )} feeGroups={feeGroups} isBusinessDebt={companySettings.isBusinessDebt} />
                    </WrappedForm>
                )}
            </div>
        </div>
    );
};

export default AddConsumerPage;
