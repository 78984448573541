import * as React from 'react';
import { DashboardWarning } from '../../../../types/dashboard';
import '../../../../styles/views/Home.scss';

interface Props {
    warning: DashboardWarning;
    clearWarning: (warningId: number) => void;
}

const WarningView = (props: Props) => {
    const handleButtonClick = () => {
        props.clearWarning(props.warning.id);
    };

    return (
        <li className="row-flex-container">
            <label className="warning">
                {props.warning.text}
                <span>
                    <button className="red-text-button" onClick={handleButtonClick}>
                        Clear
                    </button>
                </span>
            </label>
        </li>
    );
};

export default WarningView;
