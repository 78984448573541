import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ModalProps } from '../../../../types/common';
import { Consumer } from '../../../../types/consumer';
import '../../../../styles/common/ModalDialog.scss';
import '../../../../styles/views/Consumer.scss';
import QuickInfoPopupRow from './QuickInfoPopupRow';
import { accountStatusToDisplayName } from '../../../../utils/consumerUtils';

interface Props extends ModalProps {
    consumer: Consumer;
}

const ConsumerQuickInfoPopup = (props: Props) => {
    let dialogInstance: DialogComponent | null;
    const defaultButtons = [
        {
            buttonModel: {
                content: 'Close'
            },
            click: () => {
                props.setShowDialog(false);
                dialogInstance && dialogInstance.hide();
            }
        }
    ];

    return (
        <DialogComponent
            ref={dialog => dialogInstance = dialog}
            header="QuickInfo"
            isModal={true}
            buttons={defaultButtons}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() =>  {
                props.setShowDialog(false);
                dialogInstance && dialogInstance.hide()}}
            width="500px"
            height="auto"
        >
            <div className="quick-info-popup">
                <QuickInfoPopupRow label="Consumer Name" value={props.consumer.username} />
                <QuickInfoPopupRow
                    label="Consumer ID"
                    value={props.consumer.consumerID.toString()}
                />
                <QuickInfoPopupRow
                    label="Account Status"
                    value={accountStatusToDisplayName(props.consumer.accountStatus)}
                />
                <QuickInfoPopupRow
                    label="Savings balance"
                    value={`$${props.consumer.savingsBalance}`}
                />
                <QuickInfoPopupRow
                    label="Savings pending"
                    value={`$${props.consumer.savingsPending}`}
                />
            </div>
        </DialogComponent>
    );
};

export default ConsumerQuickInfoPopup;
