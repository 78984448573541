import * as React from 'react';
import '../../../../styles/views/Company.scss';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Affiliate } from '../../../../types/affiliate';
import { loadFromApi } from '../../../../api/baseApi';
import { getAffiliateUsers } from '../../../../api/affiliateApi';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import WrappedForm from '../../../common/form/WrappedForm';
import { User, UserCreate } from '../../../../types/user';
import AddUserFormInputs from '../users/AddUserFormInputs';
import EditUserDisplay from '../users/EditUserDisplay';
import { ReportType } from '../../../../types/report';
import { createUser } from '../../../../api/userApi';
import { UserRole } from '../../../../types/enums';

interface Props {
    affiliate: Affiliate;
    affiliates: Affiliate[];
    affiliateReportTypes: ReportType[];
}

const AffiliateUsersView = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const [users, setUsers] = useState<User[]>();
    // defined: that user is selected. undefined: no user selected. null: new user
    const [selectedUserId, setSelectedUserId] = useState<string | undefined | null>();
    const selectedUser =
        users && users.find(u => (selectedUserId ? u.userId === selectedUserId : undefined));

    useEffect(() => {
        const apiWrapper = loadFromApi(setApiError, setLoading);
        const loadPageData = async () => {
            await apiWrapper(() => getAffiliateUsers(props.affiliate.id), setUsers);
        };
        loadPageData();
    }, [props.affiliate, setApiError]);

    const handleUserCreatedConfirmation = (createdUser: User) => {
        if (!users) {
            setSelectedUserId(undefined);
            return;
        }

        setUsers([...users, createdUser]);
        setSelectedUserId(createdUser.userId);
    };

    const addUser = (newUser: UserCreate) => {
        // The Add User Form does not support editing the User Type/Role
        // (all users managed on this page are UserRole=Affiliate)
        const newUserFormatted: UserCreate = {
            ...newUser,
            userRole: UserRole.Affiliate
        };

        loadFromApi(setApiError, setLoading)(
            () => createUser(newUserFormatted),
            handleUserCreatedConfirmation
        );
    };

    const handleChangeSelectedUserId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.currentTarget.value;
        setSelectedUserId(selectedValue || undefined);
    };

    return (
        <div>
            <h4 className="row">Affiliate Users</h4>
            {loading ? (
                <LoadingDisplay />
            ) : users ? (
                <div>
                    <div>
                        <select
                            className="dropdown-select"
                            value={selectedUserId || 0}
                            onChange={handleChangeSelectedUserId}
                        >
                            <option value={0}>Select user...</option>
                            {users.map((u, index) => (
                                <option key={index} value={u.userId}>
                                    {u.username}
                                </option>
                            ))}
                        </select>
                        {selectedUserId !== null && (
                            <button
                                className="btn btn-primary"
                                onClick={() => setSelectedUserId(null)}
                            >
                                Add User
                            </button>
                        )}
                    </div>
                    {selectedUserId === null && (
                        <WrappedForm<UserCreate> onSubmit={addUser}>
                            <AddUserFormInputs isAffiliateUser={true} />
                            <div className="clear-fix">
                                <input
                                    className="btn btn-primary float-left"
                                    type="submit"
                                    value="Add"
                                />
                            </div>
                        </WrappedForm>
                    )}
                    {selectedUser && (
                        <div>
                            <EditUserDisplay
                                user={selectedUser}
                                updateUser={newSelectedUserValue => {
                                    const usersCopy = [...users];
                                    const selectedUserIndex = usersCopy.indexOf(selectedUser);
                                    if (selectedUserIndex) {
                                        usersCopy.splice(
                                            selectedUserIndex,
                                            1,
                                            newSelectedUserValue
                                        );
                                        setUsers(usersCopy);
                                    }
                                }}
                                affiliates={props.affiliates}
                                reportTypes={props.affiliateReportTypes}
                                isAffiliateUser={true}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <LoadFailedDisplay />
            ) }
        </div>
    );
};

export default AffiliateUsersView;
