import * as React from 'react';
import '../../../../styles/views/Consumer.scss';
import { ConsumerContext } from '../ConsumerPage';
import { useContext, useState } from 'react';
import { getLedgerFile } from '../../../../api/consumerApi';
import { UserPermission } from '../../../../types/enums';
import FreeRefundModal from './FreeRefundModal';
import { AuthContext } from '../../../../authentication/authContext';
import SimpleLedgersGrid from './SimpleLedgersGrid';
import FullLedgers from './FullLedgers';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';

const ConsumerLedgerTab = () => {
    const consumer = useContext(ConsumerContext);

    const [showFreeRefundModal, setShowFreeRefundModal] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const { isUnlimitedUser } = useContext(AuthContext);
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasViewLedgerPermissions = userHasPermission(UserPermission.ViewLedger);

    const onLedgerDownload = (isExcel: boolean, isFull: boolean, isSimple: boolean) => {
        getLedgerFile(consumer.consumerID, isExcel, isFull, isSimple);
    };

    return (
        <div className="consumer-input-section">
            <h3>Ledger</h3>

            <div>
                <div className="button-bar clear-fix">
                    <div className="float-right">
                        <button
                            className="btn btn-outline-primary margin-right-10"
                            onClick={() => {
                                onLedgerDownload(false, true, false);
                            }}
                        >
                            Download Full Ledger PDF
                        </button>
                        <button
                            className="btn btn-outline-primary margin-right-10"
                            onClick={() => {
                                onLedgerDownload(false, false, true);
                            }}
                        >
                            Download Client Ledger PDF
                        </button>
                        <button
                            className="btn btn-outline-primary margin-right-10"
                            onClick={() => {
                                onLedgerDownload(true, true, false);
                            }}
                        >
                            Download Full Ledger Excel
                        </button>
                        <button
                            className="btn btn-outline-primary margin-right-10"
                            onClick={() => {
                                onLedgerDownload(true, false, true);
                            }}
                        >
                            Download Client Ledger Excel
                        </button>
                        {isUnlimitedUser() && (
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setShowFreeRefundModal(true);
                                }}
                            >
                                Issue Refund
                            </button>
                        )}
                    </div>
                </div>
                {hasViewLedgerPermissions ? <FullLedgers refresh={refresh} 
                refreshed={()=>{
                    setRefresh(false)
                }}/> : <SimpleLedgersGrid />}

                <FreeRefundModal
                    targetId="consumerPage"
                    showDialog={showFreeRefundModal}
                    setShowDialog={(val) =>{
                        setShowFreeRefundModal(val);
                        setRefresh(true)
                    }}
                />
            </div>
        </div>
    );
};

export default ConsumerLedgerTab;
