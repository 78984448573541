import * as React from 'react';
import { useContext } from 'react';
import { getConsumerStatement } from '../../../../api/consumerApi';
import { AuthContext } from '../../../../authentication/authContext';
import { AccountStatus } from '../../../../types/enums';
import { serverDateToDisplayDate } from '../../../../utils/dateUtils';
import { nameToFormattedString } from '../../../../utils/stringUtils';
import AddressDisplay from '../../../common/AddressDisplay';
import InfoTooltip from '../../../common/InfoTooltip';
import { ConsumerContext, ConsumerSettlementSummaryContext, FeeGroupContext } from '../ConsumerPage';
import { accountStatusToDisplayName } from '../../../../utils/consumerUtils';

interface Props {
    setShowConsumerNotesModal: (newShowConsumerNotesModal: boolean) => void;
    setShowResetPasswordConfirmation: (newShowResetPasswordConfirmation: boolean) => void;
}

const ConsumerAccountProfile = (props: Props) => {
    const { isUnlimitedUser } = useContext(AuthContext);
    const consumer = useContext(ConsumerContext);
    const consumerSettlementSummary = useContext(ConsumerSettlementSummaryContext);
    const feeGroups = useContext(FeeGroupContext);

    const onDownloadStatement = () => {
        getConsumerStatement(
            consumer.consumerID,
            nameToFormattedString(
                consumer.contactInfo.primary.firstName,
                consumer.contactInfo.primary.middleInitial,
                consumer.contactInfo.primary.lastName
            )
        );
    };

    return (
        <div className="row">
            <div className="col-4">
                <div className="wrapped-info">
                    <label className="form-label">Account Status</label>
                    <div>
                        <div className="flex">
                            <span className="value">
                                {accountStatusToDisplayName(consumer.accountStatus)}
                            </span>
                            <InfoTooltip text="The account status for this account. If this is set to anything other than Active, the account will not draft." />
                        </div>
                        {consumer.accountStatus === AccountStatus.Cancelled && (
                            <span className="info">
                                {`Cancel Date: ${serverDateToDisplayDate(consumer.cancelDate)}`}
                            </span>
                        )}
                    </div>
                </div>
                <div className="wrapped-info">
                    <label className="form-label">Contact Info</label>
                    <AddressDisplay
                        name={nameToFormattedString(
                            consumer.contactInfo.primary.firstName,
                            consumer.contactInfo.primary.middleInitial,
                            consumer.contactInfo.primary.lastName
                        )}
                        address={consumer.contactInfo.primary.address}
                    ></AddressDisplay>
                </div>
                <div className="wrapped-info">
                    <label className="form-label">Email</label>
                    <span className="value">{consumer.email}</span>
                </div>
                <div className="wrapped-info">
                    <label className="form-label">Mailed Statements Only</label>
                    <span className="value">{consumer.mailedStatementOnly ? 'Yes' : 'No'}</span>
                </div>
                <div className="wrapped-info">
                    <label className="form-label">Exclude From Repay</label>
                    <span className="value">{consumer.excludeFromRepay ? 'Yes' : 'No'}</span>
                </div>
            </div>
            <div className="col-4">
                <div className="wrapped-info">
                    <label className="form-label">Affiliate</label>
                    <span className="value">{consumer.affiliateName}</span>
                </div>
                <div className="wrapped-info">
                    <label className="form-label">Fee Group</label>
                    <span className="value">
                        {feeGroups.find(g => g.id === consumer.feeGroupId)?.name || 'Not Found'}
                    </span>
                </div>
                <div className="wrapped-info">
                    <label className="form-label"># of Settlements: </label>
                    <span className="value">{consumerSettlementSummary.numberOfSettlements}</span>
                </div>
                
                <div className="wrapped-info">
                        <label className="form-label"># of Installments Paid: </label>
                        <span className="value">{consumerSettlementSummary.totalInstallments > 0 ? consumerSettlementSummary.sentInstallments + " of " + consumerSettlementSummary.totalInstallments: "n/a" }</span>
                    </div>

                <div className="wrapped-info">
                    <label className="form-label">Total Amount Paid: </label>
                    <span className="value">${consumerSettlementSummary.totalAmtPaid}</span>
                </div>
                <div className="wrapped-info">
                    <label className="form-label">Last Installment: </label>
                    <span className="value">{consumerSettlementSummary.lastInstallment > "1900-01-01T00:00:00" ? new Date(consumerSettlementSummary.lastInstallment).toLocaleDateString() :  "n/a"}</span>
                </div>
                
                
            </div>
            <div className="col-2">
                <div>
                    <button
                        className="btn btn-outline-danger view-notes"
                        onClick={() => props.setShowConsumerNotesModal(true)}
                    >
                        View Notes
                    </button>
                </div>
                {isUnlimitedUser() && (
                    <div>
                        <button
                            className="btn btn-secondary"
                            onClick={() => props.setShowResetPasswordConfirmation(true)}
                        >
                            Reset Password
                        </button>
                    </div>
                )}
                <div>
                    <button className="btn btn-secondary" onClick={onDownloadStatement}>
                        Download Statement
                    </button>
                </div>
            </div>
            <div className="col-2">
                <div className="wrapped-info">
                    <label>{`Setup Date: ${serverDateToDisplayDate(consumer.setupDate)}`}</label>
                </div>
            </div>
        </div>
    );
};

export default ConsumerAccountProfile;
