import * as React from 'react';
import { createContext, useEffect, useState, useCallback } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { getConsumer, getConsumerSettlementSummary } from '../../../api/consumerApi';
import { getFeeGroups, getFeesByFeeGroup } from '../../../api/feeSplitApi';
import { ConsumerParams, consumerRoutingPath, consumerSubPages } from '../../../pages';
import { Consumer, ConsumerContactInfo, ConsumerSettlementSummary } from '../../../types/consumer';
import { Fee, FeeGroup } from '../../../types/feeGroupTypes';
import '../../../styles/Form.scss';
import '../../../styles/views/Consumer.scss';
import ConsumerGeneralInformation from './general/ConsumerGeneralInformation';
import ConsumerTabBar from './ConsumerTabBar';
import { loadFromApi } from '../../../api/baseApi';
import LoadingDisplay from '../../common/LoadingDisplay';
import LoadFailedDisplay from '../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';
import ConsumerContactInfoTab from './contactInfo/ConsumerContactInfoTab';
import ConsumerDraftsTab from './drafts/ConsumerDraftsTab';
import ConsumerScheduleTab from './schedule/ConsumerScheduleTab';
import ConsumerBankingInfoTab from './bankingInfo/ConsumerBankingInfoTab';
import ConsumerSettlementInfoTab from './settlementInfo/ConsumerSettlementInfoTab';
import ConsumerLedgerTab from './ledgers/ConsumerLedgerTab';
import ConsumerAccountSetupTab from './accountSetup/ConsumerAccountSetupTab';
import ConsumerSupportTicketsTab from './supportTickets/ConsumerSupportTicketsTab';
import ConsumerFeesTab from './fees/ConsumerFeesTab';
import ConsumerForecastTab from './forecast/ConsumerForecastTab';

export const ConsumerContext = createContext<Consumer>({} as Consumer); //Context is never loaded until consumer is defined
export const FeeContext = createContext<Fee[]>([]);
export const FeeGroupContext = createContext<FeeGroup[]>([]);
export const ConsumerSettlementSummaryContext = createContext<ConsumerSettlementSummary>({} as ConsumerSettlementSummary);

const ConsumerPage = () => {
    let { consumerId } = useParams<ConsumerParams>();
    const [consumer, setConsumer] = useState<Consumer>();
    const [fees, setFees] = useState<Fee[]>();       
    const [feeGroups, setFeeGroups] = useState<FeeGroup[]>();
    const [consumerSettlementSummary, setSettlementSummary] = useState<ConsumerSettlementSummary>();
    const [loading, setLoading] = useState(false);
    
    const refreshHeader = (newContactInfo: ConsumerContactInfo) => {
        if (consumer) {
            setConsumer({ ...consumer, contactInfo: newContactInfo });
        }
    };

    const loadConsumerPageData = async () => {            
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );

        const consumerLoad = consumerPageApiWrapper(
            () => getConsumer(parseInt(consumerId)),
            setConsumer
        );

        await consumerLoad;
    
    };

    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );



    useEffect(() => {
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );

        setLoading(true);

        
        const loadConsumerFeeData = async () => {            
            
            const consumerFeeDetailsLoad = loadFromApi(setApiError, setLoading)(
                () => getFeesByFeeGroup(consumer!.feeGroupId),
                setFees
            );
                               
            await consumerFeeDetailsLoad;
        
        };    
        
        const loadConsumerPageData = async () => {            
        
            const consumerLoad = consumerPageApiWrapper(
                () => getConsumer(parseInt(consumerId)),
                setConsumer
            );

            const consumerFeeGroupsLoad = consumerPageApiWrapper(getFeeGroups, setFeeGroups);
            
            await consumerLoad;
            await consumerFeeGroupsLoad;                 
            
        
        };
        
        const loadConsumerSettlementSummaryData = async () => {            
            
            const consumerSettlementSummaryLoad = loadFromApi(setApiError, setLoading)(
                () => getConsumerSettlementSummary(parseInt(consumerId)),
                setSettlementSummary
            );
                               
            await consumerSettlementSummaryLoad;
        
        };    

        loadConsumerPageData();
        setLoading(false);        
         if(consumer?.feeGroupId) {
             loadConsumerFeeData();
             
            }
        loadConsumerSettlementSummaryData();
        

    }, [consumerId, addToast, setApiError, consumer?.feeGroupId]);

  


    const refreshConsumerData = async () => {
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );

        setLoading(true);

        const consumerLoad = consumerPageApiWrapper(
            () => getConsumer(parseInt(consumerId)),
            setConsumer
        );
        await consumerLoad;  

        setLoading(false);
    };

    return (
        <div className="page consumer-page" id="consumerPage">
            {loading ? (
                <LoadingDisplay />
            ) : consumer && fees && feeGroups && consumerSettlementSummary ? (
                <ConsumerContext.Provider value={consumer}>
                    <FeeGroupContext.Provider value={feeGroups}>
                    <FeeContext.Provider value={fees}>
                    <ConsumerSettlementSummaryContext.Provider value={consumerSettlementSummary}>
                        <ConsumerGeneralInformation />
                        <ConsumerTabBar />
                        <Switch>
                            <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.contactInfo)}
                                render={props => (
                                    <ConsumerContactInfoTab
                                        {...props}
                                        refreshHeader={refreshHeader}
                                        loadConsumerPageData={loadConsumerPageData}
                                    />
                                )}
                            />
                            <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.drafts)}
                                component={ConsumerDraftsTab}
                            />
                             <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.forecast)}
                                component={ConsumerForecastTab}
                            />
                             <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.fees)}
                                component={ConsumerFeesTab}
                            />
                            <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.schedule)}
                                component={ConsumerScheduleTab}
                            />
                            <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.bankingInfo)}
                                component={ConsumerBankingInfoTab}
                            />
                            <Route
                                path={consumerRoutingPath(
                                    consumerId,
                                    consumerSubPages.settlementInfo
                                )}
                                component={ConsumerSettlementInfoTab}
                            />
                            <Route
                                path={consumerRoutingPath(consumerId, consumerSubPages.ledger)}
                                component={ConsumerLedgerTab}
                            />
                            <Route
                                path={consumerRoutingPath(
                                    consumerId,
                                    consumerSubPages.accountSetup
                                )}
                                component={() => (
                                    <ConsumerAccountSetupTab
                                        updateConsumerData={refreshConsumerData}
                                    />
                                )}
                            />
                            <Route
                                path={consumerRoutingPath(
                                    consumerId,
                                    consumerSubPages.supportTickets
                                )}
                                component={ConsumerSupportTicketsTab}
                            />
                            <Redirect
                                to={consumerRoutingPath(consumerId, consumerSubPages.contactInfo)}
                            />
                        </Switch>
                    </ConsumerSettlementSummaryContext.Provider>
                    </FeeContext.Provider>
                    </FeeGroupContext.Provider>
                </ConsumerContext.Provider>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default ConsumerPage;
