import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DraftBulkEdit } from '../../../../types/drafts';
import { DraftFrequency } from '../../../../types/enums';
import { validateNonNegative, validateCurrency, validateDraftDate, validateDayOfMonth, validateDraftFrequency } from '../../../../utils/validationRules';
import { DateTime } from 'luxon-business-days';
import WrappedInput from '../../../common/form/WrappedInput';
import DatePicker from '../../../common/DatePicker';

const DraftBulkEditFormInputs = (props: any) => {
    const { register, control, watch } = useFormContext<DraftBulkEdit>();
    const savingsAmountChanged = watch('savingsAmountChanged');
    const draftAmountChanged = watch('draftAmountChanged');
    const draftDateChanged = watch('draftDateChanged');
    const draftDateDayofMonthChanged = watch('draftDateDayofMonthChanged');
    const editfees = watch('editFees');
    const { fields } = useFieldArray({
        control,
        name: 'editFees'
    });

    return (
        <div className="consumer-input-section">
            <div>
            <div className={`flex edit-checkbox`}>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftDateDayOfMonth"
                        labelText="Draft Day of the Month"
                    >
                        <input
                            type="number"
                            step="1"
                            readOnly={!draftDateDayofMonthChanged}
                            {...register('draftDateDayOfMonth', {
                                validate: {
                                    validateDayOfMonth: (value) => validateDayOfMonth(value as number, draftDateDayofMonthChanged as boolean),
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftDateDayofMonthChanged"
                        labelText="Edit"  
                        helpText={`Specify the day of the month to update the selected drafts. 
                            If the specified day does not exist in a given month, the draft will be scheduled for the last day of that month.`}                      
                    >
                        <input
                            type="checkbox"
                            {...register(`draftDateDayofMonthChanged`)}
                        />
                    </WrappedInput>
                </div>
            <div className={`flex edit-checkbox ${!draftDateChanged ? 'disabled' : ''}`}>
            <WrappedInput<DraftBulkEdit>
                    inputName="draftDate"
                    labelText="Draft Date"
                    required={false}
                >
                    <Controller
                        name="draftDate"
                        control={control}
                        rules={{
                            required: 'Please select a date.',
                            validate: validateDraftDate
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                isDateRange={false}
                                initialStartDate={value}
                                changeDate={onChange}
                                minimumDate={DateTime.utc().plusBusiness({ days: 1 }).toUTC().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                            />
                        )}
                    />
                    
                </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftDateChanged"
                        labelText="Edit"  
                        helpText={`Specify the new Draft Date along with the payment frequency.`}                      
                    >
                        <input
                            type="checkbox"
                            {...register(`draftDateChanged`)}
                        />
                    </WrappedInput>
                </div>
                <div className={`flex edit-checkbox ${!draftDateChanged ? 'hide' : ''}`}>
            <WrappedInput<DraftBulkEdit>  required={true} inputName="PaymentsFrequency" labelText="Payments Frequency">
                         <select
                            className="dropdown-select"
                            required={true}
                            {...register('paymentFrequency',
                            {
                                validate: {
                                    validateDraftFrequency: (value) => validateDraftFrequency(value as number, draftDateChanged as boolean) || 'Invalid draft frequency',
                                },
                                valueAsNumber:true
                            })}>
                            <option value={DraftFrequency.NotSet}>Not Set</option>
                            <option value={DraftFrequency.Daily}>Daily</option>
                            <option value={DraftFrequency.Weekly}>Weekly</option>
                            <option value={DraftFrequency.Biweekly}>Biweekly</option>
                            <option value={DraftFrequency.Monthly}>Monthly</option>
                            <option value={DraftFrequency.Bimonthly}>Bimonthly</option>
                            <option value={DraftFrequency.Quarterly}>Quarterly</option>
                            <option value={DraftFrequency.Semiannually}>Semiannually</option>
                            <option value={DraftFrequency.Annually}>Annually</option>
                        </select>
                </WrappedInput>
                <WrappedInput<DraftBulkEdit>
                        inputName=""
                        labelText=""                 
                    >
                    </WrappedInput>
                </div>
            <div className={`flex edit-checkbox ${savingsAmountChanged ? 'hide' : ''}`}>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftAmount"
                        labelText="Total Draft Amount"
                    >
                        <input
                            type="number"
                            step='0.01'
                            readOnly={!draftAmountChanged}
                            {...register('draftAmount', {
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName="draftAmountChanged"
                        labelText="Edit"  
                        helpText={`Specify the dollar amount you want to send to the consumers savings for this draft. 
                        The total draft amount is derived from the Savings amount plus all Fee amounts plus the RAM Fee 
                        (for the first draft in each month).`}                      
                    >
                        <input
                            type="checkbox"
                            {...register(`draftAmountChanged`)}
                            disabled={savingsAmountChanged}
                        />
                    </WrappedInput>
                </div>
                <div className={`flex edit-checkbox ${draftAmountChanged ? 'hide' : ''}`}>
                    <WrappedInput<DraftBulkEdit>
                        inputName="savingsAmount"
                        labelText="Savings Amount"
                    >
                        <input
                            type="number"
                            readOnly={!savingsAmountChanged}
                            step='0.01'
                            {...register('savingsAmount', {
                                validate: {
                                    positive: validateNonNegative,
                                    currency: validateCurrency
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName="savingsAmountChanged"
                        labelText="Edit"  
                        helpText={`Specify the dollar amount you want to send to the consumers savings for this draft. 
                        The total draft amount is derived from the Savings amount plus all Fee amounts plus the RAM Fee 
                        (for the first draft in each month).`}                      
                    >
                        <input
                            type="checkbox"
                            {...register(`savingsAmountChanged`)}
                            disabled={draftAmountChanged}
                        />
                    </WrappedInput>
                </div>
                {fields.map((field, index) => (
                    <div className='flex edit-checkbox'><WrappedInput<DraftBulkEdit>
                        key={field.id}
                        inputName={`editFees[${index}].value`}
                        labelText={field.name}
                    >
                        <input
                            type="number"
                            step='0.01'
                            readOnly={!editfees[index].edit}
                            {...register(`editFees.${index}.value`, {
                                validate: {
                                    positive: validateNonNegative,
                                    currency: validateCurrency
                                },
                                valueAsNumber: true
                            })}
                            defaultValue={field.value}
                        />
                    </WrappedInput>
                    <WrappedInput<DraftBulkEdit>
                        inputName={`editFees[${index}].edit`}
                        labelText="Edit"   
                        helpText={`Specify the dollar amount for each fee for the checked drafts. 
                        The total draft amount is derived from the Savings amount plus all Fee amounts plus the RAM Fee 
                        (for the first draft in each month).`}                     
                    >
                     <input
                         type="checkbox"
                         {...register(`editFees.${index}.edit`)}
                         disabled={props.viewOnly}
                     />
                    </WrappedInput></div>
                ))}
            </div>
            <hr></hr>
            <div className="clear-fix">
                <input className="btn btn-primary float-left" disabled={props.viewOnly} type="submit" value="Save" />
            </div>
        </div>
    );
};

export default DraftBulkEditFormInputs;
