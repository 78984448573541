import { Affiliate, AffiliateUpdate } from '../types/affiliate';
import { User } from '../types/user';
import { httpRequest, Urls } from './baseApi';

const AFFILIATE_URL = `${Urls.BASE_URL}/affiliates`;

export const getAffiliates = () => httpRequest<Affiliate[]>(AFFILIATE_URL, 'get');

export const getAffiliateUsers = (affiliateId: number) =>
    httpRequest<User[]>(`${AFFILIATE_URL}/${affiliateId}/users`, 'get');

export const updateAffiliate = (affiliateId: number, affiliateUpdate: AffiliateUpdate) =>
    httpRequest<{}>(`${AFFILIATE_URL}/${affiliateId}`, 'put', affiliateUpdate);
