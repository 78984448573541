import { useEffect, createContext,useState } from 'react';
import {GridView} from '../types/gridView';
export const GridViewContext = createContext<GridView>({} as GridView);

export const GridViewService =(data: any)=>{
    let [currentView, setCurrentView] = useState(data?.preferredView);
    let [ids, setIds] = useState(data?.preferredView);

    useEffect(() => {
        if(data){
            setCurrentView(data?.preferredView)
        }
    },[data])
    const changeView = (val?:number) => {
          if(val){
             setCurrentView(val)
          }
          else
          setCurrentView(1)
    } 
   
    return {
        changeView,
        currentView,
        ids,
        setIds
    } 
}
