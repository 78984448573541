import * as React from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    GridComponent,
    Inject,
    PdfExport,
    Toolbar, 
    Page,
    Resize,
    AggregatesDirective,
    AggregateDirective,
    AggregateColumnsDirective,
    AggregateColumnDirective,
    Aggregate
} from '@syncfusion/ej2-react-grids';
import { useEffect } from 'react';
import { useCommonGridOptions } from '../../../utils/gridUtils';
import { Report } from '../../../types/report';
import '../../../styles/views/Reporting.scss';
import { camelCaseToString } from '../../../utils/stringUtils';
import {GridViewContext} from '../../GridViewService';

interface Props {
    report: Report;
    reportId?: number;
}

const ReportGrid = (props: Props) => {
    const gridOptions = useCommonGridOptions('Report');
    let gridOptionsWithPaging =  {...gridOptions, ...{ allowPaging: true }};
    const consumersGrid = gridOptionsWithPaging && gridOptionsWithPaging.ref;

    useEffect(() => {
        consumersGrid.current?.refresh();
    }, [props.report, consumersGrid]);

    const { currentView } = React.useContext(GridViewContext);

    let dataBound = () =>{
        if(currentView == 2)
        {
            gridOptions.ref.current && gridOptions.ref.current.autoFitColumns([])
        }
    }
    const footerSum = (props: any): any  =>{
        return(<span>
                {props.Sum}
            </span>)
            };
 
    const footerText = () => {
        return( <span>
                    Total
                </span> )
                };
    
    const formats=['Debits','Credits','TotalFees','TotalDraftAmt','TotalOwed']

    return (
        <div className={`report-grid ${currentView ===2 ? 'comfort-grid-view' : ''} ${currentView ===3 ? 'compact-view' : ''}`}>            
            <GridComponent 
           {...gridOptionsWithPaging} 
           dataBound= { dataBound }
           dataSource={props.report.data[0]} 
           pageSettings={{ pageSize: 25 }} 
           clipMode='EllipsisWithTooltip'>
                <ColumnsDirective>
                    {props.report.columns.map((c, index) => (
                        <ColumnDirective
                            key={index}
                            // Convert field name to camelCase in order to match the format used by the data array
                            field={`${c.fieldName
                                .substring(0, 1)
                                .toLowerCase()}${c.fieldName.substring(1)}`}
                            headerText={camelCaseToString(c.columnName)}
                            width="100px"
                            textAlign="Left"
                            format={props.reportId == 4 && (formats.some((val) => val === c.fieldName))?'C2':''}
                        />
                    ))}
                </ColumnsDirective>
                {props.reportId == 4 && <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                         {
                                 props.report.columns.map((c,index) => {
                                    if(formats.some((val) => val === c.fieldName)){
                                        return <AggregateColumnDirective key={index}
                                        field = {`${c.fieldName.substring(0,1).toLowerCase()}${c.fieldName.substring(1)}`}
                                        type ='Sum' format="C2" footerTemplate={footerSum} 
                                    />
                                    }
                                })

                            }
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>}
                <Inject services={[PdfExport, ExcelExport, Toolbar, Page,Resize, Aggregate]} />
            </GridComponent>
        </div>        
    );
};

export default ReportGrid;
