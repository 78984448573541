import * as React from 'react';
import { BusinessPrincipal, ConsumerCreate } from '../../../../types/consumer';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../common/form/WrappedInput';
import {
    validateEmail,
    validateNullOrPhone,
    validatePhone,
    validateState,
    validateUsername
} from '../../../../utils/validationRules';
import InfoTooltip from '../../../common/InfoTooltip';
import StateDropdown from '../../../common/form/StateDropdown';
import { Affiliate } from '../../../../types/affiliate';
import { FeeGroup } from '../../../../types/feeGroupTypes';
import { InputEventArgs, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { dateMask, phoneMask, taxIdMask } from '../../../../utils/masks';
import '../../../../styles/Form.scss';
import '../../../../styles/views/Consumer.scss';
import NumericDropdown from '../../../common/NumericDropdown';
import { MAX_TEXT_BOX } from '../../../../utils/formConstants';
import { accountStatuses } from '../../../../utils/consumerUtils';
import { setPlaceHolder } from '@syncfusion/ej2-react-dropdowns';
import { Border, xToCoordinate } from '@syncfusion/ej2-react-maps';


interface Props {
    affiliates: Affiliate[];
    feeGroups: FeeGroup[];
    isBusinessDebt: boolean;
}

const AddConsumerFormInputs = (props: Props) => {
    const { register, control } = useFormContext<ConsumerCreate>();
    const [businessPrincipals, setBusinessPrincipals] = React.useState<BusinessPrincipal[]>([]);
    const [businessPrincipalname, setbusinessPrincipalName] = React.useState('');

    const [count, setCount] = React.useState(0);

    const handlePrincipalChange = (event: React.ChangeEvent) => {
        setbusinessPrincipalName((event.target as HTMLInputElement).value);
    }

    const handlePrincipalAdd = () => {
        const newPrincipal: BusinessPrincipal = { fname: count.toString(), lname: "", address: "", city: "", ssn: "", state: "", zip: "" };
        setCount(count + 1);
        setBusinessPrincipals([...businessPrincipals, newPrincipal]);

        setbusinessPrincipalName('');
    }

    const handlePrincipalRemove = (index: string) => {
        console.log(businessPrincipals, "preFilter");
        console.log(index, "filter");
        setBusinessPrincipals(businessPrincipals.filter((p) => { return p.fname != index; }))
        console.log(businessPrincipals, "postFilter");
    }

    const getUniqueAffiliates = () => {
        const unique: Affiliate[] = [];
        props.affiliates.map(p => {
            var findItem = unique.find((x) => x.id === p.id);
            if (!findItem) unique.push(p);
        });
        return unique;
    }
    const i = 0;

    return (
        <div>
            <h4 className="row">Consumer Information</h4>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<ConsumerCreate>
                        inputName="id"
                        labelText="Consumer ID"
                        helpText={`Consumer ID is a unique numeric identifier for this consumer for your company. 
                                        This is often a CRM ID or other account number. The Consumer ID must be unique across 
                                        all of your customers. It must be numeric, and it must be 5 digits or longer. 
                                        Alternatively, you can leave this field blank, and the system will number the consumer 
                                        for you.`}
                    >
                        <input
                            {...register('id', {
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: 'Consumer IDs must be numeric.'
                                },
                                minLength: {
                                    value: 5,
                                    message: 'Consumer IDs must contain at least 5 digits.'
                                },
                                maxLength: MAX_TEXT_BOX,
                                max: {
                                    value: Math.pow(2, 32) - 1,
                                    message:
                                        'Consumer ID is too long, please use a shorter numeric ID.'
                                }
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerCreate>
                        inputName="miscellaneous"
                        labelText="Miscellaneous"
                        helpText={`Miscellaneous is a text field used on the Agent Report to group consumer drafts 
                                        by agent in order to determine a total draft amount on which to base commissions. 
                                        This is a free-form entry which means that you should be careful to always type 
                                        in an agent name in the same way each time in order to keep that agent's consumers 
                                        grouped correctly on the report. For example, "John Smith" and "J. Smith" will be 
                                        two different consumer groups on the report.`}
                    >
                        <input
                            {...register('miscellaneous', {
                                maxLength: MAX_TEXT_BOX
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerCreate>
                        inputName="username"
                        labelText="Username"
                        helpText={`This is the username a consumer must enter to log in to the RAM system. Using 
                                        an email address is recommended. You can leave this field blank to auto-generate a 
                                        username.`}
                    >
                        <input
                            {...register('username', {
                                validate: validateUsername,
                                maxLength: MAX_TEXT_BOX
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerCreate>
                        inputName="email"
                        labelText="Email"
                        helpText={`The consumer's email address. If you do not have an email address, use the 
                                        placeholder 'missingemail@[ConsumerFirstName][ConsumerLastName].com' for example: 
                                        missingemail@johndoe.com or missingemail2@johndoe.com if the first one already 
                                        exists. The system requires that all email addresses entered be unique.`}
                        required={true}
                    >
                        <input
                            {...register('email', {
                                required: 'Please enter an email address.',
                                validate: validateEmail,
                                maxLength: MAX_TEXT_BOX
                            })}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    <WrappedInput<ConsumerCreate>
                        inputName="affiliateId"
                        labelText="Affiliate"
                        helpText={`The affiliate for this consumer.`}
                        required={props.affiliates.length > 0}
                    >
                        <Controller
                            name="affiliateId"
                            control={control}
                            rules={{
                                validate: a =>
                                    getUniqueAffiliates().length === 0 ||
                                    a !== undefined ||
                                    'Please select an Affiliate.'
                            }}
                            render={({ field: { onChange, value } }) => (
                                <NumericDropdown
                                    options={getUniqueAffiliates().map(a => (
                                        {
                                            value: a.id,
                                            text: a.name
                                        }
                                    ))}
                                    defaultOptionText="Select..."
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                />
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerCreate>
                        inputName="feeGroupId"
                        labelText="Fee Group"
                        helpText={`The Fee Group for this consumer. Fee Groups determine how paid fees are split 
                            and disbursed for a consumer account.`}
                        required={props.feeGroups.length > 0}
                    >
                        <Controller
                            name="feeGroupId"
                            control={control}
                            rules={{
                                validate: fg =>
                                    props.feeGroups.length === 0 ||
                                    fg !== undefined ||
                                    'Please select a Fee Group.'
                            }}
                            render={({ field: { onChange, value } }) => (
                                <NumericDropdown
                                    options={props.feeGroups.map(fg => ({
                                        value: fg.id,
                                        text: fg.name
                                    }))}
                                    defaultOptionText="Select..."
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                />
                            )}
                        />
                    </WrappedInput>

                    <WrappedInput<ConsumerCreate>
                        inputName="accountStatus"
                        labelText="Account Status"
                        helpText={`The account status for this account. If this is set to anything other than Active, 
                                        the account will not draft.`}
                        required={true}
                    >
                        <Controller
                            name="accountStatus"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <NumericDropdown
                                    options={accountStatuses.map(as => ({
                                        value: as.value,
                                        text: as.name
                                    }))}
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                    {...register("accountStatus")}
                                />
                            )}
                        />
                    </WrappedInput>


                </div>
            </div>
            <hr></hr>
            <div>
                {props.isBusinessDebt && // props.IsBusinessDebt
                    <div>
                        <div>
                            <h4>Business Information</h4>
                        </div>

                        <div className="row">

                            <div className="col-6">
                                <WrappedInput<ConsumerCreate>
                                    inputName="businessName"
                                    labelText="Business Name"
                                    helpText={`The name of the business associated with this account.`}
                                >
                                    <input
                                        {...register('businessName', {
                                            maxLength: MAX_TEXT_BOX
                                        })}
                                    />
                                </WrappedInput>
                            </div>
                            <div className="col-6">
                                <WrappedInput<ConsumerCreate>
                                    inputName="dateOfFormation"
                                    labelText="Date of Formation"
                                >
                                    <MaskedTextBoxComponent
                                        {...register('dateOfFormation')}
                                        mask={dateMask}
                                        placeholder="mm/dd/yyyy"
                                    />
                                </WrappedInput>
                            </div>
                        </div>
                        <div>
                            <div>
                                <WrappedInput<ConsumerCreate>
                                    inputName="businessPrincipals"
                                    labelText="Business Principals"

                                >
                                    <div>
                                        <button className="btn btn-primary float-left" type="button" onClick={handlePrincipalAdd}>
                                            Add
                                        </button>
                                    </div>
                                </WrappedInput>

                                {businessPrincipals.map((item, index) => (
                                    <div style={{ borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#e6e6e6" }} className="row" key={parseInt(item.fname)} >
                                        <div className="col-6"  >
                                            <WrappedInput<ConsumerCreate>
                                                inputName={`businessPrincipals.${parseInt(item.fname)}.fname`}
                                                labelText="First Name"
                                                required={false}
                                            >
                                                <input
                                                    {...register(`businessPrincipals.${parseInt(item.fname)}.fname`, {
                                                        maxLength: MAX_TEXT_BOX
                                                    })}
                                                />
                                            </WrappedInput>

                                            <WrappedInput<ConsumerCreate>
                                                inputName={`businessPrincipals.${parseInt(item.fname)}.lname`}
                                                labelText="Last Name"
                                                required={false}
                                            >
                                                <input
                                                    {...register(`businessPrincipals.${parseInt(item.fname)}.lname`, {
                                                        maxLength: MAX_TEXT_BOX
                                                    })}
                                                />
                                            </WrappedInput>

                                            <WrappedInput<ConsumerCreate>
                                                inputName={`businessPrincipals.${parseInt(item.fname)}.ssn`}
                                                labelText="Tax ID"
                                                helpText="Enter the consumer's full SSN or other Tax ID."
                                            >
                                                <MaskedTextBoxComponent
                                                    {...register(`businessPrincipals.${parseInt(item.fname)}.ssn`, {
                                                        maxLength: 11,
                                                        minLength: 9
                                                    })}
                                                    mask={taxIdMask}
                                                />
                                            </WrappedInput>
                                            <button className="btn btn-primary float-left" type="button" onClick={() => handlePrincipalRemove(item.fname)}>Remove Principal</button>
                                        </div>
                                        <div className="col-6">
                                            <WrappedInput<ConsumerCreate>
                                                inputName={`businessPrincipals.${parseInt(item.fname)}.address`}
                                                labelText="Street Address"
                                                required={false}
                                            >
                                                <input
                                                    {...register(`businessPrincipals.${parseInt(item.fname)}.address`, {
                                                        maxLength: MAX_TEXT_BOX
                                                    })}
                                                />
                                            </WrappedInput>
                                            <WrappedInput<ConsumerCreate>
                                                inputName={`businessPrincipals.${parseInt(item.fname)}.city`}
                                                labelText="City"
                                            >
                                                <input
                                                    {...register(`businessPrincipals.${parseInt(item.fname)}.city`, {
                                                        maxLength: MAX_TEXT_BOX
                                                    })}
                                                />
                                            </WrappedInput>
                                            <WrappedInput<ConsumerCreate>
                                                inputName={"businessPrincipals." + parseInt(item.fname) + ".state"}
                                                labelText="State"
                                            >
                                                <Controller
                                                    name={`businessPrincipals.${parseInt(item.fname)}.state`}

                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <StateDropdown
                                                            required={false}
                                                            selectedState={value}
                                                            setSelectedState={onChange}
                                                            includePayeeTerritories={false}
                                                        />
                                                    )}
                                                />
                                            </WrappedInput>
                                            <WrappedInput<ConsumerCreate>
                                                inputName={`businessPrincipals.${parseInt(item.fname)}.zip`}
                                                labelText="Zip"
                                                required={false}
                                            >
                                                <input
                                                    {...register(`businessPrincipals.${parseInt(item.fname)}.zip`, {
                                                        maxLength: MAX_TEXT_BOX
                                                    })}
                                                />
                                            </WrappedInput>
                                        </div>
                                    </div>

                                ))}


                            </div>

                        </div>
                    </div>
                }

                {props.isBusinessDebt && // have to do this in two calls due to react syntax, everything has to have one root tag
                    <hr></hr>
                }
                <div className="row">
                    <div className="col-6">
                        <div className="flex">
                            <h4>Primary Contact</h4>
                            <InfoTooltip
                                text={`Use these fields to enter the primary contact information for this account. 
                                            If this account is for two people (e.g. John and Pat Doe) who share a mailing address, 
                                            just type the second contact's name in the secondary contact fields and leave the other 
                                            secondary contact fields blank.`}
                            />
                        </div>
                        <div className="row">
                            <div className="first-name">
                                <WrappedInput<ConsumerCreate>
                                    inputName="contactInfo.primary.firstName"
                                    labelText="First Name"
                                    required={true}
                                >
                                    <input
                                        {...register('contactInfo.primary.firstName', {
                                            required: `Please enter a first name.`,
                                            maxLength: MAX_TEXT_BOX
                                        })}
                                    />
                                </WrappedInput>
                            </div>
                            <div className="middle-initial">
                                <WrappedInput<ConsumerCreate>
                                    inputName="contactInfo.primary.middleInitial"
                                    labelText="MI"
                                >
                                    <input
                                        {...register('contactInfo.primary.middleInitial', {
                                            maxLength: MAX_TEXT_BOX
                                        })}
                                    />
                                </WrappedInput>
                            </div>
                        </div>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.lastName"
                            labelText="Last Name"
                            required={true}
                        >
                            <input
                                {...register('contactInfo.primary.lastName', {
                                    required: `Please enter a last name.`,
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.address.streetAddress"
                            labelText="Address Line 1"
                            required={true}
                        >
                            <input
                                {...register('contactInfo.primary.address.streetAddress', {
                                    required: `Please enter a street address.`,
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.address.streetAddress2"
                            labelText="Address Line 2"
                        >
                            <input
                                {...register('contactInfo.primary.address.streetAddress2', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.address.city"
                            labelText="City"
                            required={true}
                        >
                            <input
                                {...register('contactInfo.primary.address.city', {
                                    required: `Please enter a city.`,
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.address.state"
                            labelText="State"
                            required={true}
                        >
                            <Controller
                                name="contactInfo.primary.address.state"
                                control={control}
                                rules={{
                                    validate: validateState,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <StateDropdown
                                        required={true}
                                        selectedState={value}
                                        setSelectedState={onChange}
                                        includePayeeTerritories={false}
                                    />
                                )}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.address.zip"
                            labelText="Zip"
                            required={true}
                        >
                            <input
                                {...register('contactInfo.primary.address.zip', {
                                    required: `Please enter a zip code.`,
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.taxId"
                            labelText="Tax ID"
                            required={true}
                            helpText="Enter the consumer's full SSN or other Tax ID."
                        >
                            <MaskedTextBoxComponent
                                {...register('contactInfo.primary.taxId', {
                                    required: `Please provide the SSN or TIN.`,
                                    minLength: 9,
                                    maxLength: 9,
                                    validate: ssn =>
                                        ssn!.length === 0 ||
                                        !isNaN(Number(ssn)) ||
                                        'Tax ID must be 9 digits.'
                                })}
                                mask={taxIdMask}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.birthDate"
                            labelText="Date of Birth"
                            required={true}
                        >
                            <MaskedTextBoxComponent
                                {...register('contactInfo.primary.birthDate', {
                                    required: `Please enter a date of birth.`,
                                    maxLength: MAX_TEXT_BOX
                                })}
                                mask={dateMask}
                                placeholder="mm/dd/yyyy"
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.primary.phone"
                            labelText="Phone Number 1"

                        >
                            <MaskedTextBoxComponent
                                {...register('contactInfo.primary.phone', {

                                    validate: validatePhone,
                                    setValueAs: v => v.replace(/\D/g, '')
                                })}
                                mask={phoneMask}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate> inputName="phone3" labelText="Phone Number 3">
                            <MaskedTextBoxComponent
                                {...register('contactInfo.phone3', {
                                    validate: validateNullOrPhone,
                                    setValueAs: v => (v || '').replace(/\D/g, '')
                                })}
                                mask={phoneMask}
                            />
                        </WrappedInput>
                    </div>
                    <div className="col-6">
                        <h4>Secondary Contact</h4>
                        <div className="row">
                            <div className="first-name">
                                <WrappedInput<ConsumerCreate>
                                    inputName="contactInfo.secondary.firstName"
                                    labelText="First Name"
                                >
                                    <input
                                        {...register('contactInfo.secondary.firstName', {
                                            maxLength: MAX_TEXT_BOX
                                        })}
                                    />
                                </WrappedInput>
                            </div>
                            <div className="middle-initial">
                                <WrappedInput<ConsumerCreate>
                                    inputName="contactInfo.secondary.middleInitial"
                                    labelText="MI"
                                >
                                    <input
                                        {...register('contactInfo.secondary.middleInitial', {
                                            maxLength: MAX_TEXT_BOX
                                        })}
                                    />
                                </WrappedInput>
                            </div>
                        </div>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.lastName"
                            labelText="Last Name"
                        >
                            <input
                                {...register('contactInfo.secondary.lastName', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.address.streetAddress"
                            labelText="Address Line 1"
                        >
                            <input
                                {...register('contactInfo.secondary.address.streetAddress', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.address.streetAddress2"
                            labelText="Address Line 2"
                        >
                            <input
                                {...register('contactInfo.secondary.address.streetAddress2', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.address.city"
                            labelText="City"
                        >
                            <input
                                {...register('contactInfo.secondary.address.city', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.address.state"
                            labelText="State"
                        >
                            <Controller
                                name="contactInfo.secondary.address.state"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <StateDropdown
                                        required={false}
                                        selectedState={value}
                                        setSelectedState={onChange}
                                        includePayeeTerritories={false}
                                    />
                                )}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.address.zip"
                            labelText="Zip"
                        >
                            <input
                                {...register('contactInfo.secondary.address.zip', {
                                    maxLength: MAX_TEXT_BOX
                                })}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.taxId"
                            labelText="Tax ID"
                            helpText="Enter the consumer's full SSN or other Tax ID."
                        >
                            <MaskedTextBoxComponent
                                {...register('contactInfo.secondary.taxId', {
                                    maxLength: 11,
                                    minLength: 9
                                })}
                                mask={taxIdMask}
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.birthDate"
                            labelText="Date of Birth"
                        >
                            <MaskedTextBoxComponent
                                {...register('contactInfo.secondary.birthDate')}
                                mask={dateMask}
                                placeholder="mm/dd/yyyy"
                            />
                        </WrappedInput>
                        <WrappedInput<ConsumerCreate>
                            inputName="contactInfo.secondary.phone"
                            labelText="Phone Number 2"
                        >
                            <MaskedTextBoxComponent
                                {...register('contactInfo.secondary.phone', {
                                    validate: validatePhone,
                                    setValueAs: v => v.replace(/\D/g, '')
                                })}
                                mask={phoneMask}
                            />
                        </WrappedInput>
                    </div>
                </div>
                <hr></hr>
                <div className="clearFix">
                    <input className="btn btn-primary float-left" type="submit" value="Create" />
                </div>
            </div>
        </div>
    );
};

export default AddConsumerFormInputs;
