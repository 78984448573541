import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { SettlementEdit } from '../../../../../types/settlements';
import StateDropdown from '../../../../common/form/StateDropdown';

export interface Props {
    viewOnly: boolean;
    disable?: boolean;
    type?: string;
}

const PayeeMailingDetailsFormInputs = (props: Props) => {
    const { register, control } = useFormContext<SettlementEdit>();

    return (
        <div>
            <WrappedInput<SettlementEdit>
                inputName="payeeMailingDetails.mailTo"
                labelText="Mail To (if diff. from Payee)"
                required={true}
            >
                <input
                    readOnly={props.viewOnly}
                    {...register('payeeMailingDetails.mailTo', {
                        required: 'Please enter a Mail To name.'
                    })}
                />
            </WrappedInput>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="payeeMailingDetails.payeeAddress.streetAddress"
                        labelText="Payee Address"
                        required={true}
                    >
                        <input
                            readOnly={props.viewOnly}
                            className={props.disable  ? 'disable-field' : ''}
                            {...register('payeeMailingDetails.payeeAddress.streetAddress', {
                                required: 'Please enter a street address.'
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<SettlementEdit>
                        inputName="payeeMailingDetails.payeeAddress.city"
                        labelText="Payee City"
                        required={true}
                    >
                        <input
                            readOnly={props.viewOnly}
                            className={props.disable ? 'disable-field' : ''}
                            {...register('payeeMailingDetails.payeeAddress.city', {
                                required: 'Please enter a city.'
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<SettlementEdit>
                        inputName="payeeMailingDetails.payeeAddress.zip"
                        labelText="Payee Zip"
                        required={true}
                    >
                        <input
                            readOnly={props.viewOnly}
                            className={props.disable  ? 'disable-field' : ''}
                            {...register('payeeMailingDetails.payeeAddress.zip', {
                                required: 'Please enter a zip code.'
                            })}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="payeeMailingDetails.payeeAddress.streetAddress2"
                        labelText="Payee Address 2"
                    >
                        <input
                            className={props.disable ? 'disable-field' : ''}
                            readOnly={props.viewOnly}
                            {...register('payeeMailingDetails.payeeAddress.streetAddress2')}
                        />
                    </WrappedInput>
                    <WrappedInput<SettlementEdit>
                        inputName="payeeMailingDetails.payeeAddress.state"
                        labelText="Payee State"
                        required={true}
                    >
                        <Controller
                            name="payeeMailingDetails.payeeAddress.state"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <StateDropdown
                                    required={true}
                                    selectedState={value}
                                    setSelectedState={onChange}
                                    includePayeeTerritories={true}
                                    readOnly={props.disable}
                                />
                            )}
                        />
                    </WrappedInput>
                </div>
            </div>
        </div>
    );
};

export default PayeeMailingDetailsFormInputs;
