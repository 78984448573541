import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../styles/common/ModalDialog.scss';
import { ModalProps, Payee } from '../../../../types/common';
import WrappedForm from '../../../common/form/WrappedForm';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ConsumerContext } from '../ConsumerPage';
import { applyFreeRefund, getRefundSources } from '../../../../api/consumerApi';
import { useToasts } from 'react-toast-notifications';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { FreeRefund } from '../../../../types/ledgers';
import FreeRefundFormInputs from './FreeRefundFormInputs';
import { loadFromApi } from '../../../../api/baseApi';

interface Props extends ModalProps {}

const FreeRefundModal = (props: Props) => {
    const [refundSources, setRefundSources] = useState<Payee[]>([]);
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        if (props.showDialog) {
            const freeRefundWrapper = loadFromApi(setApiError, setLoading);
            freeRefundWrapper(() => getRefundSources(consumer.consumerID), setRefundSources);
        }
    }, [setApiError, consumer.consumerID, props.showDialog]);

    const defaultFreeRefund: FreeRefund = {
        amount: 0,
        refundSourceId: 0,
        description: '',
        destination: 1,
        issueAsCheck: false
    };

    const onSubmit = async (data: FreeRefund) => {
        // Display loading spinner while submitted
        setLoading(true);
        let result = null;
        result = await applyFreeRefund(consumer.consumerID, data);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Free Refund Applied', {
                appearance: 'success',
                autoDismiss: true
            });
        }

        setLoading(false);

        //Close the modal
        props.setShowDialog(false);
    };

    return (
        <DialogComponent
            header={'Send Free Refund'}
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <WrappedForm<FreeRefund> initialValues={defaultFreeRefund} onSubmit={onSubmit}>
                        <div>
                            {loading ? (
                                <LoadingDisplay />
                            ) : (
                                <FreeRefundFormInputs refundSources={refundSources} />
                            )}
                        </div>
                    </WrappedForm>
                )}
            </div>
        </DialogComponent>
    );
};

export default FreeRefundModal;
