import * as React from 'react';
import { useState, useEffect } from 'react';
import { Fee, FeeGroup } from '../../../../types/feeGroupTypes';
import { getFeesByFeeGroup } from '../../../../api/feeSplitApi';
import FeeView from './FeeView';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';

interface Props {
    selectedFeeGroupId: number;
    payees: FeeGroup[];
}

const FeesListView = (props: Props) => {
    const [fees, setFees] = useState<Fee[]>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        const loadSelectedFeeGroupFees = async () => {
            setLoading(true);
            setFees(undefined);
            const feesResponse = await getFeesByFeeGroup(props.selectedFeeGroupId);
            if (feesResponse.error) {
                addToast(feesResponse.error, { appearance: 'error', autoDismiss: true });
            } else {                
                setFees(feesResponse.data);
            }            
            setLoading(false);
        };
        loadSelectedFeeGroupFees();
    }, [props.selectedFeeGroupId, addToast]);

    return (
        <div>
            {loading ? (
                <LoadingDisplay />
            ) : props.payees && fees ?  (
                <ul>
                    {fees
                        .sort((a, b) => a.slot - b.slot)
                        .map((f, index) => (
                            <FeeView key={index} fee={f} payees={props.payees} />
                        ))}
                </ul>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default FeesListView;
