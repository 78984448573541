import * as React from 'react';
import '../../../styles/Form.scss';
import { ReactNode } from 'react';
import { DefaultValues, FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';

interface Props<T extends {}> {
    initialValues?: DefaultValues<T>;
    children?: ReactNode;
    onSubmit: (values: UnpackNestedValue<T>) => void;
}

const WrappedForm = <T,>(props: Props<T>) => {
    const methods = useForm<T>({ defaultValues: props.initialValues });
    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(props.onSubmit)}>{props.children}</form>
        </FormProvider>
    );
};

export default WrappedForm;
