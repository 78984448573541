import * as React from 'react';
import '../../../../styles/views/Consumer.scss';

interface Props {
    label: string;
    value: string;
}

const QuickInfoPopupRow = (props: Props) => {
    return (
        <div className="row">
            <span className="label">{props.label}</span>
            <span className="value">{props.value}</span>
        </div>
    );
};

export default QuickInfoPopupRow;
