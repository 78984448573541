import * as React from 'react';
import '../../../../styles/views/Consumer.scss';
import { ConsumerContext } from '../ConsumerPage';
import { useCallback, useContext, useEffect, useState } from 'react';
import LoadingDisplay from '../../../common/LoadingDisplay';
import ConsumerDraftsGrid from './ConsumerDraftsGrid';
import { Draft } from '../../../../types/drafts';
import { loadFromApi } from '../../../../api/baseApi';
import { deleteConsumerDraft, getConsumerDrafts } from '../../../../api/consumerApi';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';
import ConsumerEditDraftModal from './ConsumerEditDraftModal';
import { luxonDateToServerDate } from '../../../../utils/dateUtils';
import ConsumerBulkEditDraftModal from './ConsumerBulkEditDraftsModal';
import MessagePopup from '../../../common/MessagePopup';
import { DateTime } from 'luxon-business-days';
import { DraftStatus, UserPermission } from '../../../../types/enums';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';

const ConsumerDraftsTab = () => {
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);
    const [drafts, setDrafts] = useState<Draft[]>([]);
    const [selectedDrafts, setSelectedDrafts] = useState<Draft[]>([]);
    const [showAddDraftModal, setShowAddDraftModal] = useState(false);
    const [type, setType] = useState('');
    const [editDraftModalId, setEditDraftModalId] = useState(0);
    const [showBulkEditDraftModal, setShowBulkEditDraftModal] = useState(false);
    const [showDeleteDraftsConfirmation, setShowDeleteDraftsConfirmation] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditDraftsPermissions = userHasPermission(UserPermission.EditDrafts);

    const defaultDraft: Draft = {
        id: 0,
        ramFee: 0,
        status: DraftStatus.Posted,
        returnedReason: null,
        bankName: null,
        date: luxonDateToServerDate(DateTime.utc().plusBusiness({ days: 2 })),
        savingsAmount: 0,
        paymentFrequency: 0,
        numberofPayments: 1,
        fees: {}
    };

    const onDeleteDrafts = async () => {
        let selectedDraftIds = selectedDrafts.map(draft => draft.id);
        let error = false;
        for (let i = 0; i < selectedDraftIds.length; i++) {
            const result = await deleteConsumerDraft(consumer.consumerID, selectedDraftIds[i]);
            if (result.error && !error) {
                setApiError(result.error);
                error = true; //Prevent errors from pilling up for bulk deletes
            }
        }
        //Refresh the grid so that the deleted drafts are no longer present. If the api fails the items will remain in the grid
        refreshGrid();
    };

    const onDraftRefunded = (draftId: number) => {
        var updatedDrafts = drafts.map(d => {
            if (d.id === draftId) {
                return {
                    ...d,
                    status: DraftStatus.Returned
                };
            }
            return d;
        });
        setDrafts(updatedDrafts);
    };

    const refreshGrid = useCallback(() => {
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );
        const loadConsumerDrafts = async () => {
            setLoading(true);
            const consumerDraftsLoad = consumerPageApiWrapper(
                () => getConsumerDrafts(consumer.consumerID),
                setDrafts
            );
            await consumerDraftsLoad;
            setSelectedDrafts([]); //Start with no drafts selected
            setLoading(false);
        };

        loadConsumerDrafts();
    }, [addToast, consumer.consumerID]);

    useEffect(() => {
        refreshGrid();
    }, [refreshGrid, addToast, consumer.consumerID]);

    return (
        <div className="consumer-input-section Drafts-tab">
            <h3>Drafts</h3>
            {loading ? (
                <LoadingDisplay />
            ) : !consumer || !drafts ? (
                <LoadFailedDisplay />
            ) : (
                <div>
                    <span>
                        Drafts cannot be scheduled earlier than 1 business day from the current date.
                    </span>

                    {hasEditDraftsPermissions ? (
                        <div className="button-bar clear-fix">
                            <div className="float-left">
                            {/*    <Link
                                    className="btn btn-secondary"
                                    to={consumerRoutingPath(
                                        consumer.consumerID.toString(),
                                        consumerSubPages.schedule
                                    )}
                                >
                                    Manage Schedule
                                    </Link> */}
                            </div>
                            <div className="float-right">
                                <button
                                    className="btn btn-outline-primary margin-right-10"
                                    disabled={selectedDrafts.length <= 0}
                                    onClick={() => {
                                        setShowDeleteDraftsConfirmation(true);
                                    }}
                                >
                                    Delete
                                </button>
                                <button
                                    className="btn btn-outline-primary margin-right-10"
                                    disabled={selectedDrafts.length <= 0}
                                    onClick={() => {
                                        selectedDrafts.length > 1
                                            ? setShowBulkEditDraftModal(true)
                                            : setEditDraftModalId(selectedDrafts[0].id);
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setShowAddDraftModal(true);
                                        setType('');
                                    }}
                                >
                                    Add New
                                </button>
                                <button
                                    className="btn btn-outline-primary margin-left-10"
                                    onClick={() => {
                                        setShowAddDraftModal(true);
                                        setType('extend');
                                    }}
                                >
                                    Extend Program
                                </button>
                            </div>
                        </div>
                    ) : null}
                    <ConsumerDraftsGrid
                        drafts={drafts}
                        selectedDrafts={selectedDrafts}
                        setSelectedDrafts={setSelectedDrafts}
                        onDraftRefunded={onDraftRefunded}
                        isSchedulePreview={false}
                        refreshGrid={refreshGrid}
                        hasEditPermissions={hasEditDraftsPermissions}
                    />
                    <ConsumerEditDraftModal
                        draft={defaultDraft}
                        targetId="consumerPage"
                        showDialog={showAddDraftModal}
                        setShowDialog={setShowAddDraftModal}
                        refreshGrid={refreshGrid}
                        type={type}
                        drafts={drafts}
                        viewOnly={!hasEditDraftsPermissions}
                    />
                    {/* There is an edit draft modal created for each selected draft in the grid, display is
                        control by the editDraftModalId, if 0 - no modal display, else modal corresponding to
                        set draft id is displayed */}
                    {drafts.map(
                        draft =>
                            DateTime.fromISO(draft.date, { zone: 'utc' }) > DateTime.now() && (
                                <ConsumerEditDraftModal
                                    key={draft.id}
                                    draft={draft}
                                    targetId="consumerPage"
                                    showDialog={editDraftModalId === draft.id}
                                    setShowDialog={newShowDialog => {
                                        newShowDialog
                                            ? setEditDraftModalId(draft.id)
                                            : setEditDraftModalId(0);
                                    }}
                                    refreshGrid={refreshGrid}
                                    viewOnly={!hasEditDraftsPermissions}
                                />
                            )
                    )}
                    <ConsumerBulkEditDraftModal
                        drafts={selectedDrafts}
                        targetId="consumerPage"
                        showDialog={showBulkEditDraftModal}
                        setShowDialog={setShowBulkEditDraftModal}
                        refreshGrid={refreshGrid}
                        viewOnly={!hasEditDraftsPermissions}
                    />
                    <MessagePopup
                        targetId="consumerPage"
                        message="Are you sure you want to delete the selected drafts?"
                        showDialog={showDeleteDraftsConfirmation}
                        setShowDialog={setShowDeleteDraftsConfirmation}
                        requireConfirmation={true}
                        onConfirmClick={onDeleteDrafts}
                    />
                </div>
            )}
        </div>
    );
};

export default ConsumerDraftsTab;
