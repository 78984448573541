export enum DashboardType {
    EnrollmentCount = 0,
    EnrollmentDraftRate,
    DraftExecutionCount,
    DraftReturnCount,
    DraftReturnRate,
    RefundsCount,
    RefundTotalAmount,
    RefundAverageAmount,
    IdleConsumersCount,
    IdleConsumersRate,
    ConsumerClosureCount,
    ConsumerClosureRate,
    ConsumerClosureSettlementRate,
    SettlementsCount,
    SettlementsRate,
    SettlementsAverageAmount,
    TotalPaid,
    PaidPerConsumer,
    PaidPerSettlement,
    StateBreakdown
}

export const getDashboardDisplayName = (dashboard: DashboardType) => {
    switch (dashboard) {
        case DashboardType.EnrollmentCount:
            return 'Enrollment Count';
        case DashboardType.EnrollmentDraftRate:
            return 'Enrollment Draft Rate';
        case DashboardType.DraftExecutionCount:
            return 'Draft Execution Count';
        case DashboardType.DraftReturnCount:
            return 'Draft Return Count';
        case DashboardType.DraftReturnRate:
            return 'Draft Return Rate';
        case DashboardType.RefundsCount:
            return 'Refunds Count';
        case DashboardType.RefundTotalAmount:
            return 'Refunds Total Amount';
        case DashboardType.RefundAverageAmount:
            return 'Refunds Average Amount';
        case DashboardType.IdleConsumersCount:
            return 'Idle Consumers Count';
        case DashboardType.IdleConsumersRate:
            return 'Idle Consumers Rate';
        case DashboardType.ConsumerClosureCount:
            return 'Consumer Closure Count';
        case DashboardType.ConsumerClosureRate:
            return 'Consumer Closure Rate';
        case DashboardType.ConsumerClosureSettlementRate:
            return 'Consumer Closure Settlement Rate';
        case DashboardType.SettlementsCount:
            return 'Settlements Count';
        case DashboardType.SettlementsRate:
            return 'Settlements Rate';
        case DashboardType.SettlementsAverageAmount:
            return 'Settlements Average Amount';
        case DashboardType.TotalPaid:
            return 'Total Paid';
        case DashboardType.PaidPerConsumer:
            return 'Paid Per Consumer';
        case DashboardType.PaidPerSettlement:
            return 'Paid Per Settlement';
        case DashboardType.StateBreakdown:
            return 'State Breakdown';
        default:
            return '';
    }
};
