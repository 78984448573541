import React from 'react';
import './styles/App.scss';
import './styles/Default.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReportConfigurationPage from './components/pages/reporting/ReportConfigurationPage';
import ReportPage from './components/pages/reporting/ReportPage';
import HomePage from './components/pages/home/HomePage';
import AddConsumerPage from './components/pages/consumer/addConsumerPage/AddConsumerPage';
import NotFoundPage from './components/pages/NotFoundPage';
import SideNavLayout from './components/layouts/SideNavLayout';
import FeeSplitPage from './components/pages/company/feeSplit/FeeSplitPage';
import {
    companyPages,
    consumerPages,
    dashboardSection,
    pagePath,
    reportingPages,
    sectionPath,
    topLevelPages
} from './pages';
import CompanyInformationPage from './components/pages/company/CompanyInformationPage';
import ConsumerPage from './components/pages/consumer/ConsumerPage';
import TopBar from './components/layouts/TopBar';
import { ToastProvider } from 'react-toast-notifications';
import { AuthService } from './authentication/authService';
import CheckLookupPage from './components/pages/company/CheckLookupPage';
import ConsumerSearchPage from './components/pages/consumer/consumerSearchPage/ConsumerSearchPage';
import DashboardPage from './components/pages/dashboards/DashboardPage';
import { CompanyService } from './components/CompanyService';
import { UserAuthorizationService } from './components/UserAuthorizationService';
import CompanyUsersPage from './components/pages/company/users/CompanyUsersPage';
import AffiliatesPage from './components/pages/company/affiliates/AffiliatesPage';
import UserSettingsPage from './components/pages/userSettings/UserSettingsPage';
import { ShortSettlementsPage } from './components/pages/company/shortSettlements/ShortSettlementsPage';
import CheckImage from './components/pages/CheckImages/CheckImage';
function App() {
    const open = localStorage.getItem('sideBarOpen') !== 'false' ? true : false;
    const [value, setvalue] = React.useState(open);
    const localStorageSetHandler = function(e: any) {
        if(open !== null){
            setvalue(!open );
        }
    };
    document.addEventListener("hidePanel", localStorageSetHandler, false);
    return (
        <div className="app">
            <div className="layout-root">
                <ToastProvider placement="bottom-right">
                    <AuthService>
                        <UserAuthorizationService>
                            <BrowserRouter>
                                <SideNavLayout />
                                <TopBar />
                                <div className={`page-container ${value  ? 'open' : 'close'}`}>
                                    <CompanyService>
                                        <Switch>
                                            <Route path={pagePath(consumerPages.addConsumer)}>
                                                <AddConsumerPage />
                                            </Route>
                                            <Route path={pagePath(topLevelPages.userSettings)}>
                                                <UserSettingsPage/>
                                            </Route>
                                            <Route path={pagePath(consumerPages.consumerSearch)}>
                                                <ConsumerSearchPage />
                                            </Route>
                                            <Route path={pagePath(consumerPages.consumer)}>
                                                <ConsumerPage />
                                            </Route>
                                            <Route
                                                path={pagePath(reportingPages.reportConfiguration)}
                                            >
                                                <ReportConfigurationPage />
                                            </Route>
                                            <Route path={pagePath(reportingPages.report)}>
                                                <ReportPage />
                                            </Route>
                                            <Route path={pagePath(companyPages.companyInformation)}>
                                                <CompanyInformationPage />
                                            </Route>
                                            <Route path={pagePath(companyPages.feeSplit)}>
                                                <FeeSplitPage />
                                            </Route>
                                            <Route path={pagePath(companyPages.shortSettlements)}>
                                                <ShortSettlementsPage />
                                            </Route>
                                            <Route path={pagePath(companyPages.checkLookup)}>
                                                <CheckLookupPage />
                                            </Route>
                                            <Route path={pagePath(companyPages.affiliates)}>
                                                <AffiliatesPage />
                                            </Route>
                                            <Route path={pagePath(companyPages.companyUsers)}>
                                                <CompanyUsersPage />
                                            </Route>
                                            <Route path={sectionPath(dashboardSection)}>
                                                <DashboardPage/>
                                            </Route>
                                            <Route path={pagePath((topLevelPages.checkImages))}>
                                                 <CheckImage />
                                            </Route>
                                            <Route path={pagePath(topLevelPages.home)} exact={true}>
                                                <HomePage />
                                            </Route>
                                            <Route path="/">
                                                <NotFoundPage />
                                            </Route>
                                        </Switch>
                                    </CompanyService>
                                </div>
                            </BrowserRouter>
                        </UserAuthorizationService>
                    </AuthService>
                </ToastProvider>
            </div>
        </div>
    );
}

export default App;
