import { DateTime, LocaleOptions } from 'luxon';
import { DateRange } from '../types/common';

export function serverDateToLuxonDateTime(date: string | null) {
    if (!date) {
        return DateTime.utc();
    }
    return DateTime.fromISO(date, { zone: 'utc' });
}

export function luxonDateToServerDate(date: DateTime) {
    return date.toFormat('yyyy-LL-dd');
}



export function serverDateToDisplayDate(
    date: string | null,
    options: LocaleOptions = DateTime.DATE_SHORT,
    format?: string
) {
    if (!date) {
        return '';
    }
    let dateTime = serverDateToLuxonDateTime(date);
    if (format) {
        const formattedDate = dateTime.toFormat(format);
        return new Date(formattedDate).getFullYear() === 1900 ? '' : formattedDate;
    } else {
        const formattedDate = dateTime.toLocaleString(options);
        return new Date(formattedDate).getFullYear() === 1900 ? '': formattedDate ;
    }
}

export function serverDateTimeToDisplayDateTime(
    dateTimeString: string | null,
    options: LocaleOptions = DateTime.DATETIME_SHORT,
    format?: string
) {
    if (!dateTimeString) {
        return '';
    }
    // Server date/time is expected to be in UTC
    // Display date/time will be converted to the local time zone
    let dateTime = serverDateToLuxonDateTime(dateTimeString);
    if (format) {
        return dateTime.toLocal().toFormat(format);
    } else {
        return dateTime.toLocal().toLocaleString(options);
    }
}

export function displayDateToServerDate(date: string | null, format?: string) {
    if (!date) {
        return '';
    }

    //Default format is localized numeric date
    if (!format) {
        format = 'D';
    }

    let dateTime = DateTime.fromFormat(date, format, { zone: 'utc' });
    return luxonDateToServerDate(dateTime);
}

export function nowAsServerDate(options?: LocaleOptions) {
    return DateTime.utc().toISO();
}

export function getApiDateFormat(formDateFormat: string) {
    // Form inputs use date format of: "mm/dd/yyyy"
    // API expects format of "yyyy-mm-dd"
    return `${formDateFormat.substring(4, 8)}-${formDateFormat.substring(
        0,
        2
    )}-${formDateFormat.substring(2, 4)}`;
}

export function getDefaultDateRange(): DateRange {
    return {
        start: DateTime.now().minus({ days: 30 }),
        end: DateTime.now()
    };
}
/**
 *  Takes in a luxon date time object and return the equivalent js date object
 *  If offsetByLocalTimeZone flag is set to true then the local timezone offset is added to the object
 *      such that the new date time object is the matching equivalent UTC time in local time
 *  Intended to be used with syncfusion components that display date times in the local timezone rather than utc time.
 *
 * @param {DateTime} date luxon datetime to convert to js date object
 * @param {boolean} offsetByLocalTimeZone flag to offset by local timezone
 * @returns {Date} JS Date object
 */
export function luxonDateToJSDate(date: DateTime, offsetByLocalTimeZone: boolean) {
    if (offsetByLocalTimeZone) {
        date = date.plus({ minutes: new Date().getTimezoneOffset() });
    }
    return date.toJSDate();
}
