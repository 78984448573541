import { removeSpaces, sanitizeFileName } from '../utils/stringUtils';
import { downloadFile, uploadFile, httpRequest, Urls, checkDownloadFile } from './baseApi';
import { BankAccount } from '../types/common';
import {
    CreditCardCharge,
    CreditCardCreate,
    CreditCardView,
    ValidateRoutingNumberResponse
} from '../types/bankingInformation';
import { consumerUrl } from './consumerApi';

// ** Bank Account ** //

export const getConsumerBankAccount = (consumerId: number) =>
    httpRequest<BankAccount>(`${consumerUrl(consumerId)}/bankAccount`, 'get');

export const updateConsumerBankAccount = (consumerId: number, bankAccount: BankAccount) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/bankAccount`, 'put', bankAccount);

export const validateRouting = (routingNumber: string) =>
    httpRequest<ValidateRoutingNumberResponse>(
        `${Urls.BASE_URL}/verifyRouting/${routingNumber}`,
        'get'
    );

export const uploadAuthorization = (consumerId: number, authorizationFile: File) =>
    uploadFile(`${consumerUrl(consumerId)}/authFile/0`, authorizationFile);

export const uploadKYC = (consumerId: number, kycFile: File) =>
    uploadFile(`${consumerUrl(consumerId)}/authFile/6`, kycFile);


export const getAuthorization = (consumerId: number, consumerName: string) =>
    downloadFile(
        `${consumerUrl(consumerId)}/authFile`,
        sanitizeFileName(`${consumerId}_${removeSpaces(consumerName)}_Auth.pdf`)
    );

// Similar to getAuthorization,  but file is not downloaded (just check if it exists)
export const checkGetAuthorization = (consumerId: number) =>
    checkDownloadFile(`${consumerUrl(consumerId)}/authFile`);

// ** Credit Cards ** //

export const getConsumerCards = (consumerId: number) =>
    httpRequest<CreditCardView[]>(`${consumerUrl(consumerId)}/cards`, 'get');

export const addConsumerCard = (consumerId: number, creditCard: CreditCardCreate) =>
    httpRequest<CreditCardView>(`${consumerUrl(consumerId)}/cards`, 'post', creditCard);

export const uploadCardAuthorization = (
    consumerId: number,
    cardId: number,
    authorizationFile: File
) => uploadFile(`${consumerUrl(consumerId)}/cards/${cardId}/authFile`, authorizationFile);

// Request body is a credit card id, or null to indicate using bank account
export const selectPaymentMethod = (consumerId: number, creditCardId: number | null) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/payment`, 'put', creditCardId);

export const chargeConsumerCard = (
    consumerId: number,
    creditCardId: number,
    charge: CreditCardCharge
) => httpRequest<{}>(`${consumerUrl(consumerId)}/cards/${creditCardId}/charge`, 'post', charge);

export const removeConsumerCard = (consumerId: number, consumerCardId: number) =>
    httpRequest<{}>(`${consumerUrl(consumerId)}/cards/${consumerCardId}`, 'delete');
