import * as React from 'react';
import {
    AnnotationDirective,
    AnnotationsDirective,
    AreaSeries,
    AxisModel,
    ChartAnnotation,
    ChartComponent,
    DateTime,
    Inject,
    Legend,
    LineSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    TooltipSettingsModel,
    Tooltip
} from '@syncfusion/ej2-react-charts';
import { useMemo } from 'react';
import '../../../styles/views/Dashboard.scss';
import { DateTime as LuxonDateTime } from 'luxon';

export interface SeriesData {
    date: LuxonDateTime;
    value: number;
}
interface DashboardProps {
    title: string;
    dataSeriesName: string;
    data: SeriesData[];
    industryAverageData?: SeriesData[];
}

const DashboardChart = (props: DashboardProps) => {
    // Get min/max date range for chart
    const xRange = useMemo(() => {
        var xValues = props.data.map(d => d.date);
        return {
            min: LuxonDateTime.min(...xValues),
            max: LuxonDateTime.max(...xValues)
        };
    }, [props.data]);

    var xAxis: AxisModel = {
        valueType: 'DateTime',
        intervalType: 'Months',
        labelFormat: 'MMM y',
        majorGridLines: { width: 0 },
        minimum: xRange.min,
        maximum: xRange.max
    };

    var yAxis: AxisModel = {
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        plotOffset: 0
    };

    var tTip: TooltipSettingsModel = {
        enable: true      
    };

    return (
        <ChartComponent
            id={props.title}
            primaryXAxis={xAxis}
            primaryYAxis={yAxis}
            tooltip={tTip}            
            height={'50%'}            
            legendSettings={{
                toggleVisibility: false,
                visible: true,
                position: 'Top',
                alignment: 'Far',
                containerPadding: {
                    bottom: 10
                }
            }}
            chartArea={{ border: { width: 0, color: 'transparent' } }}
        >
            <Inject services={[LineSeries, AreaSeries, DateTime, Legend, ChartAnnotation, Tooltip]} />
            <AnnotationsDirective>
                <AnnotationDirective
                    content={`<div class="chart-title">${props.title}</div>`}
                    coordinateUnits="Pixel"
                    horizontalAlignment="Far"
                    verticalAlignment="Bottom"
                    x="10"
                    y="11" // y offset of legend
                ></AnnotationDirective>
            </AnnotationsDirective>
            <SeriesCollectionDirective>
                <SeriesDirective
                    fill="#EBF3FB"
                    type="Area"
                    xName="date"
                    yName="value"
                    dataSource={props.data}
                />
                <SeriesDirective
                    legendShape="Circle"
                    fill="#3182D6"
                    name={props.dataSeriesName}
                    border={{ width: 2, color: '#3182D6' }}
                    type="Line"
                    xName="date"
                    yName="value"
                    dataSource={props.data}
                />
                {props.industryAverageData !== undefined && (
                    <SeriesDirective
                        legendShape="Circle"
                        fill="#E87537"
                        name="Average"
                        width={2}
                        type="Line"
                        xName="date"
                        yName="value"
                        dataSource={props.industryAverageData}                        
                    />
                )}
            </SeriesCollectionDirective>
        </ChartComponent>
    );
};

export default DashboardChart;
