import * as React from 'react';
import { httpRequest, downloadFile } from '../../api/baseApi';
import { CheckInfo } from '../../types/company';

interface Props {
    url: string;
    children?: React.ReactNode;
    fileName?: string;
    className?: string;
}

const CheckLink = ({ url, children, fileName }: Props) => (
    <button 
        onClick={() => 
            downloadFile(
                url,
                fileName ? fileName : 'check.jpg'
            )
        } 
        > {children}
    </button>
);

export default CheckLink;
