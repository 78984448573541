import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Draft, DraftsCalculation, Schedule } from '../../../../types/drafts';
import '../../../../styles/common/Elements.scss';
import ConsumerSchedulePreviewModal from './modals/ConsumerSchedulePreviewModal';
import MessagePopup from '../../../common/MessagePopup';
import { useToasts } from 'react-toast-notifications';
import { addConsumerDraft, deleteConsumerScheduledDrafts } from '../../../../api/consumerApi';
import { DraftStatus } from '../../../../types/enums';
import { ApiResult } from '../../../../api/baseApi';
import { ConsumerContext } from '../ConsumerPage';

export interface Props {
    consumerSchedule: Schedule;
    draftsCalculation?: DraftsCalculation;
    showPreviewModal: boolean;
}

// Component Responsible for displaying the scheduled drafts, and submitting them to the api if accepted by the user
const ConsumerSchedulePreview = (props: Props) => {
    const [showAcceptScheduleConfirmation, setShowAcceptScheduleConfirmation] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const consumer = useContext(ConsumerContext);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        setShowPreviewModal(props.showPreviewModal);
    }, [props.showPreviewModal]);

    const submitScheduledDrafts = async () => {
        //Delete the existing scheduled drafts
        deleteConsumerScheduledDrafts(consumer.consumerID);

        //Call add consumer draft for each new draft
        let newDrafts: Draft[] = props.draftsCalculation
            ? props.draftsCalculation.drafts.filter(draft => draft.status === DraftStatus.Scheduled)
            : [];
        let addDrafts = newDrafts.map((draft: Draft) =>
            addConsumerDraft(consumer.consumerID, draft)
        );

        //Await all add consumer draft calls complete
        let result = await Promise.all(addDrafts);
        //Parse the result to see if any failures occured
        let failure = false;
        result.reduce(function (_prevResult: ApiResult<Draft>, draftResult: ApiResult<Draft>) {
            if (draftResult.error && !failure) {
                //Only display the first error to prevent errors from accumulating on every draft
                failure = true;
                setApiError(draftResult.error);
            }
            return draftResult;
        });
        if (!failure) {
            //If no failures occured then display a success banner
            addToast('Consumer Schedule Updated', { appearance: 'success', autoDismiss: true });
        }
    };

    return (
        <div>
            <MessagePopup
                targetId="consumerPage"
                headerText="Confirm Schedule"
                message="Are you sure you want to replace the current payment schedule with this one?"
                requireConfirmation={true}
                onConfirmClick={submitScheduledDrafts}
                onCancelClick={() => setShowPreviewModal(true)}
                showDialog={showAcceptScheduleConfirmation}
                setShowDialog={setShowAcceptScheduleConfirmation}
            />
            <ConsumerSchedulePreviewModal
                targetId="consumerPage"
                draftsCalculation={props.draftsCalculation}
                targetFees={props.consumerSchedule.fees}
                targetDraftCount={props.consumerSchedule.occurrences}
                targetMonthlyDraft={props.consumerSchedule.monthlyDraft}
                showDialog={showPreviewModal}
                setShowDialog={setShowPreviewModal}
                onAcceptClick={() => {
                    setShowPreviewModal(false);
                    setShowAcceptScheduleConfirmation(true);
                }}
            />
        </div>
    );
};

export default ConsumerSchedulePreview;
