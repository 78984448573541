import * as React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import helpIcon from '../../assets/images/help.png';
import '../../styles/components/Tooltip.scss';

interface Props {
    text: string;
}

const InfoTooltip = (props: Props) => (
    <TooltipComponent content={props.text}>
        <img src={helpIcon} alt="help" />
    </TooltipComponent>
);

export default InfoTooltip;
