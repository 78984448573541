import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../common/form/WrappedInput';
import { MAX_TEXT_BOX } from '../../../../utils/formConstants';
import NumericDropdown from '../../../common/NumericDropdown';
import { accountStatuses } from '../../../../utils/consumerUtils';
import { ConsumerUpdate } from '../../../../types/consumer';
import { Affiliate } from '../../../../types/affiliate';
import { FeeGroup } from '../../../../types/feeGroupTypes';
import { validateEmail } from '../../../../utils/validationRules';
import { serverDateToDisplayDate } from '../../../../utils/dateUtils';
import '../../../../styles/Form.scss';
import '../../../../styles/views/Consumer.scss';

interface Props {
    username: string;
    affiliates: Affiliate[];
    feeGroups: FeeGroup[];
    requireUserEmail: boolean;
    viewOnly: boolean;
    businessName: string;
    dateOfFormation: string;
    isBusinessDebt: boolean;
}

const AccountSetupFormInputs = (props: Props) => {
    const { register, control } = useFormContext<ConsumerUpdate>();

    return (
        <div>
            <h4 className="row">Update Consumer</h4>
            <div className="row">
                <div className="col-6">
                    <div className="wrapped-info">
                        <label>Username</label>
                        <span className="value">{props.username}</span>
                    </div>
                    <WrappedInput<ConsumerUpdate>
                        inputName="email"
                        labelText="Email"
                        helpText={`The consumer's email address. If you do not have an email address, use the 
                                        placeholder 'missingemail@[ConsumerFirstName][ConsumerLastName].com' for example: 
                                        missingemail@johndoe.com or missingemail2@johndoe.com if the first one already 
                                        exists. The system requires that all email addresses entered be unique.`}
                        required={props.requireUserEmail}
                    >
                        <input
                            {...register('email', {
                                required: props.requireUserEmail
                                    ? 'Please enter an email address.'
                                    : undefined,
                                validate: validateEmail,
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerUpdate>
                        inputName="miscellaneous"
                        labelText="Miscellaneous"
                        helpText={`Miscellaneous is text field used on the Agent Report to group consumer 
                            drafts by agent in order to determine a total draft amount on which to base 
                            commissions. This is a free-form entry which means that you should be careful 
                            to always type in an agent name in the same way each time in order to keep 
                            that agent's consumers grouped correctly on the report. For example, "John 
                            Smith" and "J. Smith" will be two different consumer groups on the report.`}
                    >
                        <input
                            {...register('miscellaneous', {
                                maxLength: MAX_TEXT_BOX
                            })}
                            readOnly={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerUpdate>
                        inputName="mailedStatementOnly"
                        labelText="Mailed Statement Only"
                    >
                        <input
                            type="checkbox"
                            {...register(`mailedStatementOnly`)}
                            disabled={props.viewOnly}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerUpdate>
                        inputName="excludeFromRepay"
                        labelText="Exclude From Repay"
                    >
                        <input
                            type="checkbox"
                            {...register(`excludeFromRepay`)}                            
                            disabled={props.viewOnly}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    <WrappedInput<ConsumerUpdate>
                        inputName="accountStatus"
                        labelText="Account Status"
                        helpText={`The account status for this account. If this is set to anything other than Active, 
                                        the account will not draft.`}
                        required={true}
                    >
                        <Controller
                            name="accountStatus"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <NumericDropdown
                                    options={accountStatuses.map(as => ({
                                        value: as.value,
                                        text: as.name
                                    }))}
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                    readOnly={props.viewOnly}
                                />
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerUpdate>
                        inputName="affiliateId"
                        labelText="Affiliate"
                        helpText={`The affiliate for this consumer. Affiliates can be added to the system using the Payments > Affiliates menu item.`}
                    >
                        <Controller
                            name="affiliateId"
                            control={control}
                            rules={{
                                validate: a =>
                                    props.affiliates.length === 0 ||
                                    a !== undefined ||
                                    'Please select an Affiliate.'
                            }}
                            render={({ field: { onChange, value } }) => (
                                <NumericDropdown
                                    options={props.affiliates.map(a => ({
                                        value: a.id,
                                        text: a.name
                                    }))}
                                    defaultOptionText="Select..."
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                    readOnly={props.viewOnly}
                                />
                            )}
                        />
                    </WrappedInput>
                    <WrappedInput<ConsumerUpdate>
                        inputName="feeGroupId"
                        labelText="Fee Group"
                        helpText={`The Fee Group for this consumer. Fee Groups determine how paid fees 
                            are split and disbursed for a consumer account. Fee Groups can be created 
                            using the Payments > Fee Splits menu item.`}
                        required={props.feeGroups.length > 0}
                    >
                        <Controller
                            name="feeGroupId"
                            control={control}
                            rules={{
                                validate: fg =>
                                    props.feeGroups.length === 0 ||
                                    fg !== undefined ||
                                    'Please select a Fee Group.'
                            }}
                            render={({ field: { onChange, value } }) => (
                                <NumericDropdown
                                    options={props.feeGroups.map(fg => ({
                                        value: fg.id,
                                        text: fg.name
                                    }))}
                                    defaultOptionText="Select..."
                                    selectedValue={value}
                                    setSelectedValue={onChange}
                                    readOnly={props.viewOnly}
                                />
                            )}
                        />
                    </WrappedInput>
                    { props.isBusinessDebt && // props.IsBusinessDebt
                        <div>
                            <WrappedInput<ConsumerUpdate>
                                inputName="businessName"
                                labelText="Business Name"
                            >
                                <label>{props.businessName}</label>
                            </WrappedInput>
                            <WrappedInput<ConsumerUpdate>
                                inputName="dateOfFormation"
                                labelText="Date of Formation"
                            >
                                <label>{props.dateOfFormation.length > 0 ? serverDateToDisplayDate(props.dateOfFormation) : ''}</label>
                                    
                            </WrappedInput>
                        </div>
                    }
                </div>
            </div>

            {!props.viewOnly ? (
                <div className="clearFix">
                    <input className="btn btn-primary float-left" type="submit" value="Update" />
                </div>
            ) : null}
        </div>
    );
};

export default AccountSetupFormInputs;
