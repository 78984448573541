import { createContext } from 'react';
import { UserPreferredView } from '../types/user';
import { IdentityUser } from './authService';

export const AuthContext = createContext<{
    username?: any;
    userId?: any;
    companyId?: any;
    name?: any;
    roles?: any;
    expiration?:any;
    email?: any;
    clientId?: any; // API username
    logout: () => void;
    isUnlimitedUser: () => any;
    loggedInUser?: any;
    changePassword: () => void;
    savePermissions: (value?: UserPreferredView) => void;
    permissions?:any | {};
}>({
    username: null,
    userId: null,
    companyId: null,
    name: null,
    roles: [],
    expiration: null,
    email: null,
    clientId: null,
    logout: () => {},
    isUnlimitedUser: () => false,
    changePassword:() => {},
    savePermissions: () => {},
    permissions:{},
});
