import * as React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { consumerSubPages, ConsumerParams, consumerRoutingPath } from '../../../pages';
import { UserPermission } from '../../../types/enums';
import { UserAuthorizationContext } from '../../UserAuthorizationService';

const ConsumerTabBar = () => {
    const { userHasPermission } = React.useContext(UserAuthorizationContext);
    const hasViewFeesPermissions = userHasPermission(UserPermission.ViewFees);

    let { consumerId } = useParams<ConsumerParams>();    
    return (
        <div className="flex">
            {Object.entries(consumerSubPages)
                .filter(
                    ([_, subPage]) =>
                        subPage !== consumerSubPages.schedule //|| (subPage === consumerSubPages.schedule && hasEditConsumerPermissions) //removes schedule tab, may not be necessary for most clients, maybe check on has edit and also usesAPI?
                )
                .map(([s, page]) => (
                    (page.name !== 'Fees' || (page.name === 'Fees' && hasViewFeesPermissions)) && 
                    <NavLink
                        key={page.name}
                        className="tab-link"
                        activeClassName="active"
                        to={consumerRoutingPath(consumerId, page)}
                    >
                        {page.name}
                    </NavLink>
                ))}
        </div>
    );
};

export default ConsumerTabBar;
