import * as React from 'react';
import { useMemo } from 'react';
import '../../../../styles/views/FeeView.scss';
import { Fee, FeeGroup } from '../../../../types/feeGroupTypes';
import { serverDateToDisplayDate } from '../../../../utils/dateUtils';
import { DateTime } from 'luxon';

interface Props {    
    fee: Fee;
    payees: FeeGroup[];
}

function FeeView(props: Props) {
    // Add in the Payee Name to each Fee Split Detail
    const feeSplitsWithPayeeNames = useMemo(
        () =>
            // Problem: The "FeeSplitDetail" type includes "payeeId" but not "payeeName"
            // Solution: Make a copy of all fee splits, adding in an additional property called "payee" of type "FeeGroup" (which includes "name" as a sub-element).
            // Populate this new "payee" property by cross-referencing "payeeId" with the "payees" array.
            props.fee.splits.map(s => {
                return { ...s, payee: props.payees.find(p => p.id === s.payeeId) };
            }),
        [props.fee, props.payees]        
    );
    
    return (
        <li className="fee-card-flex-container">
            <div>
                <div className="date">{`Last Changed: ${serverDateToDisplayDate(
                    props.fee.lastUpdateDate,
                    DateTime.DATE_FULL
                )}`}</div>
                <span className="name">{`Fee ${props.fee.slot}: ${props.fee.name}`}</span>
                <span className="hold">{props.fee.hold ? ` (fees on hold)` : ``}</span>
            </div>

            {/* Fee Split Detail List */}
            <div>
                <ul>
                    {feeSplitsWithPayeeNames.map((splitDetail, index) => (
                        <li key={index}>
                            {/* Display string: "<value><units> to <payeeName>", or "<units><value> to <payeeName>" */}
                            {`${
                                splitDetail.isAmount
                                    ? `$${splitDetail.value}`
                                    : `${splitDetail.value}%`
                            } to ${Number(splitDetail.payeeId) === -3 ? 'Client Affiliate' : splitDetail.payee?.name || 'PAYEE NAME NOT FOUND'}`}
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
}

export default FeeView;
