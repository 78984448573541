import * as React from 'react';
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    ColumnDirective,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,
    Edit,
    ExcelExport,
    GridComponent,
    Inject,
    PdfExport,
    RowDataBoundEventArgs,
    Toolbar,
    FilterSettingsModel,
    Filter,
    Sort,
    Grid,
    Resize
} from '@syncfusion/ej2-react-grids';
import { useContext, useEffect } from 'react';
import { formatAsCurrency } from '../../../../utils/stringUtils';
import {
    formatDate,
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';
import { LedgerEntry } from '../../../../types/ledgers';
import { CompanySettingsContext } from '../../../CompanyService';
import {GridViewContext} from '../../../GridViewService';

interface Props {
    filtered: boolean;
    ledgers: BalancedLedger[];
    setRefundTransactionId: (transactionId: number) => void;
}

interface BalancedLedger extends LedgerEntry {
    rowBalance: number;
}

const FullLedgersGrid = (props: Props) => {
    const { showExtendedLedgerColumns } = useContext(CompanySettingsContext);
    const gridOptions = useCommonGridOptions('ConsumerLedgers');
    const ledgersGrid = gridOptions && gridOptions.ref;
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    useEffect(() => {
        ledgersGrid.current?.refresh();
    }, [props.ledgers, ledgersGrid]);

    /** Grid Option Definitions **/
    const commands: CommandModel[] = [
        {
            title: 'Refund',
            buttonOption: {cssClass: 'e-outline refund-icon'}
        }
    ];

    /** Value Accessor functions **/
    //  used to manipulate or format raw data before displaying it in the grid
    const getRowNumber: InternalValueAccessor<BalancedLedger> = (_field, data) => {
        return data && props.ledgers ? props.ledgers.indexOf(data) + 1 : 0;
    };

    const getRowBalance: InternalValueAccessor<BalancedLedger> = (_field, data) => {
        return formatAsCurrency(data.rowBalance);
    };

    const getLedgerAmount: InternalValueAccessor<BalancedLedger> = (_field, ledgerData) => {
        return formatAsCurrency(ledgerData.amount);
    };

    /** Grid event handler functions **/
    const onCommandClick = (args?: CommandClickEventArgs) => {
        if (args && args.rowData) {
            let ledgerData = args.rowData as BalancedLedger;
            if (args.commandColumn?.title === 'Refund') {
                props.setRefundTransactionId(ledgerData.transactionId);
            }
            args.target?.classList.add('e-disabled');
        }
    };

    const onRowDataBound = (args?: RowDataBoundEventArgs) => {
        //Add custom classes to rows based on their value
        if (!args || !args.data) {
            return;
        }

        let ledger = args.data as BalancedLedger;
        if (!ledger.refundable || ledger.itemRefunded) {
            let elementList = args.row?.querySelectorAll('.refund-icon');
            if (elementList && elementList.length > 0) {
                elementList[0].classList.add('e-disabled');
            }
        }
    };
    const { currentView } = React.useContext(GridViewContext);
    let grid:Grid|null
    const [filter,setFilter] = React.useState(false);
    let dataBound = () =>{
        if(gridOptions.ref.current)
        {   
            if(currentView === 2)
            {
                gridOptions.ref.current.autoFitColumns([])   
            }              
            if(document.getElementsByClassName('e-filtered').length > 0)
            {
                setFilter(true)
            }
            else
            {
                setFilter(false)
            }
        }
    }
    return (
        <GridComponent
            {...gridOptions}
            className={`e-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid'} ${currentView === 2 ?  'comfort-grid-view' : ''}`}
            dataBound= { dataBound } 
            dataSource={props.ledgers}
            commandClick={onCommandClick}
            rowDataBound={onRowDataBound}
            filterSettings={filterOptions}
            allowFiltering={true}
            allowSorting={false}
            clipMode='EllipsisWithTooltip'
        >
            <ColumnsDirective>
                <ColumnDirective
                    valueAccessor={vaWrapper(getRowNumber)}
                    headerText="#"
                    width="5%"
                    textAlign="Left"
                />
                <ColumnDirective
                    field="date"
                    valueAccessor={vaWrapper(formatDate)}
                    headerText="Date"
                    width="10%"
                    textAlign="Left"
                    allowFiltering={false}
                />
                <ColumnDirective
                    field="transactionId"
                    headerText="Transaction ID"
                    width="7%"
                    textAlign="Left"
                    allowFiltering={false}
                />
                <ColumnDirective
                    field="paymentStatus"
                    headerText="Payment Status"
                    width="7%"
                    textAlign="Left"
                    allowFiltering={true}
                />
                <ColumnDirective
                    field="payeeOrDepositorName"
                    headerText="Payee/Depositor"
                    width="10%"
                    textAlign="Left"
                />
                <ColumnDirective
                    field="amount"
                    valueAccessor={vaWrapper(getLedgerAmount)}
                    headerText="Amount"
                    width="10%"
                    textAlign="Left"
                    allowFiltering={false}
                />
                {/* Only show balance if not filtered */}
              {!filter && (
                    <ColumnDirective
                        valueAccessor={vaWrapper(getRowBalance)}
                        headerText="Trust Account Balance"
                        width="10%"
                        textAlign="Left"
                        field="rowBalance"
                        allowFiltering={false}
                    />
                 )}
                <ColumnDirective
                    field="scheduledPaymentDate"
                    valueAccessor={vaWrapper(formatDate)}
                    headerText="Scheduled Payment Date"
                    width="10%"
                    textAlign="Left"
                    allowFiltering={false}
                />
                <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="12%"
                    textAlign="Left"
                    allowFiltering={false}
                />
                {showExtendedLedgerColumns && (
                    <>
                        <ColumnDirective
                            field="notes"
                            headerText="Notes"
                            width="5%"
                            textAlign="Left"
                            allowFiltering={false}
                        />

                        <ColumnDirective
                            field="checkNumber"
                            headerText="Check Number"
                            width="5%"
                            textAlign="Left"
                            allowFiltering={false}
                        />
                    </>
                )}
                <ColumnDirective
                    field="feeName"
                    headerText="Fee Name"
                    width="10%"
                    textAlign="Left"
                    allowFiltering={false}
                />
                <ColumnDirective
                    field="id"
                    headerText=""
                    commands={commands}
                    textAlign="Left"
                />
            </ColumnsDirective>
            <AggregatesDirective>
                <AggregateDirective>
                    <AggregateColumnsDirective>
                        <AggregateColumnDirective field="amount" type="Sum" format="C2">
                        </AggregateColumnDirective>
                    </AggregateColumnsDirective>
                </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Aggregate, Edit, CommandColumn, PdfExport, ExcelExport, Toolbar, Filter, Sort, Resize]} />
        </GridComponent>
    );
};

export default FullLedgersGrid;
