import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ScheduleEdit } from '../../../../../types/drafts';
import WrappedInput from '../../../../common/form/WrappedInput';
import { Creditor, SettlementEdit } from '../../../../../types/settlements';
import { PaymentType } from '../../../../../types/enums';
import {
    validateCurrency,
    validateNullOrCurrency,
    validateNullOrNonNegative,
    validateNonNegative
} from '../../../../../utils/validationRules';
import DatePicker from '../../../../common/DatePicker';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import { ConsumerContext } from '../../ConsumerPage';
import CreditorsDropdown from './CreditorsDropdown';
import { Address } from '../../../../../types/common';
import { CompanySettingsContext } from '../../../../CompanyService';
import { formatAsCurrency } from '../../../../../utils/stringUtils';
import PayeeMailingDetailsFormInputs from './PayeeMailingDetailsFormInputs';
import BankAddressFormInputs from './BankAddressFormInputs';
import BankAccountFormInputs from './BankAccountFormInputs';
import PayByPhoneDetailsInputs from './PayByPhoneDetailsInput';
import AlertBanner from '../../../../common/AlertBanner';

export interface Props {
    viewOnly: boolean;
    type?: string;
    onEditCreditor: (creditor?: Creditor) => void;
    saveAdddress: (val?: any) => void;
    status: any;
}

const SettlementEditFormInputs = (props: Props) => {
    const consumer = useContext(ConsumerContext);
    const { register, control, setValue } = useFormContext<SettlementEdit>();
    const watchPaymentType = useWatch({
        control,
        name: 'paymentType'
    });
    const watchPayeeMailingDetails = useWatch({
        control,
        name: 'payeeMailingDetails'
    });
    const companySettings = useContext(CompanySettingsContext);
    const [newCreditor, setNewCreditor] = useState(false)
    const [status, setStatus] = useState(0)
    const [creditorChanged, setCreditorChanged] = useState(false)

    return (<>
        <div className="consumer-input-section settlement-inputs">
            { (newCreditor || (props.status === 1 && !creditorChanged)  || status === 1) &&  <AlertBanner message="NEW CREDITOR ALERT: This settlement will be on hold until the review process for the new Creditor is complete."></AlertBanner>
            }
            { (props.status === 3 && !creditorChanged) &&  <AlertBanner message="CREDITOR REJECTED: This Creditor has been rejected, please contact us for more info."></AlertBanner>
            }
            <div className="row">
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="paymentType"
                        labelText="Payment Type"
                        helpText={`Select the payment type for this settlement payment. Available types may include:
                        <ol type="I">
                        <li>RAM Check: a check from the RAM trust account sent via ground shipping or other method to the creditor</li>
                        <li>Wire Transfer: a wire transfer from the RAM trust account to the creditor.</li>
                        <li>ACH: a transfer via ACH to the creditor. This requires you to provide the creditor banking information</li>
                        <li>Pay by Phone: a payment made by phone from the RAM trust account to the creditor. This requires you to provide the creditor phone number for Payment By Phone (also called Check By Phone).</li>
                        </ol>`}
                    >
                        <Controller
                            name="paymentType"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <select
                                    className="dropdown-select"
                                    value={value}
                                    onChange={e => onChange(parseInt(e.target.value))}
                                    disabled={props.viewOnly}
                                >
                                    <option value={PaymentType.RamCheck}>RAM Check</option>
                                    <option value={PaymentType.WireTransfer}>Wire Transfer</option>
                                    <option value={PaymentType.Ach}>ACH</option>
                                    <option value={PaymentType.PayByPhone}>Pay By Phone</option>
                                </select>
                            )}
                        />
                    </WrappedInput>
                    <div className="creditor-dropdown-wrapper">
                        <WrappedInput<SettlementEdit>
                            inputName="creditorName"
                            labelText="Creditor Name"
                            required={true}
                        >
                            <Controller
                                name="creditorName"
                                control={control}
                                rules={{
                                    required: 'Please select a creditor.'
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CreditorsDropdown
                                        viewOnly={props.viewOnly}
                                        selectedCreditorName={value}
                                        onSelectedCreditorNameChange={(
                                            creditorName: string,
                                            CreditorAddressUid: any,
                                            status: any,
                                            creditorAddress: Address
                                        ) => {
                                            onChange(creditorName);
                                            setStatus(status);
                                            setCreditorChanged(true)                                   
                                            setValue('payeeMailingDetails', {
                                                mailTo: watchPayeeMailingDetails?.mailTo || '',
                                                payeeAddress: creditorAddress
                                            });
                                            setValue('CreditorAddressUid', CreditorAddressUid)
                                        }}
                                        onEditCreditor={props.onEditCreditor}
                                        setEnablefields={(val)=>{
                                            setNewCreditor(val)
                                            props.saveAdddress(val)
                                            setStatus(0);
                                        }}
                                    />
                                )}
                            />
                        </WrappedInput>
                        {companySettings.canEditCreditors && (
                            <button
                                className="btn btn-secondary float-right btn-new-creditor"
                                onClick={() => props.onEditCreditor()}
                            >
                                Add Creditor
                            </button>
                        )}
                    </div>
                </div>
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="payeeName"
                        labelText="Pay to the Order of"
                        required={true}
                    >
                        <input
                            readOnly={props.viewOnly}
                            {...register('payeeName', {
                                required: 'Please enter a Payee name.'
                            })}
                            onBlur={e => {
                                if (
                                    watchPayeeMailingDetails &&
                                    (!watchPayeeMailingDetails.mailTo ||
                                        watchPayeeMailingDetails.mailTo.trim() === '')
                                ) {
                                    setValue('payeeMailingDetails', {
                                        ...watchPayeeMailingDetails,
                                        mailTo: e.target.value
                                    });
                                }
                            }}
                        />
                    </WrappedInput>
                    <WrappedInput<SettlementEdit>
                        inputName="referenceNumber"
                        labelText="Reference Number"
                        required={true}
                    >
                        <input readOnly={props.viewOnly} 
                        {...register('referenceNumber', {
                            required: 'Please enter Reference Number.',
                        })}
                    />
                    </WrappedInput>
                </div>
            </div>
            <div>
                {watchPaymentType !== PaymentType.Ach && (
                    <PayeeMailingDetailsFormInputs type={props.type} viewOnly={props.viewOnly} disable={!newCreditor}/>
                )}
                {watchPaymentType === PaymentType.WireTransfer && (
                    <BankAddressFormInputs viewOnly={props.viewOnly} />
                )}
                {(watchPaymentType === PaymentType.WireTransfer ||
                    watchPaymentType === PaymentType.Ach) && (
                    <BankAccountFormInputs viewOnly={props.viewOnly} />
                )}
                {watchPaymentType === PaymentType.PayByPhone && (
                    <PayByPhoneDetailsInputs viewOnly={props.viewOnly} />
                )}
            </div>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="consumerSsnLast4"
                        labelText="Consumer Tax Id/SSN (last 4)"
                        required={true}
                        helpText={`The last 4 digits of the consumer's tax ID or SSN. This is required by some creditors for accepting payments. Provide only the last 4 digits.`}
                    >
                        <input
                            type="number"
                            step="1"
                            readOnly={props.viewOnly}
                            {...register('consumerSsnLast4', {
                                required: 'Please enter the Consumer Tax Id.',
                                validate: {
                                    fourDigits: v =>
                                        v.toString().length === 4 || 'Please only enter 4 digits.'
                                }
                            })}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="consumerAccountNumber"
                        labelText="Consumer Account Number"
                        required={true}
                    >
                        <input
                            readOnly={props.viewOnly}
                            {...register('consumerAccountNumber', {
                                required: 'Please enter an account number.'
                            })}
                        />
                    </WrappedInput>
                </div>
            </div>
            <div className="row settlement-memo">
                <WrappedInput<SettlementEdit> inputName="memo" labelText="Memo/Reference">
                    <textarea readOnly={props.viewOnly} {...register('memo')} />
                </WrappedInput>
            </div>
            <WrappedInput<SettlementEdit>
                inputName="paymentAmount"
                labelText="Amount"
                required={true}
            >
                <input
                    readOnly={props.viewOnly}
                    type="number"
                    step="0.01"
                    {...register('paymentAmount', {
                        required: 'Please enter an amount.',
                        validate: {
                            positive: validateNonNegative,
                            currency: validateCurrency
                        },
                        valueAsNumber: true
                    })}
                />
            </WrappedInput>
            <div className="wrapped-info settlement-fees">
                <label>Settlement Fee</label>
                {watchPaymentType === PaymentType.RamCheck ? (
                    <span className="value">{formatAsCurrency(consumer.ramCheckFee)}</span>
                ) : watchPaymentType === PaymentType.WireTransfer ? (
                    <span className="value">{formatAsCurrency(consumer.wireTransferFee)}</span>
                ) : watchPaymentType === PaymentType.Ach ? (
                    <span className="value">{formatAsCurrency(consumer.achFee)}</span>
                ) : (
                    <span className="value">{formatAsCurrency(consumer.payByPhoneFee)}</span>
                )}
            </div>
            <div className='row'>
            <div className="col-6">
            <WrappedInput<SettlementEdit> inputName="additionalFees" labelText="Additional Fees">
                <input
                    readOnly={props.viewOnly}
                    type="number"
                    step="0.01"
                    {...register('additionalFees', {
                        validate: {
                            positive: validateNullOrNonNegative,
                            currency: validateNullOrCurrency
                        },
                        valueAsNumber: true
                    })}
                />
            </WrappedInput>
            </div>
                <div className="col-6">
                    <WrappedInput<SettlementEdit>
                        inputName="debtAmount"
                        labelText="Total Debt"
                        required={false}
                        helpText={`The total amount owed by consumer on which the payment program for this consumer begins.`}
                    >
                        <input
                        readOnly={props.viewOnly}
                        type="number"
                        step="0.01"
                        {...register('debtAmount', {
                            validate: {
                            positive: validateNullOrNonNegative,
                            currency: validateNullOrCurrency
                        },
                        valueAsNumber: true
                    })}
                />
                    </WrappedInput>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<ScheduleEdit>
                        inputName="dueDateTime"
                        labelText="Payment Due Date"
                        required={true}
                        helpText={`The date on which the payment program for this consumer begins. 
                        This date cannot be earlier than 1 business day from the current date the first time you set up a draft schedule. 
                        This date determines on what day of the month the consumer's drafts will be scheduled 
                        (e.g. if your start date is July 16th, all drafts will be scheduled for the 16th of each month).`}
                    >
                        {/* Must pick a date in the future */}
                        <Controller
                            name="dueDateTime"
                            control={control}
                            rules={{
                                required: 'Please select a date.'
                            }}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    isDateRange={false}
                                    initialStartDate={value}
                                    changeDate={onChange}
                                    minimumDate={DateTime.utc()}
                                />
                            )}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    {props.type == 'add' && <WrappedInput<SettlementEdit>
                        inputName="totalOccurrences"
                        labelText="Total Occurences"
                        required={true}
                        helpText={`The total number of Settlement Payments to be scheduled.`}
                    >
                        <input
                            type="number"
                            step="1"
                            readOnly={props.viewOnly}
                            {...register('totalOccurrences', {
                                required: 'Please enter number of occurences.',
                                validate: {
                                    between1and100: v =>
                                        (v && v >= 1 && v <= 100) ||
                                        'Please enter a value between 1 and 100.'
                                },
                                valueAsNumber: true
                            })}
                        />
                    </WrappedInput>}
                </div>
            </div>
            <hr></hr>
            <div className="clear-fix">
                <div className="float-left">
                    <input
                        disabled={props.viewOnly}
                        className="btn btn-primary"
                        type="submit"
                        value="Save"
                    />
                </div>
            </div>
        </div>
    </>
    );
};

export default SettlementEditFormInputs;
