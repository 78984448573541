import * as React from 'react';

interface Props {
    url: string;
    children?: React.ReactNode;
    className?: string;
}

const NewTabLink = ({ url, children, className }: Props) => (
    <a href={url} target="_blank" rel="noreferrer" className={className}>
        {children}
    </a>
);

export default NewTabLink;
