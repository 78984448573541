import * as React from 'react';
import { DashboardType } from '../../../enums/Dashboards';
import DashboardView from '../../common/dashboard/DashboardView';
import { DateRange,CompanyName } from '../../../types/common';
import DashboardPageWrapper from './DashboardPageWrapper';

interface Props {
    dateRange: DateRange;
    changeDateRange?: (dateRange: DateRange) => void;
    coName: CompanyName;
}

const Settlements = (props: Props) => {
    return (
        <DashboardPageWrapper
            dateRange={props.dateRange}
            changeDateRange={props.changeDateRange}
            pageName="Settlements"
        >
            <DashboardView
                dashboardType={DashboardType.SettlementsCount}
                dateRange={props.dateRange}
                coName={props.coName}
            />
            <DashboardView
                dashboardType={DashboardType.SettlementsRate}
                dateRange={props.dateRange}
                coName={props.coName}
            />
            <DashboardView
                dashboardType={DashboardType.SettlementsAverageAmount}
                dateRange={props.dateRange}
                coName={props.coName}
            />
        </DashboardPageWrapper>
    );
};

export default Settlements;
