import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
    Aggregate,
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    ExcelExport,
    Filter,
    GridComponent,
    Inject,
    PdfExport,
    Resize,
    Toolbar
} from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useCallback, useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import { companyShortSettlements } from '../../../../api/companyApi';
import { AdvanceShortSettlements, CompanyShortSettlementsRequest, CompanyShortSettlementsResponse, ShortSettlements } from '../../../../types/company';
import { useCommonGridOptions } from '../../../../utils/gridUtils';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { GridViewContext } from '../../../GridViewService';

interface Props{

}

export const ShortSettlementsPage =(props: Props) => { 
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [disable, setDisable] = useState(true);
    const gridOptions = useCommonGridOptions('ConsumerSettlements');
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [settlements, setSettlements] = useState<any>();
    const [totalAdvances, settotalAdvances] = useState<number>(0);
    const [totalPendingBalance, setTotalPendingBalanace] = useState<number>(0);
    const apiWrapper = loadFromApi(setApiError, setLoading);
    const toastSuccess = (message: string) =>
        addToast(message, { appearance: 'success', autoDismiss: true });

    const shortSettlements = async (data?: CompanyShortSettlementsRequest ) => {
        await apiWrapper(() => companyShortSettlements(data ?? {
            runDate: new Date(),
            advanceSettlementRequests:[{
                clientID: 0,
                settlementID: 0,
            }]
        }), setSettlements);
        settotalAdvances(0);
    };

    useEffect(() => {       
        shortSettlements()
    }, [])

    const { currentView } = useContext(GridViewContext);

    let dataBound = () =>{
        console.log('currentView', currentView)
        if (gridOptions.ref && gridOptions.ref.current) {
            const records = gridOptions.ref.current?.getCurrentViewRecords() as ShortSettlements[];
            let sum =0;
            records.map((record) => {
                    sum+=record.pendingBalance;
            })
            if(currentView === 2)
            {
                gridOptions.ref.current.autoFitColumns([])
            }
        }
    }
    const onRowSelected = () => {
        if (gridOptions.ref && gridOptions.ref.current) {
            const selectedrecords = gridOptions.ref.current.getSelectedRecords() as ShortSettlements[];
            if(selectedrecords.length === 0){
                setDisable(true);
            }
            else {
                setDisable(false);
            }
            let sum = 0;
            selectedrecords.map((record) => {
            let recordAdvance = record.paymentamount + record.ramFeeAmount - (record.pendingBalance + record.availableBalance);
            sum+= (recordAdvance > 0 ? recordAdvance : 0);
            })
            settotalAdvances(sum)
        }
    };

    const saveProceedings = () => {
        if (gridOptions.ref && gridOptions.ref.current) {
            const selectedrecords = gridOptions.ref.current.getSelectedRecords() as ShortSettlements[];
            let saveRecords: AdvanceShortSettlements[] = [];
            selectedrecords.map((record) => {
                saveRecords.push({settlementID: record.id, clientID: Number(record.clientID)})
            })
            shortSettlements({
                runDate: new Date('01/20/2022'),
                advanceSettlementRequests: saveRecords
                
            })           
        }
    }

    useEffect(() => {
        setDisable(true);
        if(settlements?.errorMessages?.length > 0){
           setError(true);
        }
    }, [settlements])

    let dialogInstance: DialogComponent | null;
    const defaultButtons = [
        {
            buttonModel: {
                content: 'Close'
            },
            click: () => {
                setError(false)
                dialogInstance && dialogInstance.hide();
            }
        }
    ];
    
    return (<>{loading ? (
        <LoadingDisplay />
    ) : 
    <div className={`short-settlements `}>
        <DialogComponent
            header="Error Messages"
            visible={error}
            showCloseIcon={true}
            close={() =>  {
                setError(false)}}
            width="500px"
            height="auto"
        >{settlements?.errorMessages}</DialogComponent>
        <div>
            <div>
                <h1>Short Settlements</h1>
            </div>
            <div className='short-settlements-amnt'>
                <p className='amnt'>Total Advances:                   
                    <NumberFormat
                    value={totalAdvances.toFixed(2)}
                    thousandSeparator={true}
                    prefix={'$'}
                    displayType={'text'}
                    ></NumberFormat>
                </p>
                <p className='amnt'>Daily Total Advanced:                    
                    <NumberFormat
                    value={settlements?.advanceResult?.dailyAdvancedTotal.toFixed(2)}
                    thousandSeparator={true}
                    prefix={'$'}
                    displayType={'text'}
                    ></NumberFormat>
                </p>
                <div className={`${disable ? 'pay-disabled' : 'pay-enabled'}`}>
                    <ButtonComponent  disabled={disable} onClick={() => {
                        saveProceedings();
                    }}>Advance and Pay</ButtonComponent>
                </div>
             </div>             
        </div>
        <GridComponent
                {...gridOptions}
                width={'1500px'}
                className={`e-grid ram-grid short-settlements-grid ${currentView ===2 ? 'comfort-grid-view' : ''} ${currentView ===3 ? 'compact-view' : ''}`}
                dataBound= { dataBound } 
                dataSource={settlements?.companyShortSettlements}
                rowSelected={onRowSelected}
                rowDeselected={onRowSelected}
                clipMode='EllipsisWithTooltip'
            >
                <ColumnsDirective>
                    <ColumnDirective
                        field="select"
                        type="checkbox"
                        width="3%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                       headerText="Available Balance"
                       field="availableBalance"    
                       format="C2"                   
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />  
                   <ColumnDirective
                       headerText="Pending Balance"
                       field="pendingBalance"   
                       format="C2"                     
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   /> 
                   <ColumnDirective
                       headerText="Client F Name"
                       field="clientfname"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />  
                   <ColumnDirective
                       headerText="Client L Name"
                       field="clientlname"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />  
                   <ColumnDirective
                       headerText="Client ID"
                       field="clientID"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />    
                   <ColumnDirective
                       headerText="Settlement Due"
                       field="settlementDue"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />  
                   <ColumnDirective
                       headerText="Payment To"
                       field="paymentTo"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />  
                   <ColumnDirective
                       headerText="Account #"
                       field="accountLast4"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />   
                   <ColumnDirective
                       headerText="Payment Amount"
                       field="paymentamount"
                       format="C2"                        
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />   
                   <ColumnDirective
                       headerText="Ram Fee Amount"
                       field="ramFeeAmount"   
                       format="C2"                     
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />   
                   <ColumnDirective
                       headerText="Next Savings Draft Date"
                       field="nextSavingsDraftDate"                       
                       width="5%"
                       textAlign="Left"
                       headerTextAlign="Left"
                       allowFiltering={false}
                   />  
                    <ColumnDirective
                    headerText="Settlement ID"
                    field="id"                       
                    width="5%"
                    textAlign="Left"
                    headerTextAlign="Left"
                    allowFiltering={false}
                    />  
                    <ColumnDirective
                        headerText="Last Return Type"
                        field="lastReturnType"                       
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />  
                    <ColumnDirective
                        headerText="Last Return Date"
                        field="lastReturnDate"                       
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />  
                    <ColumnDirective
                        headerText="Name"
                        field="name"                       
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    /> 
                    <ColumnDirective
                        headerText="Prior Paid"
                        field="priorPaid"  
                        format="C2"                      
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    /> 
                    <ColumnDirective
                        headerText="Relabel"
                        field="relabel"                       
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />  
                    <ColumnDirective
                        headerText="Return Count"
                        field="returnCount"                       
                        width="5%"
                        textAlign="Left"
                        headerTextAlign="Left"
                        allowFiltering={false}
                    />
                   
                </ColumnsDirective>
                <Inject services={[Edit, CommandColumn, PdfExport, ExcelExport, Toolbar, Filter,Aggregate,Resize]} />
            </GridComponent>
    </div>}
    </>);
}