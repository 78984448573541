import { useHistory } from 'react-router-dom';
import { consumerRoutingPath, consumerSubPages } from '../pages';

export const useConsumerNavigation = () => {
    const history = useHistory();
    return (consumerId: number) =>
        history.push(
            consumerRoutingPath(consumerId.toString(), {
                name: consumerSubPages.contactInfo.name,
                extension: consumerSubPages.contactInfo.extension
            })
        );
};
