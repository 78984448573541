import { DateTime } from 'luxon';
import { Draft, DraftsCalculation, ScheduleFee, ScheduleUpdate } from '../types/drafts';
import { DraftFrequency, DraftStatus } from '../types/enums';
import { FeeGroup } from '../types/feeGroupTypes';
import { luxonDateToServerDate, serverDateToLuxonDateTime } from './dateUtils';
import { round, sanitizeFractionDenominator } from './numberUtils';
import { getPropertyByString } from './objectUtils';

export function sumDraftAmount(draft?: Draft) {
    let sum = 0;
    if (draft) {
        //Return the sum of the ramFee, savingsAmount, and all fees
        sum = draft.ramFee + draft.savingsAmount;
        Object.values(draft.fees).forEach((fee: any) => (sum += fee));
    }
    return sum;
}

/**
 * Returns a DraftCalculation object which contains array of Drafts along with errors if encountered and summary information.
 * The array of drafts includes all already paid drafts, and new drafts.
 * New drafts are calculated based on the values in the scheduleUpdate object
 *
 * @param {number} consumerId
 * @param {number} scheduleUpdate
 * @param {number} feeGroups
 * @returns {DraftsCalculation} draftCalculation
 */
export function generateSchedulePreview(
    scheduleUpdate: ScheduleUpdate,
    feeGroups: FeeGroup[],
    existingDrafts: Draft[],
    defaultRamFee: number,
    bankAccountName: string
): DraftsCalculation {
    let drafts: Draft[] = [];
    let newDrafts: Draft[] = [];

    //Pull the summary information from the set of fees already paid
    let [
        totalPaymentAmount,
        totalPaymentCount,
        totalSavingsAmount,
        feeAmountAlreadyPaid,
        feeCountAlreadyPaid
    ] = calculateAmountsAlreadyPaid(existingDrafts, feeGroups);

    //Use the already paid amount and the fee group schedule inputs to calculate the per fee amount by fee group (sb by fee slot)
    let [feeRecurringAmount, feeWeightedTotalAmount, feeRecurringWeightedAmount, errorFlag] =
        calculateRecurringFeeValues(
            feeGroups,
            scheduleUpdate,
            feeAmountAlreadyPaid,
            feeCountAlreadyPaid
        );

    //Check if errorFlag is true then the fee calculations resulted in something divided by zero
    if (errorFlag) {
        return {
            errorText:
                'Your fee structure resulted in one or more infinite fee payments, please check the numbers. This can be caused by setting a fee weight number of payments to a number smaller than the number of already completed payments.',
            drafts: [],
            totalPaymentAmount: 0,
            totalPaymentCount: 0,
            totalSavingsAmount: 0,
            totalFeeAmounts: []
        };
    }

    //Use the calculated fee values and the draft frequency to create the appropriate number of drafts
    let draftDate = serverDateToLuxonDateTime(scheduleUpdate.startDate);
    //The starting index should account for the number of payments already made
    for (
        let draftIndex = existingDrafts.length;
        draftIndex < scheduleUpdate.occurrences;
        draftIndex++
    ) {
        //Set up fees for this draft
        let ramFee = defaultRamFee;
        let fees: Record<number, number> = {};
        let totalFeeGroupValues = 0;
        let negativeFeeFlag = false;
        for (let feeGroupIndex = 0; feeGroupIndex < feeGroups.length; feeGroupIndex++) {
            let feeValue = 0;
            let feeInputs = scheduleUpdate.fees.find(f => f.feeId === feeGroups[feeGroupIndex].id);
            if (feeInputs) {
                feeValue = calculateFeeAmount(
                    serverDateToLuxonDateTime(feeInputs.startDate),
                    draftDate,
                    feeCountAlreadyPaid[feeGroupIndex],
                    feeInputs.draftsCount,
                    feeInputs.totalAmount,
                    feeAmountAlreadyPaid[feeGroupIndex],
                    feeWeightedTotalAmount[feeGroupIndex],
                    feeRecurringWeightedAmount[feeGroupIndex],
                    feeRecurringAmount[feeGroupIndex]
                );
                feeAmountAlreadyPaid[feeGroupIndex] += feeValue;
                feeCountAlreadyPaid[feeGroupIndex] += 1;
            }
            if (feeValue < 0) {
                negativeFeeFlag = true;
            }
            totalFeeGroupValues += feeValue;
            fees[feeGroups[feeGroupIndex].id] = feeValue;
        }

        //Calculate savings amount for the draft
        //Savings amount is the total monthly payment subtract by the sum of all the fees
        let savingsAmount = scheduleUpdate.monthlyDraft - totalFeeGroupValues - ramFee;
        savingsAmount = savingsAmount > 0 ? savingsAmount : 0; //Ensure the value is not negative

        //Add these calculated fees to the total amounts
        totalPaymentAmount += savingsAmount + totalFeeGroupValues + ramFee;
        totalPaymentCount += 1;
        totalSavingsAmount += savingsAmount;

        //Check the draft for negative fee amounts
        //If there are any negative fees, the error text should be passed by to the parent and no fees are returned
        if (negativeFeeFlag || ramFee < 0) {
            return {
                errorText:
                    'Your fee structure resulted in one or more negative fee payments, please check the numbers.',
                drafts: [],
                totalPaymentAmount: 0,
                totalPaymentCount: 0,
                totalSavingsAmount: 0,
                totalFeeAmounts: []
            };
        }

        //Create a new draft object containing all the fee information
        let newDraft: Draft = {
            id: 0,
            ramFee: ramFee,
            status: DraftStatus.Scheduled,
            returnedReason: null,
            bankName: bankAccountName,
            date: luxonDateToServerDate(draftDate),
            savingsAmount: savingsAmount,
            fees: fees,
            numberofPayments: 1,
            paymentFrequency: 0
        };
        //Add the new draft to the list
        newDrafts.push(newDraft);

        //Calculate the next draft date based on the frequency interval and the current draft date
        let nextDate = addDraftFequencyIntervalToDate(draftDate, scheduleUpdate.draftFrequency);
        if (!nextDate) {
            return {
                errorText: 'Payment Frequency interval is invalid.',
                drafts: [],
                totalPaymentAmount: 0,
                totalPaymentCount: 0,
                totalSavingsAmount: 0,
                totalFeeAmounts: []
            };
        } else {
            draftDate = nextDate;
        }
    }

    //Return the exising and newly calculated drafts
    drafts = [...existingDrafts, ...newDrafts];
    return {
        errorText: undefined,
        drafts: drafts,
        totalPaymentAmount: totalPaymentAmount,
        totalPaymentCount: totalPaymentCount,
        totalSavingsAmount: totalSavingsAmount,
        totalFeeAmounts: feeAmountAlreadyPaid
    };
}

export function feeTotalMatches(
    feeTotal: number | undefined,
    fees: ScheduleFee[],
    feeId: number
): boolean {
    let matchingFee = fees.find(fee => fee.feeId === feeId);
    if (matchingFee && feeTotal && round(feeTotal, 2) === round(matchingFee.totalAmount, 2)) {
        //If both are defined and their rounded values are equal the values match
        return true;
    } else if (!matchingFee && !feeTotal) {
        //If neither are defined then the values match
        return true;
    } else {
        //If one is defined and the other is not, or both are defined and the values are not equal -- they do not match
        return false;
    }
}

//**** Helper Functions *****/

/**
 *  Takes in existing drafts and fee groups and return summary information
 *
 * @param {Draft[]} existingDrafts
 * @param {FeeGroup[]} feeGroups
 * @returns {[number, number, number, number[], number[]]}
 *      item 1: totalPaymentAmount (number) - total amount paid including savings amount, fee group fee, and ram fee;
 *      item 2: totalPaymentCount (number) - total number of drafts (equivalent to existingDrafts.length);
 *      item 3: totalSavingsAmount (number);
 *      item 4: feeAmountAlreadyPaid (number[]);
 *      item 5: feeCountAlreadyPaid (number[]) - array representing the total number of fee payments paid per fee. Fees <= 0 are not included in this count;
 */
function calculateAmountsAlreadyPaid(
    existingDrafts: Draft[],
    feeGroups: FeeGroup[]
): [number, number, number, number[], number[]] {
    //Init sum values
    let totalPaymentAmount = 0;
    let totalPaymentCount = 0;
    let totalSavingsAmount = 0;
    let feeAmountAlreadyPaid: number[] = new Array(feeGroups.length).fill(0);
    let feeCountAlreadyPaid: number[] = new Array(feeGroups.length).fill(0);
    //Iterate through exisiting drafts array and calculate totals
    existingDrafts.forEach(draft => {
        totalPaymentCount += 1;
        totalSavingsAmount += draft.savingsAmount;
        //Calculate the fee amount paid by fee group
        let totalFeeGroupsAmount = 0;
        for (let feeGroupIndex = 0; feeGroupIndex < feeGroups.length; feeGroupIndex++) {
            if (Object.keys(draft.fees).includes(feeGroups[feeGroupIndex].id.toString())) {
                let feeAmount = getPropertyByString(
                    draft.fees,
                    feeGroups[feeGroupIndex].id.toString()
                );
                feeAmountAlreadyPaid[feeGroupIndex] += feeAmount;
                if (feeAmountAlreadyPaid[feeGroupIndex] > 0) {
                    feeCountAlreadyPaid[feeGroupIndex] += 1;
                }
                totalFeeGroupsAmount += feeAmount;
            }
        }
        totalPaymentAmount += draft.savingsAmount + draft.ramFee + totalFeeGroupsAmount;
    });

    return [
        totalPaymentAmount,
        totalPaymentCount,
        totalSavingsAmount,
        feeAmountAlreadyPaid,
        feeCountAlreadyPaid
    ];
}

/**
 *  Takes in feeGroups, schedule inputs, and already paid information and returns recurring fee payment values
 *
 * @param {FeeGroup[]} feeGroups
 * @param {ScheduleUpdate} scheduleUpdate
 * @param {number[]} feeAmountAlreadyPaid
 * @param {number[]} feeCountAlreadyPaid
 * @returns {[number[], number[], number[], boolean]}
 *      item 1: feeRecurringAmount (number[]);
 *      item 2: feeWeightedTotalAmount (number[]);
 *      item 3: feeRecurringWeightedAmount (number[]);
 *      item 4: errorFlag (boolean); - Indicates if the provided inputs resulted in an invalid fee value
 */
function calculateRecurringFeeValues(
    feeGroups: FeeGroup[],
    scheduleUpdate: ScheduleUpdate,
    feeAmountAlreadyPaid: number[],
    feeCountAlreadyPaid: number[]
): [number[], number[], number[], boolean] {
    let feeRecurringAmount: number[] = [];
    let feeWeightedTotalAmount: number[] = [];
    let feeRecurringWeightedAmount: number[] = [];
    let errorFlag = false;
    for (let feeGroupIndex = 0; feeGroupIndex < feeGroups.length; feeGroupIndex++) {
        let feeInputs = scheduleUpdate.fees.find(f => f.feeId === feeGroups[feeGroupIndex].id);
        if (feeInputs) {
            let feeWeightPercentage = feeInputs.feeWeight
                ? feeInputs.feeWeight.weightPercentage
                : 0;
            let feeWeightDraftsCount = feeInputs.feeWeight ? feeInputs.feeWeight.weightDrafts : 0;
            let feeResult = calculateRecurringFeeValue(
                feeInputs.draftsCount,
                feeWeightPercentage,
                feeInputs.totalAmount,
                feeAmountAlreadyPaid[feeGroupIndex],
                feeWeightDraftsCount,
                feeCountAlreadyPaid[feeGroupIndex]
            );
            feeRecurringAmount.push(feeResult[0]);
            feeWeightedTotalAmount.push(feeResult[1]);
            feeRecurringWeightedAmount.push(feeResult[2]);
            errorFlag = errorFlag || feeResult[3];
        } else {
            feeRecurringAmount.push(0);
            feeWeightedTotalAmount.push(0);
            feeRecurringWeightedAmount.push(0);
        }
    }
    return [feeRecurringAmount, feeWeightedTotalAmount, feeRecurringWeightedAmount, errorFlag];
}

/**
 *  Takes in fee inputs and already paid information and determines the reccuring fee amount, weighted or unweighted depending on if feeWeightPercentage is greater than 0.
 *
 * @param {number} feeDraftsCount
 * @param {number} feeWeightPercentage
 * @param {number} feeTotalAmount
 * @param {number} feeAmountAlreadyPaid
 * @param {number} feeWeightedDraftsCount
 * @param {number} feeCountAlreadyPaid
 * @returns {[number, number, number, boolean]}
 *      item 1: feeRecurringAmount (number);
 *      item 2: feeWeightedTotalAmount (number);
 *      item 3: feeRecurringWeightedAmount (number);
 *      item 4: errorFlag (boolean); - Indicates if the provided inputs resulted in an invalid fee value
 */
function calculateRecurringFeeValue(
    feeDraftsCount: number,
    feeWeightPercentage: number,
    feeTotalAmount: number,
    feeAmountAlreadyPaid: number,
    feeWeightedDraftsCount: number,
    feeCountAlreadyPaid: number
): [number, number, number, boolean] {
    let feeRecurringAmount = 0;
    let feeWeightedTotalAmount = 0;
    let feeRecurringWeightedAmount = 0;
    let errorFlag = false;

    //No drafts
    if (feeDraftsCount === 0) {
        return [0, 0, 0, false];
    }

    //No weights
    if (feeWeightPercentage <= 0) {
        feeRecurringAmount = round(
            (feeTotalAmount - feeAmountAlreadyPaid) /
                sanitizeFractionDenominator(feeDraftsCount - feeCountAlreadyPaid, errorFlag),
            2
        );
        return [feeRecurringAmount, feeWeightedTotalAmount, feeRecurringWeightedAmount, errorFlag];
    }

    //is weighted, so get weighted total (and account for any made payments)
    //weighted total calcs as remaining unpaid balance if pmts have been made...
    //but it needs to include any already paid amts as well since can't weight anything but first n payments
    feeWeightedTotalAmount = round(feeTotalAmount * (feeWeightPercentage / 100), 2);
    //weightedper is the per-payment amount for those remaining, need to sub out the paid amt
    feeRecurringWeightedAmount = round(
        (feeWeightedTotalAmount - feeAmountAlreadyPaid) /
            sanitizeFractionDenominator(feeWeightedDraftsCount - feeCountAlreadyPaid, errorFlag),
        2
    );

    //then figure non-weighted
    if (feeCountAlreadyPaid <= feeDraftsCount) {
        //still have unpaid weighted
        feeRecurringAmount = round(
            (feeTotalAmount - feeWeightedTotalAmount) /
                sanitizeFractionDenominator(feeDraftsCount - feeWeightedDraftsCount, errorFlag),
            2
        );
        return [feeRecurringAmount, feeWeightedTotalAmount, feeRecurringWeightedAmount, errorFlag];
    }

    //have paid all the weighted, so use the actual number of made payments
    feeRecurringAmount = round(
        (feeTotalAmount - feeWeightedTotalAmount) /
            sanitizeFractionDenominator(feeDraftsCount - feeCountAlreadyPaid, errorFlag),
        2
    );
    return [feeRecurringAmount, feeWeightedTotalAmount, feeRecurringWeightedAmount, errorFlag];
}

/**
 *  Takes in fee inputs, already paid information, and information about the active draft and return the amount that should be charged on the active draft to the specified fee.
 *
 * @param {DateTime} feeStartDate Date fees for this group start on
 * @param {DateTime} draftDate The date of the active draft is being scheduled on
 * @param {number} feeCountAlreadyPaid
 * @param {number} feeDraftsCount
 * @param {number} feeTotalAmount
 * @param {number} feeAmountAlreadyPaid
 * @param {number} feeWeightedTotalAmount
 * @param {number} feeRecurringWeightedAmount
 * @param {number} feeRecurringAmount
 * @returns {number} The fee amount charges for this fee on the active draft.
 */
function calculateFeeAmount(
    feeStartDate: DateTime,
    draftDate: DateTime,
    feeCountAlreadyPaid: number,
    feeDraftsCount: number,
    feeTotalAmount: number,
    feeAmountAlreadyPaid: number,
    feeWeightedTotalAmount: number,
    feeRecurringWeightedAmount: number,
    feeRecurringAmount: number
) {
    let feeValue = 0;
    if (draftDate >= feeStartDate) {
        if (feeCountAlreadyPaid < feeDraftsCount) {
            //check total
            if (feeCountAlreadyPaid + 1 === feeDraftsCount) {
                //final payment, pay any remaining balance
                feeValue = round(feeTotalAmount - feeAmountAlreadyPaid, 2);
            } else {
                //normal
                //check weighted
                if (round(feeAmountAlreadyPaid, 2) < feeWeightedTotalAmount) {
                    //run weighted
                    feeValue = round(feeRecurringWeightedAmount, 2);
                } else {
                    //weighted are all paid, use normal
                    feeValue = round(feeRecurringAmount, 2);
                }
            }
        }
    }
    return feeValue;
}

/**
 *  Create a new DateTime object representing the original date plus the draft frequency.
 *
 * @param {DateTime} draftDate
 * @param {DraftFrequency} draftFrequency
 * @returns {DateTime | undefined} Returns a new datetime. The original draftDate object is not modified. If undefined is returned, then the draft frequency provided was invalid.
 */
function addDraftFequencyIntervalToDate(
    draftDate: DateTime,
    draftFrequency: DraftFrequency
): DateTime | undefined {
    switch (draftFrequency) {
        case DraftFrequency.NotSet:
            return draftDate.plus({ months: 1 }); //Not Set is an invalid option, default to monthly
        case DraftFrequency.Weekly:
            return draftDate.plus({ weeks: 1 });
        case DraftFrequency.Biweekly:
            return draftDate.plus({ weeks: 2 });
        case DraftFrequency.Monthly:
            return draftDate.plus({ months: 1 });
        case DraftFrequency.Bimonthly:
            return draftDate.plus({ months: 2 });
        case DraftFrequency.Quarterly:
            return draftDate.plus({ months: 3 });
        case DraftFrequency.Semiannually:
            return draftDate.plus({ months: 6 });
        case DraftFrequency.Annually:
            return draftDate.plus({ years: 1 });
        case DraftFrequency.Daily:
            //Daily frequency is still only M-F - ensure non-business days are skipped
            let nextDay = draftDate.plus({ days: 1 });
            if (nextDay.weekday === 6) {
                return draftDate.plus({ days: 3 });
            } else if (nextDay.weekday === 7) {
                return draftDate.plus({ days: 2 });
            } else {
                return draftDate.plus({ days: 1 });
            }
        default:
            return undefined;
    }
}
