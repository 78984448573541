import * as React from 'react';
import { DashboardType, getDashboardDisplayName } from '../../../enums/Dashboards';

interface Props {
    dashboardType: DashboardType;
}

const DashboardLoadFailedDisplay = (props: Props) => (
    <div className="failed-dashboard-display">
        Could not load {getDashboardDisplayName(props.dashboardType)} dashboard.
    </div>
);

export default DashboardLoadFailedDisplay;
