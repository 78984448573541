import * as React from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { updateFeeGroup } from '../../../../api/feeSplitApi';
import { FeeGroup } from '../../../../types/feeGroupTypes';
import LoadingDisplay from '../../../common/LoadingDisplay';
import '../../../../styles/views/FeeView.scss';
import '../../../../styles/common/Elements.scss';

interface Props {
    currentFeeGroup: FeeGroup;
    onUpdate: () => void;
}

function EditFeeView(props: Props) {
    const [newFeeGroupName, setNewFeeGroupName] = useState<string>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const handleEditNameTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewFeeGroupName(e.currentTarget.value);
    };

    const renameFeeGroup = async () => {
        if (newFeeGroupName) {
            setLoading(true);

            var updateFeeGroupsResponse = await updateFeeGroup(
                props.currentFeeGroup.id,
                newFeeGroupName
            );
            setLoading(false);
            if (updateFeeGroupsResponse.error) {
                addToast(updateFeeGroupsResponse.error, { autoDismiss: true, appearance: 'error' });
            } else {
                props.onUpdate();
            }
        }
    };

    return loading ? (
        <LoadingDisplay />
    ) : (
        <div className="row-container">
            <div>
                <input
                    className="edit-name-input"
                    type="text"
                    placeholder="Edit name"
                    value={newFeeGroupName || ''}
                    onChange={handleEditNameTextInput}
                />
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-secondary"
                    disabled={!newFeeGroupName}
                    onClick={renameFeeGroup}
                >
                    Apply Name
                </button>
            </div>
        </div>
    );
}

export default EditFeeView;
