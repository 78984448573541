import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Affiliate } from '../../../../types/affiliate';
import { ReportType } from '../../../../types/report';
import CheckboxArray from '../../../common/form/CheckboxArray';
import WrappedInput from '../../../common/form/WrappedInput';
import { EditPermissions } from '../users/EditUserFormInputs';

interface Props {
    affiliates: Affiliate[];
    reportTypes: ReportType[];
}

const EditAffiliateUserFormInputs = (props: Props) => {
    const { control } = useFormContext<EditPermissions>();

    return (
        <div>
            <WrappedInput<EditPermissions>
                inputName="reportTypeAccess"
                labelText="Report Permissions"
                helpText="This list displays all of the reports currently 
                    available to the selected user. Affiliate reports are 
                    always limited only to consumers assigned to the 
                    selected affiliate."
            >
                <Controller
                    control={control}
                    name="reportTypeAccess"
                    render={({ field: { onChange, value } }) => (
                        <CheckboxArray
                            checkboxArrayId="chkArrReportAccess"
                            options={props.reportTypes.map(rt => ({
                                id: rt.reportTypeId,
                                display: rt.reportName
                            }))}
                            selectedOptions={value ?? []}
                            setSelectedOptions={onChange}
                        />
                    )}
                />
            </WrappedInput>
        </div>
    );
};

export default EditAffiliateUserFormInputs;
