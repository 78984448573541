import * as React from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import WrappedForm from '../../../common/form/WrappedForm';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { ConsumerContext } from '../ConsumerPage';
import AccountSetupFormInputs from './AccountSetupFormInputs';
import { Consumer, ConsumerUpdate } from '../../../../types/consumer';
import { updateConsumer } from '../../../../api/consumerApi';
import '../../../../styles/views/Consumer.scss';
import { getAffiliates } from '../../../../api/affiliateApi';
import { getFeeGroups } from '../../../../api/feeSplitApi';
import { loadFromApi } from '../../../../api/baseApi';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { Affiliate } from '../../../../types/affiliate';
import { FeeGroup } from '../../../../types/feeGroupTypes';
import { CompanySettingsContext } from '../../../CompanyService';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { UserPermission } from '../../../../types/enums';
import KYCUpload from '../KYCUpload';

interface Props {
    updateConsumerData: () => void;
}

const ConsumerAccountSetupTab = (props: Props) => {
    const companySettings = useContext(CompanySettingsContext);
    const consumer = useContext(ConsumerContext);

    const [affiliates, setAffiliates] = useState<Affiliate[]>();
    const [feeGroups, setFeeGroups] = useState<FeeGroup[]>();

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [businessName, setBusinessName] = useState<string>();
    const [dateOfFormation, setDateOfFormation] = useState<string>();
    const [isBusinessDebt, setIsBusinessDebt] = useState<boolean>();

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);

    useEffect(() => {
        const apiWrapper = loadFromApi(setApiError);
        const loadPageData = async () => {
            setLoading(true);
            const affiliatesResponse = apiWrapper(getAffiliates, setAffiliates);
            const feeGroupsResponse = apiWrapper(getFeeGroups, setFeeGroups);
            await affiliatesResponse;
            await feeGroupsResponse;

            if(consumer.businessName)
            {
                setBusinessName(consumer.businessName);
            }

            if(consumer.dateOfFormation)
            {
                setDateOfFormation(consumer.dateOfFormation);
            }

            if(companySettings.isBusinessDebt)
            {
                setIsBusinessDebt(companySettings.isBusinessDebt)
            } else {
                setIsBusinessDebt(false);
            }

            setLoading(false);
        };

        loadPageData();
    }, [setApiError]);

    const onSubmit = async (formConsumer: ConsumerUpdate) => {
        setLoading(true);
        const response = await updateConsumer(consumer.consumerID, formConsumer);
        if (response.error) {
            setApiError(`Failed to update consumer (${response.error})`);
        } else {
            addToast('Consumer updated', {
                appearance: 'success',
                autoDismiss: true
            });
            // Consumer data is displayed across all Consumer pages, and so must be refreshed from the server after being updated
            props.updateConsumerData();
        }
        setLoading(false);
    };

    function getConsumerUpdateFromConsumer(consumer: Consumer) {
        const consumerUpdate: ConsumerUpdate = {
            affiliateName: consumer.affiliateName,
            affiliateId: consumer.affiliateId,
            feeGroupId: consumer.feeGroupId,
            miscellaneous: consumer.miscellaneous,
            accountStatus: consumer.accountStatus,
            achStatus: consumer.achStatus,
            mailedStatementOnly: consumer.mailedStatementOnly,
            email: consumer.email,
            excludeFromRepay: consumer.excludeFromRepay
        };
        
        return consumerUpdate;
    }

    return (
        <div className="consumer-input-section account-setup-tab">
            <h3>Account Setup</h3>
            {loading ? (
                <LoadingDisplay />
            ) : affiliates && feeGroups ? (
                <div>
                    <WrappedForm<ConsumerUpdate>
                        initialValues={getConsumerUpdateFromConsumer(consumer)}
                        onSubmit={onSubmit}
                    >
                        <AccountSetupFormInputs
                            username={consumer.username}
                            affiliates={affiliates}
                            feeGroups={feeGroups}
                            requireUserEmail={companySettings.requireUserEmail}
                            viewOnly={!hasEditConsumerPermissions}
                            businessName={businessName ?? ''}
                            dateOfFormation={dateOfFormation ?? ''}
                            isBusinessDebt={isBusinessDebt ?? false}
                        />
                    </WrappedForm>
                </div>                
            ) :  (
                <LoadFailedDisplay />
            )}
            {<KYCUpload />}            
        </div>
        
    );
};

export default ConsumerAccountSetupTab;
