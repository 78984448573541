import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { CreditCardChargeEdit } from '../../../../../types/bankingInformation';
import '../../../../../styles/Form.scss';
import '../../../../../styles/views/Consumer.scss';
import { MAX_TEXT_BOX } from '../../../../../utils/formConstants';
import { CardNetwork } from '../../../../../types/enums';

interface Props {
    feeGroupId: number;
    cardNetwork: CardNetwork;
}

const ChargeCardFormInputs = (props: Props) => {
    const { register, control } = useFormContext<CreditCardChargeEdit>();

    const { fields } = useFieldArray({
        control,
        name: 'editFees'
    });

    return (
        <div className="consumer-input-modal">
            <h4 className="row">Total Charge Amount</h4>
            <div className="row">
                <div className="col-6">
                    <WrappedInput<CreditCardChargeEdit>
                        inputName="savingsAmount"
                        labelText="Savings"
                    >
                        <input
                            {...register('savingsAmount', {
                                maxLength: MAX_TEXT_BOX,
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: 'Must be numeric.'
                                }
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<CreditCardChargeEdit> inputName="cvv" labelText="CVV">
                        <input
                            {...register('cvv', {
                                pattern: {
                                    value:
                                        props.cardNetwork === CardNetwork.Amex
                                            ? /^[0-9]{4}$/
                                            : /^[0-9]{3}$/,
                                    message:
                                        props.cardNetwork === CardNetwork.Amex
                                            ? 'CVV for American Express must be 4 digits.'
                                            : 'CVV must be 3 digits.'
                                }
                            })}
                        />
                    </WrappedInput>
                </div>
                <div className="col-6">
                    {fields.map((field, index) => (
                        <WrappedInput<CreditCardChargeEdit>
                            key={field.id}
                            inputName={`editFees[${index}].value`}
                            labelText={field.name}
                        >
                            <input
                                {...register(`editFees.${index}.value`, {
                                    maxLength: MAX_TEXT_BOX,
                                    valueAsNumber: true
                                })}
                                defaultValue={field.value}
                            />
                        </WrappedInput>
                    ))}
                </div>
            </div>

            <hr></hr>

            <div className="clearFix">
                <input className="btn btn-primary float-left" type="submit" value="Charge Card" />
            </div>
        </div>
    );
};

export default ChargeCardFormInputs;
