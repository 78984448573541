import { DateTime } from 'luxon-business-days';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DraftFrequency } from '../../../../types/enums';
import {
    validateCurrency,
    validateDraftDate,
    validateNonNegative
} from '../../../../utils/validationRules';
import DatePicker from '../../../common/DatePicker';
import WrappedInput from '../../../common/form/WrappedInput';

const FeesFormInputs = (props:any) => {
    const { register, control,getValues } = useFormContext<any>();
    const { fields } = useFieldArray({
        control,
        name: 'editFees'
    });

    const [payments, setpayments] = useState(getValues('numberofPayments') ?? 1);
    useEffect(()=>{
        const element = document.getElementById('payments_text');
        element &&  element.addEventListener('change', () =>{
            const value = getValues('numberofPayments');
            setpayments(value);
            
        })
    },[])
    return (
       
        <div className="consumer-input-section">
            <div>
                
                <WrappedInput<any> inputName="scheduleDate" labelText="Schedule Start Date" required={true}>
                    <Controller
                        name="scheduleDate"
                        control={control}
                        rules={{
                            required: 'Please select a date.',
                            validate: validateDraftDate
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                isDateRange={false}
                                initialStartDate={value}
                                changeDate={onChange}
                                minimumDate={DateTime.utc().plusBusiness()}
                            />
                        )}
                    />
                </WrappedInput>
                
                <WrappedInput<any>  required={true} inputName="feeTypes" labelText="Fee Type">
                         <select
                            className="dropdown-select"
                            required={true}
                            disabled={props.viewOnly}
                            {...register('feeTypes')}>
                            {
                                props.feeTypes && props.feeTypes.map((type: any, index: any) =>{
                                    return  <option key={index} value={type.name}>{type.name}</option>
                                })
                            }

                        </select>
                </WrappedInput>
                <WrappedInput<any> required={true} inputName="vendorPayeeNames" labelText="Payee">
                       <select
                       required={true}
                       disabled={props.viewOnly}
                            className="dropdown-select"
                            {...register('vendorPayeeNames')}>
                            {
                                props.vendorPayeeNames && props.vendorPayeeNames.map((type: any, index: any) =>{
                                    return  <option key={index} value={type.name}>{type.name}</option>
                                })
                            }
                     </select>                        
                </WrappedInput>
                {<WrappedInput<any> required={true} inputName="numberofPayments" labelText="Number of Payments">
                    <input
                        id='payments_text'
                        type="number"
                        min={1}
                        required={true}
                        max={260}
                        readOnly={props.viewOnly}
                        {...register('numberofPayments', {
                            valueAsNumber: true,
                        })}
                    />
                </WrappedInput>}
               {payments > 1 && <WrappedInput<any>  required={true} inputName="PaymentsFrequency" labelText="Payments Frequency">
                         <select
                            className="dropdown-select"
                            required={true}
                            disabled={props.viewOnly}
                            {...register('paymentFrequency',
                            {
                                valueAsNumber:true
                            })}>
                            <option value={DraftFrequency.NotSet}>Not Set</option>
                            <option value={DraftFrequency.Daily}>Daily</option>
                            <option value={DraftFrequency.Weekly}>Weekly</option>
                            <option value={DraftFrequency.Biweekly}>Biweekly</option>
                            <option value={DraftFrequency.Monthly}>Monthly</option>
                            <option value={DraftFrequency.Bimonthly}>Bimonthly</option>
                            <option value={DraftFrequency.Quarterly}>Quarterly</option>
                            <option value={DraftFrequency.Semiannually}>Semiannually</option>
                            <option value={DraftFrequency.Annually}>Annually</option>
                        </select>
                </WrappedInput>}
                {props.showError && props.type!='Edit' && <span className='payment_error'>Payment Frequency is mandatory</span> }
                 <WrappedInput<any> required={true} inputName="feeAmount" labelText="FeeAmount">
                    <input
                        type="number"
                        required={true}
                        min={1}
                        step=".01"
                        readOnly={props.viewOnly}
                        {...register('feeAmount', {
                            validate: {
                                positive: validateNonNegative,
                                currency: validateCurrency
                            },
                            valueAsNumber: true
                        })}
                    />
                </WrappedInput>
                <WrappedInput<any> required={true}  inputName="description" labelText="Description">
                    <input
                        required={true}
                        type="text"
                        readOnly={props.viewOnly}
                        {...register('description')}
                    />
                </WrappedInput>
                <WrappedInput<any> inputName="externalID" labelText="External ID">
                    <input
                        type="text"
                        readOnly={props.viewOnly}
                        {...register('externalID')}
                    />
                </WrappedInput>

               
            </div>
            <hr></hr>
            <div className="clear-fix">
                <input className="btn btn-primary float-left"  disabled={props.viewOnly} type="submit" value="Save" />
            </div>
        </div>
    );
};

export default FeesFormInputs;
