/* eslint-disable no-template-curly-in-string */
import * as React from 'react';
import { MapsTooltip } from '@syncfusion/ej2-maps';
import {
    MapsComponent,
    LayersDirective,
    LayerDirective,
    ITooltipRenderEventArgs
} from '@syncfusion/ej2-react-maps';
import { Inject } from '@syncfusion/ej2-react-charts';
import { usMap } from '../../../assets/geojson/usa';
import { StateEnrollment } from '../../../types/dashboard';

interface Props {
    breakdownData: StateEnrollment[];
}

class StateBreakdownMap extends React.Component<Props, {}> {
    private mapInstance: MapsComponent | null;
    constructor(props: Props) {
        super(props);
        this.mapInstance = null;
    }

    componentDidUpdate() {
        this.mapInstance && this.mapInstance.refresh();
    }

    componentDidMount() {
        this.mapInstance && this.mapInstance.refresh();
        this.forceUpdate();
    }

    componentWillUnmount() {
        this.mapInstance?.destroy();
        this.mapInstance = null;
    }

    render() {
        return (
            <MapsComponent
                id="maps"
                tooltipRender={this.tooltipRender.bind(this)}
                ref={m => (this.mapInstance = m)}
                legendSettings={{
                    visible: false
                }}
                titleSettings={{
                    textStyle: {
                        color: '#000033'
                    },
                    text: 'State by State Breakdown',
                    alignment: 'Near'
                }}
            >
                <Inject services={[MapsTooltip]} />
                <LayersDirective>
                    <LayerDirective
                        shapeData={usMap}
                        shapeDataPath="stateInitials"
                        shapePropertyPath="iso_3166_2"
                        dataSource={this.props.breakdownData}
                        shapeSettings={{
                            fill: '#3182D6',
                            border: {
                                width: 1,
                                color: 'white'
                            }
                        }}
                        tooltipSettings={{
                            visible: true,

                            valuePath: 'iso_3166_2',
                            template:
                                '<div id="tooltip">' +
                                '<div class="title">${name}</div>' +
                                '<div class="breakdown">' +
                                '<div class="label">' +
                                '<div>Enrollments Total: </div>' +
                                '<div>Current Active: </div>' +
                                '<div>Enrolled Last 30 Days: </div>' +
                                '<div>Total Rev Last 30 Days: </div>' +
                                '<div>Total Rev Last 365 Days: </div>' +
                                '</div>' +
                                '<div class="value">' +
                                '<div>${totalEnrollmentsCount}</div>' +
                                '<div>${activeConsumersCount}</div>' +
                                '<div>${enrollmentsCountLastMonth}</div>' +
                                '<div>$${revenueLastMonth}</div>' +
                                '<div>$${revenueLastYear}</div>' +
                                '</div>' +
                                '</div>' +
                                '</div>'
                        }}
                    ></LayerDirective>
                </LayersDirective>
            </MapsComponent>
        );
    }

    public tooltipRender(args?: ITooltipRenderEventArgs): void {
        if (args && args.options && !(args.options as any)['data']) {
            args.cancel = true;
        }
    }
}

export default StateBreakdownMap;
