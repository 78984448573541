import * as React from 'react';
import {
    CheckBoxChangeEventArgs,
    ColumnDirective,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,
    Edit,
    ExcelExport,
    ExcelQueryCellInfoEventArgs,
    FilterSettingsModel,
    Filter,
    GridComponent,
    Inject,
    PdfExport,
    PdfQueryCellInfoEventArgs,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    Toolbar,
    Sort,
    Grid,
    Resize,
    Aggregate
} from '@syncfusion/ej2-react-grids';
import { ConsumerContext, FeeContext } from '../ConsumerPage';
import {GridViewContext} from '../../../GridViewService';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
    formatDate,
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';

interface Props {
    forecasts: any[];
    setSelectedFees: (newSelectedFees: any[]) => void;
}

const ConsumerForecastsGrid = (props: Props) => {
    const consumer = useContext(ConsumerContext);    
    const fees = useContext(FeeContext);
    const { addToast } = useToasts();
    const gridOptions = useCommonGridOptions('ConsumerForecasts');
    const feesGrid = gridOptions && gridOptions.ref;
    const [showRefundConfirmation, setShowRefundConfirmation] = useState(false);
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };



    useEffect(() => {
        feesGrid.current?.refresh();
    }, [props.forecasts, feesGrid]);

    /** Api Definitions **/
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    /** Grid Option Definitions **/
    const commands: CommandModel[] = [
        { title: 'Refund', buttonOption: {  cssClass:'e-outline refund-icon'  }}
    ];
    const onRowDataBound = (args?: RowDataBoundEventArgs) => {
        //Add custom classes to rows based on their value
        if (args && args.data) {
            let consumerFeeData = args.data as any;
            if (consumerFeeData.paymentStatus !== 'Scheduled') {
                args.row
                    ?.getElementsByClassName('e-gridchkbox')[0]
                    .classList.add('disabled-checkbox');
                args.row
                    ?.getElementsByClassName('e-checkbox-wrapper')[0]
                    .classList.add('disabled-checkbox');
                args.row?.classList.add('disabled-row');
            }
        }
    };

    const onRowSelected = () => {
        if (feesGrid && feesGrid.current) {
            // Get the selected records and send them to the parent
            const selectedrecords = feesGrid.current.getSelectedRecords() as any;
            props.setSelectedFees(selectedrecords);
        }
    };

    const onCheckboxChange = (args?: CheckBoxChangeEventArgs) => {
        //Prevent the select all button from selected rows which have been marked as disabled
        if (
            args &&
            args.checked &&
            args.target?.classList.contains('e-checkselectall') &&
            args.selectedRowIndexes &&
            feesGrid &&
            feesGrid.current
        ) {
            var customsSelect = [];
            for (var i = 0; i < args.selectedRowIndexes.length; i++) {
                var row = feesGrid.current.getRowByIndex(args.selectedRowIndexes[i]);
                if (!row.querySelector('.disabled-checkbox')) {
                    customsSelect.push(args.selectedRowIndexes[i]);
                }
            }
            feesGrid.current.selectRows(customsSelect);
        }
    };

    const setExportCellStyle = (
        propName: string,
        args?: PdfQueryCellInfoEventArgs | ExcelQueryCellInfoEventArgs
    ) => {
        if (!args || !args.data || !args.column) {
            return;
        }
    };

    const customExcelQueryCellInfo = (args?: ExcelQueryCellInfoEventArgs) => {
        gridOptions.excelQueryCellInfo(args);
        setExportCellStyle('fontColor', args);
    };

    const customPdfQueryCellInfo = (args?: PdfQueryCellInfoEventArgs) => {
        gridOptions.pdfQueryCellInfo(args);
        setExportCellStyle('textBrushColor', args);
    };

    const { currentView } = React.useContext(GridViewContext);
    let grid:Grid|null
        let dataBound = () =>{
            if(gridOptions.ref.current && currentView === 2)
            {
                gridOptions.ref.current.autoFitColumns([])
            }
        }
     const footerSum = (props: any): any  =>{
            return(<span>
                    {props.Sum}
                </span>)
                };
     
     const footerText = () => {
        return( <span>
                    Total
                </span> )
                };
        
    return (
        <div>
            <GridComponent
                {...gridOptions}
               className={`e-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid'} ${currentView === 2 ?  'comfort-grid-view' : 'default-grid-view'}`}
                dataBound= { dataBound } 
                //Remove [] when data is pulled from DB
                dataSource={props.forecasts}
                // commandClick={onCommandClick}
                rowDataBound={onRowDataBound}
                filterSettings={filterOptions}
                // selectionSettings={selectionSettings}
                rowSelected={onRowSelected}
                rowDeselected={onRowSelected}
                checkBoxChange={onCheckboxChange}
                allowSorting={false}
                allowFiltering={true}
                excelQueryCellInfo={customExcelQueryCellInfo}
                pdfQueryCellInfo={customPdfQueryCellInfo}
                clipMode='EllipsisWithTooltip'
            >
                <ColumnsDirective>
                    <ColumnDirective
                        type="checkbox"
                        width="1%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="transactionDate"
                        valueAccessor={vaWrapper(formatDate)}
                        headerText="Transaction Date"
                        width="4%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="transactionType"
                        headerText="Transaction Type"
                        width="6%"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="transactionAmount"
                        headerText="Transaction Amount"
                        width="5%"
                        format="C2"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    <ColumnDirective
                        field="forcastTotals"
                        headerText="Forecast Amount"
                        width="5%"
                        format="C2"
                        textAlign="Left"
                        allowFiltering={false}
                    />
                    
                </ColumnsDirective>
                <Inject services={[Edit, CommandColumn,Aggregate, PdfExport, ExcelExport, Toolbar, Filter, Sort, Resize]} />
            </GridComponent>
           
        </div>
    );
};

export default ConsumerForecastsGrid;
