import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../../types/common';
import WrappedForm from '../../../../common/form/WrappedForm';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ConsumerContext } from '../../ConsumerPage';
import { addSettlement, saveCreditorsAddress, updateSettlement } from '../../../../../api/consumerApi';
import { useToasts } from 'react-toast-notifications';
import LoadingDisplay from '../../../../common/LoadingDisplay';
import { Creditor, Settlement, SettlementEdit } from '../../../../../types/settlements';
import SettlementEditFormInputs from '../form/SettlementEditFormInputs';
import {
    getSettlementEditFromSettlement,
    getSettlementUpdateFromSettlementEdit
} from '../../../../../utils/settlementUtils';

interface Props extends ModalProps {
    settlement: Settlement;
    refreshGrid: () => void;
    viewOnly: boolean;
    onEditCreditor: (creditor?: Creditor) => void;
}

const SettlementEditModal = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const consumer = useContext(ConsumerContext);
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [ showEditAddress, setShowEditAddress ] = useState(false);
    const [ saveAddress, setSaveAddress ] = useState(false);
    
    const onSubmit = async (settlement: SettlementEdit) => {
        let uid;
        let settlementUpdate;
        if(saveAddress){
              const payeeAddress = {
                "payeeName": settlement?.creditorName,
                "payeeAddress":settlement?.payeeMailingDetails?.payeeAddress?.streetAddress,
                "payeeAddress2": settlement?.payeeMailingDetails?.payeeAddress.streetAddress2,
                "payeeCity": settlement?.payeeMailingDetails?.payeeAddress.city,
                "payeeState": settlement?.payeeMailingDetails?.payeeAddress.state,
                "payeeZIP":settlement?.payeeMailingDetails?.payeeAddress.zip,
                "uid": 0,
                "requestedVendor": 0,
                "status": 1,
                "createdDateTime": new Date().toISOString(),
                "createdBy":""
              }
              let res: any = await saveCreditorsAddress(payeeAddress);
              uid = res.data.creditorAddressUid;
              settlementUpdate = getSettlementUpdateFromSettlementEdit(settlement, uid);
        }
        else{
            settlementUpdate = getSettlementUpdateFromSettlementEdit(settlement);
        }

        //Submit the DraftEdit to the API
        // Display loading spinner while submitted
        setLoading(true);
        let result;
        //Use add consumer endpoint if new and edit consumer endpoint if existing
        if (props.settlement.id === 0) {
            result = await addSettlement(consumer.consumerID, settlementUpdate);
        } else {
            result = await updateSettlement(
                consumer.consumerID,
                props.settlement.id,
                settlementUpdate
            );
        }
        if (result.error) {
            setApiError(result.error);
        } else {
            props.setShowDialog(false);
            addToast(props.settlement.id === 0 ? 'Settlement Added' : 'Settlement Updated', {
                appearance: 'success',
                autoDismiss: true
            });
        }
        setLoading(false);

        if (!result.error) {
            props.refreshGrid();
        }
    };

    return (<>
        <DialogComponent
            header={
                props.settlement.id === 0 ? 'Add Consumer Settlement' : 'Edit Consumer Settlement'
            }
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
            }}
            width="1400px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <WrappedForm<SettlementEdit>
                        initialValues={getSettlementEditFromSettlement(props.settlement, consumer)}
                        onSubmit={onSubmit}
                    >
                        <div>
                            {loading ? (
                                <LoadingDisplay />
                            ) : (
                                <SettlementEditFormInputs
                                    type={props.settlement.id === 0 ? 'add' : 'edit'}
                                    status = {props.settlement.uidStatus}
                                    viewOnly={props.viewOnly}
                                    onEditCreditor={props.onEditCreditor}
                                    saveAdddress={(val)=>{
                                        setSaveAddress(val)
                                    }}
                                />
                            )}
                        </div>
                    </WrappedForm>
                )}
            </div>
        </DialogComponent></>
    );
};

export default SettlementEditModal;
