import * as React from 'react';
import '../../../../styles/views/Home.scss';
import { DashboardReturn } from '../../../../types/dashboard';
import { DateTime as LuxonDateTime } from 'luxon';
import { consumerSection } from '../../../../pages';
import { useHistory } from 'react-router-dom';

interface Props {
    return: DashboardReturn;
}

const ReturnView = (props: Props) => {
    const history = useHistory();

    return (
        <li
            className="card"
            onClick={() => {
                history.push(`/${consumerSection.extension}/${props.return.consumerId}`);
            }}
        >
            <div className="top-flex-container">
                <div className="consumer-id">{`ID: ${props.return.consumerId}`}</div>
                <div className="return-reason">{props.return.returnReason}</div>
            </div>
            <div className="bottom-flex-container">
                <div>
                    <div className="consumer-name">{props.return.consumerName}</div>
                    <div className="affiliate-id">{props.return.affiliateId}</div>
                </div>
                <div>
                    {/* Remove time component from DateTime */}
                    <div className="date">
                        {LuxonDateTime.fromISO(props.return.scheduledDate).toISODate()}
                    </div>
                    <div className="amount">{`$${props.return.amount}`}</div>
                </div>
            </div>
        </li>
    );
};

export default ReturnView;
