import * as React from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../api/baseApi';
import { getCompanySettings } from '../api/companyApi';
import { CompanySettings } from '../types/company';
import LoadFailedDisplay from './common/LoadFailedDisplay';
import LoadingDisplay from './common/LoadingDisplay';

interface Props {
    children?: React.ReactNode;
}

export const CompanySettingsContext = createContext<CompanySettings>({} as CompanySettings);

export const CompanyService = (props: Props) => {
    const [companySettings, setCompanySettings] = useState<CompanySettings>();

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) =>
            error &&
            addToast(`Failed to load company settings (${error})`, {
                appearance: 'error',
                autoDismiss: true
            }),
        [addToast]
    );

    useEffect(() => {
        const apiWrapper = loadFromApi(setApiError, setLoading);
        const loadPageData = async () => {
            await apiWrapper(getCompanySettings, setCompanySettings);
        };        
        loadPageData();
    }, [setApiError]);

    return loading ? (
        <LoadingDisplay />
    ) : companySettings ?  (
        <CompanySettingsContext.Provider value={companySettings}>
            {props.children}
        </CompanySettingsContext.Provider>
    ) :
    <></>
};
