import * as React from 'react';
import { ConsumerContext, FeeGroupContext } from '../ConsumerPage';
import { useCallback, useContext, useEffect, useState } from 'react';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { useToasts } from 'react-toast-notifications';
import {
    Draft,
    DraftsCalculation,
    Schedule,
    ScheduleEdit,
    ScheduleFee
} from '../../../../types/drafts';
import { loadFromApi } from '../../../../api/baseApi';
import {
    getConsumerDrafts,
    getConsumerSchedule,
    updateConsumerSchedule
} from '../../../../api/consumerApi';
import { luxonDateToServerDate } from '../../../../utils/dateUtils';
import { DraftStatus, UserPermission } from '../../../../types/enums';
import '../../../../styles/common/Elements.scss';
import { generateSchedulePreview } from '../../../../utils/draftUtils';
import { BankAccount } from '../../../../types/common';
import ConsumerScheduleForm from './ConsumerScheduleForm';
import ConsumerScheduleInfo from './ConsumerScheduleInfo';
import ConsumerSchedulePreview from './ConsumerSchedulePreview';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { defaultSchedule } from '../../../../utils/defaultData';
import { DateTime } from 'luxon-business-days';
import { getConsumerBankAccount } from '../../../../api/consumerBankingInfoApi';
import { CompanySettingsContext } from '../../../CompanyService';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { useHistory } from 'react-router-dom';
import { pagePath, topLevelPages } from '../../../../pages';

const ConsumerScheduleTab = () => {
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);
    const history = useHistory();
    useEffect(() => {
        if (!hasEditConsumerPermissions) {
            history.push(`${pagePath(topLevelPages.home)}`);
        }
    }, [hasEditConsumerPermissions, history]);

    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);

    const consumer = useContext(ConsumerContext);
    const feeGroups = useContext(FeeGroupContext);

    const defaultRamFee = useContext(CompanySettingsContext).defaultMonthlyRamFee;

    const [consumerSchedule, setConsumerSchedule] = useState<Schedule>();

    const [draftsCalculation, setDraftsCalculation] = useState<DraftsCalculation>();
    const [existingDrafts, setExistingDrafts] = useState<Draft[]>();

    const [bankAccountName, setBankAccountName] = useState<string>();

    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const onSubmit = async (data: ScheduleEdit, enabledFeeGroupIds: number[]) => {
        setLoading(true);

        //Convert the ScheduleEdit object to a ScheduleUpdate object
        let { startDateTime, feeEdits, ...scheduleUpdate } = data;
        let fees: ScheduleFee[] = [];
        data.feeEdits.forEach(feeEdit => {
            if (enabledFeeGroupIds.includes(feeEdit.feeId)) {
                let { enabled, startDateTime, useFeeWeighting, ...fee } = feeEdit;
                fee.startDate = luxonDateToServerDate(feeEdit.startDateTime);
                if (!feeEdit.useFeeWeighting) {
                    fee.feeWeight = null;
                } else if (feeEdit.useFeeWeighting && fee.feeWeight) {
                    fee.feeWeight.weightDrafts = fee.feeWeight.weightDrafts || 0;
                    fee.feeWeight.weightPercentage = fee.feeWeight.weightPercentage || 0;
                }
                fees.push(fee);
            }
        });
        scheduleUpdate.monthlyDraft = scheduleUpdate.monthlyDraft || 0;
        scheduleUpdate.totalAllowed = scheduleUpdate.totalAllowed || 0;
        scheduleUpdate.feePercent = scheduleUpdate.feePercent || 0;
        scheduleUpdate.startDate = luxonDateToServerDate(data.startDateTime);
        scheduleUpdate.fees = fees;

        //Send the ScheduleUpdate to the Api
        const result = await updateConsumerSchedule(consumer.consumerID, scheduleUpdate);
        if (result.error) {
            setApiError(result.error);
        } else {
            addToast('Consumer Schedule Saved', { appearance: 'success', autoDismiss: true });
            //Because the server sets the last changed date and last changed by, pull the updated schedule back from the server
            refreshSchedule(true);
        }

        //Generate the schedule preview
        let draftCalculation = generateSchedulePreview(
            scheduleUpdate,
            feeGroups,
            existingDrafts || [],
            defaultRamFee || 0,
            bankAccountName || ''
        );
        if (draftCalculation.errorText) {
            addToast(draftCalculation.errorText, { appearance: 'error', autoDismiss: false });
        } else {
            setDraftsCalculation(draftCalculation);
            setShowPreviewModal(true);
        }

        setLoading(false);
    };

    const refreshSchedule = useCallback(
        (toggleLoading: boolean) => {
            const loadConsumerScheduleData = async () => {
                if (toggleLoading) {
                    setLoading(true);
                }

                let result = await getConsumerSchedule(consumer.consumerID);
                if (result.error) {
                    setApiError(result.error);
                } else {
                    if (result.data) {
                        setConsumerSchedule(result.data);
                    } else {
                        setConsumerSchedule({
                            ...defaultSchedule,
                            startDate: luxonDateToServerDate(
                                DateTime.utc().plusBusiness({ days: 2 })
                            )
                        });
                    }
                }

                if (toggleLoading) {
                    setLoading(false);
                }
            };

            loadConsumerScheduleData();
        },
        [setApiError, consumer.consumerID]
    );

    useEffect(() => {
        //Functions to pull Needed API information for calculating scheduled drafts
        const consumerScheduleApiWrapper = loadFromApi(setApiError);
        const loadDraftsData = async () => {
            const existingDraftsLoad = consumerScheduleApiWrapper(
                () => getConsumerDrafts(consumer.consumerID),
                (drafts: Draft[]) =>
                    setExistingDrafts(drafts.filter(draft => draft.status === DraftStatus.Posted))
            );
            const accountNameLoad = consumerScheduleApiWrapper(
                () => getConsumerBankAccount(consumer.consumerID),
                (bankAccount: BankAccount) => setBankAccountName(bankAccount.bankName)
            );

            await existingDraftsLoad;
            await accountNameLoad;
        };

        const loadConsumerScheduleData = async () => {
            setLoading(true);
            await Promise.all([refreshSchedule(false), loadDraftsData()]);
            setLoading(false);
        };

        loadConsumerScheduleData();
    }, [refreshSchedule, setApiError, consumer.consumerID]);

    return (
        <div className="consumer-input-section">
            {loading ? (
                <LoadingDisplay />
            ) : consumerSchedule ? (
                <div>
                    <div className="clear-fix">
                        <h3 className="float-left">Schedule</h3>
                        <ConsumerScheduleInfo />
                    </div>
                    <ConsumerScheduleForm
                        consumerSchedule={consumerSchedule}
                        savingEnabled={
                            existingDrafts !== undefined &&
                            defaultRamFee !== undefined &&
                            bankAccountName !== undefined
                        }
                        onSubmit={onSubmit}
                    />
                    <ConsumerSchedulePreview
                        consumerSchedule={consumerSchedule}
                        draftsCalculation={draftsCalculation}
                        showPreviewModal={showPreviewModal}
                    />
                </div>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default ConsumerScheduleTab;
