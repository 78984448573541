import * as React from 'react';
import '../../../../styles/views/Home.scss';
import '../../../../styles/common/scrollbar.scss';
import { useCallback, useEffect, useState } from 'react';
import ConsumerView from './ConsumerView';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';
import { Consumer } from '../../../../types/consumer';
import { getDashboardNewConsumers } from '../../../../api/dashboardApi';
import { DateTime as LuxonDateTime } from 'luxon';

interface Props {
    viewAllNewConsumers: boolean;
    toggleViewAllNewConsumers: () => void;
}

const RecentlyCreatedConsumersView = (props: Props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const [consumers, setConsumers] = useState<Consumer[]>();
    const numConsumersVisibleDefault = 5;
    const numConsumersVisibleMax = 50;

    useEffect(() => {
        const loadReturns = async () => {
            setApiLoading(true);
            const response = await getDashboardNewConsumers();
            if (response.error) {
                setApiError(response.error);
            } else if (response.data) {
                setConsumers(response.data);
            }
            setApiLoading(false);
        };
        loadReturns();
    }, [setApiError]);

    return (
        <div>
            <label className="title-container-sidebar">
                Recently Created Consumers
                {!apiLoading && consumers && consumers.length > numConsumersVisibleDefault && (
                    <span>
                        <button
                            className="red-text-button"
                            onClick={() => props.toggleViewAllNewConsumers()}
                        >
                            {props.viewAllNewConsumers ? 'View fewer' : 'View all'}
                        </button>
                    </span>
                )}
            </label>
            {apiLoading ? (
                <LoadingDisplay />
            ) : consumers ? consumers.length === 0 ? (
                <p>Nothing to display</p>
            ) : (
                <ul className={props.viewAllNewConsumers ? 'view-all' : 'view-some'}>
                    {consumers
                        .sort(
                            (a, b) =>
                                LuxonDateTime.fromISO(b.addDate).toMillis() -
                                LuxonDateTime.fromISO(a.addDate).toMillis()
                        )
                        .filter(
                            (c, index) =>
                                (props.viewAllNewConsumers && index <= numConsumersVisibleMax) ||
                                index < numConsumersVisibleDefault
                        )
                        .map((c, index) => (
                            <ConsumerView key={index} consumer={c} />
                        ))}
                </ul>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default RecentlyCreatedConsumersView;
