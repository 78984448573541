import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { getConsumerNotes, resetConsumerPassword } from '../../../../api/consumerApi';
import { ConsumerNote } from '../../../../types/consumer';
import { nameToFormattedString } from '../../../../utils/stringUtils';
import MessagePopup from '../../../common/MessagePopup';
import ConsumerNotesModal from './ConsumerNotesModal';
import ConsumerHeader from './ConsumerHeader';
import ConsumerAccountProfile from './ConsumerAccountProfile';
import { ConsumerContext } from '../ConsumerPage';
import { useToasts } from 'react-toast-notifications';

const ConsumerGeneralInformation = () => {
    const consumer = useContext(ConsumerContext);
    const [consumerNotes, setConsumerNotes] = useState<ConsumerNote[]>();
    const [showConsumerNotesModal, setShowConsumerNotesModal] = useState(false);
    const [showResetPasswordConfirmation, setShowResetPasswordConfirmation] = useState(false);
    const [consumerNotesApiError, setConsumerNotesApiError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const onResetPassword = async () => {
        const result = await resetConsumerPassword(consumer.consumerID);
        if (result.error) {
            addToast(result.error, { appearance: 'error', autoDismiss: true });
        } else {
            addToast('Reset password successful.', { appearance: 'success', autoDismiss: true });
        }
    };

    useEffect(() => {
        const loadConsumerNotes = async () => {
            setLoading(true);
            const result = await getConsumerNotes(consumer.consumerID);
            setConsumerNotes(result.data);
            if (result.error) {
                setConsumerNotesApiError(result.error);
            } else {
                setConsumerNotesApiError(undefined);
            }
            setLoading(false);
        };

        loadConsumerNotes();
    }, [consumer.consumerID]);

    return (
        <div className="consumer-general-information">
            <ConsumerHeader />
            <ConsumerAccountProfile
                setShowConsumerNotesModal={setShowConsumerNotesModal}
                setShowResetPasswordConfirmation={setShowResetPasswordConfirmation}
            />
            <ConsumerNotesModal
                targetId="consumerPage"
                consumerNotes={consumerNotes}
                errorMessage={consumerNotesApiError}
                loading={loading}
                showDialog={showConsumerNotesModal}
                consumerName={nameToFormattedString(
                    consumer.contactInfo.primary.firstName,
                    consumer.contactInfo.primary.middleInitial,
                    consumer.contactInfo.primary.lastName
                )}
                setShowDialog={setShowConsumerNotesModal}
            />
            <MessagePopup
                targetId="consumerPage"
                headerText="Confirm Password Reset"
                message="Are you sure you want to reset the client's password?"
                showDialog={showResetPasswordConfirmation}
                setShowDialog={setShowResetPasswordConfirmation}
                requireConfirmation={true}
                onConfirmClick={onResetPassword}
            />
        </div>
    );
};

export default ConsumerGeneralInformation;
