import { User, UserCreate, UserPermissionsUpdate, UserPreferredView } from '../types/user';
import { httpRequest, Urls } from './baseApi';

const USER_URL = `${Urls.BASE_URL}/users`;

export const getUsers = () => httpRequest<User[]>(USER_URL);

export const createUser = (user: UserCreate) => httpRequest<User>(USER_URL, 'post', user);

export const updateUserLockout = (userId: string, isLockedOut: boolean) =>
    httpRequest<{}>(`${USER_URL}/${userId}/lockout?lockedOut=${isLockedOut}`, 'put');

export const updatePreferredView = (userId: string | null, selectedView: number) =>
    httpRequest<{}>(`${USER_URL}/${userId}/${selectedView}`, 'put');

export const resetUserPassword = (userId: string) =>
    httpRequest<{}>(`${USER_URL}/${userId}/resetPassword`, 'post');

export const getUserPermissions = (userId: string) =>
    httpRequest<UserPreferredView>(`${USER_URL}/${userId}`);

export const updateUserPermissions = async (userId: string, update: UserPermissionsUpdate) =>
    httpRequest<{}>(`${USER_URL}/${userId}`, 'put', update);
