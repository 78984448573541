import * as React from 'react';
import { FeeContext } from '../ConsumerPage';
import { useContext, useEffect, useState } from 'react';
import WrappedForm from '../../../common/form/WrappedForm';
import { Schedule, ScheduleEdit, ScheduleFeeEdit } from '../../../../types/drafts';
import ConsumerScheduleFormInputs from './form/ConsumerScheduleFormInputs';
import { luxonDateToServerDate, serverDateToLuxonDateTime } from '../../../../utils/dateUtils';
import { DateTime } from 'luxon-business-days';
import '../../../../styles/common/Elements.scss';
import { defaultSchedule } from '../../../../utils/defaultData';

export interface Props {
    consumerSchedule: Schedule;
    savingEnabled: boolean;
    onSubmit: (data: ScheduleEdit, enabledFeeGroupIds: number[]) => void;
}

// Display a consumer schedule form with initial values defined by the consumerSchedule object passed in.
// Component raises the save event up to the parent component.
// Saving can be conditionally enabled.
const ConsumerScheduleForm = (props: Props) => {
    const feeGroups = useContext(FeeContext);

    const [enabledFeeGroupIds, setEnabledFeeGroupIds] = useState<number[]>([]);

    function getScheduleEditFromSchedule(schedule: Schedule) {
        let { lastChanged, lastChangedUser, ...scheduleUpdate } = schedule;
        //Convert each ScheduleFee object to a ScheduleFee Edit
        let feeEdits: ScheduleFeeEdit[] = [];
        feeGroups.forEach(group => {
            let feeGroupValue = scheduleUpdate.fees.find(f => f.feeId === group.slot);
            if (feeGroupValue) {
                feeEdits.push({
                    ...feeGroupValue,
                    enabled: true,
                    startDateTime: serverDateToLuxonDateTime(feeGroupValue.startDate),
                    feeWeight: feeGroupValue.feeWeight
                        ? feeGroupValue.feeWeight
                        : { weightDrafts: 0, weightPercentage: 0 },
                    useFeeWeighting: feeGroupValue.feeWeight != null
                });
            } else {
                feeEdits.push({
                    feeId: group.slot,
                    totalAmount: 0,
                    startDate: '',
                    draftsCount: 0,
                    feeWeight: { weightDrafts: 0, weightPercentage: 0 },
                    enabled: false,
                    startDateTime: DateTime.utc().plusBusiness({ days: 2 }),
                    useFeeWeighting: false
                });
            }
        });
        //Build the ScheduleEdit object from the ScheduleUpdate object and the feeEdits
        let scheduleEdit = {
            ...scheduleUpdate,
            startDateTime: serverDateToLuxonDateTime(scheduleUpdate.startDate),
            feeEdits: feeEdits
        };
        return scheduleEdit;
    }

    useEffect(() => {
        setEnabledFeeGroupIds(
            props.consumerSchedule ? props.consumerSchedule.fees.map(f => f.feeId) : []
        );
    }, [props.consumerSchedule]);

    return (
        <div>
            <WrappedForm<ScheduleEdit>
                initialValues={getScheduleEditFromSchedule(props.consumerSchedule)}
                onSubmit={(data: ScheduleEdit) => props.onSubmit(data, enabledFeeGroupIds)}
            >
                <ConsumerScheduleFormInputs
                    cleanInputs={getScheduleEditFromSchedule({
                        ...defaultSchedule,
                        startDate: luxonDateToServerDate(DateTime.utc().plusBusiness({ days: 2 }))
                    })}
                    enabledFeeGroupIds={enabledFeeGroupIds}
                    onEnableFeeGroupChange={setEnabledFeeGroupIds}
                    savingEnabled={props.savingEnabled}
                />
            </WrappedForm>
            <div>
                <div className="wrapped-info">
                    <label>Last Changed Date:</label>
                    <span className="value">{props.consumerSchedule?.lastChanged}</span>
                </div>
                <div className="wrapped-info">
                    <label>Last Changed By:</label>
                    <span className="value">{props.consumerSchedule?.lastChangedUser}</span>
                </div>
            </div>
        </div>
    );
};

export default ConsumerScheduleForm;
