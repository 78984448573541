import { DateTime } from 'luxon-business-days';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { loadFromApi } from '../../../../api/baseApi';
import { deleteConsumerFees, getConsumeForecasts, getConsumerFees, getConsumerFeeTypes, getVendorPayeeNames } from '../../../../api/consumerApi';
import '../../../../styles/views/Consumer.scss';
import {  UserPermission } from '../../../../types/enums';
import { luxonDateToServerDate, serverDateToLuxonDateTime } from '../../../../utils/dateUtils';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import LoadingDisplay from '../../../common/LoadingDisplay';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { ConsumerContext } from '../ConsumerPage';
import ConsumerForecastsGrid from './ConsumerForecastsGrid';
import ConsumerFeesGrid from './ConsumerForecastsGrid';

const ConsumerForecastTab = () => {
    const consumer = useContext(ConsumerContext);
    const [loading, setLoading] = useState(false);
    const [forecasts, setForecasts] = useState<any>([]);
    // const [feeTypes, setFeeTypes] = useState<any>([]);
    const [payeeTypes, setPayeeTypes] = useState<any>([]);
    const [vendorPayeeNames, setVendorPayeeNames] = useState<any>([]);
    const [selectedFees, setSelectedFees] = useState<any[]>([]);
   
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasEditConsumerPermissions = userHasPermission(UserPermission.EditConsumers);

    const defaultFees: any = {
        id: 0,
        "clientId": 0,
        "feeAmount": 0,
        vendorPayeeNames: payeeTypes.length  === 1 ? payeeTypes[0].relabel: '',
        "numberofPayments": 1,
        "paymentFrequency": 0,
        "description": "",
        "externalID": "",
        "tCode": "",
        feeTypes: "",
        "tSubCode": "",
        "scheduleDate": serverDateToLuxonDateTime(luxonDateToServerDate(DateTime.utc().plusBusiness({ days: 2 }))),
    };

    const onDeleteFees = async () => {
        let selectedFeesIds = selectedFees.map(fees => fees.id);
        let error = false;
        const result = await deleteConsumerFees(consumer.consumerID, selectedFeesIds);
        if (result.error && !error) {
            setApiError(result.error);
            error = true; //Prevent errors from pilling up for bulk deletes
        }
        else if(result.data){
            addToast('Deleted Successfully', {
                appearance:'success',
                autoDismiss: true
            })
        }
        refreshGrid();
    };


    const refreshGrid = useCallback(() => {
        const consumerPageApiWrapper = loadFromApi(
            error => error && addToast(error, { appearance: 'error', autoDismiss: true })
        );
        const loadConsumerFees = async () => {
            setLoading(true);
            const consumerForecastsLoad = consumerPageApiWrapper(
                () => getConsumeForecasts(consumer.consumerID),
                setForecasts
            );
            await consumerForecastsLoad;
            setSelectedFees([]); 
            setLoading(false);
        };

        loadConsumerFees();
    }, [addToast, consumer.consumerID]);


   
    // useEffect(()=>{
    //     const consumerPageApiWrapper = loadFromApi(
    //         error => error && addToast(error, { appearance: 'error', autoDismiss: true })
    //     );
    //     const getFeeTypes = async () => {
    //         const consumeFeeTypesLoad = consumerPageApiWrapper(
    //             () => getConsumerFeeTypes(),
    //             setFeeTypes
    //         );
    //         await consumeFeeTypesLoad;
    //         const consumPayeeTypesLoad = consumerPageApiWrapper(
    //             () => getVendorPayeeNames(),
    //             setPayeeTypes
    //         );
    //         await consumPayeeTypesLoad;
    //     };

    //     getFeeTypes();

    // }, [])

    // useEffect(()=>{
    //     const consumerPageApiWrapper = loadFromApi(
    //         error => error && addToast(error, { appearance: 'error', autoDismiss: true })
    //     );
    //     const getPayeeNames = async () => {
    //         const loadVendorPayeeNames = consumerPageApiWrapper(
    //             () => getVendorPayeeNames(),
    //             setVendorPayeeNames
    //         );
    //         await loadVendorPayeeNames;
    //     };

    //     getPayeeNames();

    // }, [])

    useEffect(() => {
        refreshGrid();
    }, [refreshGrid, addToast, consumer.consumerID]);

    return (
        <div className="consumer-input-section Drafts-tab Fees-tab">
            <h3>Forecast</h3>
            {loading ? (
                <LoadingDisplay />
            ) : !consumer || !forecasts ? (
                <LoadFailedDisplay />
            ) : (
                <div>
                    <ConsumerForecastsGrid
                        forecasts={forecasts}
                        setSelectedFees={setSelectedFees}
                       
                    />
                    
                </div>
            )}
        </div>
    );
};

export default ConsumerForecastTab;
