import * as React from 'react';
import { useEffect } from 'react';
import '../../../styles/components/CheckboxArray.scss';
import { updateSelectedOptionsArray } from '../../../utils/objectUtils';

interface CheckboxArrayOption {
    id: number;
    display: string;
}

interface Props {
    checkboxArrayId: string;
    options: CheckboxArrayOption[];
    selectedOptions: number[];
    setSelectedOptions: (newSelectedOptions: number[]) => void;
}

const CheckboxArray = (props: Props) => {
    const { options, selectedOptions, setSelectedOptions } = props;

    useEffect(() => {
        ///Validate the options are valid on load
        let newOptions: number[] = [];
        let initValuesInvalid = false;
        selectedOptions && selectedOptions.forEach(option => {
            if (options.find(o => o.id === option)) {
                newOptions.push(option);
            } else {
                initValuesInvalid = true;
            }
        });
        if (initValuesInvalid) {
            setSelectedOptions(newOptions);
        }
    }, [selectedOptions, options, setSelectedOptions]);

    return (
        <div className="checkbox-array">
            <ul>
                {props.options.map(option => (
                    <li className="checkbox-array-item clear-fix" key={option.id}>
                        <label
                            htmlFor={props.checkboxArrayId + '_' + option.id}
                            className="clickable"
                        >
                            {option.display}
                        </label>
                        <input
                            id={props.checkboxArrayId + '_' + option.id}
                            className="default-checkbox"
                            type="checkbox"
                            checked={props.selectedOptions?.includes(option.id)}
                            onChange={e =>
                                props.setSelectedOptions(
                                    updateSelectedOptionsArray(
                                        option.id,
                                        e.target.checked,
                                        props.selectedOptions
                                    )
                                )
                            }
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CheckboxArray;
