import * as React from 'react';
import {
    ChangedEventArgs,
    DatePickerComponent,
    DateRangePickerComponent,
    RangeEventArgs
} from '@syncfusion/ej2-react-calendars';
import { DateTime as LuxonDateTime } from 'luxon';
import { luxonDateToJSDate } from '../../utils/dateUtils';
import { DateRange } from '../../types/common';

interface DatePickerProps {
    isDateRange: boolean;
    initialStartDate: LuxonDateTime;
    initialEndDate?: LuxonDateTime;
    changeDate?: (date: LuxonDateTime | null) => void;
    changeDateRange?: (dateRange: DateRange) => void;
    minimumDate?: LuxonDateTime;
    maximumDate?: LuxonDateTime;
}

export default class DatePicker extends React.Component<DatePickerProps, {}> {
    private startDate: Date | undefined = this.props.initialStartDate && luxonDateToJSDate(this.props.initialStartDate, true);
    private endDate: Date | undefined = this.props.initialEndDate
        ? luxonDateToJSDate(this.props.initialEndDate, true)
        : undefined;

    private handleRangeChange = (arg?: RangeEventArgs | undefined) => {
        if (arg && arg.startDate && arg.endDate) {
            if (arg.startDate <= arg.endDate && this.props.changeDateRange) {
                this.props.changeDateRange({
                    start: LuxonDateTime.fromJSDate(arg.startDate),
                    end: LuxonDateTime.fromJSDate(arg.endDate)
                });
            }
        }
    };

    private handleDateChange = (arg?: ChangedEventArgs | undefined) => {
        if (arg && this.props.changeDate) {
            if (arg.value) {
                this.props.changeDate(LuxonDateTime.fromJSDate(arg.value));
            } else {
                this.props.changeDate(null);
            }
        }
    };

    public render() {
        return (
            <div>
                {this.props.isDateRange ? (
                    <DateRangePickerComponent
                        placeholder="Select date range"
                        startDate={this.startDate}
                        endDate={this.endDate}
                        change={this.handleRangeChange}
                        showClearButton={false}
                        serverTimezoneOffset={0}
                        min={
                            this.props.minimumDate
                                ? luxonDateToJSDate(this.props.minimumDate, true)
                                : undefined
                        }
                        max={
                            this.props.maximumDate
                                ? luxonDateToJSDate(this.props.maximumDate, true)
                                : undefined
                        }
                    />
                ) : (
                    <DatePickerComponent
                        placeholder="Select date"
                        value={this.startDate}
                        change={this.handleDateChange}
                        serverTimezoneOffset={0}
                        min={
                            this.props.minimumDate
                                ? luxonDateToJSDate(this.props.minimumDate, true)
                                : undefined
                        }
                        max={
                            this.props.maximumDate
                                ? luxonDateToJSDate(this.props.maximumDate, true)
                                : undefined
                        }
                    />
                )}
            </div>
        );
    }
}
