import * as React from 'react';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { uploadCardAuthorization } from '../../../../../api/consumerBankingInfoApi';
import { ConsumerContext } from '../../ConsumerPage';
import '../../../../../styles/views/Consumer.scss';

interface Props {
    cardId: number;
    refreshConsumerCards: () => void;
}

function CreditCardAuthorization(props: Props) {
    const consumer = useContext(ConsumerContext);

    const [authFile, setAuthFile] = useState<File>();

    const [loadingUploadAuth, setLoadingUploadAuth] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const handlePickFile = (e: ChangeEvent<HTMLInputElement>) => {
        setAuthFile(undefined);
        e.target.files && setAuthFile(e.target.files[0]);
    };

    const handleUploadFile = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (authFile) {
            setLoadingUploadAuth(true);
            const response = await uploadCardAuthorization(
                consumer.consumerID,
                props.cardId,
                authFile
            );
            if (response.error) {
                setApiError(`Failed to add card authorization (${response.error})`);
            } else {
                addToast('Card authorization added', { appearance: 'success', autoDismiss: true });
                props.refreshConsumerCards();
            }
            setLoadingUploadAuth(false);
        }
    };

    return (
        <div>
            {/* <label>Upload Authorization PDF:</label> */}
            <input className="file-picker" type="file" accept=".pdf" onChange={handlePickFile} />
            <button
                className="btn btn-secondary"
                onClick={handleUploadFile}
                disabled={authFile === undefined}
            >
                {loadingUploadAuth ? `Uploading Authorization...` : `Upload Authorization`}
            </button>
        </div>
    );
}

export default CreditCardAuthorization;
