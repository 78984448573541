import * as React from 'react';
import '../../../../styles/views/Home.scss';
import '../../../../styles/common/scrollbar.scss';
import { useCallback, useEffect, useState } from 'react';
import ReturnView from './ReturnView';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import { useToasts } from 'react-toast-notifications';
import { getDashboardReturns } from '../../../../api/dashboardApi';
import { DashboardReturn } from '../../../../types/dashboard';
import { DateTime as LuxonDateTime } from 'luxon';

interface Props {
    viewAllReturns: boolean;
    toggleViewAllReturns: () => void;
}

const ReturnsView = (props: Props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const [returns, setReturns] = useState<DashboardReturn[]>();
    const numReturnsVisibleDefault = 3;

    useEffect(() => {
        const loadReturns = async () => {
            setApiLoading(true);
            const response = await getDashboardReturns();
            if (response.error) {
                setApiError(response.error);
            } else if (response.data) {
                setReturns(response.data);
            }
            setApiLoading(false);
        };
        loadReturns();
    }, [setApiError]);

    return (
        <div>
            <label className="title-container-sidebar">
                Returns (last 3 days)
                {!apiLoading && returns && returns.length > numReturnsVisibleDefault && (
                    <span>
                        <button
                            className="red-text-button"
                            onClick={() => props.toggleViewAllReturns()}
                        >
                            {props.viewAllReturns ? 'View fewer' : 'View all'}
                        </button>
                    </span>
                )}
            </label>
            {apiLoading ? (
                <LoadingDisplay />
            ) : !returns ? (
                <LoadFailedDisplay />
            ) : returns.length === 0 ? (
                <p>Nothing to display</p>
            ) : (
                <ul className={props.viewAllReturns ? 'view-all' : 'view-some'}>
                    {returns
                        .sort(
                            (a, b) =>
                                LuxonDateTime.fromISO(a.scheduledDate).toMillis() -
                                LuxonDateTime.fromISO(b.scheduledDate).toMillis()
                        )
                        .filter(
                            (r, index) => props.viewAllReturns || index < numReturnsVisibleDefault
                        )
                        .map((r, index) => (
                            <ReturnView key={index} return={r} />
                        ))}
                </ul>
            )}
        </div>
    );
};

export default ReturnsView;
