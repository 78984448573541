import * as React from 'react';
import { ReactNode } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { getPropertyByString } from '../../../utils/objectUtils';
import InfoTooltip from '../InfoTooltip';

interface Props<T> {
    inputName: keyof T | string;
    labelText: string;
    helpText?: string;
    children?: ReactNode;
    required?: boolean;
}

const WrappedInput = <T,>(props: Props<T>) => {
    const {
        formState: { errors }
    } = useFormContext<T>();

    const error =
        (errors[props.inputName] as FieldError | undefined) ??
        (getPropertyByString(errors, props.inputName as string) as FieldError | undefined);

    return (
        <div className="wrapped-input-container">
            <div className="wrapped-input">
                <label
                    htmlFor={props.inputName.toString()}
                    className={`${props.required ? 'required' : ''}`}
                >
                    {props.labelText}
                </label>
                {props.children}
                {props.helpText && <InfoTooltip text={props.helpText} />}
            </div>
            <div className="error-message">
                <span>{error && error.message}</span>
            </div>
        </div>
    );
};

export default WrappedInput;
