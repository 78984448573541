import * as React from 'react';

var usStateInitials = [
    '', //force select
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
];

var payeeTerritories = [
    'Guam',
    'Puerto Rico',
    'American Samoa',
    'AF Europe',
    'AF Pacific',
    'AF Americas'
];

export const unsupportedConsumerStates = ['CT', 'NJ'];

interface Props {
    required: boolean;
    selectedState: string;
    setSelectedState: (selectedState: string | null) => void;
    includePayeeTerritories: boolean;
    readOnly?: boolean;
}

const StateDropdown = (props: Props) => {
    return (
        <select
            disabled={props.readOnly}
            className="dropdown-select"
            value={props.selectedState || (props.required ? usStateInitials[0] : '')}
            onChange={e => props.setSelectedState(e.currentTarget.value)}
        >
            {!props.required && <option value={''}>Select...</option>}
            {props.includePayeeTerritories
                ? [...usStateInitials, ...payeeTerritories].map((state, index) => (
                      <option key={index} value={state}>
                          {state}
                      </option>
                  ))
                : usStateInitials.map((state, index) => (
                      <option key={index} value={state}>
                          {state}
                      </option>
                  ))}
        </select>
    );
};

export default StateDropdown;
