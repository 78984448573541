import * as React from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,    
    ExcelExport,
    GridComponent,
    Inject,
    PdfExport,
    Toolbar,
    FilterSettingsModel,
    Filter,
    Sort,
    Grid,
    Resize
} from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';
import {
    formatDate,
    InternalValueAccessor,
    useCommonGridOptions,
    vaWrapper
} from '../../../../utils/gridUtils';
import { Consumer } from '../../../../types/consumer';
import { Address } from '../../../../types/common';
import ConsumerQuickInfoPopup from './ConsumerQuickInfoPopup';
import '../../../../styles/views/Consumer.scss';
import { useConsumerNavigation } from '../../../../hooks/useConsumerNavigation';
import {GridViewContext} from '../../../GridViewService';

interface Props {
    consumers: Consumer[];    
}

const ConsumerSearchResultsGrid = (props: Props) => {
    const navigateToConsumerPage = useConsumerNavigation();
    const gridOptions = useCommonGridOptions('ConsumerSearchResults');
    const consumersGrid = gridOptions && gridOptions.ref;

    const [quickInfoPopupConsumer, setQuickInfoPopupConsumer] = useState<Consumer>();
    const [showPopup, setshowPopup] = useState(false);
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };

    useEffect(() => {
        consumersGrid.current?.refresh();
    }, [props.consumers, consumersGrid]);

    /** Grid Option Definitions **/
    const selectTitle = 'Select';
    const quickInfoTitle = 'QuickInfo';
    const selectCommands: CommandModel[] = [
        {
            title: selectTitle,
            buttonOption: {cssClass: 'e-outline select-icon' }
        }
    ];

    const quickInfoCommands: CommandModel[] = [
        {
            title: quickInfoTitle,
            buttonOption: {cssClass: 'e-outline info-icon' }
        }
    ];

    /** Value Accessor functions **/
    //  used to manipulate or format raw data before displaying it in the grid
    const serverAddressToDisplayAddress = (serverAddress: Address) => {
        return `${serverAddress.streetAddress}, ${serverAddress.city}, ${serverAddress.state} ${serverAddress.zip}`;
    };

    const serverNameToDisplayName = (fname: string, lname: string) => {
        return `${fname} ${lname}`;
    }

    const formatName: InternalValueAccessor<Consumer> = (_field, data) =>
        data.contactInfo.primary.firstName 
            ? serverNameToDisplayName(data.contactInfo.primary.firstName, data.contactInfo.primary.lastName)
            : '';


    const formatAddress: InternalValueAccessor<Consumer> = (_field, data) =>
        data.contactInfo.primary.address
            ? serverAddressToDisplayAddress(data.contactInfo.primary.address)
            : '';

    /** Grid event handler functions **/
    const onCommandClick = (args?: CommandClickEventArgs) => {
        if (args && args.rowData && args.commandColumn) {
            const consumerData = args.rowData as Consumer;
            switch (args.commandColumn.title) {
                case selectTitle:
                    navigateToConsumerPage(consumerData.id);
                    break;
                case quickInfoTitle:{
                    setQuickInfoPopupConsumer(consumerData);
                    setshowPopup(true);
                    break;}
            }
        }
    };
    const { currentView } = React.useContext(GridViewContext);
    let grid:Grid|null
    let dataBound = () =>{
        if(gridOptions.ref.current && currentView === 2)
        {
                gridOptions.ref.current.autoFitColumns([])
        }
    }
    return (
        <div>
            <GridComponent
                {...gridOptions}
                dataSource={props.consumers} 
               dataBound= { dataBound }
               className={`e-grid ram-grid ${currentView === 3 ? 'compact-grid' : 'consumer-grid'} ${currentView === 2 ?  'comfort-grid-view' : 'default-grid-view'}`}
                filterSettings={filterOptions}
                allowFiltering={false}
                allowSorting={false}
                clipMode='EllipsisWithTooltip'
                commandClick={onCommandClick}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        field="Select"
                        headerText=""
                        width="6%"
                        commands={selectCommands}
                        textAlign="Left"
                    />
                     <ColumnDirective
                        field="quickInfo"
                        headerText=""
                        width="6%"
                        commands={quickInfoCommands}
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="consumerID"
                        headerText="Acct"
                        width="10%"
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="contactInfo.primary.firstName"
                        valueAccessor={vaWrapper(formatName)}
                        headerText="Name"
                        width="15%"
                        textAlign="Left"
                    />                    
                    <ColumnDirective
                        field="email"
                        headerText="Email"
                        width="15%"
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="addDate"
                        valueAccessor={vaWrapper(formatDate)}
                        headerText="Date Added"
                        width="10%"
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="contactInfo.primary.address"
                        valueAccessor={vaWrapper(formatAddress)}
                        headerText="Address"
                        width="20%"
                        textAlign="Left"
                    />
                    <ColumnDirective
                        field="contactInfo.primary.phone"
                        headerText="Phone 1"
                        width="10%"
                        textAlign="Left"
                    />   
                </ColumnsDirective>
                <Inject services={[CommandColumn, PdfExport, ExcelExport, Toolbar, Filter, Sort, Resize]} />
            </GridComponent>
            {quickInfoPopupConsumer && (
                <ConsumerQuickInfoPopup
                    consumer={quickInfoPopupConsumer}
                    showDialog={showPopup}
                    setShowDialog={() => setshowPopup(false)}
                ></ConsumerQuickInfoPopup>
            )}
        </div>
    );
};

export default ConsumerSearchResultsGrid;
