import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '../../../../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../../../../types/common';

const ConsumerScheduleProcessModal = (props: ModalProps) => {
    return (
        <DialogComponent
            header="Schedule Information"
            isModal={true}
            target={props.targetId ? '#' + props.targetId : undefined}
            visible={props.showDialog}
            showCloseIcon={true}
            close={() => {
                props.setShowDialog(false);
            }}
            width="800px"
            height="auto"
        >
            <div>
                {props.showDialog && (
                    <div>
                        <div>
                            <h4>Building a Schedule</h4>
                            <p>
                                The major driving factors for a draft schedule in RAM are the total
                                monthly payment, number of drafts, and draft frequency.
                            </p>
                            <p>
                                To start, enter the payment start date (no earlier than 2 business
                                days from today), the draft frequency (such as monthly, bi-monthly,
                                etc), and the total monthly payment the consumer has requested.
                            </p>
                            <p>
                                Then, provide the total fee amount for each of your applicable fees
                                as well as the number of drafts over which fee is to be collected.
                                For example, under the attorney model you may have the first two
                                drafts to cover an attorney retainer fee (your fee 1).
                            </p>
                            <p>
                                In this case, you would set 500 as your total fee 1 amount, then set
                                2 as the fee 1 number of drafts. Since the draft is not weighted
                                (100% is collected over 2 drafts), you can leave the weight
                                percentage and weighted over number of drafts fields blank or zero.
                            </p>
                            <p>
                                Repeat the fee setup for the remaining applicable fees. For fees
                                that occur on every draft (such as maintenance fees), your number of
                                fee drafts should equal your number of drafts.
                            </p>
                            <p>
                                When you are done setting up fees, click Save. Then click Generate
                                Schedule. The system creates a schedule and displays it at the
                                bottom of the screen.
                            </p>
                        </div>
                        <div>
                            <h4>How Schedules are Generated</h4>
                            <p>
                                Using the information below the system takes the monthly payment you
                                have provided (for example, $750) then adds any fees you have
                                specified. If your total fee 1 amount is $500 split over 2 drafts,
                                each of your first two drafts will have a Fee 1 amount of $250 out
                                of a total payment of $750.
                            </p>
                            <p>
                                If you have a Fee 2 amount, that total amount is divided by the
                                number of Fee 2 drafts and the per-draft amount is generated. For
                                example, if your total Fee 2 amount is $2500 over the life of the
                                program (36 drafts for example) then the per-draft amount is $69.44
                                (with the final draft adjusted to be $69.60 to account for
                                rounding). Now, your first two drafts have both the Fee 1 amount
                                ($250) and the Fee 2 amount ($69.44).
                            </p>
                            <p>
                                Each month, the RAM fee is automatically added to that month's first
                                draft for each consumer. A typical RAM fee is $9.95 so that would
                                also be added to your two fee amounts. At this point, all leftover
                                money from the $750 monthly payment requested goes into Savings. So
                                the consumer's first two months look like: $750 total draft, with
                                $250 to Fee 1, $69.44 to Fee 2 and $9.95 to the RAM fee. This leaves
                                $420.61 going to savings for each month.
                            </p>
                            <p>
                                After the first two drafts, the Fee 1 (Retainer) is completely paid,
                                so that Fee amount drops off and now $670.61 of each draft is going
                                to Savings (and $69.44 to Fee 2 and $9.95 to RAM).
                            </p>
                            <p>
                                The system will allow your monthly payment to exceed the Total
                                Monthly Payment you specify if your fee amounts dictate a higher
                                monthly payment. If this happens, those months are displayed in red
                                in the generated schedule. For example, if your total monthly
                                payment was only $300, the combination of the Fee 1 amount in the
                                first two months, the Fee 2 amount, and the RAM Fee from the above
                                example would combine to be a total of $329.39 in fees each month.
                                This exceeds the $300 you specified, so the system will generate a
                                total monthly payment for those first two months of $329.39 with $0
                                going to savings and display those amounts in red. The system will
                                <b> not</b> adjust your total monthly payment below the minimum
                                required by the fee amounts you provide.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </DialogComponent>
    );
};

export default ConsumerScheduleProcessModal;
