import * as React from 'react';
import '../../../../styles/views/Company.scss';
import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { pagePath, topLevelPages } from '../../../../pages';
import NumericDropdown from '../../../common/NumericDropdown';
import { useToasts } from 'react-toast-notifications';
import { Affiliate, AffiliateUpdate } from '../../../../types/affiliate';
import { loadFromApi } from '../../../../api/baseApi';
import { getAffiliates, updateAffiliate } from '../../../../api/affiliateApi';
import LoadingDisplay from '../../../common/LoadingDisplay';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import UpdateAffiliateFormInputs from './UpdateAffiliateFormInputs';
import WrappedForm from '../../../common/form/WrappedForm';
import AffiliateUsersView from './AffiliateUsersView';
import { getReportTypes } from '../../../../api/reportApi';
import { ReportType } from '../../../../types/report';
import { UserAuthorizationContext } from '../../../UserAuthorizationService';
import { UserPermission } from '../../../../types/enums';

const AffiliatesPage = () => {
    const { userHasPermission } = useContext(UserAuthorizationContext);
    const hasViewAffiliatesPermissions = userHasPermission(UserPermission.ViewAffiliates);
    const history = useHistory();
    useEffect(() => {
        if (!hasViewAffiliatesPermissions) {
            history.push(`${pagePath(topLevelPages.home)}`);
        }
    }, [hasViewAffiliatesPermissions, history]);

    const [affiliates, setAffiliates] = useState<Affiliate[]>();
    const [affiliateReportTypes, setAffiliateReportTypes] = useState<ReportType[]>();
    const [selectedAffiliateId, setSelectedAffiliateId] = useState<number>();
    let selectedAffiliate = affiliates && affiliates.find(a => a.id === selectedAffiliateId);

    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const loadPageData = async () => {
            const apiWrapper = loadFromApi(setApiError);
            setLoading(true);
            const affiliatesLoad = apiWrapper(getAffiliates, setAffiliates);
            const affiliateReportTypesLoad = apiWrapper(
                () => getReportTypes(true),
                setAffiliateReportTypes
            );
            await affiliatesLoad;
            await affiliateReportTypesLoad;
            setLoading(false);
        };
        loadPageData();
    }, [setApiError]);

    const onSubmit = async (updateAffiliateFormData: AffiliateUpdate) => {
        const apiWrapper = loadFromApi(setApiError);
        const reloadPageData = async () => {
            await apiWrapper(getAffiliates, setAffiliates);
        };

        if (selectedAffiliateId) {
            setLoading(true);
            const updateAffiliateResponse = await updateAffiliate(
                selectedAffiliateId,
                updateAffiliateFormData
            );
            if (updateAffiliateResponse.error) {
                setApiError(updateAffiliateResponse.error);
            } else {
                reloadPageData();
            }
            setLoading(false);
        }
    };

    const getInitialFormValues = () => {
        return {
            printedName: selectedAffiliate ? selectedAffiliate.printedName : ''
        } as AffiliateUpdate;
    };

    return (
        <div className="page company-affiliates">
            <h2 className="page-title">Affiliates</h2>
            {loading ? (
                <LoadingDisplay />
            ) : !affiliates || !affiliateReportTypes ? (
                <LoadFailedDisplay />
            ) : (
                <div>
                    <NumericDropdown
                        options={affiliates.map(a => ({
                            value: a.id,
                            text: a.name
                        }))}
                        defaultOptionText={'Select Affiliate...'}
                        selectedValue={selectedAffiliateId}
                        setSelectedValue={setSelectedAffiliateId}
                    />
                    {selectedAffiliate && (
                        <WrappedForm<AffiliateUpdate>
                            onSubmit={onSubmit}
                            initialValues={getInitialFormValues()}
                        >
                            <UpdateAffiliateFormInputs affiliate={selectedAffiliate} />
                        </WrappedForm>
                    )}
                    {selectedAffiliate && (
                        <AffiliateUsersView
                            affiliate={selectedAffiliate}
                            affiliates={affiliates}
                            affiliateReportTypes={affiliateReportTypes}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AffiliatesPage;
