import { Report, ReportType, RunReportQueryParameters } from '../types/report';
import { httpRequest, Urls } from './baseApi';

const REPORT_URL = `${Urls.BASE_URL}/reports`;

export const getReportTypes = (affiliateOnly?: boolean) =>
    httpRequest<ReportType[]>(`${REPORT_URL}${affiliateOnly ? '?affiliateOnly=true' : ''}`);

export const runReport = (queryParams: RunReportQueryParameters) => {
    let queryString = `${queryParams.consumerId ? `consumerId=${queryParams.consumerId}&` : ''}`
        .concat(`${queryParams.startDate ? `StartDate=${queryParams.startDate}&` : ''}`)
        .concat(`${queryParams.endDate ? `EndDate=${queryParams.endDate}&` : ''}`)
        .concat(`${queryParams.affiliateId ? `AffiliateId=${queryParams.affiliateId}&` : ''}`)
        .concat(`${queryParams.feeGroupId ? `feeGroup=${queryParams.feeGroupId}&` : ''}`)
        .concat(
            `${queryParams.accountStatus ? `AccountStatus=${queryParams.accountStatus}&` : ''}`
        );
    // Remove trailing "&"
    if (queryString.length > 0) {
        queryString = queryString.substring(0, queryString.length - 1);
    }

    return httpRequest<Report>(
        `${REPORT_URL}/${queryParams.reportId}${queryString && `?${queryString}`}`,
        'get'
    );
};
