import * as React from 'react';
import DatePicker from '../../common/DatePicker';
import { DateRange } from '../../../types/common';
import { ReactNode } from 'react';

interface Props {
    dateRange: DateRange;
    changeDateRange?: (dateRange: DateRange) => void;
    pageName: string;
    children?: ReactNode;
}

const DashboardPageWrapper = (props: Props) => {
    return (
        <div>
            <div className="header">
                <div className="title">{props.pageName}</div>
                <div className="date-picker">
                    <DatePicker
                        isDateRange={true}
                        initialStartDate={props.dateRange.start}
                        initialEndDate={props.dateRange.end}
                        changeDateRange={props.changeDateRange}
                    />
                </div>
            </div>
            <div className="dashboard-charts"> {props.children}</div>
        </div>
    );
};

export default DashboardPageWrapper;
