import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { SettlementEdit } from '../../../../../types/settlements';
import StateDropdown from '../../../../common/form/StateDropdown';

export interface Props {
    viewOnly: boolean;
}

const BankAddressFormInputs = (props: Props) => {
    const { register, control } = useFormContext<SettlementEdit>();

    return (
        <div className="row">
            <div className="col-6">
                <WrappedInput<SettlementEdit> inputName="bankName" labelText="Payee Bank Name">
                    <input readOnly={props.viewOnly} {...register('bankName')} />
                </WrappedInput>
                <WrappedInput<SettlementEdit>
                    inputName="bankAddress.city"
                    labelText="Payee Bank City"
                >
                    <input readOnly={props.viewOnly} {...register('bankAddress.city')} />
                </WrappedInput>
            </div>
            <div className="col-6">
                <WrappedInput<SettlementEdit>
                    inputName="bankAddress.streetAddress"
                    labelText="Payee Bank Address"
                >
                    <input readOnly={props.viewOnly} {...register('bankAddress.streetAddress')} />
                </WrappedInput>
                <WrappedInput<SettlementEdit>
                    inputName="bankAddress.state"
                    labelText="Payee Bank State"
                >
                    <Controller
                        name="bankAddress.state"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <StateDropdown
                                required={false}
                                selectedState={value}
                                setSelectedState={onChange}
                                includePayeeTerritories={true}
                            />
                        )}
                    />
                </WrappedInput>
                <WrappedInput<SettlementEdit>
                    inputName="bankAddress.zip"
                    labelText="Payee Bank Zip"
                >
                    <input readOnly={props.viewOnly} {...register('bankAddress.zip')} />
                </WrappedInput>
            </div>
        </div>
    );
};

export default BankAddressFormInputs;
