import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import WrappedInput from '../../../../common/form/WrappedInput';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { expirationDateMask } from '../../../../../utils/masks';
import '../../../../../styles/Form.scss';
import '../../../../../styles/views/Consumer.scss';
import { CreditCardCreate } from '../../../../../types/bankingInformation';
import CreditCardTypeDropdown from './CreditCardTypeDropdown';
// import { validateCreditCard } from './cardValidate';
import '../../../../../styles/Form.scss';
import '../../../../../styles/views/Consumer.scss';
import { MAX_TEXT_BOX } from '../../../../../utils/formConstants';
import { validateCreditCard } from '../../../../../utils/validationRules';

const AddCardFormInputs = () => {
    const { register, control, watch } = useFormContext<CreditCardCreate>();

    const watchExpiry = watch('expiry');
    const watchCardNetwork = watch('cardNetwork');

    return (
        <div className="consumer-input-modal">
            <div className="row">
                <div className="col-6">
                    <WrappedInput<CreditCardCreate>
                        inputName="number"
                        labelText="Number"
                        required={true}
                    >
                        <input
                            className="input-large"
                            {...register('number', {
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: 'Card number must be numeric.'
                                },
                                maxLength: MAX_TEXT_BOX,
                                validate: (cardNumber: string) =>
                                    validateCreditCard(cardNumber, watchExpiry, watchCardNetwork),

                                required: 'Please enter a card number.'
                            })}
                        />
                    </WrappedInput>
                    <WrappedInput<CreditCardCreate>
                        inputName="expiry"
                        labelText="Expiration Date"
                        required={true}
                    >
                        <MaskedTextBoxComponent
                            {...register('expiry', {
                                required: `Please enter an expiration date.`
                            })}
                            mask={expirationDateMask}
                            placeholder="mm/yy"
                        />
                    </WrappedInput>
                    <WrappedInput<CreditCardCreate>
                        inputName="cardNetwork"
                        labelText="Card Type"
                        required={true}
                    >
                        <Controller
                            name="cardNetwork"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <CreditCardTypeDropdown
                                    selectedCardNetwork={value}
                                    setSelectedCardNetwork={onChange}
                                />
                            )}
                        />
                    </WrappedInput>
                </div>
            </div>

            <hr></hr>

            <div className="clearFix">
                <input className="btn btn-primary float-left" type="submit" value="Add" />
            </div>
        </div>
    );
};

export default AddCardFormInputs;
