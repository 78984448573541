import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getAffiliates } from '../../../../api/affiliateApi';
import { loadFromApi } from '../../../../api/baseApi';
import { getReportTypes } from '../../../../api/reportApi';
import { createUser, getUsers } from '../../../../api/userApi';
import { companyPages } from '../../../../pages';
import { Affiliate } from '../../../../types/affiliate';
import { ReportType } from '../../../../types/report';
import { User, UserCreate } from '../../../../types/user';
import WrappedForm from '../../../common/form/WrappedForm';
import LoadFailedDisplay from '../../../common/LoadFailedDisplay';
import LoadingDisplay from '../../../common/LoadingDisplay';
import AddUserFormInputs from './AddUserFormInputs';
import EditUserDisplay from './EditUserDisplay';

const CompanyUsersPage = () => {
    const [users, setUsers] = useState<User[]>();
    const [affiliates, setAffiliates] = useState<Affiliate[]>();
    const [reportTypes, setReportTypes] = useState<ReportType[]>();
    // defined: that user is selected. undefined: no user selected. null: new user
    const [selectedUserId, setSelectedUserId] = useState<string | undefined | null>();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    useEffect(() => {
        const loadPageData = async () => {
            const apiWrapper = loadFromApi(setApiError);
            setLoading(true);

            const usersLoad = apiWrapper(getUsers, setUsers);
            const affiliatesLoad = apiWrapper(getAffiliates, setAffiliates);
            const reportTypesRequest = apiWrapper(getReportTypes, setReportTypes);

            await reportTypesRequest;
            await usersLoad;
            await affiliatesLoad;

            setLoading(false);
        };

        loadPageData();
    }, [setApiError]);

    const selectedUser = users && users.find(u => u.userId === selectedUserId);

    const handleUserCreatedConfirmation = (createdUser: User) => {
        if (!users) {
            setSelectedUserId(undefined);
            return;
        }

        setUsers([...users, createdUser]);
        setSelectedUserId(createdUser.userId);
    };

    const addUser = (newUser: UserCreate) =>
        loadFromApi(setApiError, setLoading)(
            () => createUser(newUser),
            handleUserCreatedConfirmation
        );

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.currentTarget.value;
        setSelectedUserId(selectedValue || undefined);
    };

    return (
        <div className="page company-users">
            <h2 className="page-title">{companyPages.companyUsers.name}</h2>
            {loading ? (
                <LoadingDisplay />
            ) : 
            users && affiliates && reportTypes ? 
            (
                <div>
                    <div>
                        <select
                            className="dropdown-select"
                            value={selectedUserId || 0}
                            onChange={handleChange}
                        >
                            <option value={0}>Select user...</option>
                            {users.map((u, index) => (
                                <option key={index} value={u.userId}>
                                    {u.username}
                                </option>
                            ))}
                        </select>
                        {selectedUserId !== null && (
                            <button
                                className="btn btn-primary"
                                onClick={() => setSelectedUserId(null)}
                            >
                                Add User
                            </button>
                        )}
                    </div>
                    {selectedUserId === null && (
                        <WrappedForm<UserCreate> onSubmit={addUser}>
                            <AddUserFormInputs />
                            <div className="clear-fix">
                                <input
                                    className="btn btn-primary float-left"
                                    type="submit"
                                    value="Add"
                                />
                            </div>
                        </WrappedForm>
                    )}
                    {selectedUser && (
                        <EditUserDisplay
                            user={selectedUser}
                            updateUser={newSelectedUserValue => {
                                const usersCopy = [...users];
                                const selectedUserIndex = usersCopy.indexOf(selectedUser);
                                if (selectedUserIndex) {
                                    usersCopy.splice(selectedUserIndex, 1, newSelectedUserValue);
                                    setUsers(usersCopy);
                                }
                            }}
                            affiliates={affiliates}
                            reportTypes={reportTypes}
                        />
                    )}
                </div>
            ) : (
                <LoadFailedDisplay />
            )}
        </div>
    );
};

export default CompanyUsersPage;
