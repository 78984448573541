import * as React from 'react';
import { DateTime as LuxonDateTime } from 'luxon';
import DatePicker from './DatePicker';
import { getDefaultDateRange } from '../../utils/dateUtils';
import { DateRange } from '../../types/common';

interface DatePickerProps {
    initialDateRange?: DateRange;
    changeDateRange?: (dateRange: DateRange) => void;
    minimumDate?: LuxonDateTime;
    maximumDate?: LuxonDateTime;
}

const DateRangePickerFormWrapper = (props: DatePickerProps) => (
    <div className="date-picker">
        <DatePicker
            isDateRange={true}
            initialStartDate={
                (props.initialDateRange && props.initialDateRange.start) ||
                getDefaultDateRange().start
            }
            initialEndDate={
                (props.initialDateRange && props.initialDateRange.end) || getDefaultDateRange().end
            }
            changeDateRange={props.changeDateRange}
            minimumDate={props.minimumDate}
            maximumDate={props.maximumDate}
        />
    </div>
);

export default DateRangePickerFormWrapper;
