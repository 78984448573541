import { DashboardType } from '../enums/Dashboards';
import {
    DashboardData,
    DashboardReturn,
    DashboardStatistics,
    DashboardWarning,
    StateEnrollment
} from '../types/dashboard';
import { Consumer } from '../types/consumer';
import { httpRequest, Urls } from './baseApi';

const DASHBOARD_URL = `${Urls.BASE_URL}/dashboard`;
const MY_SETTINGS_URL = `${Urls.BASE_URL}/mySettings`;

export const getDashbardWarnings = () =>
    httpRequest<DashboardWarning[]>(`${DASHBOARD_URL}/Warnings`, 'get');

export const clearDashbardWarning = (warningId: number) =>
    httpRequest<void>(`${DASHBOARD_URL}/Warnings/${warningId}`, 'delete');

export const getDashboardStatistics = (startDate: string, endDate: string, isIndustry: boolean) =>
    httpRequest<DashboardStatistics>(
        `${DASHBOARD_URL}/statistics/?startDate=${startDate}&endDate=${endDate}&isIndustry=${isIndustry}`,
        'get'
    );

export const getDashboardReturns = () =>
    httpRequest<DashboardReturn[]>(`${DASHBOARD_URL}/returns`, 'get');

export const getDashboardData = (    
    dashboardType: DashboardType,
    startDate: string,
    endDate: string,
    isIndustry: boolean
) =>
    httpRequest<DashboardData[]>(
        `${DASHBOARD_URL}/data?type=${dashboardType}&startDate=${startDate}&endDate=${endDate}&isIndustry=${isIndustry}`,
        'get'
    );

export const getDashboardNewConsumers = () =>
    httpRequest<Consumer[]>(`${DASHBOARD_URL}/newConsumers`, 'get');

export const getStateData = () => httpRequest<StateEnrollment[]>(`${DASHBOARD_URL}/states`, 'get');

export const getDashboardSelectedControls = () =>
    httpRequest<number[] | null>(`${MY_SETTINGS_URL}/dashboardControls`, 'get');

export const updateDashboardSelectedControls = (dashboardTypes: number[]) =>
    httpRequest<{}>(`${MY_SETTINGS_URL}/dashboardControls`, 'put', dashboardTypes);
